/* eslint-disable */
import {
  GraphQLResolveInfo,
  GraphQLScalarType,
  GraphQLScalarTypeConfig,
} from 'graphql';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = Omit<T, K> & {
  [P in K]-?: NonNullable<T[P]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** Language Code in ISO 2-letter format */
  LanguageCodeIso: any;
  /** The `SafeInt` scalar type represents non-fractional signed whole numeric values that are considered safe as defined by the ECMAScript specification. */
  SafeInt: any;
}

export interface AccessCvInput {
  connectionType: TalentSearchConnectionType;
  profileId: Scalars['Int'];
  advertiserEmail: Scalars['String'];
}

export interface AccessEmailReceiverInput {
  /** Email of the user */
  email: Scalars['String'];
  /** First name of the user */
  firstName: Scalars['String'];
  /** User ID only used for logging/audit purposes */
  auditUserId: Scalars['String'];
}

export enum AccessRightFilter {
  Active = 'ACTIVE',
  Pending = 'PENDING',
  Deactivated = 'DEACTIVATED',
  AdminRole = 'ADMIN_ROLE',
  UserRole = 'USER_ROLE',
}

export enum AccessRightStatus {
  /** User association with advertiser is active. */
  Active = 'ACTIVE',
  /** User association with advertiser has been deactivated (equivalent to deleted). */
  Deactivated = 'DEACTIVATED',
  /** Awaiting email verification. */
  Pending = 'PENDING',
}

export enum AccountActivationStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING',
}

export interface AccountBillingAddressInput {
  /** Address lines of where the Advertiser would like billings to go to */
  addressLines?: Maybe<Array<Scalars['String']>>;
  /** Address line of where the Advertiser would like billings to go to */
  addressLine1?: Maybe<Scalars['String']>;
  /** Additional address line of where the advertiser would like their billing to go to. Can be left blank if it is not needed */
  addressLine2?: Maybe<Scalars['String']>;
  /** Suburb in which the Advertiser would like billings to go to */
  suburb: Scalars['String'];
  /**
   * State in which the Advertiser would like billings to go to.
   * Can be NULL if their billing address is a country outside of Australia
   */
  state?: Maybe<Scalars['String']>;
  /** Postcode in which the Advertiser would like billings to go to */
  postCode?: Maybe<Scalars['String']>;
  /** Country code of where the Advertiser would like billings to go to */
  countryCodeAlpha2: Scalars['String'];
  /** Seek billing account ID used by CRM */
  seekBillingAccountId?: Maybe<Scalars['String']>;
  /** Seek ID */
  seekId?: Maybe<Scalars['String']>;
}

export interface AccountBillingEmailAddressInput {
  /** Email address to send invoices, outstanding balance notifications etc. */
  billingEmail: Scalars['String'];
  /** Seek billing account ID used by CRM */
  seekBillingAccountId?: Maybe<Scalars['String']>;
  /** Seek ID used in Hirer Accounts API. */
  seekId?: Maybe<Scalars['String']>;
}

export interface AccountDetailsUpdateResponse {
  /** Response message we receive from calling the account/details API */
  message?: Maybe<Scalars['String']>;
}

export interface AccountHierarchyOption {
  key: Scalars['Int'];
  value: Scalars['String'];
  count: Scalars['Int'];
}

export interface AccountPostalAddressInput {
  /** Boolean to signify if the user would the postal address to be the same as the billing address */
  useForBillingAddress: Scalars['Boolean'];
  /** Address lines of where the Advertiser resides */
  addressLines?: Maybe<Array<Scalars['String']>>;
  /** Address line of where the Advertiser resides */
  addressLine1?: Maybe<Scalars['String']>;
  /** Additional address line of where the advertiser resides. Can be left blank if it is not needed */
  addressLine2?: Maybe<Scalars['String']>;
  /** Suburb in which the Advertiser resides */
  suburb: Scalars['String'];
  /**
   * State in which the Advertiser resides
   * Can be NULL if their postal address is a country outside of Australia
   */
  state?: Maybe<Scalars['String']>;
  /** Postcode in which the Advertiser resides */
  postCode?: Maybe<Scalars['String']>;
  /** Country code of where the Advertiser resides */
  countryCodeAlpha2: Scalars['String'];
  /** Seek billing account ID used by CRM */
  seekBillingAccountId?: Maybe<Scalars['String']>;
  /** Seek ID */
  seekId?: Maybe<Scalars['String']>;
}

export interface AccountPrimaryContactInput {
  /** First name of the person we are assigning to be the primary contact */
  firstName: Scalars['String'];
  /** Last name of the person we are assigning to be the primary contact */
  lastName: Scalars['String'];
  /** Email of the person we are assigning to be the primary contact */
  email: Scalars['String'];
  /** Phone number of the person we are assigning to be the primary contact */
  phone: Scalars['String'];
  /** Seek billing account ID used by CRM */
  seekBillingAccountId?: Maybe<Scalars['String']>;
  /** Seek ID */
  seekId?: Maybe<Scalars['String']>;
}

export interface AccountSelectionEdge {
  node: AdvertiserAccountOption;
}

export interface AccountSelectionOptions {
  /** Default account for automatic sign-in, bypassing account selection. */
  defaultAccount?: Maybe<AdvertiserAccountOption>;
  /** List of advertiser accounts to which a user has access. */
  accounts?: Maybe<Array<AdvertiserAccountOption>>;
}

export interface AccountSelectionQueryInput {
  /** Return uri for successful account switching. */
  returnUri?: Maybe<Scalars['String']>;
}

export interface AccountSelectionResults {
  totalCount: Scalars['Int'];
  defaultAccount?: Maybe<AdvertiserAccountOption>;
  edges: Array<AccountSelectionEdge>;
}

export type AccountSelectionV2Response =
  | AccountSelectionResults
  | ResponseError;

export interface ActionLink {
  viewProfile?: Maybe<Scalars['String']>;
}

export interface Actor {
  /** The Auth0 user id of the actor, eg 'samlp|seek-okta-global|tkilvington@seek.com.au'. */
  id: Scalars['String'];
  /** Email address of the actor. */
  email: Scalars['String'];
}

export interface ActorContext {
  /** Email address of the CS user. */
  operatorEmail: Scalars['String'];
  /** Url to toggle customer service mode. */
  csToggleHref: Scalars['String'];
  /**
   * Text of CS toggle button.
   * @deprecated Text defined in client for translations purposes. Can use isCsView.
   */
  csToggleText?: Maybe<Scalars['String']>;
  /** Whether the actor is in Customer Service View. */
  isCsView: Scalars['Boolean'];
}

export interface Ad extends Product {
  name: Scalars['String'];
  productType: ProductType;
  adType: AdType;
  metadata: AdMetaData;
  price?: Maybe<Price>;
  /** To show or hide the price */
  hidePrice: Scalars['Boolean'];
}

export interface AdBudget extends Product {
  name: Scalars['String'];
  productType: ProductType;
  value: Money;
  metadata: AdBudgetMetadata;
  id: Scalars['ID'];
  ads: AdBudgetType;
}

export interface AdBudgetAttributes {
  /** Id of selected Ad Budget. */
  budgetId: Scalars['ID'];
}

export interface AdBudgetMetadata {
  /** Title used when displaying this product as a line item of a purchase */
  displayItemTitle: Scalars['String'];
  /** The ad posting frequency of budgets */
  adPostingFrequency: Scalars['String'];
  /** Approximate amount to convey the savings on ads as a percentage */
  percentageSavings: Scalars['Float'];
  /** Length of the contract in months */
  discountDurationMonths: Scalars['Int'];
  /** Set to true when a discount is offered on standOut ads */
  hasStandOutDiscount: Scalars['Boolean'];
}

export interface AdBudgetProduct extends CatalogueProduct {
  /** Unique ID of the adbudget product */
  id: Scalars['ID'];
  /** name of the adbudget product */
  name: Scalars['String'];
  /** Price of the adbudget product, or price access level (priceVisibility) if hidePrice is enabled for the hirer */
  price: CatalogueProductPrice;
  /** Features of the adbudget product */
  features: Array<Maybe<CatalogueProductFeature>>;
  /** Display label for Ad posting frequency. Eg Occasional, Regular, Frequent. */
  adPostingFrequency: Scalars['String'];
  /** Display label for Ad posting range. */
  adRange: Range;
  /** List of short labels describing products that can be purchased from the budget */
  purchasableProducts: PurchasableProducts;
}

export interface AdBudgetProductList {
  /** A list of ad budget products */
  items: Array<AdBudgetProduct>;
}

export interface AdBudgetProductsForUpsellInput {
  /** Unique ID of the product set */
  productSetId: Scalars['ID'];
  /** List of product ids */
  productIds: Array<Scalars['ID']>;
  /** Id of the advertiser. */
  advertiserId?: Maybe<Scalars['ID']>;
}

export type AdBudgetProductsForUpsellResponse =
  | AdBudgetProductList
  | ProductSetExpiredError
  | ProductSetNotFoundError;

export interface AdBudgetResponse {
  /** List of ad budgets. */
  adBudgets: Array<AdBudget>;
}

export enum AdBudgetType {
  ClassicOnwards = 'CLASSIC_ONWARDS',
  BrandedOnwards = 'BRANDED_ONWARDS',
  PremiumOnly = 'PREMIUM_ONLY',
}

export interface AdBudgetsByAdvertiserInput {
  advertiserId?: Maybe<Scalars['ID']>;
  /**
   * ISO Alpha-2 country code based on the advertiser's billing address.
   * This is useful to ensure we surface the budgets matching the hirer's location
   * and we may not have their billing address in the system yet. Eg: when new NZ hirers
   * who register and create a Job at the same time on AU website. The NZ budgets will be
   * surfaced on AU site as upsell only if this param is populated by client.
   */
  advertiserNation?: Maybe<Scalars['String']>;
}

export interface AdMetaData {
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  theme?: Maybe<AdTypeTheme>;
  subTitle?: Maybe<SubTitle>;
  highlightText?: Maybe<Scalars['String']>;
  features?: Maybe<Array<Maybe<ProductFeature>>>;
  extendsAdType?: Maybe<AdType>;
}

export interface AdPerformanceReportResponse {
  hirerOriginZone: Scalars['String'];
  hirerId: Scalars['Int'];
}

export interface AdPerformanceReportWidgetInput {
  jobId: Scalars['String'];
}

export interface AdProduct extends CatalogueProduct {
  id: Scalars['ID'];
  /** @deprecated Avoid use of this field and consider displayMetadata.name to display for customers, or use 'features' to make decisions instead */
  name: Scalars['String'];
  price: CatalogueProductPrice;
  pricing?: Maybe<Pricing>;
  features: Array<Maybe<CatalogueProductFeature>>;
  /** Upgrade paths available for the product */
  upgradePaths: Array<UpgradePath>;
  /** Product information to display to customers */
  displayMetadata?: Maybe<AdProductDisplayMetadata>;
}

export interface AdProductDisplayMetadata {
  /** Product name that can be shown to customers (for example in an ad ladder) */
  name?: Maybe<Scalars['String']>;
  /** Tagline that can be shown to customers (for example in an ad ladder) */
  tagline?: Maybe<Scalars['String']>;
  /** Selling points or features of a product */
  sellingPoints?: Maybe<Array<SellingPoint>>;
  /** Image to help describe an upgrade product */
  image?: Maybe<Image>;
  /** Description of upgrade product price */
  priceDescription?: Maybe<Scalars['String']>;
  /** Detailed name where more context or formality is required (e.g. Order summaries, invoicing, CRM) */
  qualifiedName?: Maybe<Scalars['String']>;
  /** Url to redirect to the product booking form */
  redirectUrl?: Maybe<Scalars['String']>;
}

export type AdProductSetResponse =
  | ProductList
  | ProductSetExpiredError
  | ProductSetNotFoundError
  | DisplayMetadataError;

export interface AdProductUpgrade extends CatalogueProduct {
  id: Scalars['ID'];
  name: Scalars['String'];
  price: CatalogueProductPrice;
  features: Array<Maybe<CatalogueProductFeature>>;
  displayMetadata?: Maybe<AdProductDisplayMetadata>;
}

export interface AdProductsForAdPostingInput {
  /** Unique ID of the product set */
  productSetId: Scalars['ID'];
  /** Id of the advertiser. */
  advertiserId?: Maybe<Scalars['ID']>;
  /** The locale to create product set */
  locale?: Maybe<Scalars['String']>;
}

export interface AdRatingLabels {
  groupLabel?: Maybe<Scalars['String']>;
  locationLabel?: Maybe<Scalars['String']>;
}

export interface AdRatingResponse {
  adRatingConfidence?: Maybe<AnalyticsAdRatingConfidence>;
  adRatingScore?: Maybe<AnalyticsAdRatingScore>;
  applications: Scalars['Int'];
  applicationsBenchmarkHigh: Scalars['Int'];
  applicationsBenchmarkLow: Scalars['Int'];
  benchmarkLocationLabel?: Maybe<Scalars['String']>;
  benchmarkScenario?: Maybe<AnalyticsBenchmarkScenario>;
  classificationName: Scalars['String'];
  incompleteApplications: Scalars['Int'];
  isLinkOutAd: Scalars['Boolean'];
  jobCreatedTimestampUTC: Scalars['DateTime'];
  lastUpdatedTimestampUTC: Scalars['DateTime'];
  normalisedRoleTitle?: Maybe<Scalars['String']>;
  subClassificationName: Scalars['String'];
}

export enum AdRatingType {
  High = 'HIGH',
  Normal = 'NORMAL',
  Low = 'LOW',
  TooSoonToRate = 'TOO_SOON_TO_RATE',
  UnableToRate = 'UNABLE_TO_RATE',
}

export interface AdRatingTypeOption {
  key: FiltersAdRatingType;
  count: Scalars['Int'];
}

export interface AdRatingWidgetInput {
  jobId: Scalars['String'];
  hirerOriginZone: Scalars['String'];
  locale: Scalars['String'];
}

export enum AdRepost {
  NotRepost = 'NOT_REPOST',
  Repost = 'REPOST',
}

/** AdBudgetProduct feature to indicate ad spend allowance */
export interface AdSpendFeature extends CatalogueProductFeature {
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
  /** Money allowance to purchase ad products */
  allowance: Money;
}

export interface AdStatusOption {
  key: AdUsageAdStatus;
  count: Scalars['Int'];
}

export interface AdTipsOption {
  key: AdTipsType;
  count: Scalars['Int'];
}

export enum AdTipsType {
  ReviewAdSummary = 'REVIEW_AD_SUMMARY',
  ReviewAdDetails = 'REVIEW_AD_DETAILS',
  RevealSalary = 'REVEAL_SALARY',
  IncreaseSalary = 'INCREASE_SALARY',
  AddSpecificLocation = 'ADD_SPECIFIC_LOCATION',
  MaximiseBranding = 'MAXIMISE_BRANDING',
  AddQuestions = 'ADD_QUESTIONS',
  NoTips = 'NO_TIPS',
}

export enum AdType {
  Classic = 'CLASSIC',
  Branded = 'BRANDED',
  Premium = 'PREMIUM',
  BrandedUpgrade = 'BRANDED_UPGRADE',
  Guaranteed = 'GUARANTEED',
  Concierge = 'CONCIERGE',
}

export interface AdTypeAverageCost {
  type: AdUsageAdType;
  cost: Money;
}

/** Discount for Ad Product by legacy AdType */
export interface AdTypeDiscount {
  /** Type of the ad. Eg CLASSIC, BRANDED, PREMIUM */
  adType: AdType;
  /** Discount in absolute money amount or percentage */
  discount: Discount;
}

/** AdBudgetProduct feature to determine discounts for Ad products. */
export interface AdTypeDiscountsFeature extends CatalogueProductFeature {
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
  /** Discounts applicable to the different Ad Products */
  discounts: Array<AdTypeDiscount>;
}

export interface AdTypeOption {
  key: AdUsageAdType;
  count: Scalars['Int'];
}

export interface AdTypePostCount {
  type: AdUsageAdType;
  adsCount: Scalars['Int'];
}

export enum AdTypeTheme {
  Primary = 'PRIMARY',
  Basic = 'BASIC',
}

export enum AdUsageAdStatus {
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
}

export enum AdUsageAdType {
  Basic = 'BASIC',
  Classic = 'CLASSIC',
  Premium = 'PREMIUM',
  Branded = 'BRANDED',
  Standout = 'STANDOUT',
}

export interface AdUsageAdTypeTrendInput {
  locale: Scalars['String'];
  timezone: Scalars['String'];
  filters: AdUsageQueryFilters;
}

export interface AdUsageAdTypeTrendResponse {
  daily: Array<AdUsageTrendDaily>;
  monthly: Array<AdUsageTrendMonthly>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  totalAdTypes?: Maybe<Array<Maybe<TrendsAdTypeOption>>>;
}

export interface AdUsageConversionResponse {
  jobSearchViews: ConversionRateData;
  adClicks: ConversionRateData;
  applicationsStarted: ConversionRateData;
  applicationsCompleted: ConversionRateData;
  adsWithoutRatingPercentage?: Maybe<Scalars['Float']>;
  linkOutAds: Scalars['Float'];
}

export interface AdUsageConversionWidgetInput {
  filters: AdUsageQueryFilters;
}

export interface AdUsageFiltersResponse {
  locations: Array<Maybe<LocationOption>>;
  classifications: Array<Maybe<ClassificationOption>>;
  adIds: Array<Scalars['String']>;
  adTypes: Array<Maybe<AdTypeOption>>;
  jobTitles: Array<Maybe<JobTitleOption>>;
  users: Array<Maybe<UserOption>>;
  adRatings: Array<Maybe<AdRatingTypeOption>>;
  accountHierarchies: Array<Maybe<AccountHierarchyOption>>;
  adStatus: Array<Maybe<AdStatusOption>>;
  tips: Array<Maybe<AdTipsOption>>;
  reposts: Array<Maybe<RepostOption>>;
  isChildAccount: Scalars['Boolean'];
}

export interface AdUsageFiltersWidgetInput {
  locale: Scalars['String'];
  filters: AdUsageQueryFilters;
}

export interface AdUsageQueryFilters {
  locationIds: Array<Maybe<Scalars['Int']>>;
  subClassificationIds: Array<Maybe<Scalars['Int']>>;
  adTypes: Array<Maybe<AdUsageAdType>>;
  jobTitles: Array<Maybe<Scalars['String']>>;
  userHashes: Array<Maybe<Scalars['String']>>;
  postingDates: AdUsageQueryFiltersDates;
  adStatuses: Array<Maybe<AdUsageAdStatus>>;
  adRatings: Array<Maybe<FiltersAdRatingType>>;
  adIds: Array<Maybe<Scalars['Int']>>;
  accountIds: Array<Maybe<Scalars['Int']>>;
  adTips: Array<Maybe<AdTipsType>>;
  repostTypes: Array<Maybe<AdRepost>>;
}

export interface AdUsageQueryFiltersDates {
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
}

export interface AdUsageSpendResponse {
  totalPostByAdTypes?: Maybe<Array<Maybe<AdTypePostCount>>>;
  totalSpend?: Maybe<Money>;
  averageCostByAdTypes?: Maybe<Array<Maybe<AdTypeAverageCost>>>;
  averageApplications?: Maybe<Scalars['Int']>;
  averageCostPerAd?: Maybe<Money>;
  hidePrice: Scalars['Boolean'];
  totalApplications?: Maybe<Scalars['Int']>;
  averageCostPerApplication?: Maybe<Money>;
  repostedAdsCount?: Maybe<Scalars['Int']>;
  repostedAdsSpend?: Maybe<Money>;
  otherCurrencyAdsCount?: Maybe<Scalars['Int']>;
  totalAds: Scalars['Int'];
}

export interface AdUsageSpendWidgetInput {
  filters: AdUsageQueryFilters;
  siteCurrency?: Maybe<Scalars['String']>;
}

export interface AdUsageTrendClassification {
  classificationName: Scalars['String'];
  totalAds: Scalars['Int'];
  percentage: Scalars['Float'];
}

export interface AdUsageTrendClassificationsAndUsersInput {
  locale: Scalars['String'];
  filters: AdUsageQueryFilters;
}

export interface AdUsageTrendClassificationsAndUsersResponse {
  classifications: Array<AdUsageTrendClassification>;
  users: Array<AdUsageTrendUser>;
}

export interface AdUsageTrendDaily {
  items?: Maybe<Array<Maybe<TrendsAdTypeOption>>>;
  day: Scalars['String'];
}

export interface AdUsageTrendMonthly {
  items?: Maybe<Array<Maybe<TrendsAdTypeOption>>>;
  month: Scalars['String'];
}

export interface AdUsageTrendUser {
  userFullname?: Maybe<Scalars['String']>;
  totalAds: Scalars['Int'];
  percentage: Scalars['Float'];
}

export interface AddQuestions extends TipToImproveAds {
  type: TipsToImproveAdsType;
  adCount: Scalars['Int'];
  questions?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface AddSpecificLocation extends TipToImproveAds {
  type: TipsToImproveAdsType;
  adCount: Scalars['Int'];
  specificLocation: Scalars['String'];
  nonSpecificLocation: Scalars['String'];
}

export interface AddUserToAccountInput {
  /** First name of the person we are wanting to add to the account */
  firstName: Scalars['String'];
  /** Last name of the person we are wanting to add to the account */
  lastName: Scalars['String'];
  /** Email of the person we are wanting to add to the account */
  email: Scalars['String'];
  /** The audience zone of the site that this user originally registered on */
  originZone: Scalars['String'];
}

export interface AddUserToAccountResponse {
  /** Current status ID of the newly created user */
  statusId: Scalars['String'];
  /** User ID of the newly created user */
  userId: Scalars['String'];
  /** Email of the person we are wanting to add to the account */
  email: Scalars['String'];
  /** First name of the person we are wanting to add to the account */
  firstName: Scalars['String'];
  /** Last name of the person we are wanting to add to the account */
  lastName: Scalars['String'];
  /** The create date of the newly created user */
  createdDateUtc: Scalars['String'];
  lastDailyCandidateMailDateUtc?: Maybe<Scalars['String']>;
  lastWeeklyCandidateMailDateUtc?: Maybe<Scalars['String']>;
  links: Array<Link>;
}

export interface AdvertiserAccount {
  name: Scalars['String'];
  advertiserId: Scalars['Int'];
  seekAdvertiserId: Scalars['Int'];
  isOnCreditHold: Scalars['Boolean'];
  isApproved: Scalars['Boolean'];
  country: Scalars['String'];
  seekBillingAccountId: Scalars['String'];
  status: Scalars['String'];
  agencyAdvertiserId?: Maybe<Scalars['Int']>;
  hasPremiumTalentSearch: Scalars['Boolean'];
  accessRightStatus: Scalars['String'];
  role: Scalars['String'];
  hasPostalAddress: Scalars['Boolean'];
}

export interface AdvertiserAccountOption {
  /** Name of the advertiser account. */
  name: Scalars['String'];
  /** Text to display on account links. Combines account name and billing id (if present). */
  displayText: Scalars['String'];
  /** ID of the advertiser account. */
  advertiserId: Scalars['ID'];
  /** SeekWeb Advertiser ID. */
  seekId: Scalars['ID'];
  /** Optional billing ID provided by CRM. */
  billingId?: Maybe<Scalars['String']>;
  /** Child accounts, applicable to agencies. Will be visually nested during account selection. */
  childAccounts: Array<AdvertiserAccountOption>;
  /** The Hirer Account Status */
  hirerAccountStatus: AdvertiserStatus;
  /** Status of access right connecting the user to the advertiser. */
  accessRightStatus: AccessRightStatus;
  /** Todo: Make me an enum. */
  role: Role;
}

export interface AdvertiserHeaderContext {
  /** Name of the advertiser. */
  name: Scalars['String'];
  /** Billing ID */
  billingId: Scalars['String'];
  /** Array of permissions for a user on behalf of the advertiser. */
  permissions: Array<Permission>;
  /** Indicates whether the account is on credit hold. */
  showOnCreditHoldMessage: Scalars['Boolean'];
  /** Url to switch accounts. Will be null if user has a single account. */
  switchAccountsHref?: Maybe<Scalars['String']>;
  /** Indicates whether the user has multiple accounts. Should really be part of the user payload :thinking-face: */
  hasMultipleAccounts: Scalars['Boolean'];
  /** Indicates whether the user's activation is pending for this account. */
  isActivationPending: Scalars['Boolean'];
  /** Indicates whether the advertiser is an agency account. */
  isAgency: Scalars['Boolean'];
}

export enum AdvertiserStatus {
  /** A freshly migrated/created advertiser. */
  New = 'NEW',
  /** A deactivated advertiser. */
  Deactivated = 'DEACTIVATED',
  /** Awaiting primary contact registration. */
  Pending = 'PENDING',
  /** An active advertiser. */
  Active = 'ACTIVE',
  /** Awaiting advertiser account security link activation. */
  PendingActivation = 'PENDING_ACTIVATION',
}

export interface AhpraRegistration {
  profession: Scalars['String'];
  specialty: Scalars['String'];
  type: Scalars['String'];
  subType: Scalars['String'];
}

export interface AnalyticsAdRating {
  status: AnalyticsAdRatingStatus;
  score?: Maybe<AnalyticsAdRatingScore>;
}

export enum AnalyticsAdRatingConfidence {
  Low = 'LOW',
  High = 'HIGH',
}

export enum AnalyticsAdRatingScore {
  Low = 'LOW',
  Normal = 'NORMAL',
  High = 'HIGH',
}

export enum AnalyticsAdRatingStatus {
  AvailableWithRating = 'AVAILABLE_WITH_RATING',
  AvailablePendingRating = 'AVAILABLE_PENDING_RATING',
  AvailableCannotRate = 'AVAILABLE_CANNOT_RATE',
  Unavailable = 'UNAVAILABLE',
  Withheld = 'WITHHELD',
  Archived = 'ARCHIVED',
  Pending = 'PENDING',
  NotApplicable = 'NOT_APPLICABLE',
  Errored = 'ERRORED',
}

export enum AnalyticsAdType {
  Branded = 'BRANDED',
  Classic = 'CLASSIC',
  Premium = 'PREMIUM',
  Standout = 'STANDOUT',
  AutoInclude = 'AUTO_INCLUDE',
  Basic = 'BASIC',
}

export enum AnalyticsBenchmarkScenario {
  Exact = 'EXACT',
  Ideal = 'IDEAL',
  ExactRegional = 'EXACT_REGIONAL',
  IdealRegional = 'IDEAL_REGIONAL',
  ExactCountry = 'EXACT_COUNTRY',
  IdealCountry = 'IDEAL_COUNTRY',
  SubclassLocation = 'SUBCLASS_LOCATION',
  SubclassRegional = 'SUBCLASS_REGIONAL',
  SubclassCountry = 'SUBCLASS_COUNTRY',
  Country = 'COUNTRY',
}

export interface AnalyticsLatestJobs {
  jobCreatedTimestampUTC: Scalars['DateTime'];
  jobId: Scalars['String'];
  jobLocationLabel: Scalars['String'];
  jobTitle: Scalars['String'];
  userFullName?: Maybe<Scalars['String']>;
}

export interface AnalyticsMarketRoleInsightsJobTitlesInput {
  dataSource?: Maybe<Scalars['String']>;
}

export interface AnalyticsMarketRoleInsightsJobTitlesResult {
  results?: Maybe<Array<Maybe<AnalyticsMarketRoleInsightsSuggestion>>>;
}

export interface AnalyticsMarketRoleInsightsLocationsInput {
  dataSource?: Maybe<Scalars['String']>;
}

export interface AnalyticsMarketRoleInsightsLocationsResult {
  results?: Maybe<Array<Maybe<AnalyticsMarketRoleInsightsSuggestion>>>;
}

export interface AnalyticsMarketRoleInsightsSearchInput {
  dataSource?: Maybe<Scalars['String']>;
  locationIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  normalizedEntityIds?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface AnalyticsMarketRoleInsightsSearchResult {
  results?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface AnalyticsMarketRoleInsightsSuggestion {
  count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
}

export enum AnalyticsPostTypeCategory {
  Direct = 'DIRECT',
  Indirect = 'INDIRECT',
}

export enum AnalyticsSalaryType {
  AnnualCommission = 'ANNUAL_COMMISSION',
  AnnualPackage = 'ANNUAL_PACKAGE',
  CommissionOnly = 'COMMISSION_ONLY',
  Monthly = 'MONTHLY',
  Hourly = 'HOURLY',
}

export interface AnalyticsTalentSearchUsageAccount {
  key: Scalars['ID'];
  value: Scalars['String'];
  count: Scalars['Int'];
}

export interface AnalyticsTalentSearchUsageCreditsUsage {
  userHash: Scalars['ID'];
  userFullName: Scalars['String'];
  profileSearchCount: Scalars['Int'];
  profileViewCount: Scalars['Int'];
  cvDownloadCount: Scalars['Int'];
  sendMessageCount: Scalars['Int'];
  sendJobCount: Scalars['Int'];
  totalCount: Scalars['Int'];
  cvDownloadCredits: Scalars['Int'];
  sendMessageCredits: Scalars['Int'];
  sendJobCredits: Scalars['Int'];
  totalCredits: Scalars['Int'];
}

export interface AnalyticsTalentSearchUsageCreditsUsageListInput {
  pageNumber: Scalars['Int'];
  size: Scalars['Int'];
  filters: AnalyticsTalentSearchUsageFilters;
  locale: Scalars['String'];
}

export interface AnalyticsTalentSearchUsageCreditsUsageListResponse {
  userCount: Scalars['Int'];
  creditsUsageList: Array<AnalyticsTalentSearchUsageCreditsUsage>;
}

export interface AnalyticsTalentSearchUsageDateRangeInput {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}

export interface AnalyticsTalentSearchUsageFilters {
  userHashes: Array<Maybe<Scalars['ID']>>;
  usageDates: AnalyticsTalentSearchUsageDateRangeInput;
  accountIds: Array<Maybe<Scalars['ID']>>;
}

export interface AnalyticsTalentSearchUsageFiltersInput {
  locale: Scalars['String'];
  filters: AnalyticsTalentSearchUsageFilters;
}

export interface AnalyticsTalentSearchUsageFiltersResponse {
  users: Array<Maybe<AnalyticsTalentSearchUsageUser>>;
  accounts: Array<Maybe<AnalyticsTalentSearchUsageAccount>>;
}

export interface AnalyticsTalentSearchUsageLastUpdatedResponse {
  lastUpdated: Scalars['DateTime'];
}

export interface AnalyticsTalentSearchUsageUser {
  key: Scalars['ID'];
  value: Scalars['String'];
  count: Scalars['Int'];
}

export enum AnalyticsWorkType {
  Casual = 'CASUAL',
  Contract = 'CONTRACT',
  FullTime = 'FULL_TIME',
  PartTime = 'PART_TIME',
}

export interface ApplicationPerformanceResponse {
  applications: Scalars['Int'];
  isLinkOutAd: Scalars['Boolean'];
  adRatingConfidence: AnalyticsAdRatingConfidence;
  mobileViewsInPercentage: Scalars['Float'];
  similarAdsInPercentage?: Maybe<Scalars['Float']>;
  similarAdsAverage?: Maybe<Scalars['Int']>;
  hasAdRating: Scalars['Boolean'];
}

export interface ApplicationPerformanceWidgetInput {
  jobId: Scalars['String'];
  hirerOriginZone: Scalars['String'];
}

export interface AssociatedAction {
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
  job?: Maybe<InteractedJob>;
}

export interface AssociatedParty {
  organizationName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  formattedName?: Maybe<Scalars['String']>;
}

/**
 * Country ID - ['bca', 'bni', 'bri', 'cimb', 'mandiri']
 * Country TH - ['kbank', 'scb', 'bbl', 'ktb', 'bay', 'tmb', 'uob']
 * Country PH - ['bpi', 'unionbank']
 */
export enum Bank2C2P {
  Bca = 'bca',
  Bni = 'bni',
  Bri = 'bri',
  Cimb = 'cimb',
  Mandiri = 'mandiri',
  Kbank = 'kbank',
  Scb = 'scb',
  Bbl = 'bbl',
  Ktb = 'ktb',
  Bay = 'bay',
  Tmb = 'tmb',
  Uob = 'uob',
  Bpi = 'bpi',
  Unionbank = 'unionbank',
}

export interface BillableApplicantsData {
  count: Scalars['Int'];
}

export type BillableApplicantsResponse =
  | BillableApplicantsResponseSuccess
  | ResponseError;

export interface BillableApplicantsResponseSuccess {
  data?: Maybe<BillableApplicantsData>;
}

export interface BillingContact {
  /** Email address of the user */
  email?: Maybe<Scalars['String']>;
}

export enum BillingStatus {
  Ok = 'OK',
  OnCreditHold = 'ON_CREDIT_HOLD',
}

export interface Brand {
  id: Scalars['String'];
  legacyId?: Maybe<Scalars['Int']>;
  /** @deprecated Please use Brand.owner instead. */
  ownerId?: Maybe<Scalars['String']>;
  owner: BrandOwner;
  name: Scalars['String'];
  assets: BrandAssets;
  state?: Maybe<BrandState>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isLiveOnAd?: Maybe<Scalars['Boolean']>;
  createdOn?: Maybe<Scalars['String']>;
}

export type BrandAssetStrategies = LogoStrategies | CoverStrategies;

export interface BrandAssetType {
  id: Scalars['String'];
  url: Scalars['String'];
  strategies: Scalars['JSON'];
}

export enum BrandAssetTypeEnum {
  Logo = 'logo',
  Cover = 'cover',
}

export interface BrandAssetTypeInput {
  id: Scalars['String'];
  url: Scalars['String'];
  strategies: Scalars['JSON'];
}

export interface BrandAssets {
  logo: BrandAssetType;
  cover?: Maybe<BrandAssetType>;
}

export interface BrandAssetsInput {
  logo: BrandAssetTypeInput;
  cover?: Maybe<BrandAssetTypeInput>;
}

export interface BrandOwner {
  id?: Maybe<Scalars['String']>;
  type?: Maybe<BrandOwnerType>;
}

export interface BrandOwnerInput {
  id?: Maybe<Scalars['String']>;
  type?: Maybe<BrandOwnerType>;
}

export enum BrandOwnerType {
  SeekId = 'seekId',
  AdvertiserId = 'advertiserId',
}

export enum BrandState {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Deleted = 'DELETED',
}

export interface BrandedAd {
  type: RecommendationType;
  bulletPointsUsed: Scalars['Boolean'];
  logoUsed: Scalars['Boolean'];
  coverImageUsed: Scalars['Boolean'];
}

/**
 * AdBudgetProduct feature to indicate budget is allowed to purchase ads of branded tier and onwards.
 * Also known as 'BrandedOnwards' in legacy terms.
 */
export interface BrandedFeaturesFeature extends CatalogueProductFeature {
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
}

export interface BrandingAsset {
  id: Scalars['String'];
  url: Scalars['String'];
  strategies: BrandAssetStrategies;
  isDeletable?: Maybe<Scalars['Boolean']>;
  isLiveOnAd?: Maybe<Scalars['Boolean']>;
  /** @deprecated Non translated string. Will return english only. */
  deleteWarning?: Maybe<Scalars['String']>;
  isOnDefaultBrand?: Maybe<Scalars['Boolean']>;
  numberOfBrands?: Maybe<Scalars['Int']>;
  numberOfLiveAds?: Maybe<Scalars['Int']>;
}

/** AdProductUpgrade feature that indicates branding properties available for ad product */
export interface BrandingFeature extends CatalogueProductFeature {
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
  /** Indicates job ad can have a logo */
  hasLogo: Scalars['Boolean'];
  /** Indicates job ad can have cover image */
  hasCoverImage: Scalars['Boolean'];
  /**
   * Number of bullet points available on job ad. Only positive numbers allowed.
   * @deprecated bulletPoints is deprecated. Use BulletPointsFeature instead.
   */
  bulletPoints: Scalars['Int'];
  /** @deprecated borderForSearchListing is deprecated. Use StyleEnhancementFeature instead. */
  borderForSearchListing: Scalars['Boolean'];
}

export interface BrandingInput {
  brandId?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['Int']>;
}

/** Product feature that indicates bullet points for an existing ad. */
export interface BulletPointsFeature extends CatalogueProductFeature {
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
  count: Scalars['Int'];
}

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE',
}

export enum CandidateAnswerType {
  SingleSelect = 'SingleSelect',
  FreeText = 'FreeText',
}

export interface CandidateDemandResponse {
  comparisonPeriod?: Maybe<Array<Maybe<MarketInsightMonth>>>;
  currentPeriod?: Maybe<Array<Maybe<MarketInsightMonth>>>;
  hasCandidateDemand: Scalars['Boolean'];
}

export interface CandidateDemandWidgetInput {
  jobId: Scalars['String'];
  hirerOriginZone: Scalars['String'];
}

export enum CandidateLabel {
  Candidates = 'CANDIDATES',
  ApplicationStarts = 'APPLICATION_STARTS',
}

export interface CandidateSupplyResponse {
  comparisonPeriod?: Maybe<Array<Maybe<MarketInsightMonth>>>;
  currentPeriod?: Maybe<Array<Maybe<MarketInsightMonth>>>;
  hasCandidateSupply: Scalars['Boolean'];
}

export interface CandidateSupplyWidgetInput {
  jobId: Scalars['String'];
  hirerOriginZone: Scalars['String'];
}

export interface CappedUnitPrice {
  type: Scalars['String'];
  maxBillableUnits: Scalars['Int'];
  unitPrice: CatalogueProductPrice;
  cappedPrice: CatalogueProductPrice;
  metadata: CappedUnitPriceMetadata;
}

export interface CappedUnitPriceMetadata {
  unitType: Scalars['String'];
}

export interface CatalogueProduct {
  /** Unique ID of the catalogue product */
  id: Scalars['ID'];
  /** Name of the catalogue product */
  name: Scalars['String'];
  /** Price of the catalogue product, or price access level (priceVisibility) if hidePrice is enabled for the hirer */
  price: CatalogueProductPrice;
  /** Features of the catalogue product */
  features: Array<Maybe<CatalogueProductFeature>>;
}

export interface CatalogueProductAttributes {
  productSetId: Scalars['String'];
  productId: Scalars['String'];
  jobId?: Maybe<Scalars['String']>;
  /** TODO: what if the set doesn't contain any JobAds? it is optional */
  revisionId?: Maybe<Scalars['String']>;
}

export interface CatalogueProductFeature {
  /** Unique ID of the catalogue product feature */
  id: Scalars['ID'];
  /** Name of the catalogue product feature, ie. Listing, Branding, StandardTalentSearch etc */
  name: Scalars['String'];
  /** Type of the catalogue product feature, ie. Listing, Branding, StandardTalentSearch etc */
  type: Scalars['String'];
}

export type CatalogueProductPrice = Price | HiddenPrice;

export interface Category {
  categoryId: Scalars['Int'];
  categoryName: Scalars['String'];
  subCategoryId: Scalars['Int'];
  subCategoryName: Scalars['String'];
}

export interface CategorySuggestion {
  category: Category;
  probability: Scalars['Float'];
}

export enum ChannelCode {
  Ovo = 'OVO',
  Dana = 'DANA',
  Linkaja = 'LINKAJA',
  Shpqr = 'SHPQR',
  Gcash = 'GCASH',
  Paymaya = 'PAYMAYA',
  Grab = 'GRAB',
  Paypal = 'PAYPAL',
  Alipayhk = 'ALIPAYHK',
}

export interface ChildAccountBannerResponse {
  isChild: Scalars['Boolean'];
}

export interface Claim {
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

export interface ClassificationOption {
  key: Scalars['Int'];
  value: Scalars['String'];
  count: Scalars['Int'];
  subClassifications?: Maybe<Array<ClassificationOption>>;
}

export interface ClicksOnYourAdResponse {
  adRatingConfidence?: Maybe<AnalyticsAdRatingConfidence>;
  appConversionInPercentage?: Maybe<Scalars['Float']>;
  hasAdRating: Scalars['Boolean'];
  isLinkOutAd: Scalars['Boolean'];
  mobileClicksInPercentage: Scalars['Float'];
  similarAdsAverage?: Maybe<Scalars['Int']>;
  similarAdsInPercentage?: Maybe<Scalars['Float']>;
  totalClicks: Scalars['Int'];
}

export interface ClicksOnYourAdWidgetInput {
  jobId: Scalars['String'];
  hirerOriginZone: Scalars['String'];
}

export interface CompanyAddress {
  country?: Maybe<Scalars['String']>;
}

export interface CompanyProfile {
  id: Scalars['ID'];
  name: Scalars['String'];
  branding?: Maybe<CompanyProfileBranding>;
  overview?: Maybe<CompanyProfileOverview>;
  missionStatement?: Maybe<Array<Scalars['String']>>;
  featured?: Maybe<CompanyProfileFeatured>;
  awards?: Maybe<Array<CompanyProfileAward>>;
  cultureAndValues?: Maybe<CompanyProfileCultureAndValues>;
  customStatement?: Maybe<CompanyProfileCustomStatement>;
  perksAndBenefits?: Maybe<Array<CompanyProfilePerksAndBenefits>>;
  gallery?: Maybe<Array<CompanyProfilePhoto>>;
}

export interface CompanyProfileAward {
  name: Scalars['String'];
  year: Scalars['Int'];
  imageUrl: Scalars['String'];
  description: Scalars['String'];
}

export interface CompanyProfileAwardInput {
  name: Scalars['String'];
  year: Scalars['Int'];
  imageUrl: Scalars['String'];
  description: Scalars['String'];
}

export interface CompanyProfileBranding {
  brandId?: Maybe<Scalars['String']>;
  logo: Scalars['String'];
  cover?: Maybe<Scalars['String']>;
}

export interface CompanyProfileBrandingInput {
  brandId?: Maybe<Scalars['String']>;
  logo: Scalars['String'];
  cover?: Maybe<Scalars['String']>;
}

export interface CompanyProfileCultureAndValues {
  title: Scalars['String'];
  description: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  values?: Maybe<Array<CompanyProfileCultureValue>>;
}

export interface CompanyProfileCultureAndValuesInput {
  title: Scalars['String'];
  description: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  values?: Maybe<Array<CompanyProfileCultureValueInput>>;
}

export interface CompanyProfileCultureValue {
  title: Scalars['String'];
  description: Scalars['String'];
}

export interface CompanyProfileCultureValueInput {
  title: Scalars['String'];
  description: Scalars['String'];
}

export interface CompanyProfileCustomStatement {
  title: Scalars['String'];
  description: Array<Scalars['String']>;
}

export interface CompanyProfileCustomStatementInput {
  title: Scalars['String'];
  description: Array<Scalars['String']>;
}

export interface CompanyProfileFeatured {
  title: Scalars['String'];
  description?: Maybe<Array<Scalars['String']>>;
  imageUrl?: Maybe<Scalars['String']>;
  videoUrl?: Maybe<Scalars['String']>;
}

export interface CompanyProfileFeaturedInput {
  title: Scalars['String'];
  description?: Maybe<Array<Scalars['String']>>;
  imageUrl?: Maybe<Scalars['String']>;
  videoUrl?: Maybe<Scalars['String']>;
}

export interface CompanyProfileIds {
  companyIds?: Maybe<Array<Scalars['ID']>>;
}

export interface CompanyProfileInput {
  id: Scalars['ID'];
  name: Scalars['String'];
  branding?: Maybe<CompanyProfileBrandingInput>;
  overview?: Maybe<CompanyProfileOverviewInput>;
  missionStatement?: Maybe<Array<Scalars['String']>>;
  featured?: Maybe<CompanyProfileFeaturedInput>;
  awards?: Maybe<Array<CompanyProfileAwardInput>>;
  cultureAndValues?: Maybe<CompanyProfileCultureAndValuesInput>;
  customStatement?: Maybe<CompanyProfileCustomStatementInput>;
  perksAndBenefits?: Maybe<Array<CompanyProfilePerksAndBenefitsInput>>;
  gallery?: Maybe<Array<CompanyProfilePhotoInput>>;
}

export interface CompanyProfileOverview {
  website?: Maybe<CompanyProfileWebsite>;
  industry?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  primaryLocation?: Maybe<Scalars['String']>;
  specialities?: Maybe<Array<Scalars['String']>>;
  description?: Maybe<Array<Scalars['String']>>;
}

export interface CompanyProfileOverviewInput {
  website?: Maybe<CompanyProfileWebsiteInput>;
  industry?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  primaryLocation?: Maybe<Scalars['String']>;
  specialities?: Maybe<Array<Scalars['String']>>;
  description?: Maybe<Array<Scalars['String']>>;
}

export interface CompanyProfilePerksAndBenefits {
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
}

export interface CompanyProfilePerksAndBenefitsInput {
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
}

export interface CompanyProfilePhoto {
  link: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
}

export interface CompanyProfilePhotoInput {
  link: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
}

export interface CompanyProfileWebsite {
  url: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
}

export interface CompanyProfileWebsiteInput {
  url: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
}

export interface ConfirmInvoiceOrderInput {
  advertiserId?: Maybe<Scalars['String']>;
  orderId: Scalars['ID'];
  idempotencyKey: Scalars['ID'];
  billToAgent: Scalars['Boolean'];
}

export type ConfirmInvoiceOrderPayload =
  | InvoiceOrderSuccessPayload
  | InvoiceOrderNotFoundPayload
  | OrderNotFoundError
  | OrderExpiredError
  | OrderInvalidError
  | PromotionInvalidError;

export interface ConnectionStatusPayload {
  connectionStatus: TalentSearchConnectionStatus;
  creditsRedeemedDateUtc?: Maybe<Scalars['String']>;
  nextAvailableDateUtc?: Maybe<Scalars['String']>;
}

export interface Contract {
  expiry: Expiry;
  value: ContractValue;
  current: Scalars['Boolean'];
  /** Info on discounts that are available for Ad products, only available for SEEK_CONTRACT types */
  adDiscounts?: Maybe<Array<Maybe<ContractAdDiscount>>>;
  /** The date from when contract is consumable eg. '2019-11-01T11:00:00.000Z' */
  validFromDate: Scalars['String'];
  /** The date when contract was created eg. '2019-10-09T11:00:00.000Z' */
  createdDate: Scalars['String'];
}

export interface ContractAdDiscount {
  adType: AdType;
  /** Minimum possible discount for an AdType according to the contract and the current variable ad prices */
  minimumPercentageSavings?: Maybe<Scalars['Float']>;
}

export interface ContractBalance {
  title: Scalars['String'];
  contractType: ContractType;
  dollarBased: Scalars['Boolean'];
  adType: AdType;
  adTypeDescription?: Maybe<Scalars['String']>;
  contracts?: Maybe<Array<Contract>>;
  totalValue: ContractValue;
  lowBalance: Scalars['Boolean'];
  recommendUpSell: Scalars['Boolean'];
  /** To show or hide the balance */
  hideBalance: Scalars['Boolean'];
}

export interface ContractDollarValue {
  remaining: Money;
  original: Money;
}

export enum ContractType {
  Subscription = 'SUBSCRIPTION',
  AdPack = 'AD_PACK',
  SeekContract = 'SEEK_CONTRACT',
  MasterAgreement = 'MASTER_AGREEMENT',
  TalentSearchContract = 'TALENT_SEARCH_CONTRACT',
  PricingGroup = 'PRICING_GROUP',
}

export interface ContractUnitValue {
  remaining: Scalars['Int'];
  original: Scalars['Int'];
}

export interface ContractValue {
  dollars: ContractDollarValue;
  units?: Maybe<ContractUnitValue>;
}

export interface ConversionRateData {
  total: Scalars['Float'];
  similarAdAverage: Scalars['Float'];
  adConversionRate?: Maybe<Scalars['Float']>;
  similarAdConversionRate?: Maybe<Scalars['Float']>;
  mobileRate: Scalars['Float'];
}

export interface CountriesInput {
  /** Used to generate the 'contextualName', this should usually represent the country the hire is located in. If it isn't provided it will fallback to the hirer's nation */
  contextual?: Maybe<Scalars['String']>;
  /** Restricts the location suggestion search to only include these countries in the results. */
  only?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Controls the order of results so that you can specify for one countries results to appear above other(s). If it isn't provided it will use the order of 'only' as fallback. If 'only' isn't provided then it will fallback to 'contextual' as first preference. */
  preferenceOrder?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface Country {
  /** Full name of the country eg United Kingdom */
  name: Scalars['String'];
  /** 2 digit code in ISO 3166-1 alpha-2 format eg GB */
  countryCode: Scalars['String'];
}

export interface CountrySelectionOptions {
  /** List of countries to populate a drop-down list or autocomplete. */
  options: Array<Country>;
}

export interface CoverStrategies {
  jdpCoverThumbnail: Scalars['String'];
  jdpCover: Scalars['String'];
}

export interface Create2C2PPaymentIntentForInvoiceInput {
  /** Nation of the advertiser. */
  advertiserNation?: Maybe<Scalars['String']>;
  /** The currency of the payment. */
  paymentCurrency: Currency;
  /** Type of payment method */
  paymentMethodType: PaymentMethodType2C2P;
  /** bank used by 2c2p to identify the bank */
  bank?: Maybe<Bank2C2P>;
  /** The return url for the frontend to redirect to after payment is completed. */
  frontendReturnUrl?: Maybe<Scalars['String']>;
  /** optional, only for e-wallets. channelCode used by 2c2p to identify the channel */
  channelCode?: Maybe<ChannelCode>;
  /**
   * This would normally come from the access token, but for this query the user may be unauthenticated.
   * customer number for payment link experience.
   * seekBillingAccountId of the advertiser login experience
   * This will be mapped to customerId on paymentService
   */
  seekBillingAccountId: Scalars['String'];
  /**
   * This would normally come from the access token, but for this query the user may be unauthenticated.
   * seekAdvertiserId of the advertiser.
   * This will be mapped to accountId on paymentService
   */
  seekAdvertiserId?: Maybe<Scalars['String']>;
  /** The reference of the invoice */
  prn: Scalars['String'];
  /** The total amount to be paid for the payment intent. REMOVE this later when we are able to get the total amount from the invoice API. */
  totalForPaymentIntent?: Maybe<Scalars['SafeInt']>;
  /** Temporary for the fix of big total amount to be paid for the payment intent. REMOVE this after update totalForPaymentIntent to SafeInt. */
  totalForPaymentIntentSafeInt?: Maybe<Scalars['SafeInt']>;
  /** Invoices to be paid. REMOVE this later when we are able to get the total amount from the invoice API. */
  invoices: Array<InvoiceInput>;
  /** The email of the advertiser. */
  email: Scalars['String'];
  /** The phone number of payer when OVO channelcode is used. */
  accountNo?: Maybe<Scalars['String']>;
}

export interface Create2C2PPaymentIntentInput {
  /** Id of the order. */
  orderId: Scalars['ID'];
  /** Nation of the advertiser. */
  advertiserNation: Scalars['String'];
  /**
   * The currency of the payment. If no currency input, it will use the currency from pending order.
   * This is a temporary bypass to test Asia currency, to be removed when OrderAPI supports Asia currency.
   */
  paymentCurrency?: Maybe<Scalars['String']>;
  paymentMethodType: PaymentMethodType2C2P;
  bank?: Maybe<Bank2C2P>;
  frontendReturnUrl?: Maybe<Scalars['String']>;
  channelCode?: Maybe<ChannelCode>;
  accountNo?: Maybe<Scalars['String']>;
}

export type Create2c2pPaymentIntentPayload =
  | PaymentIntent2C2PPayload
  | PaymentMethodError
  | PaymentIntentChargeError
  | OrderNotFoundError
  | OrderExpiredError
  | OrderInvalidError
  | PromotionInvalidError
  | InvoiceError;

export interface CreateBrandInput {
  name?: Maybe<Scalars['String']>;
  owner?: Maybe<BrandOwnerInput>;
  assets: BrandAssetsInput;
}

export type CreateBrandPayload =
  | CreateBrandSuccessPayload
  | InvalidInputPayload;

export interface CreateBrandSuccessPayload {
  id: Scalars['String'];
  legacyId?: Maybe<Scalars['Int']>;
  owner: BrandOwner;
  name: Scalars['String'];
  assets: BrandAssets;
  state?: Maybe<BrandState>;
  isDefault?: Maybe<Scalars['Boolean']>;
  createdOn?: Maybe<Scalars['String']>;
}

export interface CreateCustomQuestionErrorResult {
  reason: Scalars['String'];
}

export interface CreateCustomQuestionInput {
  languageId: Scalars['String'];
  question: CustomQuestion;
}

export type CreateCustomQuestionOutputUnion =
  | CreateCustomQuestionResult
  | CreateCustomQuestionErrorResult;

export interface CreateCustomQuestionResult {
  question: OutputCustomQuestion;
  validationFailures: Array<CustomQuestionValidationFailure>;
}

export interface CreateDraftJobInput {
  /** Adcentre advertiserId */
  adcentreAdvertiserId?: Maybe<Scalars['String']>;
  /** aka LinkOut url, is used to apply via external website */
  applicationFormUrl?: Maybe<Scalars['String']>;
  /** Selected branding info */
  branding?: Maybe<DraftJobBrandingInput>;
  /** Selected category and sub-category */
  categories?: Maybe<Array<DraftJobCategoryInput>>;
  /** The status of the draft */
  status?: Maybe<DraftStatus>;
  /** CreatedAt date will only update when used in migration */
  createdAt?: Maybe<Scalars['String']>;
  /** The Job Description, aka "Write my own" */
  details?: Maybe<Scalars['String']>;
  /** Selected expire data */
  expiresAt?: Maybe<Scalars['String']>;
  /** The flow a draft is created from (e.g. create, edit) */
  flowType?: Maybe<DraftJobFlowType>;
  /** The pre-defined Guided Job description, aka "Help me write my ad" */
  guidedDetails?: Maybe<Array<DraftJobGuidedDetailsInput>>;
  /** The flag to tell whether the hirer is posting a private or a public Ad. */
  isConfidential?: Maybe<Scalars['Boolean']>;
  /** Standard or Guided Mode for writing job description */
  jobWritingMode?: Maybe<DraftJobWritingMode>;
  /** Reference number is displayed on invoices and used for linkout ads */
  jobAdReference?: Maybe<Scalars['String']>;
  /** Selling points - used in the search description for standout/premium ads */
  keySellingPoints?: Maybe<Array<Scalars['String']>>;
  /** The job location info */
  locations?: Maybe<Array<DraftJobLocationInput>>;
  /** Selected products info */
  products?: Maybe<DraftJobProductsInput>;
  /** The id for a role requirements questionnaire */
  questionnaireId?: Maybe<Scalars['String']>;
  /** Min/Max salaries info */
  salary?: Maybe<DraftJobSalaryDetailsInput>;
  /** The job summary to appear in search */
  summary?: Maybe<Scalars['String']>;
  /** The job title(s) */
  titles?: Maybe<Array<DraftJobRoleTitleInput>>;
  /** The video info, e.g. video url */
  video?: Maybe<DraftJobVideoInput>;
  /** The work type(s) (e.g. full time, part time) */
  workTypes?: Maybe<Array<WorkType>>;
  /**
   * The draft progress made in the job posting's create flow
   * Edit flow and refresh flow do not have draftProgress
   */
  draftProgress?: Maybe<DraftJobProgress>;
  /** The selected payment option (e.g. invoice, credit card etc.) */
  paymentOption?: Maybe<DraftJobPaymentOption>;
  /** Hirer's phone number */
  phoneNumber?: Maybe<Scalars['String']>;
}

export interface CreateDraftJobPayload {
  /**
   * The successfully created draft job id. Deprecated "Int" version.
   * @deprecated Will be removed once no clients are calling it
   */
  draftId?: Maybe<Scalars['Int']>;
  /** The successfully created draft job id */
  id?: Maybe<Scalars['String']>;
}

export interface CreateIntegrationRelationshipsInput {
  partnerId: Scalars['ID'];
  relationshipTypeCodes: Array<Scalars['String']>;
}

export interface CreateJobInput {
  advertiserId?: Maybe<Scalars['String']>;
  draftId: Scalars['String'];
  branding?: Maybe<BrandingInput>;
  idempotencyKey: Scalars['String'];
}

export interface CreateJobResponse {
  jobId: Scalars['String'];
  /** The id of the revision that was created for the job */
  revisionId: Scalars['String'];
  /** The key used to create the job */
  idempotencyKey: Scalars['String'];
}

export interface CreateOrderInput {
  /** Advertiser's Id. */
  advertiserId?: Maybe<Scalars['String']>;
  /** Advertiser's nation. */
  advertiserNation: Scalars['String'];
  /** Selected line items. Contains input attributes associated to selected product. */
  lineItems: Array<OrderRequestInputItem>;
  /** Idempotency key for create order request */
  orderCreationId?: Maybe<Scalars['String']>;
  /** Promotion code. */
  promoCode?: Maybe<Scalars['String']>;
}

export interface CreateOrderPayload {
  order?: Maybe<Order>;
}

export interface CreateOrderSuccessPayload {
  order?: Maybe<Order>;
  promotion?: Maybe<Promotion>;
}

export type CreateOrderWithPromotionPayload =
  | CreateOrderSuccessPayload
  | PromotionInvalidError;

export interface CreatePaymentIntentInput {
  /** Credit card brand to determine any surcharges. */
  creditCardBrand: CreditCardBrandInput;
  /** Id of the advertiser. */
  advertiserId?: Maybe<Scalars['ID']>;
  /** Id of the order. */
  orderId: Scalars['ID'];
  /** Nation of the advertiser. */
  advertiserNation: Scalars['String'];
  /** The payment intent will be set up to save the card (as a payment method) entered on the payment UI against advertiser Id. */
  saveCard: Scalars['Boolean'];
}

export type CreatePaymentIntentPayload =
  | PaymentIntentPayload
  | OrderNotFoundError
  | OrderExpiredError
  | OrderInvalidError
  | PromotionInvalidError;

export interface CreateProductSetForAdEditingInput {
  advertiserId?: Maybe<Scalars['ID']>;
  jobId: Scalars['ID'];
  /** The current job details as seen by the hirer during edit */
  jobAdDetails: JobAdDetails;
  /** The draft ID associated to the job posting flow */
  draftId: Scalars['String'];
  /** The locale to create product set */
  locale?: Maybe<Scalars['String']>;
}

export interface CreateProductSetForAdEditingPayload {
  /** The created product set */
  productSet: ProductSet;
}

export interface CreateProductSetForAdPostingInput {
  advertiserId?: Maybe<Scalars['ID']>;
  advertiserNation?: Maybe<Scalars['String']>;
  jobAdDetails: JobAdDetails;
  /** The draft ID associated to the job posting flow */
  draftId?: Maybe<Scalars['String']>;
  /** The user intent */
  userIntent?: Maybe<UserIntentType>;
  /** The locale to create product set */
  locale?: Maybe<Scalars['String']>;
}

export interface CreateProductSetForAdPostingPayload {
  /** The created product set */
  productSet: ProductSet;
}

export interface CreateProductSetForAdRefreshInput {
  /** The job ID associated to the job refresh flow */
  jobId: Scalars['ID'];
  /** The draft ID associated to the job refresh flow */
  draftId?: Maybe<Scalars['String']>;
  /** The user intent */
  userIntent?: Maybe<UserIntentType>;
}

export interface CreateProductSetForAdRefreshPayload {
  /** The created product set */
  productSet: ProductSet;
}

export interface CreateProductSetForBudgetsPurchaseForHirerPayload {
  /** The created product set */
  productSet: ProductSet;
}

export interface CreateProductSetForBudgetsPurchaseForVisitorInput {
  /** The randomly generated UUIDv4 of the logged out user */
  visitorId: Scalars['ID'];
}

export interface CreateProductSetForBudgetsPurchaseForVisitorPayload {
  /** The created product set */
  productSet: ProductSet;
}

export interface CreateProductSetForMultiLocationInput {
  /** The ID of the advertiser */
  advertiserId?: Maybe<Scalars['ID']>;
  /** The current job details as seen by the hirer during edit */
  jobAdDetails: JobAdDetails;
  /** The draft ID associated to the job posting flow */
  draftId?: Maybe<Scalars['String']>;
  /** ID of the original job being posted to multiple locations */
  jobId: Scalars['String'];
  /** The locale to create product set */
  locale?: Maybe<Scalars['String']>;
}

export interface CreateProductSetForMultiLocationPayload {
  /** The created product set */
  productSet: ProductSet;
}

export interface CreateProductSetForPriceCheckerPreviewInput {
  advertiserNation?: Maybe<Scalars['String']>;
  jobAdDetails: JobAdDetails;
}

export interface CreateProductSetForPriceCheckerPreviewPayload {
  /** The created product set */
  productSet: ProductSet;
}

export interface CreateQuestionnaireInput {
  advertiserId?: Maybe<Scalars['String']>;
  sourceQuestionnaireId?: Maybe<Scalars['String']>;
  /** This is the country code for the website domain being used to post job and create questionnaire. e.g values au and nz as strings */
  domainCountryCode: Scalars['String'];
  language: Scalars['String'];
  adCentreAdvertiserId: Scalars['String'];
}

export interface CreateQuestionnaireResponse {
  questionnaireId?: Maybe<Scalars['String']>;
  questionnaireUrl?: Maybe<Scalars['String']>;
}

export interface CreateSavedSearchInput {
  name: Scalars['String'];
  criteria: TalentSearchCriteriaInput;
  createdDate: Scalars['String'];
}

export interface CreateSavedSearchPayload {
  savedSearch?: Maybe<TalentSearchSavedSearch>;
}

export interface CreateStripePaymentIntentForInvoiceInput {
  /** Type of payment method */
  paymentMethod: StripePaymentMethod;
  /** The payment intent will be set up to save the card (as a payment method) entered on the payment UI against advertiser Id. */
  saveCard?: Maybe<Scalars['Boolean']>;
  /**
   * The currency of the payment. If no currency input, it will use the currency from pending order.
   * This is a temporary bypass to test Asia currency, to be removed when OrderAPI supports Asia currency.
   */
  paymentCurrency: Currency;
  /**
   * This would normally come from the access token, but for this query the user may be unauthenticated.
   * customer number for payment link experience.
   * seekBillingAccountId of the advertiser login experience
   * This will be mapped to customerId on paymentService
   */
  seekBillingAccountId: Scalars['String'];
  /**
   * This would normally come from the access token, but for this query the user may be unauthenticated.
   * seekAdvertiserId of the advertiser.
   * This will be mapped to accountId on paymentService
   */
  seekAdvertiserId?: Maybe<Scalars['String']>;
  /** The reference of the invoice */
  prn: Scalars['String'];
  /** The total amount to be paid for the payment intent. REMOVE this later when we are able to get the total amount from the invoice API. */
  totalForPaymentIntent?: Maybe<Scalars['SafeInt']>;
  /** Temporary for the fix of big total amount to be paid for the payment intent. REMOVE this after update totalForPaymentIntent to SafeInt. */
  totalForPaymentIntentSafeInt?: Maybe<Scalars['SafeInt']>;
  /** Invoices to be paid. REMOVE this later when we are able to get the total amount from the invoice API. */
  invoices: Array<InvoiceInput>;
  /** Credit card brand to determine any surcharges. */
  creditCardBrand?: Maybe<CreditCardBrandInput>;
  /** The email of the advertiser. */
  email: Scalars['String'];
  /** Number of days before the payment intent expires and is disabled for payment. Minimum of at least 1 day */
  expiryDays?: Maybe<Scalars['Int']>;
  /** The idempotency key for the payment intent. If not provided, a random uuid will be generated. */
  idempotencyKey?: Maybe<Scalars['String']>;
}

export interface CreateStripePaymentIntentInput {
  /** Id of the order. */
  orderId: Scalars['ID'];
  /** Nation of the advertiser. */
  advertiserNation: Scalars['String'];
  /** Type of payment method */
  paymentMethod: StripePaymentMethod;
  /** Credit card brand to determine any surcharges. */
  creditCardBrand?: Maybe<CreditCardBrandInput>;
  /** The payment intent will be set up to save the card (as a payment method) entered on the payment UI against advertiser Id. */
  saveCard?: Maybe<Scalars['Boolean']>;
  /**
   * The currency of the payment. If no currency input, it will use the currency from pending order.
   * This is a temporary bypass to test Asia currency, to be removed when OrderAPI supports Asia currency.
   */
  paymentCurrency?: Maybe<Scalars['String']>;
  /** The idempotency key for the payment intent. If not provided, a random uuid will be generated. */
  idempotencyKey?: Maybe<Scalars['String']>;
}

export type CreateStripePaymentIntentPayload =
  | StripePaymentIntentPayload
  | PaymentMethodError
  | OrderNotFoundError
  | OrderExpiredError
  | OrderInvalidError
  | PromotionInvalidError
  | InvoiceError;

export interface CreditCard {
  /** A token that uniquely represents the card, e.g. 'card_1HPZvMAtb48kSZAqvNZabcde' */
  token: Scalars['String'];
  /** The last (typically 4) digits of a credit card, e.g. '4234' */
  lastDigits: Scalars['String'];
  /** The brand of credit card processor such as 'Visa' or 'MasterCard' */
  brand: Scalars['String'];
  /** The expiry month of the credit from 1 to 12, such as '02' for February or '12' for December. */
  expiryMonth: Scalars['String'];
  /** A four digit number representing the expiry year, e.g. '2022' */
  expiryYear: Scalars['String'];
}

export enum CreditCardBrandInput {
  Amex = 'AMEX',
  Visa = 'VISA',
  MasterCard = 'MASTER_CARD',
}

export enum Currency {
  Nzd = 'NZD',
  Aud = 'AUD',
  Idr = 'IDR',
  Php = 'PHP',
  Sgd = 'SGD',
  Myr = 'MYR',
  Hkd = 'HKD',
  Thb = 'THB',
}

export interface CurrentAdvertiserContext {
  /** Name of the advertiser account. */
  name: Scalars['String'];
  /** ID of the advertiser account. */
  advertiserId: Scalars['ID'];
  /** SeekWeb Advertiser ID. */
  seekId: Scalars['ID'];
  /** Optional billing ID provided by CRM. */
  billingId?: Maybe<Scalars['String']>;
  /** Array of permissions for a user on behalf of the advertiser. */
  permissions: Array<Permission>;
  /** Indicates whether the advertiser is an agency account. */
  isAgency: Scalars['Boolean'];
  /** User claims relevant to the specified advertiser */
  claims?: Maybe<Array<Maybe<Claim>>>;
  /** Status of Profile Properties related to the account of the user currently signed in */
  profileProperties?: Maybe<ProfileProperties>;
  /** Role of user relevant to the specified advertiser */
  role: Scalars['String'];
  /** Effective Talent Search access for this user against this advertiser */
  talentSearchAccess?: Maybe<TalentSearchAccess>;
  /** The advertiser's status. */
  status: AdvertiserStatus;
}

export type CurrentAdvertiserResponse =
  | CurrentAdvertiserContext
  | MissingAdvertiserIdClaimError;

export interface CurrentUserContext {
  /** Adcentre user id. */
  id: Scalars['String'];
  /** First name of the user. */
  firstName: Scalars['String'];
  /** Last name of the user. */
  lastName: Scalars['String'];
  /** First and last names of the user. */
  name: Scalars['String'];
  /** Email of the user currently signed in */
  email: Scalars['String'];
  /** Verification status of the user. */
  status: Scalars['String'];
}

export type CurrentUserResponse = CurrentUserContext | MissingUserIdClaimError;

export interface CustomQuestion {
  /** Question Text */
  text: Scalars['String'];
  /** Hirer Question Type e.g SingleSelect, FreeText */
  questionType: HirerQuestionType;
  /** Options */
  options: Array<Option>;
}

export interface CustomQuestionValidationFailure {
  /** Custom Question Validation Failure Message */
  message: Scalars['String'];
}

export interface DateAxisLabel {
  label?: Maybe<Scalars['String']>;
  date: Scalars['Date'];
}

export interface DeleteBrandingAssetInput {
  assetId: Scalars['ID'];
  assetType: BrandAssetTypeEnum;
  owner?: Maybe<BrandOwnerInput>;
}

export interface DeleteBrandingAssetPayload {
  success?: Maybe<Scalars['Boolean']>;
  successMessage?: Maybe<Scalars['String']>;
}

export interface DeleteDraftJobInput {
  /** The id of the draft job to be deleted. */
  id: Scalars['String'];
}

export interface DeleteDraftJobPayload {
  /** Indicates if deleting the draft job was successful or not. */
  successful: Scalars['Boolean'];
}

export interface DeleteIntegrationRelationshipsInput {
  relationshipIds: Array<Scalars['ID']>;
}

export interface DeleteSavedCreditCardInput {
  /** Id of the advertiser. */
  advertiserId?: Maybe<Scalars['ID']>;
  /** Nation of the advertiser. */
  advertiserNation: Scalars['String'];
  /** Id of the payment method. Examples: payment method id (pm_XXX) or saved card id (card_XXX) */
  paymentMethodId: Scalars['String'];
}

export interface DeleteSavedCreditCardPayload {
  /** Status of the credit card deletion */
  successful?: Maybe<Scalars['Boolean']>;
}

export interface DeleteSavedSearchPayload {
  deletedCriteriaHash?: Maybe<Scalars['String']>;
}

export interface DeleteStripeSavedCreditCardInput {
  /** Nation of the advertiser. */
  advertiserNation: Scalars['String'];
  /** Id of the payment method. Examples: payment method id (pm_XXX) or saved card id (card_XXX) */
  paymentMethodId: Scalars['String'];
}

export interface DeleteStripeSavedCreditCardPayload {
  /** Status of the credit card deletion */
  successful?: Maybe<Scalars['Boolean']>;
}

export interface DisableMessageType {
  en: Scalars['String'];
}

export interface Discount {
  discountPercentage: Scalars['Float'];
  discountAmount: Money;
}

export interface DisplayMetadataError extends Error {
  message: Scalars['String'];
}

export enum DisplayRecommendation {
  Inclusive = 'inclusive',
  Exclusive = 'exclusive',
}

export interface DraftJob {
  id: Scalars['String'];
  /** aka LinkOut url, is used to apply via external website */
  applicationFormUrl?: Maybe<Scalars['String']>;
  /** Selected branding info */
  branding?: Maybe<DraftJobBranding>;
  /** Selected category and sub-category */
  categories?: Maybe<Array<DraftJobCategory>>;
  /** The Job Description, aka "Write my own" */
  details?: Maybe<Scalars['String']>;
  /** Selected expire data */
  expiresAt?: Maybe<Scalars['String']>;
  /** The pre-defined Guided Job description, aka "Help me write my ad" */
  guidedDetails?: Maybe<Array<Maybe<DraftJobGuidedDetails>>>;
  /** Standard or Guided Mode for writing job description */
  jobWritingMode?: Maybe<DraftJobWritingMode>;
  /** Reference number is displayed on invoices and used for linkout ads */
  jobAdReference?: Maybe<Scalars['String']>;
  /** The flag to tell whether the hirer is posting a private or a public Ad. */
  isConfidential?: Maybe<Scalars['Boolean']>;
  /** Selling points - used in the search description for standout/premium ads */
  keySellingPoints?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The job location info */
  locations?: Maybe<Array<Maybe<DraftJobLocation>>>;
  /**
   * Adcentre adType that is old way of representing "products"
   * @deprecated Will be removed once deprecated express api
   */
  adType?: Maybe<Scalars['String']>;
  /** Selected products info */
  products?: Maybe<DraftJobProducts>;
  /** The id for a role requirements questionnaire */
  questionnaireId?: Maybe<Scalars['String']>;
  /** Min/Max salaries info */
  salary?: Maybe<DraftJobSalaryDetails>;
  /** The job summary to appear in search */
  summary?: Maybe<Scalars['String']>;
  /** The job title(s) */
  titles?: Maybe<Array<Maybe<DraftJobRoleTitle>>>;
  /** The video info, e.g. video url */
  video?: Maybe<DraftJobVideo>;
  /** The work type(s) (e.g. full time, part time) */
  workTypes?: Maybe<Array<Maybe<WorkType>>>;
  /**
   * The draft progress made in the job posting's create flow
   * Edit flow and refresh flow do not have draftProgress
   * @deprecated Will be removed once deprecated express api
   */
  draftProgress?: Maybe<DraftJobProgress>;
  /**
   * The selected payment option (e.g. invoice, credit card etc.)
   * @deprecated Will be removed once deprecated express api
   */
  paymentOption?: Maybe<DraftJobPaymentOption>;
  /** The phone number for the job */
  phoneNumber?: Maybe<Scalars['String']>;
}

export interface DraftJobBranding {
  legacyId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
}

export interface DraftJobBrandingInput {
  legacyId?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
}

export interface DraftJobCategory {
  categoryId?: Maybe<Scalars['String']>;
  subCategoryId?: Maybe<Scalars['String']>;
  adcentreCategoryId?: Maybe<Scalars['String']>;
  adcentreSubCategoryId?: Maybe<Scalars['String']>;
}

export interface DraftJobCategoryInput {
  categoryId: Scalars['String'];
  subCategoryId: Scalars['String'];
  adcentreCategoryId?: Maybe<Scalars['String']>;
  adcentreSubCategoryId?: Maybe<Scalars['String']>;
}

export interface DraftJobEdge {
  /** A `DraftJob` from the pagination query. */
  node?: Maybe<DraftJobNode>;
}

export enum DraftJobFlowType {
  Create = 'CREATE',
  Edit = 'EDIT',
}

export interface DraftJobGuidedDetails {
  type: GuidedDetailsType;
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface DraftJobGuidedDetailsInput {
  type: GuidedDetailsType;
  values: Array<Scalars['String']>;
}

export interface DraftJobLocation {
  seekId: Scalars['String'];
}

export interface DraftJobLocationInput {
  seekId: Scalars['String'];
}

export interface DraftJobNode {
  /** The draft job ID. */
  id: Scalars['String'];
  /** The full name of the user who created the draft job. */
  createdBy: Scalars['String'];
  /** Date/time in UTC (ISO 8601 format) the draft job was created. */
  createdDate: Scalars['String'];
  /** Draft job title appearing on search results, job lists and candidate management pages once the draft is posted. */
  title?: Maybe<Scalars['String']>;
  /** A reference provided by the advertiser to facilitate searching in draft job lists. */
  advertiserJobReference?: Maybe<Scalars['String']>;
  /** A reference provided by the agent to facilitate searching in draft job lists. */
  agencyExternalReference?: Maybe<Scalars['String']>;
  /**
   * Locations the job is advertised in.
   * While the schema allows multiple locations, up to one will be returned for AU/NZ.
   * @deprecated Use locations array instead to get more details
   */
  locationDisplayNames?: Maybe<Array<Scalars['String']>>;
  /**
   * Locations the job is advertised in.
   * While the schema allows multiple locations, up to one will be returned for AU/NZ.
   */
  locations: Array<Location>;
  /** Draft job status */
  status: DraftStatus;
}

export interface DraftJobNotFoundPayload {
  reason: Scalars['String'];
}

export type DraftJobPayload = DraftJobSuccessPayload | DraftJobNotFoundPayload;

export enum DraftJobPaymentOption {
  Contract = 'CONTRACT',
  CreditCard = 'CREDIT_CARD',
  Invoice = 'INVOICE',
  SavedCreditCard = 'SAVED_CREDIT_CARD',
}

export interface DraftJobProducts {
  productIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  productSetId?: Maybe<Scalars['String']>;
}

export interface DraftJobProductsInput {
  /** List of product id */
  productIds?: Maybe<Array<Scalars['String']>>;
  /** Unique ID of the product set */
  productSetId?: Maybe<Scalars['String']>;
}

export enum DraftJobProgress {
  Classify = 'CLASSIFY',
  SelectAdType = 'SELECT_AD_TYPE',
  Write = 'WRITE',
  Manage = 'MANAGE',
  PayAndPost = 'PAY_AND_POST',
}

export interface DraftJobRoleTitle {
  title?: Maybe<Scalars['String']>;
  language: Scalars['String'];
}

export interface DraftJobRoleTitleInput {
  title: Scalars['String'];
  language: Scalars['String'];
}

export enum DraftJobSalaryCurrency {
  Aud = 'AUD',
  Bdt = 'BDT',
  Cny = 'CNY',
  Eur = 'EUR',
  Gbp = 'GBP',
  Hkd = 'HKD',
  Idr = 'IDR',
  Inr = 'INR',
  Jpy = 'JPY',
  Myr = 'MYR',
  Nzd = 'NZD',
  Php = 'PHP',
  Sgd = 'SGD',
  Thb = 'THB',
  Usd = 'USD',
  Vnd = 'VND',
}

export interface DraftJobSalaryDetails {
  currency?: Maybe<DraftJobSalaryCurrency>;
  displayValue?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  minimum?: Maybe<Scalars['SafeInt']>;
  maximum?: Maybe<Scalars['SafeInt']>;
  type?: Maybe<SalaryType>;
}

export interface DraftJobSalaryDetailsInput {
  /** The currency for the salary */
  currency?: Maybe<DraftJobSalaryCurrency>;
  /** The text to be displayed to show salary to candidates */
  displayValue?: Maybe<Scalars['String']>;
  /** Should the salary text be displayed or not */
  isHidden?: Maybe<Scalars['Boolean']>;
  /** The min salary value */
  minimum?: Maybe<Scalars['SafeInt']>;
  /** The max salary value */
  maximum?: Maybe<Scalars['SafeInt']>;
  /** The pay type (e.g. annual package, hourly rate) */
  type?: Maybe<SalaryType>;
}

export interface DraftJobSuccessPayload {
  flowType?: Maybe<DraftJobFlowType>;
  status: DraftStatus;
  draft: DraftJob;
}

export interface DraftJobVideo {
  url: Scalars['String'];
}

export interface DraftJobVideoInput {
  url: Scalars['String'];
}

export enum DraftJobWritingMode {
  Standard = 'STANDARD',
  Guided = 'GUIDED',
}

export interface DraftJobsInput {
  /**
   * The number of results to skip where the value must be a multiple of `limit`.
   * `null` defaults to an offset of `0`.
   * Example: With a `limit` of `5` results per page, an `offset` of `0` implies the first page of results, and an `offset` of `15` implies the fourth page of results.
   */
  offset?: Maybe<Scalars['Int']>;
  /** The maximum number of results per page. */
  limit?: Maybe<Scalars['Int']>;
  /** The draft job search term. */
  term?: Maybe<Scalars['String']>;
  /** The advertiser country */
  contextualCountry?: Maybe<Scalars['String']>;
  /** The language SEEK site is using */
  language?: Maybe<Scalars['LanguageCodeIso']>;
}

export interface DraftJobsPayload {
  /** The total number of draft jobs across all pages. */
  totalCount: Scalars['Int'];
  /** The total number of pages. */
  totalPageCount: Scalars['Int'];
  /** The list of `DraftJob` edges for the current page. */
  edges: Array<DraftJobEdge>;
}

export enum DraftStatus {
  Open = 'OPEN',
  Closed = 'CLOSED',
  Deleted = 'DELETED',
  Pending = 'PENDING',
}

export interface DrawdownContractDetails {
  contractType: ContractType;
  adType: AdType;
  /**
   * The contract is flexible and not unit based.
   *
   * This is not resolved for getCheckoutDetails query.
   */
  dollarBased?: Maybe<Scalars['Boolean']>;
  /**
   * The expiry date of the contract.
   *
   * This is not resolved for getCheckoutDetails query.
   */
  expiryDate?: Maybe<Scalars['String']>;
  /**
   * The current balance of the contract.
   *
   * This is not resolved for getCheckoutDetails query.
   */
  currentValue?: Maybe<DrawdownContractValue>;
  /**
   * The remaining contract balance after paying for the current purchase.
   *
   * This is not resolved for getCheckoutDetails query.
   */
  remainingValue?: Maybe<DrawdownContractValue>;
  /** This the ID of the contract being used for drawdown. */
  contractId: Scalars['String'];
  /** A UI consumable name of the contract for consistent naming. */
  contractName?: Maybe<Scalars['String']>;
}

export interface DrawdownContractValue {
  dollars?: Maybe<Money>;
}

export interface DrawdownItem {
  drawdownContractDetails: DrawdownContractDetails;
  amount: Scalars['Int'];
}

export enum DrawdownType {
  Pack = 'PACK',
  Balance = 'BALANCE',
  Payable = 'PAYABLE',
}

export interface DriversLicence {
  verified: Scalars['Boolean'];
}

/** Feature to determine duration of a catalogue product is active. Eg AdBudgetProduct active for 12 months. */
export interface DurationFeature extends CatalogueProductFeature {
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
  /** Size of duration. Eg 60, 12 */
  value: Scalars['Int'];
  /** Unit of the duration. Eg YEAR, MONTH, DAY */
  unit: DurationUnit;
}

export enum DurationUnit {
  Year = 'YEAR',
  Month = 'MONTH',
  Week = 'WEEK',
  Day = 'DAY',
  Hour = 'HOUR',
  Minute = 'MINUTE',
  Second = 'SECOND',
}

export interface EditJobInput {
  advertiserId?: Maybe<Scalars['String']>;
  draftId: Scalars['String'];
  branding?: Maybe<BrandingInput>;
  idempotencyKey: Scalars['String'];
  jobId: Scalars['String'];
}

export interface EditJobResponse {
  revisionId?: Maybe<Scalars['String']>;
}

/** Base error interface. */
export interface Error {
  message: Scalars['String'];
}

export interface ExpectedSalaryV2 {
  amount: Array<SalaryAmountV2>;
  currency: Scalars['String'];
}

export interface ExpireJobInput {
  advertiserId?: Maybe<Scalars['String']>;
  jobId: Scalars['String'];
  /** When the expiryTime is set the job will be updated to expire at that time. This is only available during impersonation sessions */
  expiryTime?: Maybe<Scalars['DateTime']>;
  /** The reason for expiry - defaults to MANUAL */
  reason?: Maybe<ExpireReason>;
}

export interface ExpireJobResponse {
  jobId: Scalars['String'];
}

export enum ExpireReason {
  Natural = 'NATURAL',
  Manual = 'MANUAL',
  Fraudulent = 'FRAUDULENT',
  Noncompliant = 'NONCOMPLIANT',
}

export interface Expiry {
  expiryDate: Scalars['String'];
  expiresSoon: Scalars['Boolean'];
  daysRemaining: Scalars['Int'];
}

export interface ExportProfileInput {
  connectionType: TalentSearchConnectionType;
  profileId: Scalars['Int'];
  externalReferenceId?: Maybe<Scalars['String']>;
}

export enum FiltersAdRatingType {
  High = 'HIGH',
  Normal = 'NORMAL',
  Low = 'LOW',
  NoRating = 'NO_RATING',
}

export enum Frequency {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  EveryApplication = 'EVERY_APPLICATION',
  Never = 'NEVER',
}

export interface GetBrandingAssetsInput {
  assetType: BrandAssetTypeEnum;
  owner?: Maybe<BrandOwnerInput>;
}

export interface GetProductSetInput {
  /** Unique ID of the product set */
  productSetId: Scalars['ID'];
  /** The locale to get product set */
  locale?: Maybe<Scalars['String']>;
}

export type GetProductSetResponse =
  | ProductSet
  | ProductSetExpiredError
  | ProductSetNotFoundError;

export interface GetSurchargeAmountInput {
  /** The currency of the payment. */
  paymentCurrency: Currency;
  /** Credit card brand to determine any surcharges. */
  creditCardBrand?: Maybe<CreditCardBrandInput>;
  /** The total amount to be paid for the payment intent. */
  totalForPaymentIntent?: Maybe<Scalars['SafeInt']>;
  /** Temporary for the fix of big total amount to be paid for the payment intent. REMOVE this after update totalForPaymentIntent to SafeInt. */
  totalForPaymentIntentSafeInt?: Maybe<Scalars['SafeInt']>;
}

export interface GetSurchargeAmountPayload {
  /** Determine if the surcharge is applicable for payment method */
  surchargeApplicable: Scalars['Boolean'];
  /** The surcharge amount for payment method */
  surchargeAmount: Scalars['Int'];
}

export interface GranularLocation {
  type: RecommendationType;
  nonSpecificLocation: Scalars['String'];
  specificLocation: Scalars['String'];
}

export enum GuidedDetailsType {
  AboutBusiness = 'ABOUT_BUSINESS',
  Benefits = 'BENEFITS',
  Qualifications = 'QUALIFICATIONS',
  Responsibilities = 'RESPONSIBILITIES',
}

export interface HeaderFooterContext {
  /** Context supplied by Identity Provider (IdP). */
  identityContext?: Maybe<IdentityHeaderContext>;
  /** Profile information about the current user. */
  user?: Maybe<UserHeaderContext>;
  /** Advertiser-specific information. This will be null until account selection is complete. */
  advertiser?: Maybe<AdvertiserHeaderContext>;
}

export interface HeaderFooterContextInput {
  /** Location object. Optional. Supplied by client in order to support customer service view toggling. */
  location?: Maybe<WindowLocation>;
}

export interface HelpTip {
  /** Content of a help tip */
  content?: Maybe<Scalars['String']>;
  /** Image to help describe a product */
  image?: Maybe<HelpTipImage>;
}

export interface HelpTipImage {
  /** Image path where staging/prod url path is prepended */
  url: Scalars['String'];
  /** Alternative text used in the UI */
  altText: Scalars['String'];
}

export interface HiddenPrice {
  /** price access level (priceVisibility) if hidePrice is enabled for the hirer */
  priceVisibility: PriceVisibility;
}

export interface HirerAccount {
  billingStatus?: Maybe<BillingStatus>;
  nation?: Maybe<Scalars['String']>;
  parent?: Maybe<Parent>;
  /** Seek billing account ID used by CRM */
  billingAccountId?: Maybe<Scalars['String']>;
  /** The billing address of the account */
  billingAddress: PhysicalAddress;
  /** The postal address of the account */
  postalAddress: PhysicalAddress;
  /** The primary contact of the account */
  primaryContact: PrimaryContact;
  /** The billing contact of the account */
  billingContact: BillingContact;
  /**
   * Hirer (Advertiser) account status
   * @deprecated Use activationStatus instead
   */
  status: HirerAccountStatus;
  /** Hirer account activation status */
  activationStatus: AccountActivationStatus;
  /** Business name that gets collected on the registration form */
  businessName: Scalars['String'];
  /** Country-specific identifier like ABN, NZBN */
  businessIdentifier?: Maybe<Scalars['String']>;
  /** The details of the agency that manages the account if there is one */
  managingAgency?: Maybe<ManagingAgency>;
  /** Indicates if the account is managed by an agency */
  hasAgent?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the LinkOut feature is enabled for the hirer. */
  hasLinkOutEnabled?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the account is archived and in deactivated status. */
  isArchived: Scalars['Boolean'];
  /** Indicates whether the Mirroring/Hyperlinks permission is enabled in CRM. */
  isEsmAccount?: Maybe<Scalars['Boolean']>;
  /** Show or hide all pricing from hirer */
  hidePrice?: Maybe<Scalars['Boolean']>;
  hirerGroup?: Maybe<HirerGroup>;
  /** Indicates which type hirer belongs to */
  hirerType?: Maybe<HirerType>;
  /** Indicates the zone the account was originally created in. */
  originZone?: Maybe<Scalars['String']>;
  /** Indicates whether the advertiser would prefer to hide their company name in their job ads. Null if the hirer has not set their preference yet. */
  isPrivateAdvertiser?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the advertiser is yet to be approved in Salesforce/SAP. */
  isApproved?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the account is approved for Talent Search access. New accounts have to wait 24 hours from when the account is approved before access to Talent Search access is granted. */
  isTalentSearchApproved?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the advertiser is marked as a test account in CRM. */
  isTestAccount?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the advertiser is managed by a Sales Consultant. */
  isManagedAccount?: Maybe<Scalars['Boolean']>;
  /** To show or hide the different price states */
  priceVisibility?: Maybe<PriceVisibility>;
  /** Indicates which pricing model hirer belongs to, either Fixed or Horizon */
  pricingModel?: Maybe<PricingModel>;
  /** @deprecated This field is no longer neccessary due to FAA */
  rules?: Maybe<Array<Maybe<Rule>>>;
  /** Indicates if a hirer has a pricing group contract available */
  pricingGroupContractExists?: Maybe<Scalars['Boolean']>;
  /** The list of users associated with the account */
  users: Array<User>;
  integrations: IntegrationConnection;
}

export interface HirerAccount_IntegrationsArgs {
  input: HirerAccountIntegrationsInput;
}

export interface HirerAccountDetails {
  /** Seek billing account ID used by CRM */
  billingAccountId?: Maybe<Scalars['String']>;
  /** The billing address of the account */
  billingAddress: PhysicalAddress;
  /** The postal address of the account */
  postalAddress: PhysicalAddress;
  /** The primary contact of the account */
  primaryContact: PrimaryContact;
  /** The billing contact of the account */
  billingContact: BillingContact;
  /** Hirer (Advertiser) account status */
  status: HirerAccountStatus;
  /** Business name that gets collected on the registration form */
  businessName?: Maybe<Scalars['String']>;
  /** Country-specific identifier like ABN, NZBN */
  businessIdentifier?: Maybe<Scalars['String']>;
  /** The details of the agency that manages the account if there is one */
  managingAgency: ManagingAgency;
}

export interface HirerAccountDetailsQueryInput {
  /** The ID of Advertiser in AdCentre */
  adCentreAdvertiserId: Scalars['ID'];
}

export interface HirerAccountIntegrationsInput {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}

export interface HirerAccountLoginUrlInput {
  /** Hirer Account ID for the login request. The user will require authorization to view the account. */
  hirerAccountId: Scalars['String'];
  /** Return uri for successful account switching. */
  returnUri?: Maybe<Scalars['String']>;
  /** Language param to apply additional localisation to auth related pages. */
  language?: Maybe<Scalars['String']>;
}

export enum HirerAccountStatus {
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED',
  PendingActivation = 'PENDING_ACTIVATION',
}

export interface HirerAccountUserQueryInput {
  /** User ID of the user we want to find */
  userId: Scalars['String'];
  /** Request to get user permissions to account. Can be "ACCESS_DETAILS" */
  include?: Maybe<UsersParamsInclude>;
}

export interface HirerActivitiesByJobInput {
  /** The ID of the job */
  jobId: Scalars['String'];
  /** The SEEK ID of the advertiser */
  advertiserId?: Maybe<Scalars['String']>;
}

export interface HirerActivity {
  id: Scalars['ID'];
  /** The type of the activity */
  activityType: HirerActivityType;
  /** A list of extra data related the activity */
  activityData: Array<HirerActivityData>;
  /** The time when the activity occurred */
  activityTime: Scalars['DateTime'];
  /** First name of the account user who triggers the activity. */
  userFirstName: Scalars['String'];
  /** Last name of the account user who triggers the activity. */
  userLastName: Scalars['String'];
  /** Partial list of the  job applications related to the activity, max 3 job applications. */
  partialJobApplicationList: Array<HirerActivityJobApplication>;
}

export interface HirerActivityConnection {
  pageInfo: PageInfo;
  edges: Array<HirerActivityEdge>;
}

export interface HirerActivityData {
  /** The name of data. eg: noteContent */
  name: Scalars['String'];
  /** The value of data. eg: content of the note */
  value?: Maybe<Scalars['String']>;
}

export interface HirerActivityEdge {
  /** String based cursor for the edge node */
  cursor: Scalars['String'];
  /** Node representing hirer activity */
  node: HirerActivity;
}

export interface HirerActivityJobApplication {
  /** First name of the candidate. */
  candidateFirstName: Scalars['String'];
  /** Last name of the candidate. */
  candidateLastName: Scalars['String'];
  /** Prospect id of job application in Adcentre */
  adcentreProspectId: Scalars['Int'];
}

export enum HirerActivityType {
  NoteAdded = 'NOTE_ADDED',
  NoteDeleted = 'NOTE_DELETED',
}

export interface HirerDashboardArticle {
  /** Image URL */
  imageUrl: Scalars['String'];
  /** Title of the image */
  imageTitle: Scalars['String'];
  /** Title of the article */
  title: Scalars['String'];
  /** Article URL */
  url: Scalars['String'];
  /** Minutes to read article */
  timeToRead: Scalars['Int'];
}

export interface HirerDashboardBudgetUpsellInput {
  advertiserId?: Maybe<Scalars['String']>;
  dataSource?: Maybe<Scalars['String']>;
}

export interface HirerDashboardBudgetUpsellResponse {
  /** Set to true when a hirer is eligible for ad budget upsell on the hirer dashboard */
  upsellEligible: Scalars['Boolean'];
  /** Max contract discount rate used for display on the hirer dashboard */
  maxContractDiscountRate?: Maybe<Scalars['Float']>;
}

export interface HirerDashboardHiringAdviceList {
  /** Articles to display on the dashboard */
  articles?: Maybe<Array<Maybe<HirerDashboardArticle>>>;
  count: Scalars['Int'];
}

export interface HirerDashboardJob {
  /** The ID of the position from the AdCentre database */
  positionId?: Maybe<Scalars['String']>;
  /** The ID of the listing from the AdCentre database */
  listingId?: Maybe<Scalars['String']>;
  /** The ID of the job from the SeekWeb database */
  jobId?: Maybe<Scalars['String']>;
  /** The ID of the draft from the AdCentre database */
  draftId?: Maybe<Scalars['String']>;
  /** The title of the position/job/draft */
  title: Scalars['String'];
  /** Number of candidates */
  numberOfCandidates: Scalars['Int'];
  /** Number of new candidates */
  numberOfNewCandidates: Scalars['Int'];
  /** Status of the job */
  status?: Maybe<Scalars['String']>;
  /** The day job listing has started */
  startDateUtc?: Maybe<Scalars['String']>;
  /** The day job listing ends */
  endDateUtc?: Maybe<Scalars['String']>;
  /** Number of days remaining until the job gets expired, if the job end date is in the future. */
  daysRemaining?: Maybe<Scalars['Int']>;
  /** The source of job as direct job, indirect job (posted via an uploader), or draft */
  kind: Scalars['String'];
  /**
   * Ad Health Check rating of the job
   * @deprecated Use performanceRating instead to grant more control over ad rating
   */
  jobAdRating?: Maybe<AnalyticsAdRatingScore>;
  /**
   * Indicates the performance rating of the job. Includes UNAVAILABLE|WITHHELD to
   * cover more scenario with introduction of new markets
   */
  performanceRating?: Maybe<AnalyticsAdRating>;
  /** Job location ID */
  unifiedLocationId?: Maybe<Scalars['Int']>;
  /** Location details resolved from the unifiedLocationId */
  location?: Maybe<Location>;
  /** Position of the item in the list */
  listPosition: Scalars['Int'];
  /** Does the ad fit the criteria to allow it to be refreshed (Ad is direct posted, is Classic or Standout, has status of Active, has less than 29 days remaining) */
  canBeRefreshed?: Maybe<Scalars['Boolean']>;
  /** Type of ad */
  adType?: Maybe<Scalars['String']>;
  /** Impersonation-aware url for the job on talent search. */
  talentSearchJobHref?: Maybe<Scalars['String']>;
}

export interface HirerDashboardJobCounts {
  /** Count of drafts.  For indirect listings, this count will always be zero. */
  draft?: Maybe<Scalars['Int']>;
  /**
   * Count of listings that have been posted; they will either have successfully posted and are Active, or they are in a transitional state of Saved, Pending or Unsuccessful.
   * For direct listings, the underlying datasource caps this at 20, and the true count may be higher.
   */
  posted?: Maybe<Scalars['Int']>;
  /**
   * Count of expired or disabled listings.
   * For direct listings, the underlying datasource caps this at 20, and the true count may be higher.
   */
  expired?: Maybe<Scalars['Int']>;
}

export interface HirerDashboardJobs {
  /** Jobs to display on the dashboard */
  jobs?: Maybe<Array<Maybe<HirerDashboardJob>>>;
  /** Last Draft this user has worked with */
  lastDraft?: Maybe<HirerDashboardJob>;
  /** Directly posted job counts by status */
  directCounts?: Maybe<HirerDashboardJobCounts>;
  /** Indirectly posted job counts by status */
  indirectCounts?: Maybe<HirerDashboardJobCounts>;
}

export interface HirerDashboardJobsQueryInput {
  /** The ID of Advertiser in AdCentre */
  adCentreAdvertiserId?: Maybe<Scalars['ID']>;
  /**
   * Maximum number of jobs to be returned.
   * Will return all jobs received from resource API if this parameter is not provided, or the value is 0.
   */
  maxResults?: Maybe<Scalars['Int']>;
  /** SEEK Advertiser Id */
  seekAdvertiserId?: Maybe<Scalars['String']>;
}

export interface HirerDashboardTalentSearch {
  premiumTalentSearchHref?: Maybe<Scalars['String']>;
}

export interface HirerDashboardUserDetails {
  /** First name of the user */
  firstName: Scalars['String'];
  /** Last name of the user */
  lastName: Scalars['String'];
  /** Email of the user */
  email: Scalars['String'];
}

export interface HirerDashboardWelcome {
  /** Welcome message for the user */
  message: Scalars['String'];
  /** Timestamp when user logged in (ISO 8601) */
  timestamp: Scalars['String'];
}

export interface HirerExperience {
  /** Describes if simplified answers were selected - either "simplifiedAnswers" or "answers" */
  selectionType: Scalars['String'];
  /** The simplified answers for the question - present only if selectionType is "simplifiedAnswers" */
  simplifiedAnswers?: Maybe<Array<SimplifiedAnswers>>;
  /**
   * The hirer control type of this question - one of 'Default', 'Range', 'RangeUpTo', 'RangeAtLeast', 'AdvancedAvailability',
   * this will be null when the question source is custom
   */
  controlType?: Maybe<Scalars['String']>;
  /** Null if the question is not mandatory, defined if the question is mandataory */
  mandatory?: Maybe<MandatoryData>;
}

export enum HirerGroup {
  Advertiser = 'ADVERTISER',
  Agent = 'AGENT',
  RecruitmentSoftwareProvider = 'RECRUITMENT_SOFTWARE_PROVIDER',
}

export interface HirerJob {
  /**
   * The job ID.
   * The value may be `null` if the `status` is `PENDING`.
   */
  id?: Maybe<Scalars['String']>;
  /** The AdCentre position ID. */
  adCentrePositionId: Scalars['String'];
  /** The AdCentre listing ID. */
  adCentreListingId?: Maybe<Scalars['String']>;
  /**
   * The full name of the user who created the job.
   * May be `null` if the `postChannel` is `INDIRECT`.
   */
  createdBy?: Maybe<Scalars['String']>;
  /**
   * Date/time in UTC (ISO 8601 format) the job was created.
   * The job is only accessible to candidates once it has a `listingDate`.
   */
  createdDate: Scalars['String'];
  /**
   * Date/time in UTC (ISO 8601 format) the job was searchable and accessible to candidates.
   * This may be later than `createdDate` as a hirer may create the job but complete the payment at a later date.
   */
  listingDate?: Maybe<Scalars['String']>;
  /** Number of days remaining until the job gets expired, if the job end date is in the future. */
  daysRemaining?: Maybe<Scalars['Int']>;
  /**
   * An indication on if the job is open or closed.
   * For more specific details on the wrapped job status, see the `job.status` field.
   */
  status: HirerJobStatus;
  /** The channel the job was posted from. This does not refer to which underlying system used to post the job. */
  postChannel: HirerJobPostChannel;
  /** Job title appearing on search results, job lists and candidate management pages. */
  title: Scalars['String'];
  /** A reference provided by the advertiser to facilitate searching in job lists. */
  advertiserJobReference?: Maybe<Scalars['String']>;
  /** A reference provided by the agent to facilitate searching in job lists. */
  agencyExternalReference?: Maybe<Scalars['String']>;
  /**
   * Locations the job is advertised in.
   * While the schema allows multiple locations, only one will be returned for AU/NZ.
   * @deprecated Use locations array instead to get more details
   */
  locationDisplayNames?: Maybe<Array<Scalars['String']>>;
  /**
   * Locations the job is advertised in.
   * While the schema allows multiple locations, up to one will be returned for AU/NZ.
   */
  locations: Array<Location>;
  /** Number of candidates. */
  numberOfCandidates: Scalars['Int'];
  /** Number of new candidates. */
  numberOfNewCandidates: Scalars['Int'];
  /**
   * Indicates the performance rating of the job.
   * @deprecated Use performanceRating instead to grant more control over ad rating
   */
  jobAdRating?: Maybe<AnalyticsAdRatingScore>;
  /**
   * Indicates the performance rating of the job. Includes UNAVAILABLE|WITHHELD to
   * cover more scenario with introduction of new markets
   */
  performanceRating?: Maybe<AnalyticsAdRating>;
  /** Impersonation-aware url for the job on talent search. */
  talentSearchJobHref?: Maybe<Scalars['String']>;
  /** Indicates if the job ad can be extended or not. */
  canExtendAd: Scalars['Boolean'];
  /**
   * Indicates if the job ad can be upgraded to a Premium job. (Used by CS)
   * @deprecated No longer used
   */
  canUpgradeToPremium?: Maybe<Scalars['Boolean']>;
  /** Indicates if the job ad is promoted (is a premium listing) or not. */
  isPromoted: Scalars['Boolean'];
}

export interface HirerJobConnection {
  /**
   * The total number of hirer jobs across all pages.
   * The UI can use the `totalCount` and input `limit` to compute the total page count.
   */
  totalCount: Scalars['Int'];
  /** The list of `HirerJob` edges for the current page. */
  edges: Array<HirerJobEdge>;
}

export interface HirerJobEdge {
  /** A `HirerJob` from the pagination query. */
  node?: Maybe<HirerJob>;
}

export enum HirerJobPostChannel {
  /** Jobs posted via the SEEK Employer Website. */
  Direct = 'DIRECT',
  /** Jobs posted using a third party system via the SEEK API. */
  Indirect = 'INDIRECT',
}

export enum HirerJobStatus {
  /** The job has been posted but is not searchable or visible to candidates yet. */
  Pending = 'PENDING',
  /** The job has been posted but may or may not be visible to candidates due to the `JobStatus`. */
  Open = 'OPEN',
  /** The job is no longer visible to candidates typically due to being expired. */
  Closed = 'CLOSED',
}

export enum HirerJobStatusFilter {
  /** The job has been posted but may or may not be visible to candidates due to the `JobStatus`. */
  Open = 'OPEN',
  /** The job is no longer visible to candidates typically due to being expired. */
  Closed = 'CLOSED',
}

export interface HirerJobsInput {
  /** The advertiser that owns the jobs. */
  advertiserId?: Maybe<Scalars['String']>;
  /**
   * The number of results to skip where the value must be a multiple of `limit`.
   * `null` defaults to an offset of `0`.
   * Example: With a `limit` of `5` results per page, an `offset` of `0` implies the first page of results, and an `offset` of `15` implies the fourth page of results.
   */
  offset?: Maybe<Scalars['Int']>;
  /** The maximum number of results per page. */
  limit?: Maybe<Scalars['Int']>;
  /** The job search term. */
  term?: Maybe<Scalars['String']>;
  /**
   * Fetch only open or closed jobs.
   * When `null`, open and closed jobs are returned.
   */
  status?: Maybe<HirerJobStatusFilter>;
  /**
   * The channel the job was posted from.
   * When `null`, jobs for all relevant channel types are returned.
   */
  postChannel?: Maybe<HirerJobPostChannel>;
  /** The advertiser country */
  contextualCountry?: Maybe<Scalars['String']>;
  /** The language SEEK site is using */
  language?: Maybe<Scalars['LanguageCodeIso']>;
}

export interface HirerPrimaryContact {
  /** First name of the user */
  firstName: Scalars['String'];
  /** Last name of the user */
  lastName: Scalars['String'];
  /** Email address of the user */
  email: Scalars['String'];
  /** Phone number of the contact */
  phone?: Maybe<Scalars['String']>;
}

export enum HirerQuestionType {
  SingleSelect = 'SingleSelect',
  FreeText = 'FreeText',
}

export enum HirerType {
  Bpo = 'BPO',
  Corporate = 'CORPORATE',
  JoraLocal = 'JORA_LOCAL',
  Government = 'GOVERNMENT',
  Recruiter = 'RECRUITER',
  Sme = 'SME',
  SubscriptionPartner = 'SUBSCRIPTION_PARTNER',
}

export interface HirerUserDetails {
  userDeets: Scalars['JSON'];
  /** The id of the hirer user (formerly known as "userId") */
  hirerUserId: Scalars['String'];
  /** Email address of the hirer user */
  email: Scalars['String'];
  /** Family name of the hirer user (formerly known as "Last Name" in ANZ systems) */
  familyName: Scalars['String'];
  /** Given name of the hirer user (formerly known as "First Name" in ANZ Systems) */
  givenName: Scalars['String'];
  /** If the Hirer User is currently in a deactivated  / archived state */
  isArchived: Scalars['Boolean'];
  /** Verification status of the hirer user - I.e. Whether they have "activated" themselves or are yet to click on the verification email */
  isVerified: Scalars['Boolean'];
}

export interface HirerUserEmailInput {
  /** Email of the user we want to retrieve */
  userEmail: Scalars['String'];
}

export interface HirerUserEmailQueryInput {
  /** Email address of the user we want to find */
  email: Scalars['String'];
}

export interface Identity {
  /** User requesting access to a resource. */
  subject: Subject;
  /** Operator making requests on behalf of user. */
  actor?: Maybe<Actor>;
  /**
   * Indicates that the user is CS operator, regardless of whether they're actively impersonating.
   * Either true or undefined.
   */
  allowImpersonation?: Maybe<Scalars['Boolean']>;
  /** Indicates that the CS operator has enabled administrator mode. Either true or undefined. */
  showCustomerServiceView?: Maybe<Scalars['Boolean']>;
}

export interface IdentityHeaderContext {
  /** Email address of the user. */
  email: Scalars['String'];
  /** Details of the cs user making requests on behalf of user. */
  act?: Maybe<ActorContext>;
}

export interface IdentityHeaderContext_ActArgs {
  input?: Maybe<HeaderFooterContextInput>;
}

export interface IdentityIds {
  /** Advertiser ID which the user is currently signed in as */
  advertiserId: Scalars['String'];
  /** AdCentre user ID of the user that is currently signed in */
  userId: Scalars['String'];
}

export interface Image {
  /** Image path where staging/prod url path is prepended */
  url: Scalars['String'];
  /** Description of an image */
  description: Scalars['String'];
}

export interface InitiateConnectionPayload {
  connectionId: Scalars['String'];
}

export interface Integration {
  partner: IntegrationPartner;
  relationships: Array<IntegrationRelationship>;
}

export interface IntegrationConnection {
  totalCount: Scalars['Int'];
  edges: Array<IntegrationEdge>;
}

export interface IntegrationEdge {
  node: Integration;
}

export interface IntegrationPartner {
  id: Scalars['ID'];
  name: Scalars['String'];
  configurableRelationships: Array<IntegrationRelationshipType>;
}

export interface IntegrationRelationship {
  id: Scalars['ID'];
  relationshipType: IntegrationRelationshipType;
}

export interface IntegrationRelationshipType {
  typeCode: Scalars['String'];
  name: Scalars['String'];
}

export interface InteractedJob {
  title: Scalars['String'];
  locationId?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
}

export interface InteractedJob_LocationArgs {
  language?: Maybe<Scalars['LanguageCodeIso']>;
}

export interface InvalidInputPayload {
  /** Reason of invalid used input. i.e. 'Validation error: logo is required to create a brand' */
  reason?: Maybe<Scalars['String']>;
}

export interface Invoice {
  /** The ID of the invoice. */
  id: Scalars['ID'];
  /** The status of the invoice. */
  invoiceStatus: InvoiceStatus;
}

/** Error for invalid, expired, or not found invoices. */
export interface InvoiceError extends Error {
  message: Scalars['String'];
}

export interface InvoiceInput {
  reference: Scalars['String'];
  amount: Scalars['SafeInt'];
}

export interface InvoiceOrderNotFoundPayload {
  /** Requested OrderId could not be found. */
  reason?: Maybe<Scalars['String']>;
}

export interface InvoiceOrderSuccessPayload {
  responseStatus: Scalars['String'];
}

export enum InvoiceStatus {
  /** The invoice is due. */
  Due = 'due',
  /** The invoice is pending. */
  Pending = 'pending',
  /** The invoice is paid. */
  Paid = 'paid',
}

export interface Job {
  /**
   * The job ID.
   * This should contain the same values as `jobId` and will eventually replace the `jobId` field.
   */
  id: Scalars['String'];
  /**
   * The channel the job was posted from. This does not refer to which underlying system used to post the job.
   * Current values could be `DIRECT`, `INDIRECT` or `IMPORT`.
   */
  postChannel: Scalars['String'];
  /** @deprecated Use `productDisplayName` instead and if empty fallback to `adType` */
  adType?: Maybe<JobAdType>;
  productDisplayName?: Maybe<Scalars['String']>;
  /** A reference provided by the advertiser to facilitate searching in job lists. */
  advertiserJobReference?: Maybe<Scalars['String']>;
  applicationFormUrl?: Maybe<Scalars['String']>;
  branding?: Maybe<Brand>;
  /**
   * Date/time in UTC (ISO 8601 format) the job was created.
   * The job is only accessible to candidates once it has a `listingDate`.
   */
  createdDate: Scalars['String'];
  /**
   * Date/time in UTC (ISO 8601 format) the job was searchable and accessible to candidates.
   * This may be later than `createdDate` as a hirer may create the job but complete the payment at a later date.
   */
  listingDate?: Maybe<Scalars['String']>;
  /**
   * Expiry date/time in UTC (ISO 8601 format).
   * This may be null if the job is not live yet.
   */
  expiryDate?: Maybe<Scalars['String']>;
  /** Indicates whether this is a private ad */
  isConfidential?: Maybe<Scalars['Boolean']>;
  jobDescription?: Maybe<Scalars['String']>;
  /** @deprecated Use `id` instead */
  jobId: Scalars['String'];
  jobSummary?: Maybe<Scalars['String']>;
  keySellingPoints?: Maybe<Array<Maybe<Scalars['String']>>>;
  locationId?: Maybe<Scalars['String']>;
  /**
   * Location details resolved from the `locationId`
   * While the schema allows multiple locations, only one will be returned for AU/NZ.
   */
  locations: Array<Location>;
  maxSalary?: Maybe<Scalars['SafeInt']>;
  minSalary?: Maybe<Scalars['SafeInt']>;
  payType?: Maybe<Scalars['String']>;
  /** Hirer's phone number */
  phoneNumber?: Maybe<Scalars['String']>;
  questionnaireUrl?: Maybe<Scalars['String']>;
  salaryCurrency?: Maybe<JobSalaryCurrency>;
  salaryText?: Maybe<Scalars['String']>;
  status: JobStatus;
  subCategoryId?: Maybe<Scalars['String']>;
  categoryName?: Maybe<Scalars['String']>;
  subCategoryName?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  videoUrl?: Maybe<Scalars['String']>;
  workType?: Maybe<WorkType>;
  /** Indicates the performance rating of the job. */
  jobAdRating?: Maybe<AnalyticsAdRatingScore>;
  /** Product features belonging to the job */
  productFeatures?: Maybe<Array<CatalogueProductFeature>>;
  performanceRating?: Maybe<AnalyticsAdRating>;
  billableApplicants: BillableApplicantsResponse;
}

export interface JobAd {
  adRating?: Maybe<AdRatingType>;
  adType: AdUsageAdType;
  applications: Scalars['Int'];
  locationLabel: Scalars['String'];
  groupKey: Scalars['String'];
  hidePrice: Scalars['Boolean'];
  isLatestGroup: Scalars['Boolean'];
  isRefresh: Scalars['Boolean'];
  isRepost: Scalars['Boolean'];
  jobId: Scalars['String'];
  jobTitle: Scalars['String'];
  jobCreatedTimestampUTC: Scalars['String'];
  status: AdUsageAdStatus;
  totalNetAmount: Money;
  userFullName?: Maybe<Scalars['String']>;
}

export enum JobAdBookingCountryCode {
  Au = 'AU',
  Nz = 'NZ',
  Hk = 'HK',
  Th = 'TH',
  My = 'MY',
  Sg = 'SG',
  Id = 'ID',
  Ph = 'PH',
}

export interface JobAdDetails {
  roleTitle: Scalars['String'];
  locationId: Scalars['String'];
  subClassificationId: Scalars['String'];
}

/** AdProductUpgrade feature that indicates the ability for editing the details of an existing ad. */
export interface JobAdEditFeature extends CatalogueProductFeature {
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
}

export interface JobAdListResponse {
  lastUpdated: Scalars['DateTime'];
  count: Scalars['Int'];
  jobAds?: Maybe<Array<Maybe<JobAd>>>;
  hasMultipleCurrencies: Scalars['Boolean'];
}

export interface JobAdListWidgetInput {
  locale: Scalars['String'];
  pageNumber: Scalars['Int'];
  size: Scalars['Int'];
  filters: AdUsageQueryFilters;
}

export interface JobAdNotUpgradableToPremium {
  /** Reason this job is not upgradable. i.e. 'Job Ad must be Branded to be upgradable to Premium' or 'Job Ad can only be upgraded on the day of posting' */
  reason?: Maybe<Scalars['String']>;
}

export interface JobAdRevisionAttributes {
  /** Id of the existing Job Ad. */
  jobId: Scalars['String'];
  /** Revision id of the existing Job Ad. */
  revisionId: Scalars['String'];
}

export enum JobAdType {
  Classic = 'CLASSIC',
  Branded = 'BRANDED',
  Premium = 'PREMIUM',
  BrandedUpgrade = 'BRANDED_UPGRADE',
  Guaranteed = 'GUARANTEED',
  Concierge = 'CONCIERGE',
}

export enum JobSalaryCurrency {
  Aud = 'AUD',
  Bdt = 'BDT',
  Cny = 'CNY',
  Eur = 'EUR',
  Gbp = 'GBP',
  Hkd = 'HKD',
  Jpy = 'JPY',
  Idr = 'IDR',
  Inr = 'INR',
  Myr = 'MYR',
  Nzd = 'NZD',
  Php = 'PHP',
  Sgd = 'SGD',
  Thb = 'THB',
  Usd = 'USD',
  Vnd = 'VND',
}

export enum JobStatus {
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Pending = 'PENDING',
  Suspended = 'SUSPENDED',
  PendingAccountApproval = 'PENDING_ACCOUNT_APPROVAL',
}

export interface JobTitleOption {
  key: Scalars['String'];
  count: Scalars['Int'];
}

export interface LastUpdatedResponse {
  lastUpdated: Scalars['DateTime'];
}

/** This specific type is used for order summary */
export interface LineItemProduct extends Product {
  /** Name used when displaying this product as a line item of a purchase */
  name: Scalars['String'];
  /** Type of product purchased */
  productType: ProductType;
  /** Description of the line item */
  description: Scalars['String'];
}

export interface Link {
  rel: Scalars['String'];
  href: Scalars['String'];
}

export interface LocalisedContactInfoContext {
  /** Contact us link. Stored in hygraph. */
  contactUsLink?: Maybe<Scalars['String']>;
  /** Help centre link. Stored in hygraph. */
  helpCentreLink?: Maybe<Scalars['String']>;
  /** Contact number. Stored in hygraph. */
  contactNumber?: Maybe<Scalars['String']>;
  /** Business hours. Stored in hygraph. */
  businessHours?: Maybe<Scalars['String']>;
}

export interface Location {
  id: Scalars['ID'];
  country: Scalars['String'];
  type: Scalars['String'];
  description: Scalars['String'];
  displayDescription: Scalars['String'];
  parent?: Maybe<ParentLocation>;
}

export enum LocationContext {
  /** Uses JoB Posting Filter logic. This will be the default if context is not specified. */
  JobPosting = 'JOB_POSTING',
  /** Uses Talent Search Filter logic. */
  TalentSearch = 'TALENT_SEARCH',
}

export interface LocationOption {
  key: Scalars['Int'];
  value: Scalars['String'];
  level: Scalars['Int'];
  parentId: Scalars['Int'];
  count: Scalars['Int'];
}

export interface LocationSearchResult {
  id: Scalars['ID'];
  country: Scalars['String'];
  countryId: Scalars['ID'];
  type: Scalars['String'];
  description: Scalars['String'];
  parents: Array<Maybe<LocationSearchResult>>;
  /** @deprecated unified location service doesn't return this field */
  matchedSearchString?: Maybe<Array<MatchedSearchString>>;
}

export interface LogoStrategies {
  serpLogo: Scalars['String'];
  jdpLogo: Scalars['String'];
}

export interface LowApply {
  type: RecommendationType;
  conversionRate: Scalars['Float'];
  averageConversionRate: Scalars['Float'];
}

export interface LowClick {
  type: RecommendationType;
  conversionRate: Scalars['Float'];
  averageConversionRate: Scalars['Float'];
}

export interface LowSalary {
  type: RecommendationType;
  currency: Scalars['String'];
  salaryType: AnalyticsSalaryType;
  salaryRangeMin: Scalars['Float'];
  salaryRangeMax: Scalars['Float'];
  suggestedRangeMin?: Maybe<Scalars['Int']>;
  suggestedRangeMax?: Maybe<Scalars['Int']>;
  suggestedAverageRangeMin?: Maybe<Scalars['Int']>;
  suggestedAverageRangeMax?: Maybe<Scalars['Int']>;
  normalisedRoleTitle: Scalars['String'];
  benchmarkLocationLabel: Scalars['String'];
}

export interface LowestPricedProductForAdPostingInput {
  /** Id of the advertiser */
  advertiserId?: Maybe<Scalars['ID']>;
  /** Unique ID of the product set */
  productSetId: Scalars['ID'];
}

export type LowestPricedProductForAdPostingResponse =
  | AdProduct
  | ProductSetExpiredError
  | ProductSetNotFoundError;

export interface Maintenance {
  paymentMethod: Scalars['String'];
  disablePayment?: Maybe<Scalars['Boolean']>;
  showMaintenance?: Maybe<Scalars['Boolean']>;
  disableMessage?: Maybe<DisableMessageType>;
  paymentMedium?: Maybe<Array<Maybe<PaymentMediumType>>>;
}

export interface ManagingAgency {
  /** Id of the agency. */
  advertiserId?: Maybe<Scalars['String']>;
}

export interface MandatoryData {
  /** Tooltip-friendly answer text to display for mandatory questions */
  mandatoryAnswerText: Array<Scalars['String']>;
}

export interface MarketInsightMonth {
  count: Scalars['Int'];
  calendarMonth: Scalars['String'];
}

export interface MarketInsightsTitleResponse {
  benchmarkLocationLabel?: Maybe<Scalars['String']>;
  benchmarkScenario?: Maybe<AnalyticsBenchmarkScenario>;
  classificationName: Scalars['String'];
  normalisedRoleTitle?: Maybe<Scalars['String']>;
  subClassificationName: Scalars['String'];
}

export interface MarketInsightsTitleWidgetInput {
  jobId: Scalars['String'];
  hirerOriginZone: Scalars['String'];
  locale: Scalars['String'];
}

export interface MarketPerformanceResponse {
  highAds: Scalars['Int'];
  lowAds: Scalars['Int'];
  normalAds: Scalars['Int'];
  noRatingAds: Scalars['Int'];
  totalAds: Scalars['Int'];
}

export interface MarketPerformanceWidgetInput {
  filters: AdUsageQueryFilters;
}

export interface MatchedSearchString {
  text: Scalars['String'];
  matches?: Maybe<Array<Maybe<Array<Maybe<Scalars['Int']>>>>>;
}

export interface MaximiseBranding extends TipToImproveAds {
  type: TipsToImproveAdsType;
  adCount: Scalars['Int'];
  bulletPointsUsed: Scalars['Boolean'];
  logoUsed: Scalars['Boolean'];
  coverImageUsed: Scalars['Boolean'];
}

export interface MissingAdvertiserIdClaimError extends Error {
  message: Scalars['String'];
}

export interface MissingUserIdClaimError extends Error {
  message: Scalars['String'];
}

export interface Money {
  value: Scalars['SafeInt'];
  currency: Currency;
}

export interface MostAdsByPerformanceCategory {
  user: MostAdsByPerformanceEntity;
  classification: MostAdsByPerformanceEntity;
}

export interface MostAdsByPerformanceEntity {
  label?: Maybe<Scalars['String']>;
  number: Scalars['Int'];
  percentage: Scalars['Float'];
}

export interface MostAdsByPerformanceResponse {
  low: MostAdsByPerformanceCategory;
  high: MostAdsByPerformanceCategory;
}

export interface MostAdsByPerformanceWidgetInput {
  locale: Scalars['String'];
  filters: AdUsageQueryFilters;
}

export interface Mutation {
  _empty?: Maybe<Scalars['String']>;
  applyCoverToAllBrands?: Maybe<Array<Maybe<Brand>>>;
  createBrand?: Maybe<CreateBrandPayload>;
  updateBrand?: Maybe<UpdateBrandPayload>;
  clearDefaultBrand?: Maybe<Brand>;
  setDefaultBrand?: Maybe<Brand>;
  deleteBrand?: Maybe<Brand>;
  deleteBrandingAsset?: Maybe<DeleteBrandingAssetPayload>;
  patchCompanyProfile?: Maybe<PatchCompanyProfileResponse>;
  /** Creates a Payment Intent and provides the clientSecret which is used on the client-side to charge the card with the Stripe confirmCardPayment API. */
  createPaymentIntent?: Maybe<CreatePaymentIntentPayload>;
  /** To pay for a given order using provided payment method id associated to a saved credit card. */
  payBySavedCreditCard?: Maybe<PayBySavedCreditCardPayload>;
  /** To delete a saved credit card using provided payment method id. */
  deleteSavedCreditCard?: Maybe<DeleteSavedCreditCardPayload>;
  deleteDraftJob?: Maybe<DeleteDraftJobPayload>;
  createDraftJob: CreateDraftJobPayload;
  updateDraftJob: UpdateDraftPayload;
  updateAccountPostalAddress?: Maybe<AccountDetailsUpdateResponse>;
  updateAccountPrimaryContact?: Maybe<AccountDetailsUpdateResponse>;
  updateAccountBillingAddress?: Maybe<AccountDetailsUpdateResponse>;
  updateAccountBillingEmailAddress?: Maybe<AccountDetailsUpdateResponse>;
  publishJobPostedEvent?: Maybe<PublishJobPostedEventPayload>;
  triggerActivation: TriggerActivationPayload;
  /** Send a verification email to a hirer. */
  sendEmailVerificationEmail?: Maybe<SendEmailVerificationEmailPayload>;
  /** Track the details of a successful login which cannot be reliably tracked server-side. */
  sendLoginCallbackEvent?: Maybe<SendLoginCallbackEventPayload>;
  /** Send a enrolment to MFA email to a hirer. */
  sendEnrolmentEmail?: Maybe<SendEnrolmentEmailPayload>;
  /** Creates relationships between a hirer and partner */
  createIntegrationRelationships: Array<IntegrationRelationship>;
  /** Deletes relationships between a hirer and partner */
  deleteIntegrationRelationships: Array<IntegrationRelationship>;
  /** Update the status of an invoice. */
  updateInvoiceStatus: UpdateInvoiceStatusPayload;
  createJob?: Maybe<CreateJobResponse>;
  editJob?: Maybe<EditJobResponse>;
  expireJob?: Maybe<ExpireJobResponse>;
  refreshJob?: Maybe<RefreshJobResponse>;
  upgradeToPremium?: Maybe<UpgradeToPremiumPayload>;
  publishJobAdBookingEvent?: Maybe<PublishJobAdBookingEventPayload>;
  publishCPQAEvent?: Maybe<PublishCpqaEventPayload>;
  /**
   * Track they query text entered or chosen location id after a user has been presented with location suggestions.
   * By tracking the outcome of a location suggestion request the accuracy of location data in future can be improved.
   *
   * The sessionId should also be sent to the "locations" query so that tracking between the suggestions provided and the chosen
   * result can be completed.
   */
  trackLocationSuggestion: TrackLocationSuggestionPayload;
  /** Set notification preferences for account users for a specific job */
  setNotificationPreferences: SetNotificationPreferencesPayload;
  /** Set default notification preference for current logged in user for a specific job */
  setDefaultNotificationPreference: NotificationPreference;
  /** Request to create a order from selected items. Returns Order. */
  createOrder?: Maybe<CreateOrderPayload>;
  /** Request to create an order with a promotion code applied. Returns Order. */
  createOrderWithPromotion?: Maybe<CreateOrderWithPromotionPayload>;
  /** Request to confirm an invoice order. Returns request status. */
  confirmInvoiceOrder?: Maybe<ConfirmInvoiceOrderPayload>;
  /** Creates a Payment Intent and provides the clientSecret which is used on the client-side to charge the card with the Stripe confirmCardPayment API. */
  createStripePaymentIntent: CreateStripePaymentIntentPayload;
  /** Creates a Payment Intent for invoices and provides the clientSecret which is used on the client-side to charge the card with the Stripe confirmCardPayment API. */
  createStripePaymentIntentForInvoice: CreateStripePaymentIntentPayload;
  /** To pay for a given order using provided payment method id associated to a saved credit card. */
  payByStripeSavedCreditCard?: Maybe<PayByStripeSavedCreditCardPayload>;
  /** To pay for a given order using provided payment method id associated to a saved credit card. */
  payByStripeSavedCreditCardForInvoice?: Maybe<PayByStripeSavedCreditCardPayload>;
  /** To delete a saved credit card using provided payment method id. */
  deleteStripeSavedCreditCard?: Maybe<DeleteStripeSavedCreditCardPayload>;
  /** Creates a Payment Intent and provides the paymentLink where user can complete the payment and paymentToken where client can check the payment status */
  create2C2PPaymentIntent: Create2c2pPaymentIntentPayload;
  /** Creates a Payment Intent for invoices and provides the paymentLink where user can complete the payment and paymentToken where client can check the payment status */
  create2C2PPaymentIntentForInvoices: Create2c2pPaymentIntentPayload;
  createProductSetForAdPosting: CreateProductSetForAdPostingPayload;
  createProductSetForPriceCheckerPreview: CreateProductSetForAdPostingPayload;
  /** createProductSet mutation used when posting a job to multiple locations */
  createProductSetForMultiLocation: CreateProductSetForMultiLocationPayload;
  createProductSetForAdEditing: CreateProductSetForAdEditingPayload;
  createProductSetForAdRefresh: CreateProductSetForAdRefreshPayload;
  createProductSetForBudgetsPurchaseForHirer: CreateProductSetForBudgetsPurchaseForHirerPayload;
  createProductSetForBudgetsPurchaseForVisitor: CreateProductSetForBudgetsPurchaseForVisitorPayload;
  createQuestionnaire?: Maybe<CreateQuestionnaireResponse>;
  createCustomQuestion: CreateCustomQuestionOutputUnion;
  registerNewUser?: Maybe<RegisterPayload>;
  registerAdditionalAdvertiser?: Maybe<RegisterPayload>;
  completeUserRegistration: SecureLinkResponse;
  completeAdditionalAdvertiserRegistration: SecureLinkResponse;
  initiateSendJob?: Maybe<InitiateConnectionPayload>;
  initiateSendMessage?: Maybe<InitiateConnectionPayload>;
  initiateAccessCv?: Maybe<InitiateConnectionPayload>;
  initiateExportProfile?: Maybe<InitiateConnectionPayload>;
  createTalentPool: TalentSearchPool;
  deleteTalentPool: Scalars['String'];
  updateTalentPool: Scalars['String'];
  addProfileToTalentPool: TalentSearchPoolProfile;
  addOrRemoveProfileToTalentPools: Scalars['String'];
  shareTalentPool: Scalars['String'];
  createSavedSearch?: Maybe<CreateSavedSearchPayload>;
  deleteSavedSearch?: Maybe<DeleteSavedSearchPayload>;
  updateUserDetails?: Maybe<UpdateUserDetailsResponse>;
  sendPasswordResetEmail?: Maybe<SendEmailResponse>;
  sendRegistrationEmail?: Maybe<SendEmailResponse>;
  /** Set job access for hirer account users for a specific job */
  setHirerAccountUsersJobAccess?: Maybe<SetHirerAccountUsersJobAccessPayload>;
  /** Send email to hirer account users notifying they have access to a specific job */
  sendHirerAccountUsersJobAccessEmail?: Maybe<SendHirerAccountUsersJobAccessEmailPayload>;
  addUserToAccount?: Maybe<AddUserToAccountResponse>;
  updateUserPermissions?: Maybe<UpdateUserPermissionsResponse>;
  removeUserFromAccount?: Maybe<AccountDetailsUpdateResponse>;
  reinstate?: Maybe<ReinstateResponse>;
}

export interface Mutation_ApplyCoverToAllBrandsArgs {
  brandId: Scalars['String'];
  owner?: Maybe<BrandOwnerInput>;
}

export interface Mutation_CreateBrandArgs {
  input: CreateBrandInput;
}

export interface Mutation_UpdateBrandArgs {
  input: UpdateBrandInput;
}

export interface Mutation_ClearDefaultBrandArgs {
  owner?: Maybe<BrandOwnerInput>;
}

export interface Mutation_SetDefaultBrandArgs {
  id: Scalars['ID'];
  owner?: Maybe<BrandOwnerInput>;
}

export interface Mutation_DeleteBrandArgs {
  id: Scalars['ID'];
  owner?: Maybe<BrandOwnerInput>;
}

export interface Mutation_DeleteBrandingAssetArgs {
  input: DeleteBrandingAssetInput;
}

export interface Mutation_PatchCompanyProfileArgs {
  input: CompanyProfileInput;
}

export interface Mutation_CreatePaymentIntentArgs {
  input: CreatePaymentIntentInput;
}

export interface Mutation_PayBySavedCreditCardArgs {
  input: PayBySavedCreditCardInput;
}

export interface Mutation_DeleteSavedCreditCardArgs {
  input: DeleteSavedCreditCardInput;
}

export interface Mutation_DeleteDraftJobArgs {
  input: DeleteDraftJobInput;
}

export interface Mutation_CreateDraftJobArgs {
  input: CreateDraftJobInput;
}

export interface Mutation_UpdateDraftJobArgs {
  input: UpdateDraftJobInput;
}

export interface Mutation_UpdateAccountPostalAddressArgs {
  input?: Maybe<AccountPostalAddressInput>;
}

export interface Mutation_UpdateAccountPrimaryContactArgs {
  input?: Maybe<AccountPrimaryContactInput>;
}

export interface Mutation_UpdateAccountBillingAddressArgs {
  input?: Maybe<AccountBillingAddressInput>;
}

export interface Mutation_UpdateAccountBillingEmailAddressArgs {
  input?: Maybe<AccountBillingEmailAddressInput>;
}

export interface Mutation_PublishJobPostedEventArgs {
  input?: Maybe<PublishJobPostedEventInput>;
}

export interface Mutation_CreateIntegrationRelationshipsArgs {
  input: CreateIntegrationRelationshipsInput;
}

export interface Mutation_DeleteIntegrationRelationshipsArgs {
  input: DeleteIntegrationRelationshipsInput;
}

export interface Mutation_UpdateInvoiceStatusArgs {
  input: UpdateInvoiceStatusInput;
}

export interface Mutation_CreateJobArgs {
  input?: Maybe<CreateJobInput>;
}

export interface Mutation_EditJobArgs {
  input?: Maybe<EditJobInput>;
}

export interface Mutation_ExpireJobArgs {
  input?: Maybe<ExpireJobInput>;
}

export interface Mutation_RefreshJobArgs {
  input?: Maybe<RefreshJobInput>;
}

export interface Mutation_UpgradeToPremiumArgs {
  input?: Maybe<UpgradeToPremiumInput>;
}

export interface Mutation_PublishJobAdBookingEventArgs {
  input: PublishJobAdBookingEventInput;
}

export interface Mutation_PublishCpqaEventArgs {
  input: PublishCpqaEventInput;
}

export interface Mutation_TrackLocationSuggestionArgs {
  input: TrackLocationSuggestionInput;
}

export interface Mutation_SetNotificationPreferencesArgs {
  input: SetNotificationPreferencesInput;
}

export interface Mutation_SetDefaultNotificationPreferenceArgs {
  input: SetDefaultNotificationPreferenceInput;
}

export interface Mutation_CreateOrderArgs {
  input: CreateOrderInput;
}

export interface Mutation_CreateOrderWithPromotionArgs {
  input: CreateOrderInput;
}

export interface Mutation_ConfirmInvoiceOrderArgs {
  input: ConfirmInvoiceOrderInput;
}

export interface Mutation_CreateStripePaymentIntentArgs {
  input: CreateStripePaymentIntentInput;
}

export interface Mutation_CreateStripePaymentIntentForInvoiceArgs {
  input: CreateStripePaymentIntentForInvoiceInput;
}

export interface Mutation_PayByStripeSavedCreditCardArgs {
  input: PayByStripeSavedCreditCardInput;
}

export interface Mutation_PayByStripeSavedCreditCardForInvoiceArgs {
  input: PayByStripeSavedCreditCardForInvoiceInput;
}

export interface Mutation_DeleteStripeSavedCreditCardArgs {
  input: DeleteStripeSavedCreditCardInput;
}

export interface Mutation_Create2C2PPaymentIntentArgs {
  input: Create2C2PPaymentIntentInput;
}

export interface Mutation_Create2C2PPaymentIntentForInvoicesArgs {
  input: Create2C2PPaymentIntentForInvoiceInput;
}

export interface Mutation_CreateProductSetForAdPostingArgs {
  input: CreateProductSetForAdPostingInput;
}

export interface Mutation_CreateProductSetForPriceCheckerPreviewArgs {
  input: CreateProductSetForPriceCheckerPreviewInput;
}

export interface Mutation_CreateProductSetForMultiLocationArgs {
  input: CreateProductSetForMultiLocationInput;
}

export interface Mutation_CreateProductSetForAdEditingArgs {
  input: CreateProductSetForAdEditingInput;
}

export interface Mutation_CreateProductSetForAdRefreshArgs {
  input: CreateProductSetForAdRefreshInput;
}

export interface Mutation_CreateProductSetForBudgetsPurchaseForVisitorArgs {
  input: CreateProductSetForBudgetsPurchaseForVisitorInput;
}

export interface Mutation_CreateQuestionnaireArgs {
  input: CreateQuestionnaireInput;
}

export interface Mutation_CreateCustomQuestionArgs {
  input: CreateCustomQuestionInput;
}

export interface Mutation_RegisterNewUserArgs {
  input: RegisterNewUserInput;
}

export interface Mutation_RegisterAdditionalAdvertiserArgs {
  input: RegisterAdditionalAdvertiserInput;
}

export interface Mutation_CompleteUserRegistrationArgs {
  input: SecureLinkInput;
}

export interface Mutation_CompleteAdditionalAdvertiserRegistrationArgs {
  input: SecureLinkInput;
}

export interface Mutation_InitiateSendJobArgs {
  input: SendJobInput;
}

export interface Mutation_InitiateSendMessageArgs {
  input: SendMessageInput;
}

export interface Mutation_InitiateAccessCvArgs {
  input: AccessCvInput;
}

export interface Mutation_InitiateExportProfileArgs {
  input: ExportProfileInput;
}

export interface Mutation_CreateTalentPoolArgs {
  input: TalentSearchCreateTalentPoolInput;
}

export interface Mutation_DeleteTalentPoolArgs {
  input: TalentSearchDeleteTalentPoolInput;
}

export interface Mutation_UpdateTalentPoolArgs {
  input: TalentSearchUpdateTalentPoolInput;
}

export interface Mutation_AddProfileToTalentPoolArgs {
  input?: Maybe<TalentSearchAddProfileToTalentPoolInput>;
}

export interface Mutation_AddOrRemoveProfileToTalentPoolsArgs {
  input: TalentSearchAddOrRemoveProfileTalentPoolsInput;
}

export interface Mutation_ShareTalentPoolArgs {
  input: TalentSearchShareTalentPoolInput;
}

export interface Mutation_CreateSavedSearchArgs {
  input: CreateSavedSearchInput;
}

export interface Mutation_DeleteSavedSearchArgs {
  criteriaHash: Scalars['String'];
}

export interface Mutation_UpdateUserDetailsArgs {
  input: UserDetailsInput;
}

export interface Mutation_SendPasswordResetEmailArgs {
  input?: Maybe<SendPasswordResetEmailInput>;
}

export interface Mutation_SendRegistrationEmailArgs {
  input?: Maybe<SendRegistrationEmailInput>;
}

export interface Mutation_SetHirerAccountUsersJobAccessArgs {
  input: SetHirerAccountUsersJobAccessInput;
}

export interface Mutation_SendHirerAccountUsersJobAccessEmailArgs {
  input: SendHirerAccountUsersJobAccessEmailInput;
}

export interface Mutation_AddUserToAccountArgs {
  input: AddUserToAccountInput;
}

export interface Mutation_UpdateUserPermissionsArgs {
  input: UpdateUserPermissionsInput;
}

export interface Mutation_RemoveUserFromAccountArgs {
  input: RemoveUserFromAccountInput;
}

export interface Mutation_ReinstateArgs {
  input: ReinstateInput;
}

export interface MyJobInformationJobEdit {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  timestampUTC: Scalars['DateTime'];
}

export interface MyJobInformationResponse {
  advertiserName: Scalars['String'];
  applyUrl?: Maybe<Scalars['String']>;
  classificationName: Scalars['String'];
  createdByName: Scalars['String'];
  createdByEmail: Scalars['String'];
  isLinkOut: Scalars['Boolean'];
  isSalaryHidden: Scalars['Boolean'];
  isPrivateAdvertiser: Scalars['Boolean'];
  jobCreatedTimestampUTC: Scalars['DateTime'];
  jobLocationLabel: Scalars['String'];
  jobTitle: Scalars['String'];
  latestEdit?: Maybe<MyJobInformationJobEdit>;
  mirrorClassificationName?: Maybe<Scalars['String']>;
  mirrorSubClassificationName?: Maybe<Scalars['String']>;
  postTypeCategory: AnalyticsPostTypeCategory;
  productDisplayName: Scalars['String'];
  salary: MyJobInformationSalary;
  subClassificationName: Scalars['String'];
  workType: AnalyticsWorkType;
}

export interface MyJobInformationSalary {
  additionalText?: Maybe<Scalars['String']>;
  currency: Scalars['String'];
  maximum: Scalars['Float'];
  minimum: Scalars['Float'];
  type?: Maybe<AnalyticsSalaryType>;
}

export interface MyJobInformationWidgetInput {
  jobId: Scalars['String'];
  hirerOriginZone: Scalars['String'];
  locale: Scalars['String'];
}

export interface NewAdvertiser {
  /** Business name. */
  businessName: Scalars['String'];
  /** Business phone number. */
  businessPhoneNumber: Scalars['String'];
  /** Billing country. */
  country: Scalars['String'];
  /** Indicates whether the employer is an agency account. */
  isAgency?: Maybe<Scalars['Boolean']>;
  /** The zone where the user registered. */
  originZone?: Maybe<Scalars['String']>;
}

export interface NewUser {
  /** Given name */
  firstName: Scalars['String'];
  /** Family name */
  lastName: Scalars['String'];
  /** The audience zone of the site that this user originally registered on */
  originZone?: Maybe<Scalars['String']>;
}

export interface NotificationPreference {
  /** Id of the account user who receives the notification. Positive number only. */
  userId: Scalars['ID'];
  /** Frequency of receiving notifications. */
  frequency: Frequency;
  /** Email which notification will be sent to if account user chooses to receive notification for every new application. */
  applicationEmail?: Maybe<Scalars['String']>;
}

export interface NotificationPreferenceInput {
  /** Id of the account user who receives the notification. Positive number only. */
  userId: Scalars['ID'];
  /** Email which notification will be sent to if account user chooses to receive notification for every new application. */
  applicationEmail?: Maybe<Scalars['String']>;
  /** Frequency of receiving notifications. */
  frequency: Frequency;
}

export interface Option {
  /** Option Text */
  text: Scalars['String'];
  /** Option Order */
  order: Scalars['Int'];
}

export interface OptionWithId {
  /** Option Id */
  id: Scalars['ID'];
  /** Option Text */
  text: Scalars['String'];
  /** Option Order */
  order: Scalars['Int'];
}

export interface Order {
  orderId: Scalars['String'];
  orderNumber: Scalars['String'];
  status: Scalars['String'];
}

/** Error for order that has past expiry date. */
export interface OrderExpiredError extends Error {
  message: Scalars['String'];
}

/** Error for order without expiryTime or paymentBreakdown, any status not in pending, or an incomplete priced order. */
export interface OrderInvalidError extends Error {
  message: Scalars['String'];
}

export interface OrderItem {
  productType: ProductType;
  productReference?: Maybe<Scalars['String']>;
}

export interface OrderLineItem {
  /** Reference to product. Eg JobAd, AdPack, AdBudget. */
  product: Product;
  /** Price of the order item. */
  price: Price;
  pricing?: Maybe<Pricing>;
  /** Drawdown items of the order item. */
  drawdownItems: Array<DrawdownItem>;
  /** Drawdown type of the order item. */
  drawdownType: DrawdownType;
}

/** Error for order not found. */
export interface OrderNotFoundError extends Error {
  message: Scalars['String'];
}

export interface OrderProductAttributesInput {
  /** Required input attributes if product selected is a Job Ad. */
  jobAd?: Maybe<JobAdRevisionAttributes>;
  /** Required input attributes if product selected is a Job Ad Edit. */
  jobAdEdit?: Maybe<JobAdRevisionAttributes>;
  /** Required input attributes if product selected is a Job Ad Extend. */
  jobAdExtend?: Maybe<JobAdRevisionAttributes>;
  /** Required input attributes if product selected is an Ad Budget. */
  adBudget?: Maybe<AdBudgetAttributes>;
  catalogueProduct?: Maybe<CatalogueProductAttributes>;
}

export interface OrderRequestInputItem {
  /** Type of product. Eg. Jod ad, Job ad edit, Ad budget */
  productType: ProductTypeInput;
  /** Required attributes associated to the product. Key name to attributes must match product type. Eg. jobAd for JOB_AD, adBudget for AD_BUDGET. */
  productAttributes: OrderProductAttributesInput;
}

export interface OrderSummary {
  /** The ID of the entity the order summary is built on. */
  orderId: Scalars['ID'];
  /** All the line items of the order summary. */
  lineItems: Array<OrderLineItem>;
  /** The tax of the order summary. */
  tax?: Maybe<Tax>;
  /** This is the sum of the line item prices. The subTotal does not include taxes. */
  subTotal: Money;
  /** Total price of the order summary. Includes taxes. */
  total: Money;
  /** Optional surcharges that can be applied to the order. The order total does not include surcharges. */
  surcharges: Array<Surcharge>;
}

export interface OutputCustomQuestion {
  /** Question Id */
  id: Scalars['ID'];
  /** Question Text */
  text: Scalars['String'];
  /** Hirer Question Type e.g SingleSelect, FreeText */
  questionType: HirerQuestionType;
  /** Candidate Answer Type e.g SingleSelect, FreeText */
  answerType: CandidateAnswerType;
  /** Options */
  options: Array<OptionWithId>;
}

export interface PageInfo {
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
}

export interface PageTitleActionsResponse {
  adType: AnalyticsAdType;
  advertiserName: Scalars['String'];
  classificationName: Scalars['String'];
  displayCurrency?: Maybe<Scalars['String']>;
  jobCreatedTimestampUTC: Scalars['DateTime'];
  jobExpiryTimestampUTC?: Maybe<Scalars['DateTime']>;
  isExpired: Scalars['Boolean'];
  isPrivateAdvertiser: Scalars['Boolean'];
  jobId: Scalars['String'];
  jobLocationLabel: Scalars['String'];
  jobSummary?: Maybe<Scalars['String']>;
  jobTitle: Scalars['String'];
  keySellingPoints?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastUpdatedTimestampUTC?: Maybe<Scalars['String']>;
  latestJobs: Array<AnalyticsLatestJobs>;
  postTypeCategory: AnalyticsPostTypeCategory;
  salaryAdditionalText?: Maybe<Scalars['String']>;
  serpLogoUrl?: Maybe<Scalars['String']>;
  subClassificationName: Scalars['String'];
}

export interface PageTitleActionsWidgetInput {
  jobId: Scalars['String'];
  hirerOriginZone: Scalars['String'];
  locale: Scalars['String'];
}

export interface Parent {
  advertiserId?: Maybe<Scalars['ID']>;
  nation?: Maybe<Scalars['String']>;
}

export interface ParentAdvertiser {
  id: Scalars['Int'];
}

export interface ParentLocation {
  id: Scalars['ID'];
  country: Scalars['String'];
  type: Scalars['String'];
  description: Scalars['String'];
  displayDescription: Scalars['String'];
}

export interface PatchCompanyProfileResponse {
  id: Scalars['String'];
}

export interface PayBySavedCreditCardInput {
  /** Credit card brand to determine any surcharges. */
  creditCardBrand: CreditCardBrandInput;
  /** Id of the advertiser. */
  advertiserId?: Maybe<Scalars['ID']>;
  /** Id of the order. */
  orderId: Scalars['ID'];
  /** Nation of the advertiser. */
  advertiserNation: Scalars['String'];
  /** Id of the payment method. Examples: payment method id (pm_XXX) or saved card id (card_XXX) */
  paymentMethodId: Scalars['String'];
}

export type PayBySavedCreditCardPayload =
  | SavedCreditCardPaymentSuccessPayload
  | SavedCreditCardPaymentError
  | OrderNotFoundError
  | OrderExpiredError
  | OrderInvalidError
  | PromotionInvalidError;

export interface PayByStripeSavedCreditCardForInvoiceInput {
  /** Credit card brand to determine any surcharges. */
  creditCardBrand: CreditCardBrandInput;
  /**
   * This would normally come from the access token, but for this query the user may be unauthenticated.
   * customer number for payment link experience.
   * seekBillingAccountId of the advertiser login experience
   * This will be mapped to customerId on paymentService
   */
  seekBillingAccountId: Scalars['String'];
  /**
   * This would normally come from the access token, but for this query the user may be unauthenticated.
   * seekAdvertiserId of the advertiser.
   * This will be mapped to accountId on paymentService
   */
  seekAdvertiserId?: Maybe<Scalars['String']>;
  /** The reference of the invoice */
  prn: Scalars['String'];
  /** The total amount to be paid for the payment intent. REMOVE this later when we are able to get the total amount from the invoice API. */
  totalForPaymentIntent?: Maybe<Scalars['SafeInt']>;
  /** Temporary for the fix of big total amount to be paid for the payment intent. REMOVE this after update totalForPaymentIntent to SafeInt. */
  totalForPaymentIntentSafeInt?: Maybe<Scalars['SafeInt']>;
  /** Invoices to be paid. REMOVE this later when we are able to get the total amount from the invoice API. */
  invoices: Array<InvoiceInput>;
  /** The email of the advertiser. */
  email: Scalars['String'];
  /** Nation of the advertiser. */
  advertiserNation: Scalars['String'];
  /** Id of the payment method. Examples: payment method id (pm_XXX) or saved card id (card_XXX) */
  paymentMethodId: Scalars['String'];
  /**
   * The currency of the payment. If no currency input, it will use the currency from pending order.
   * This is a temporary bypass to test Asia currency, to be removed when OrderAPI supports Asia currency.
   */
  paymentCurrency: Currency;
  /** Number of days before the payment intent expires and is disabled for payment. Minimum of at least 1 day */
  expiryDays?: Maybe<Scalars['Int']>;
  /** The idempotency key for the payment intent. If not provided, a random uuid will be generated. */
  idempotencyKey?: Maybe<Scalars['String']>;
}

export interface PayByStripeSavedCreditCardInput {
  /** Credit card brand to determine any surcharges. */
  creditCardBrand: CreditCardBrandInput;
  /** Id of the order. */
  orderId: Scalars['ID'];
  /** Nation of the advertiser. */
  advertiserNation: Scalars['String'];
  /** Id of the payment method. Examples: payment method id (pm_XXX) or saved card id (card_XXX) */
  paymentMethodId: Scalars['String'];
}

export type PayByStripeSavedCreditCardPayload =
  | SavedCreditCardPaymentSuccessPayload
  | SavedCreditCardPaymentError
  | OrderNotFoundError
  | OrderExpiredError
  | OrderInvalidError
  | PromotionInvalidError;

export interface Payment2c2pInquiry {
  amount?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  /** Status of payment using 2c2p gateway */
  status?: Maybe<Payment2c2pInquiryStatus>;
}

/** Error for 2c2p payment inquiry */
export interface Payment2c2pInquiryError extends Error {
  message: Scalars['String'];
}

export type Payment2c2pInquiryPayload =
  | Payment2c2pInquiry
  | Payment2c2pInquiryError;

export enum Payment2c2pInquiryStatus {
  Pending = 'pending',
  Processing = 'processing',
  Cancelled = 'cancelled',
  Succeeded = 'succeeded',
}

export interface PaymentConfigPayload {
  features?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface PaymentIntent2C2PPayload {
  paymentLink?: Maybe<Scalars['String']>;
  paymentToken?: Maybe<Scalars['String']>;
  expiryTimer?: Maybe<Scalars['String']>;
}

/** Error for payment intent failed to generate */
export interface PaymentIntentChargeError extends Error {
  message: Scalars['String'];
  code: Scalars['String'];
}

export interface PaymentIntentPayload {
  /** The client secret of this PaymentIntent. Used for client-side retrieval using a publishable key. */
  clientSecret: Scalars['String'];
}

export interface PaymentMediumType {
  value: Scalars['String'];
  disablePayment: Scalars['Boolean'];
}

export enum PaymentMethod {
  Invoice = 'INVOICE',
  CreditCard = 'CREDIT_CARD',
  Contract = 'CONTRACT',
}

/** Error for payment method not supported for the currency */
export interface PaymentMethodError extends Error {
  message: Scalars['String'];
}

export interface PaymentMethodPayload {
  maintenance: Maintenance;
  paymentMethods?: Maybe<Array<Maybe<Maintenance>>>;
}

export enum PaymentMethodType2C2P {
  Webpay = 'WEBPAY',
  Dpay = 'DPAY',
  Qr = 'QR',
}

/** PerApplicantBilling feature to indicate CPQA product */
export interface PerApplicantBillingFeature extends CatalogueProductFeature {
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
}

/** PerUnitBilling feature to indicate CPQA product */
export interface PerUnitBillingFeature extends CatalogueProductFeature {
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
  unit: Scalars['String'];
}

export interface PerformancePredictionChartEvent {
  comparisonValue: Scalars['Int'];
  date: Scalars['Date'];
  day: Scalars['Int'];
  editMade: Scalars['Boolean'];
  value: Scalars['Int'];
}

export interface PerformancePredictionResponse {
  events?: Maybe<Array<Maybe<PerformancePredictionChartEvent>>>;
  predictionDate?: Maybe<Scalars['Date']>;
  predictionDayOptions: Array<PredictionDayOptions>;
  valueLabel?: Maybe<CandidateLabel>;
  xAxisLabels?: Maybe<Array<DateAxisLabel>>;
  xRange?: Maybe<Array<Scalars['Date']>>;
  yRange?: Maybe<Array<Scalars['Int']>>;
}

export interface PerformancePredictionWidgetInput {
  jobId: Scalars['String'];
  hirerOriginZone: Scalars['String'];
}

export enum Permission {
  CreateJobs = 'createJobs',
  ManageBrand = 'manageBrand',
  ManageUsers = 'manageUsers',
  PayInvoices = 'payInvoices',
  ViewInvoiceHistory = 'viewInvoiceHistory',
  ViewManagerReports = 'viewManagerReports',
}

export interface PhysicalAddress {
  /** All address lines */
  addressLines?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Address line 1 */
  addressLine1?: Maybe<Scalars['String']>;
  /** Address line 2 */
  addressLine2?: Maybe<Scalars['String']>;
  /** Suburb */
  suburb?: Maybe<Scalars['String']>;
  /** State */
  state?: Maybe<Scalars['String']>;
  /** Post code */
  postCode?: Maybe<Scalars['String']>;
  /** Name of the Country */
  country?: Maybe<Scalars['String']>;
}

export interface PredictionDayOptions {
  text: Scalars['String'];
  prediction: Scalars['Int'];
}

export interface Price {
  retailPrice: Money;
  netPrice: Money;
  discount?: Maybe<Discount>;
  isVariable?: Maybe<Scalars['Boolean']>;
  /** Tax data for the net price. */
  tax?: Maybe<Tax>;
  /** Tax data for the retail price. */
  retailTax?: Maybe<Tax>;
  /** Cost per applicant price for CPQA product. */
  costPerApplicant?: Maybe<Money>;
}

export interface PriceAttributes {
  roleTitle?: Maybe<Scalars['String']>;
  subclassificationId?: Maybe<Scalars['Float']>;
  locationId?: Maybe<Scalars['Float']>;
}

export interface PriceType {
  type: Scalars['String'];
}

export enum PriceVisibility {
  ShowRetailPrice = 'SHOW_RETAIL_PRICE',
  ShowDiscountedPrice = 'SHOW_DISCOUNTED_PRICE',
  HidePrice = 'HIDE_PRICE',
}

export type Pricing = CappedUnitPrice | PriceType;

export enum PricingModel {
  Fixed = 'FIXED',
  Horizon = 'HORIZON',
}

export interface PrimaryContact {
  /** First name of the user */
  firstName?: Maybe<Scalars['String']>;
  /** Last name of the user */
  lastName?: Maybe<Scalars['String']>;
  /** Email address of the user */
  email?: Maybe<Scalars['String']>;
  /** Phone number of the advertiser collected on registration form */
  phoneNumber?: Maybe<Scalars['String']>;
}

export enum PrivacyTypes {
  Standard = 'standard',
  Limited = 'limited',
}

export interface Product {
  name: Scalars['String'];
  productType: ProductType;
}

export interface ProductFeature {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
}

export interface ProductList {
  /** A list of all catalogue products in the product set */
  items: Array<CatalogueProduct>;
  /** A list of catalogue products in the product set which can be presented to the customer (eg. in an ad ladder) */
  products: Array<CatalogueProduct>;
}

export interface ProductReference {
  /** Unique ID of the product reference that references to an addon/upgrade */
  id: Scalars['ID'];
}

export interface ProductSet {
  /** Unique ID of the product set */
  id: Scalars['ID'];
  /** A list of all catalogue products in the product set */
  items: Array<Maybe<CatalogueProduct>>;
}

export interface ProductSetExpiredError extends Error {
  message: Scalars['String'];
}

export interface ProductSetNotFoundError extends Error {
  message: Scalars['String'];
}

export enum ProductType {
  JobAd = 'JOB_AD',
  JobAdEdit = 'JOB_AD_EDIT',
  JobAdUpgrade = 'JOB_AD_UPGRADE',
  JobAdExtend = 'JOB_AD_EXTEND',
  AdBudget = 'AD_BUDGET',
  CatalogueProduct = 'CATALOGUE_PRODUCT',
}

export enum ProductTypeInput {
  JobAd = 'JOB_AD',
  JobAdEdit = 'JOB_AD_EDIT',
  JobAdExtend = 'JOB_AD_EXTEND',
  AdBudget = 'AD_BUDGET',
  CatalogueProduct = 'CATALOGUE_PRODUCT',
}

export interface ProfileEducation {
  qualificationName: Scalars['String'];
  institutionName: Scalars['String'];
  isCompleted: Scalars['Boolean'];
  completionYear: Scalars['Int'];
  completionMonth: Scalars['Int'];
}

export interface ProfileProperties {
  HasOnlyUsedExpressCaja?: Maybe<Scalars['String']>;
  HasLoggedInBefore?: Maybe<Scalars['String']>;
  HasSeenJobDetailsTour?: Maybe<Scalars['String']>;
  UserStatusBeforeDeactivation?: Maybe<Scalars['String']>;
  HasSeenGuidedCallOut?: Maybe<Scalars['String']>;
  ExpressCajaOptOut?: Maybe<Scalars['String']>;
}

export interface ProfileResultHighlight {
  resumeSnippet?: Maybe<Scalars['String']>;
  skills?: Maybe<Array<Scalars['String']>>;
}

export interface ProfileSalaryV2 {
  expected?: Maybe<ExpectedSalaryV2>;
}

export enum ProfileSource {
  Seek = 'seek',
  Combined = 'combined',
}

export enum ProfileTypes {
  Seek = 'seek',
  Combined = 'combined',
  Partner = 'partner',
  Shared = 'shared',
}

export interface ProfilesByEmailInput {
  email: Scalars['String'];
  countryCode?: Maybe<Scalars['String']>;
  advertiserId?: Maybe<Scalars['String']>;
}

export interface PromoCode {
  /** The promo code value that a hirer can use on a product in order to get a discount */
  code: Scalars['String'];
  /** The discount amount or rate on a product after the promo code has been applied. Currently only applicable to discount rate */
  discount: PromoCodeDiscount;
  /** The date and time when the promo code was created */
  createdAt: Scalars['DateTime'];
  /** The date and time when the promo code was last updated */
  updatedAt: Scalars['DateTime'];
  /** The date and time when the promo code is valid from */
  validFrom: Scalars['DateTime'];
  /** The date and time when the promo code is valid to */
  validTo: Scalars['DateTime'];
  /** The products that the promo code can be applied to */
  validProducts: Array<PromoCodeProduct>;
  /** The campaign that the promo code belongs to */
  campaignId: Scalars['ID'];
}

export type PromoCodeDiscount = PromoCodeDiscountRate;

export interface PromoCodeDiscountRate {
  /** The promo code discount rate in percentage up to two decimal places eg. 99.99 is equivalent to 99.99% */
  discountRate: Scalars['Float'];
}

export interface PromoCodeProduct {
  /** The product id */
  id: Scalars['ID'];
  /** The product display name */
  displayName: Scalars['String'];
}

export interface Promotion {
  code?: Maybe<Scalars['String']>;
}

/** Error for invalid, expired, or not found promotion code. */
export interface PromotionInvalidError extends Error {
  message: Scalars['String'];
}

export interface PublishCpqaEventInput {
  /** The job title */
  roleTitle: Scalars['String'];
  /** Advertiser name */
  accountName: Scalars['String'];
}

export interface PublishCpqaEventPayload {
  published?: Maybe<Scalars['Boolean']>;
}

export interface PublishJobAdBookingEventInput {
  /** The name of the company Hirer recruiting for */
  companyName: Scalars['String'];
  /** The contact name for this particular request */
  contactName: Scalars['String'];
  /** The phone number where CS can contact Hirer on */
  phoneNumber: Scalars['String'];
  /** The Seek advertiser/account id */
  advertiserId?: Maybe<Scalars['String']>;
  /** The user email */
  userEmail: Scalars['String'];
  /** The job title */
  jobTitle?: Maybe<Scalars['String']>;
  /** The job location */
  jobLocation?: Maybe<Scalars['String']>;
  /** The contact by time in UTC */
  contactBy: Scalars['String'];
  /** The product ID */
  productId?: Maybe<Scalars['String']>;
  /** Country code for the website where customer made the request. */
  websiteCountryCode: JobAdBookingCountryCode;
  /** Two digit ISO country for location of job ad. */
  jobCountryCode: JobAdBookingCountryCode;
  /** The Job Category ID */
  jobCategorySubClassificationId: Scalars['String'];
  /** The Job Location ID */
  jobLocationId: Scalars['String'];
}

export interface PublishJobAdBookingEventPayload {
  published?: Maybe<Scalars['Boolean']>;
}

export enum PublishJobAdProductCode {
  /** The product we offer in AU, GUARANTEED_HIRE */
  Auskghrb = 'AUSKGHRB',
  /** The product we offer in NZ, CONCIERGE */
  Concierge = 'CONCIERGE',
}

export interface PublishJobPostedEventInput {
  /** The ID of the user in Adcentre */
  adcentreUserId: Scalars['Int'];
  /** The ID of the draft in Adcentre */
  adcentreDraftId?: Maybe<Scalars['Int']>;
  /**
   * TODO: Make this below mandatory once frontend updated to post it
   * and remove the above adcentreDraftId
   */
  draftId?: Maybe<Scalars['String']>;
  /** The ID of the listing in Adcentre */
  adcentreListingId?: Maybe<Scalars['Int']>;
  /** The ID of the advertiser */
  advertiserId?: Maybe<Scalars['String']>;
  /** The ID of the job */
  jobId: Scalars['String'];
  /** The type of device used by the hirer to post their job */
  device: Scalars['String'];
  /** The experience the hirer used to post their job */
  jobPostingExperience: Scalars['String'];
  /** The job writing flow that hirer has followed (guided or standard) */
  jobWritingFlow: Scalars['String'];
  /** The experiment being run while this job was posted */
  experimentId?: Maybe<Scalars['String']>;
  /** The experiment variant being run while this job was posted */
  experimentVariantId?: Maybe<Scalars['String']>;
  /** Flow used to create/update the job, e.g. refresh, edit, create */
  flowType?: Maybe<Scalars['String']>;
}

export interface PublishJobPostedEventPayload {
  jobId: Scalars['String'];
}

export type PurchasableProducts =
  | PurchasableProductsList
  | PurchasableProductsString;

export interface PurchasableProductsList {
  products?: Maybe<Array<Scalars['String']>>;
}

export interface PurchasableProductsString {
  product: Scalars['String'];
}

export interface Query {
  currentAdvertiser: CurrentAdvertiserResponse;
  currentUser: CurrentUserResponse;
  /** Ad Details query */
  analyticsMyJobInformation?: Maybe<MyJobInformationResponse>;
  /** Ad Performance Report query */
  analyticsAdPerformanceReport?: Maybe<AdPerformanceReportResponse>;
  /** Application Performance query */
  analyticsApplicationPerformance?: Maybe<ApplicationPerformanceResponse>;
  /** Candidate Demand query */
  analyticsCandidateDemand?: Maybe<CandidateDemandResponse>;
  /** Candidate Supply query */
  analyticsCandidateSupply?: Maybe<CandidateSupplyResponse>;
  /** Clicks on your ad query */
  analyticsClicksOnYourAd?: Maybe<ClicksOnYourAdResponse>;
  /** Market insights title query */
  analyticsMarketInsightsTitle?: Maybe<MarketInsightsTitleResponse>;
  /** Ad Rating query */
  analyticsAdRating?: Maybe<AdRatingResponse>;
  /** Page Title Actions query */
  analyticsPageTitleActions?: Maybe<PageTitleActionsResponse>;
  /** Performance Prediction query */
  analyticsPerformancePrediction?: Maybe<PerformancePredictionResponse>;
  /** Recommendations query */
  analyticsRecommendations?: Maybe<RecommendationsResponse>;
  /** Similar Ads query */
  analyticsSimilarAdsDetails?: Maybe<SimilarAdsResponse>;
  /** Views in Job Search query */
  analyticsViewsInJobSearch?: Maybe<ViewsInJobSearchResponse>;
  /** Job Ad List query */
  analyticsJobAdList?: Maybe<JobAdListResponse>;
  /** Ad Usage Filters query */
  analyticsAdUsageFilters?: Maybe<AdUsageFiltersResponse>;
  /** Market Performance query */
  analyticsMarketPerformance?: Maybe<MarketPerformanceResponse>;
  /** Ad Usage Spend query */
  analyticsAdUsageSpend?: Maybe<AdUsageSpendResponse>;
  /** Ad Usage Conversion query */
  analyticsAdUsageConversion?: Maybe<AdUsageConversionResponse>;
  /** Ad Usage Trend classification and user widget */
  analyticsClassificationsAndUsersTrend?: Maybe<AdUsageTrendClassificationsAndUsersResponse>;
  /** Ad Usage Trend ad type daily and monthly widget */
  analyticsAdTypeTrend?: Maybe<AdUsageAdTypeTrendResponse>;
  /** Ad Usage Tips widget */
  analyticsAdUsageTipsToImproveAds?: Maybe<TipsToImproveAdsResponse>;
  /** Ad Usage Child Account Banner query */
  analyticsChildAccountBanner?: Maybe<ChildAccountBannerResponse>;
  /** Ad Usage Most Ads By Performance query */
  analyticsMostAdsByPerformance?: Maybe<MostAdsByPerformanceResponse>;
  /** Ad Usage Last Updated query */
  analyticsAdUsageLastUpdated?: Maybe<LastUpdatedResponse>;
  analyticsMarketRoleInsightsSearch?: Maybe<AnalyticsMarketRoleInsightsSearchResult>;
  analyticsMarketRoleInsightsLocations?: Maybe<AnalyticsMarketRoleInsightsLocationsResult>;
  analyticsMarketRoleInsightsJobTitles?: Maybe<AnalyticsMarketRoleInsightsJobTitlesResult>;
  analyticsTalentSearchUsageFilters?: Maybe<AnalyticsTalentSearchUsageFiltersResponse>;
  analyticsTalentSearchUsageCreditsUsageList?: Maybe<AnalyticsTalentSearchUsageCreditsUsageListResponse>;
  analyticsTalentSearchUsageLastUpdated?: Maybe<AnalyticsTalentSearchUsageLastUpdatedResponse>;
  analyticsUserParentAdvertiserId: ParentAdvertiser;
  contractBalances: Array<ContractBalance>;
  _empty?: Maybe<Scalars['String']>;
  brand?: Maybe<Brand>;
  defaultBrand?: Maybe<Brand>;
  brands?: Maybe<Array<Maybe<Brand>>>;
  brandingAssets?: Maybe<Array<Maybe<BrandingAsset>>>;
  suggestedCategories: Array<CategorySuggestion>;
  companyProfile?: Maybe<CompanyProfile>;
  companyProfileIds?: Maybe<CompanyProfileIds>;
  /** Retrieves a list of credit cards for a particular user against an advertiser account either in 'AU' or 'NZ' country codes. */
  SavedCreditCards?: Maybe<Array<Maybe<CreditCard>>>;
  draftJob?: Maybe<DraftJobPayload>;
  draftJobs?: Maybe<DraftJobsPayload>;
  hirerOriginZone?: Maybe<Scalars['String']>;
  headerFooterContext?: Maybe<HeaderFooterContext>;
  /** Summary of account details from hirer accounts API */
  hirerAccount?: Maybe<HirerAccount>;
  /**
   * Summary of account details currently used by account details vertical
   * @deprecated Use Query.hirerAccount instead
   */
  hirerAccountDetails?: Maybe<HirerAccountDetails>;
  /** Get hirer activities in pagination for a specific job order by most recent activity. Currently only add/delete notes on candidate */
  hirerActivitiesByJob: HirerActivityConnection;
  hirerDashboardJobs?: Maybe<HirerDashboardJobs>;
  hirerDashboardHiringAdviceList?: Maybe<HirerDashboardHiringAdviceList>;
  hirerDashboardTalentSearch: HirerDashboardTalentSearch;
  /** Get a page-based paginated list of open and/or closed jobs. */
  hirerJobs: HirerJobConnection;
  /** @deprecated This is only a dummy endpoint to verify a connection to Hirer users API - Please do not use in production */
  testUser?: Maybe<HirerUserDetails>;
  /**
   * Identity of the currently authenticated user.
   * @deprecated Use email and emailVerified queries
   */
  identity: Identity;
  /** Is the user enroled for multi-factor authentication. */
  isMfaEnroled?: Maybe<Scalars['Boolean']>;
  /** Email verification status of the user. */
  emailVerified: Scalars['Boolean'];
  /** Email address of the user. */
  email: Scalars['String'];
  /**
   * Searches for integration partners by name.
   *
   * A maximum of 10 integration partners will be returned.
   */
  searchIntegrationPartners: Array<IntegrationPartner>;
  getJobDetails?: Maybe<Job>;
  location?: Maybe<Location>;
  locationsByIds: Array<Maybe<Location>>;
  locations: Array<Maybe<LocationSearchResult>>;
  /** Get notification preference for current logged in user for a specific job */
  notificationPreference: NotificationPreference;
  order?: Maybe<Order>;
  orderSummary?: Maybe<OrderSummary>;
  /** Retrieves a list of credit cards for a particular user against an advertiser account either in 'AU' or 'NZ' country codes. */
  SavedStripeCreditCards?: Maybe<Array<Maybe<CreditCard>>>;
  payment2c2pInquiry?: Maybe<Payment2c2pInquiryPayload>;
  payment2c2pInquiryForInvoices?: Maybe<Payment2c2pInquiryPayload>;
  retrievePaymentIntent?: Maybe<RetrievePaymentIntentPayload>;
  getPaymentConfig?: Maybe<PaymentConfigPayload>;
  getPaymentMethodForMaintenance?: Maybe<PaymentMethodPayload>;
  /** Get the surcharge amount for amex payment method */
  getSurchargeAmount: GetSurchargeAmountPayload;
  /** Request to get ad budgets for the signed out advertiser. */
  adBudgets: AdBudgetResponse;
  /** Request to get ad budget for given budget id. */
  adBudgetById: AdBudget;
  /** Request to get product set */
  getProductSet: GetProductSetResponse;
  /** Request to get adProducts for Ad Posting */
  adProductsForAdPosting: AdProductSetResponse;
  /** Query to get ad budget products for upselling given list of product ids */
  adBudgetProductsForUpsell: AdBudgetProductsForUpsellResponse;
  /** Request to get lowest priced AdProduct during Ad Posting within a given ProductSet */
  lowestPricedProductForAdPosting: LowestPricedProductForAdPostingResponse;
  hirerDashboardBudgetUpsell: HirerDashboardBudgetUpsellResponse;
  promoCodes: Array<PromoCode>;
  questionnaire: Questionnaire;
  suggestedSalary?: Maybe<SuggestedSalary>;
  /** @deprecated Transition to accountSelectionV2 */
  accountSelection: AccountSelectionOptions;
  accountSelectionV2: AccountSelectionV2Response;
  countrySelectionOptions: CountrySelectionOptions;
  localisedContactInfo?: Maybe<LocalisedContactInfoContext>;
  registrationOptions: RegistrationOptions;
  registrationStatus: RegistrationStatusInfo;
  /** @deprecated Transition to hirerAccountLoginUrl */
  redirectUrl: Scalars['String'];
  hirerAccountLoginUrl: Scalars['String'];
  talentSearchAccounts?: Maybe<TalentSearchAccounts>;
  talentSearchATSJobs?: Maybe<TalentSearchAtsJobsConnection>;
  /** Fetches all jobs for an advertiser id. */
  talentSearchBudgetBalance: TalentSearchBudgetBalance;
  talentSearchCvUrl: Scalars['String'];
  talentSearchConnectionStatus: ConnectionStatusPayload;
  talentSearchConnectionsAvailabilities: Array<TalentSearchProfileConnectionsAvailability>;
  talentSearchMessageDetails: TalentSearchMessageDetails;
  talentSearchSendJobStatus: Array<TalentSearchProfileSendJobStatus>;
  /** Fetches all feature flags for the current user */
  talentSearchUserFeatures: Array<Maybe<TalentSearchFeature>>;
  talentSearchInteractions?: Maybe<TalentSearchInteractionsConnection>;
  talentSearchLatestInteractions: Array<TalentSearchLatestInteraction>;
  /** Fetches all jobs for an advertiser id (Version 2). */
  talentSearchJobsV2?: Maybe<TalentSearchJobsV2>;
  /** Fetches job by Id for an advertiser (Version 2) */
  talentSearchJobByIdV2?: Maybe<TalentSearchJobV2>;
  /** Fetches available jobs that has not been sent to the candidate (Version 2). */
  talentSearchAvailableJobsV2: Array<TalentSearchAvailableJobV2>;
  talentSearchMetadataIndustries: Array<TalentSearchMetadataIndustry>;
  talentSearchMetadataCompanySuggest: Array<Scalars['String']>;
  talentSearchMetadataQueryValidations?: Maybe<TalentSearchMetadataQueryValidations>;
  talentSearchMetadataMarkets: Array<TalentSearchMarket>;
  talentSearchPools: Array<TalentSearchPool>;
  talentSearchAssignedPools: Array<TalentSearchAssignedPools>;
  /** Get details for SEEK profile by email */
  talentSearchProfilesByEmail: TalentSearchProfileResultsV2;
  /** Get details for SEEK, Combined or Partner profile V2 */
  talentSearchProfileV2: TalentSearchProfileResultV2;
  /** Get profile resume for SEEK, Combined or Partner profile */
  talentSearchProfileResume: TalentSearchProfileResumeResult;
  /** Get matching similar candidates for SEEK profile */
  talentSearchSimilarCandidatesByIdV2?: Maybe<TalentSearchSimilarCandidatesResultV2>;
  /** Get search results for uncoupled search query with unified requirement */
  talentSearchUncoupledSearchV2: TalentSearchProfileResultsV2;
  /** Get profiles count for uncoupled search query V2 */
  talentSearchUncoupledCountForQueryV2: Scalars['Int'];
  /** Get V2 search results facets for uncoupled search query */
  talentSearchUncoupledCountsV2: TalentSearchCountsResults;
  /** Get recommended SEEK candidates powered by CQs recommendation algorithm for a job */
  talentSearchRecommendedCandidatesV2?: Maybe<TalentSearchProfileResultsV2>;
  /** Get all SEEK, Combined or Partner profiles added to a pool */
  talentSearchPoolCandidatesV2: TalentSearchProfileResultsV2;
  talentSearchSavedSearches: Array<TalentSearchSavedSearch>;
  /** Paginated Ts Hirer Activity for all filtered results */
  talentSearchUsageHirerActivityV2: TalentSearchUsageHirerActivityV2Connection;
  /** Summary of Talent Search usage aggregate for all filtered activities */
  talentSearchUsageAggregateV2: TalentSearchUsageAggregateV2;
  /** Summary of Talent Search spend aggregate for all filtered activities */
  talentSearchUsageSpendAggregate: TalentSearchUsageSpendAggregate;
  /**
   * Resolves user context data and relies on advertiserId in request headers
   * @deprecated Use talentSearchUserContextV2 instead
   */
  talentSearchUserContext?: Maybe<TalentSearchUserContext>;
  /** Resolves user context data for first available account or a specific account based on input parameters */
  talentSearchUserContextV2: TalentSearchUserContext;
  /** @deprecated Use currentAdvertiser and currentUser queries instead. */
  user?: Maybe<UserInfo>;
  /** Find the user associated with given User ID and AdCentre Advertiser ID */
  hirerAccountUser?: Maybe<UserDetails>;
  /** Finds the user associated with the given email */
  hirerUserEmail: UserEmailDetails;
  /** Finds the accounts associated with the given email */
  advertiserAccounts?: Maybe<Array<Maybe<AdvertiserAccount>>>;
  /** List all active and pending users tied to the Hirer account with their access permission information to the job. */
  hirerAccountUsersWithJobPermissions: Array<UserWithJobPermission>;
  yourCandidates: YourCandidatesResponse;
}

export interface Query_AnalyticsMyJobInformationArgs {
  input: MyJobInformationWidgetInput;
}

export interface Query_AnalyticsAdPerformanceReportArgs {
  input: AdPerformanceReportWidgetInput;
}

export interface Query_AnalyticsApplicationPerformanceArgs {
  input: ApplicationPerformanceWidgetInput;
}

export interface Query_AnalyticsCandidateDemandArgs {
  input: CandidateDemandWidgetInput;
}

export interface Query_AnalyticsCandidateSupplyArgs {
  input: CandidateSupplyWidgetInput;
}

export interface Query_AnalyticsClicksOnYourAdArgs {
  input: ClicksOnYourAdWidgetInput;
}

export interface Query_AnalyticsMarketInsightsTitleArgs {
  input: MarketInsightsTitleWidgetInput;
}

export interface Query_AnalyticsAdRatingArgs {
  input: AdRatingWidgetInput;
}

export interface Query_AnalyticsPageTitleActionsArgs {
  input: PageTitleActionsWidgetInput;
}

export interface Query_AnalyticsPerformancePredictionArgs {
  input: PerformancePredictionWidgetInput;
}

export interface Query_AnalyticsRecommendationsArgs {
  input: RecommendationsWidgetInput;
}

export interface Query_AnalyticsSimilarAdsDetailsArgs {
  input: SimilarAdsWidgetInput;
}

export interface Query_AnalyticsViewsInJobSearchArgs {
  input: ViewsInJobSearchWidgetInput;
}

export interface Query_AnalyticsJobAdListArgs {
  input: JobAdListWidgetInput;
}

export interface Query_AnalyticsAdUsageFiltersArgs {
  input: AdUsageFiltersWidgetInput;
}

export interface Query_AnalyticsMarketPerformanceArgs {
  input: MarketPerformanceWidgetInput;
}

export interface Query_AnalyticsAdUsageSpendArgs {
  input: AdUsageSpendWidgetInput;
}

export interface Query_AnalyticsAdUsageConversionArgs {
  input: AdUsageConversionWidgetInput;
}

export interface Query_AnalyticsClassificationsAndUsersTrendArgs {
  input: AdUsageTrendClassificationsAndUsersInput;
}

export interface Query_AnalyticsAdTypeTrendArgs {
  input: AdUsageAdTypeTrendInput;
}

export interface Query_AnalyticsAdUsageTipsToImproveAdsArgs {
  input: TipsToImproveAdsWidgetInput;
}

export interface Query_AnalyticsMostAdsByPerformanceArgs {
  input: MostAdsByPerformanceWidgetInput;
}

export interface Query_AnalyticsMarketRoleInsightsSearchArgs {
  input?: Maybe<AnalyticsMarketRoleInsightsSearchInput>;
}

export interface Query_AnalyticsMarketRoleInsightsLocationsArgs {
  input?: Maybe<AnalyticsMarketRoleInsightsLocationsInput>;
}

export interface Query_AnalyticsMarketRoleInsightsJobTitlesArgs {
  input?: Maybe<AnalyticsMarketRoleInsightsJobTitlesInput>;
}

export interface Query_AnalyticsTalentSearchUsageFiltersArgs {
  input: AnalyticsTalentSearchUsageFiltersInput;
}

export interface Query_AnalyticsTalentSearchUsageCreditsUsageListArgs {
  input: AnalyticsTalentSearchUsageCreditsUsageListInput;
}

export interface Query_ContractBalancesArgs {
  advertiserId?: Maybe<Scalars['String']>;
  advertiserNation: Scalars['String'];
}

export interface Query_BrandArgs {
  id: Scalars['ID'];
  owner?: Maybe<BrandOwnerInput>;
}

export interface Query_DefaultBrandArgs {
  owner?: Maybe<BrandOwnerInput>;
}

export interface Query_BrandsArgs {
  owner?: Maybe<BrandOwnerInput>;
}

export interface Query_BrandingAssetsArgs {
  input: GetBrandingAssetsInput;
}

export interface Query_SuggestedCategoriesArgs {
  jobTitle: Scalars['String'];
  country: Scalars['String'];
  jobOrDraftId?: Maybe<Scalars['Int']>;
  maxResults?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
}

export interface Query_CompanyProfileArgs {
  id: Scalars['ID'];
}

export interface Query_SavedCreditCardsArgs {
  advertiserId?: Maybe<Scalars['String']>;
  countryCode: Scalars['String'];
}

export interface Query_DraftJobArgs {
  id: Scalars['ID'];
}

export interface Query_DraftJobsArgs {
  input: DraftJobsInput;
}

export interface Query_HeaderFooterContextArgs {
  input?: Maybe<HeaderFooterContextInput>;
}

export interface Query_HirerAccountArgs {
  advertiserId?: Maybe<Scalars['Int']>;
  usersParams?: Maybe<UsersParamsInput>;
}

export interface Query_HirerAccountDetailsArgs {
  input?: Maybe<HirerAccountDetailsQueryInput>;
}

export interface Query_HirerActivitiesByJobArgs {
  input: HirerActivitiesByJobInput;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}

export interface Query_HirerDashboardJobsArgs {
  input?: Maybe<HirerDashboardJobsQueryInput>;
}

export interface Query_HirerJobsArgs {
  input: HirerJobsInput;
}

export interface Query_SearchIntegrationPartnersArgs {
  input: SearchIntegrationPartnersInput;
}

export interface Query_GetJobDetailsArgs {
  advertiserId?: Maybe<Scalars['String']>;
  jobId: Scalars['String'];
  contextualCountry?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['LanguageCodeIso']>;
}

export interface Query_LocationArgs {
  id: Scalars['ID'];
  contextualCountry?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['LanguageCodeIso']>;
}

export interface Query_LocationsByIdsArgs {
  ids: Array<Scalars['ID']>;
  contextualCountry?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['LanguageCodeIso']>;
}

export interface Query_LocationsArgs {
  advertiserId?: Maybe<Scalars['String']>;
  queryText?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['ID']>;
  context?: Maybe<LocationContext>;
  countries?: Maybe<CountriesInput>;
  excludeLocations?: Maybe<Array<Scalars['ID']>>;
  language?: Maybe<Scalars['LanguageCodeIso']>;
}

export interface Query_NotificationPreferenceArgs {
  jobId: Scalars['ID'];
  advertiserId: Scalars['ID'];
}

export interface Query_OrderArgs {
  id?: Maybe<Scalars['Int']>;
}

export interface Query_OrderSummaryArgs {
  advertiserId?: Maybe<Scalars['ID']>;
  orderId: Scalars['ID'];
  advertiserNation: Scalars['String'];
}

export interface Query_SavedStripeCreditCardsArgs {
  countryCode: Scalars['String'];
}

export interface Query_Payment2c2pInquiryArgs {
  currency: Scalars['String'];
  paymentToken?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
}

export interface Query_Payment2c2pInquiryForInvoicesArgs {
  currency: Scalars['String'];
  paymentToken?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
}

export interface Query_RetrievePaymentIntentArgs {
  id: Scalars['String'];
  country: Scalars['String'];
  paymentMethodType?: Maybe<StripePaymentMethod>;
}

export interface Query_GetPaymentConfigArgs {
  site: Scalars['String'];
  country: Scalars['String'];
  advertiserId?: Maybe<Scalars['String']>;
}

export interface Query_GetPaymentMethodForMaintenanceArgs {
  site: Scalars['String'];
  country: Scalars['String'];
}

export interface Query_GetSurchargeAmountArgs {
  input: GetSurchargeAmountInput;
}

export interface Query_AdBudgetByIdArgs {
  advertiserId?: Maybe<Scalars['ID']>;
  advertiserNation?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
}

export interface Query_GetProductSetArgs {
  input: GetProductSetInput;
}

export interface Query_AdProductsForAdPostingArgs {
  input: AdProductsForAdPostingInput;
}

export interface Query_AdBudgetProductsForUpsellArgs {
  input: AdBudgetProductsForUpsellInput;
}

export interface Query_LowestPricedProductForAdPostingArgs {
  input: LowestPricedProductForAdPostingInput;
}

export interface Query_HirerDashboardBudgetUpsellArgs {
  input: HirerDashboardBudgetUpsellInput;
}

export interface Query_QuestionnaireArgs {
  id: Scalars['String'];
  adcentreAdvertiserId: Scalars['String'];
}

export interface Query_SuggestedSalaryArgs {
  countryCode: Scalars['String'];
  currency?: Maybe<Scalars['String']>;
  locationId: Scalars['String'];
  roleTitle: Scalars['String'];
  workType?: Maybe<WorkType>;
  salaryType?: Maybe<SalaryType>;
  jobId?: Maybe<Scalars['String']>;
}

export interface Query_AccountSelectionArgs {
  input?: Maybe<AccountSelectionQueryInput>;
}

export interface Query_LocalisedContactInfoArgs {
  locale: Scalars['String'];
}

export interface Query_RegistrationStatusArgs {
  input?: Maybe<RegistrationStatusInput>;
}

export interface Query_RedirectUrlArgs {
  input?: Maybe<RedirectUrlQueryInput>;
}

export interface Query_HirerAccountLoginUrlArgs {
  input: HirerAccountLoginUrlInput;
}

export interface Query_TalentSearchAccountsArgs {
  input?: Maybe<TalentSearchAccountsInput>;
}

export interface Query_TalentSearchAtsJobsArgs {
  input: TalentSearchAtsJobsInput;
}

export interface Query_TalentSearchCvUrlArgs {
  profileId: Scalars['Int'];
}

export interface Query_TalentSearchConnectionStatusArgs {
  connectionId: Scalars['String'];
}

export interface Query_TalentSearchConnectionsAvailabilitiesArgs {
  profileIds: Array<Scalars['Int']>;
}

export interface Query_TalentSearchMessageDetailsArgs {
  connectionId: Scalars['String'];
}

export interface Query_TalentSearchSendJobStatusArgs {
  profileIds: Array<Scalars['Int']>;
}

export interface Query_TalentSearchInteractionsArgs {
  profileId: Scalars['Int'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}

export interface Query_TalentSearchLatestInteractionsArgs {
  profileIds: Array<Scalars['Int']>;
}

export interface Query_TalentSearchJobsV2Args {
  input?: Maybe<TalentSearchJobsInput>;
}

export interface Query_TalentSearchJobByIdV2Args {
  id: Scalars['Int'];
  language?: Maybe<Scalars['LanguageCodeIso']>;
}

export interface Query_TalentSearchAvailableJobsV2Args {
  input: TalentSearchAvailableJobsInput;
}

export interface Query_TalentSearchMetadataIndustriesArgs {
  input?: Maybe<TalentSearchMetadataIndustriesInput>;
}

export interface Query_TalentSearchMetadataCompanySuggestArgs {
  input?: Maybe<TalentSearchMetadataCompanySuggestInput>;
}

export interface Query_TalentSearchMetadataQueryValidationsArgs {
  input?: Maybe<TalentSearchMetadataQueryValidationsInput>;
}

export interface Query_TalentSearchMetadataMarketsArgs {
  input?: Maybe<TalentSearchMetadataMarketsInput>;
}

export interface Query_TalentSearchAssignedPoolsArgs {
  input?: Maybe<TalentSearchAssignedPoolsInput>;
}

export interface Query_TalentSearchProfilesByEmailArgs {
  input: ProfilesByEmailInput;
}

export interface Query_TalentSearchProfileV2Args {
  input: TalentSearchProfileInput;
  language?: Maybe<Scalars['LanguageCodeIso']>;
}

export interface Query_TalentSearchProfileResumeArgs {
  input: TalentSearchProfileResumeInput;
}

export interface Query_TalentSearchSimilarCandidatesByIdV2Args {
  input: TalentSearchSimilarCandidatesByIdInputV2;
}

export interface Query_TalentSearchUncoupledSearchV2Args {
  input: TalentSearchUncoupledSearchInputV2;
}

export interface Query_TalentSearchUncoupledCountForQueryV2Args {
  input: TalentSearchUncoupledCountForQueryInputV2;
}

export interface Query_TalentSearchUncoupledCountsV2Args {
  input: TalentSearchUncoupledCountForQueryInputV2;
}

export interface Query_TalentSearchRecommendedCandidatesV2Args {
  input: TalentSearchRecommendedCandidatesInputV2;
}

export interface Query_TalentSearchPoolCandidatesV2Args {
  input: TalentSearchPoolCandidatesInputV2;
}

export interface Query_TalentSearchUsageHirerActivityV2Args {
  input: TalentSearchUsageConnectionInput;
}

export interface Query_TalentSearchUsageAggregateV2Args {
  input: TalentSearchUsageAggregateInput;
}

export interface Query_TalentSearchUsageSpendAggregateArgs {
  input: TalentSearchUsageAggregateInput;
}

export interface Query_TalentSearchUserContextV2Args {
  input: TalentSearchUserContextInput;
}

export interface Query_UserArgs {
  input?: Maybe<UserDetailsQueryInput>;
}

export interface Query_HirerAccountUserArgs {
  input: HirerAccountUserQueryInput;
}

export interface Query_HirerUserEmailArgs {
  input: HirerUserEmailQueryInput;
}

export interface Query_AdvertiserAccountsArgs {
  input: HirerUserEmailQueryInput;
}

export interface Query_HirerAccountUsersWithJobPermissionsArgs {
  input: UsersWithJobPermissionInput;
}

export interface Query_YourCandidatesArgs {
  input: YourCandidatesInput;
}

export interface Questionnaire {
  id: Scalars['String'];
  published: Scalars['Boolean'];
  questions?: Maybe<Array<QuestionnaireQuestion>>;
}

export interface Questionnaire_QuestionsArgs {
  input?: Maybe<QuestionnaireQuestionFilter>;
}

export interface QuestionnaireAnswer {
  /**
   * RR Answer Local ID
   * @deprecated Use globalId instead. This id will be retired from FEB 13 2023. This value will only be returned for some legacy jobs
   */
  id?: Maybe<Scalars['String']>;
  /** RR Answer Global ID */
  globalId?: Maybe<Scalars['String']>;
  /** A statement that represents this answer */
  text: Scalars['String'];
  /** If true, represents that this answer is the inverse or 'negative' option to its siblings */
  isNegative: Scalars['Boolean'];
  /** If true, represents that this answer is a preferred answer for the hirer */
  isPreferred: Scalars['Boolean'];
  /** The display order of this answer in relation to that of its siblings */
  order: Scalars['Int'];
}

export interface QuestionnaireQuestion {
  /**
   * RR Question Local ID
   * @deprecated Use globalId instead. This id will be retired from FEB 13 2023. This value will only be returned for some legacy jobs
   */
  id?: Maybe<Scalars['String']>;
  /** RR Question Global ID */
  globalId?: Maybe<Scalars['String']>;
  /** Whether a question is library or custom */
  source: Scalars['String'];
  /** The answer's type. e.g MultiSelect, SingleSelect, FreeText, UrlSingleSelect */
  answerType: Scalars['String'];
  /** The display order of this question in relation to that of its siblings */
  order: Scalars['Int'];
  /** The question being asked */
  text: Scalars['String'];
  /** A shorter, summarised version of the question being asked */
  statement?: Maybe<Scalars['String']>;
  /** If true represents that this question is a must-have requirement */
  essential?: Maybe<Scalars['Boolean']>;
  /** The answers to a question */
  answers?: Maybe<Array<QuestionnaireAnswer>>;
  hirerExperience?: Maybe<HirerExperience>;
}

export enum QuestionnaireQuestionCategory {
  Salary = 'SALARY',
}

export interface QuestionnaireQuestionFilter {
  category?: Maybe<QuestionnaireQuestionCategory>;
}

export interface Range {
  minimum: Scalars['Int'];
  maximum: Scalars['Int'];
}

export type Recommendation =
  | RevealSalary
  | GranularLocation
  | LowClick
  | LowApply
  | BrandedAd
  | RoleRequirements
  | LowSalary
  | TalentSearch;

export enum RecommendationType {
  LowClick = 'LOW_CLICK',
  LowApply = 'LOW_APPLY',
  RevealSalary = 'REVEAL_SALARY',
  LowSalary = 'LOW_SALARY',
  GranularLocation = 'GRANULAR_LOCATION',
  BrandedAd = 'BRANDED_AD',
  RoleRequirements = 'ROLE_REQUIREMENTS',
  TalentSearch = 'TALENT_SEARCH',
}

export interface RecommendationsResponse {
  isExpired: Scalars['Boolean'];
  postTypeCategory: AnalyticsPostTypeCategory;
  recommendations: Array<Recommendation>;
}

export interface RecommendationsWidgetInput {
  jobId: Scalars['String'];
  hirerOriginZone: Scalars['String'];
  locale: Scalars['String'];
}

export interface RedirectUrlQueryInput {
  /** Hirer Account ID of the new advertiser */
  hirerAccountId?: Maybe<Scalars['String']>;
  /** Return uri for successful account switching. */
  returnUri?: Maybe<Scalars['String']>;
  /** Language param to apply additional localisation to auth related pages. */
  language?: Maybe<Scalars['String']>;
}

export interface RefreshJobInput {
  jobId: Scalars['String'];
  advertiserId?: Maybe<Scalars['String']>;
  idempotencyKey: Scalars['String'];
}

export interface RefreshJobResponse {
  jobId: Scalars['String'];
  /** The id of the revision that was created for the job */
  revisionId: Scalars['String'];
  /** The key used to refresh the job */
  idempotencyKey: Scalars['String'];
}

export interface RegisterAdditionalAdvertiserInput {
  /** New advertiser record to persist */
  advertiser: NewAdvertiser;
  /** Redirection uri for after signing into new account. */
  returnUri?: Maybe<Scalars['String']>;
}

export interface RegisterNewUserInput {
  /** New user record to persist */
  user: NewUser;
  /** New advertiser record to persist */
  advertiser: NewAdvertiser;
  /** Redirection uri for after signing into new account. */
  returnUri?: Maybe<Scalars['String']>;
  /** Language param to apply additional localisation to auth related pages. */
  language?: Maybe<Scalars['String']>;
}

export interface RegisterPayload {
  /** Adcentre advertiser ID of new advertiser */
  advertiserId: Scalars['ID'];
  /** SEEK ID of new advertiser */
  seekId: Scalars['ID'];
  /** Unified URL to sign into the advertiser account. Undefined for additional advertiser registrations. */
  redirectUrl?: Maybe<Scalars['String']>;
}

export interface RegistrationOptions {
  /** Allows customer service to register new businesses on clients behalf */
  allowAgencyRegistration: Scalars['Boolean'];
}

export interface RegistrationStatusInfo {
  /** Indicates whether the user exists in Adcentre DB. */
  userExists: Scalars['Boolean'];
}

export interface RegistrationStatusInput {
  /** Filter to restrict returned results on user status */
  filter?: Maybe<Scalars['String']>;
}

export interface ReinstateInput {
  /** The ID of the user to be reinstated */
  userId: Scalars['Int'];
}

export interface ReinstateResponse {
  /** Response message we receive from requesting to reinstate a user status */
  message: Scalars['String'];
}

export interface RemoveUserFromAccountInput {
  /** The ID of the user to be removed */
  userId: Scalars['Int'];
}

export interface RepostOption {
  key: AdRepost;
  count: Scalars['Int'];
}

export interface ResponseError {
  error: ResponseErrorReason;
}

export enum ResponseErrorReason {
  NotFound = 'NOT_FOUND',
  BadInput = 'BAD_INPUT',
  Forbidden = 'FORBIDDEN',
  UnexpectedError = 'UNEXPECTED_ERROR',
}

export interface ResumeEducation {
  qualificationName?: Maybe<Scalars['String']>;
  institutionName?: Maybe<Scalars['String']>;
  displayDate?: Maybe<Scalars['String']>;
}

export interface ResumeWorkHistory {
  companyName: Scalars['String'];
  duration?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  jobTitle?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  foundInCV: Scalars['Boolean'];
  highlight?: Maybe<WorkHistoryHighlight>;
  displayDescription: Array<ResumeWorkHistoryDisplayDescription>;
}

export interface ResumeWorkHistoryDisplayDescription {
  isBullet: Scalars['Boolean'];
  description: Scalars['String'];
}

export interface RetrievePaymentIntentPayload {
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  virtualAccount?: Maybe<VirtualAccount>;
}

export interface RevealAdSalary extends TipToImproveAds {
  type: TipsToImproveAdsType;
  adCount: Scalars['Int'];
}

export interface RevealSalary {
  type: RecommendationType;
  currency: Scalars['String'];
}

export interface ReviewAdDetails extends TipToImproveAds {
  type: TipsToImproveAdsType;
  adCount: Scalars['Int'];
  conversionRate?: Maybe<Scalars['Float']>;
  benchmarkConversionRate?: Maybe<Scalars['Float']>;
}

export interface ReviewAdSummary extends TipToImproveAds {
  type: TipsToImproveAdsType;
  adCount: Scalars['Int'];
  conversionRate?: Maybe<Scalars['Float']>;
  benchmarkConversionRate?: Maybe<Scalars['Float']>;
}

export interface RightToWork extends Verification {
  /** @deprecated use label instead */
  value?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  isVerified: Scalars['Boolean'];
  viewFields?: Maybe<Array<VerificationViewField>>;
}

export interface RightToWorkInSavedSearch {
  id: Scalars['String'];
  label?: Maybe<Scalars['String']>;
}

export interface RightToWorkInSavedSearch_LabelArgs {
  language?: Maybe<Scalars['LanguageCodeIso']>;
}

export interface RightToWorkInput {
  ids: Array<Scalars['String']>;
  includeUnspecified: Scalars['Boolean'];
}

export enum Role {
  /** An administrator. */
  Administrator = 'ADMINISTRATOR',
  /** A standard user. */
  User = 'USER',
}

export interface RoleRequirements {
  type: RecommendationType;
  roleReqsQuestions?: Maybe<Array<Scalars['String']>>;
}

/** RoleRequirementsMandatoryQuestions feature to indicate CPQA product */
export interface RoleRequirementsMandatoryQuestionsFeature
  extends CatalogueProductFeature {
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
  questionAnswerSet: Scalars['String'];
}

export interface Rule {
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Boolean']>;
}

export interface Salary {
  range?: Maybe<SalaryRange>;
  frequency: SalaryFrequency;
  includeUnspecified: Scalars['Boolean'];
}

export interface SalaryAmountV2 {
  frequency: SalaryFrequency;
  value: Scalars['Int'];
}

export enum SalaryFrequency {
  Annual = 'ANNUAL',
  Monthly = 'MONTHLY',
  Hourly = 'HOURLY',
}

export interface SalaryRange {
  minimum?: Maybe<Scalars['SafeInt']>;
  maximum?: Maybe<Scalars['SafeInt']>;
}

export enum SalaryType {
  AnnualPackage = 'ANNUAL_PACKAGE',
  AnnualCommission = 'ANNUAL_COMMISSION',
  CommissionOnly = 'COMMISSION_ONLY',
  HourlyRate = 'HOURLY_RATE',
  Monthly = 'MONTHLY',
}

export interface SavedCreditCardPaymentError extends Error {
  /** A human-readable message providing more details about the error. */
  message: Scalars['String'];
  /** Short string indicating the error code reported. */
  errorCode?: Maybe<Scalars['String']>;
  /** For card errors resulting from a card issuer decline, a short string indicating the card issuer’s reason for the decline if they provide one. */
  declineCode?: Maybe<Scalars['String']>;
  /** If the error is parameter-specific, the parameter related to the error. For example, you can use this to display a message near the correct form field. */
  param?: Maybe<Scalars['String']>;
  /** Id of the failed payment intent. */
  paymentIntentId: Scalars['String'];
  /** The status of the payment intent. Examples: "requires_payment_method" "requires_confirmation" "requires_action" "processing" "requires_capture" "canceled". */
  paymentIntentStatus: Scalars['String'];
}

export interface SavedCreditCardPaymentSuccessPayload {
  /** Id of the successful payment intent. */
  paymentIntentId: Scalars['String'];
  /** The client secret of this PaymentIntent. Used for client-side retrieval using a publishable key. */
  clientSecret: Scalars['String'];
}

export interface SearchIntegrationPartnersInput {
  search: Scalars['String'];
}

export interface SecureLinkExpiredError extends Error {
  message: Scalars['String'];
}

export interface SecureLinkInput {
  /** Temporary link GUID of SecurityLink entities. */
  link: Scalars['String'];
}

export interface SecureLinkInvalidError extends Error {
  message: Scalars['String'];
}

export type SecureLinkResponse =
  | SecureLinkSuccessResponse
  | SecureLinkUsedError
  | SecureLinkExpiredError
  | SecureLinkInvalidError;

export interface SecureLinkSuccessResponse {
  redirectUri: Scalars['String'];
}

export interface SecureLinkUsedError extends Error {
  message: Scalars['String'];
}

export interface SellingPoint {
  /** Description of a selling point */
  name: Scalars['String'];
  /** To determine whether to highlight the displayed selling point or not */
  isHighlighted: Scalars['Boolean'];
  /** Additional information about a product */
  helpTip?: Maybe<HelpTip>;
}

export interface SendEmailResponse {
  /** Response message we receive from requesting the password and registration emails to be sent. */
  message?: Maybe<Scalars['String']>;
}

export interface SendEmailVerificationEmailPayload {
  /** Success status of sending email. */
  success: Scalars['Boolean'];
  /** Indicates whether a user has already verified their email address. */
  emailVerified: Scalars['Boolean'];
}

export interface SendEnrolmentEmailPayload {
  /** success of sending Enrolment Email */
  isEnrolmentEmailSent: Scalars['Boolean'];
}

export interface SendHirerAccountUsersJobAccessEmailInput {
  /** Id of the job that users are to be notified that they have access to */
  jobId: Scalars['ID'];
  /** The job advertiser id */
  advertiserId: Scalars['ID'];
  /** A list of team members who are invited to access the job */
  receivers: Array<AccessEmailReceiverInput>;
  /** Custom email message written by the sender */
  emailMessage?: Maybe<Scalars['String']>;
  /** If set to true, sender will be included in the email cc list */
  includeSender?: Maybe<Scalars['Boolean']>;
}

export interface SendHirerAccountUsersJobAccessEmailPayload {
  /** Success status of sending email. Returns true if send email operation is accepted. */
  success: Scalars['Boolean'];
}

export interface SendJobInput {
  connectionType: TalentSearchConnectionType;
  profileId: Scalars['Int'];
}

export interface SendLoginCallbackEventPayload {
  /** Success status of processing callback event. */
  success: Scalars['Boolean'];
}

export interface SendMessageInput {
  connectionType: TalentSearchConnectionType;
  profileId: Scalars['Int'];
  subject: Scalars['String'];
  body: Scalars['String'];
  advertiserFirstName: Scalars['String'];
  advertiserLastName: Scalars['String'];
  advertiserTitle: Scalars['String'];
  advertiserEmail: Scalars['String'];
  advertiserPhone?: Maybe<Scalars['String']>;
}

export interface SendPasswordResetEmailInput {
  /** Email address to send password reset email to. */
  email: Scalars['String'];
}

export interface SendRegistrationEmailInput {
  /** Is the user in Customer Service view. */
  isInCSView: Scalars['Boolean'];
  /** ID of user to send registration email to. */
  userId: Scalars['Int'];
}

export interface SetDefaultNotificationPreferenceInput {
  /** Id of the job to set notification preferences. */
  jobId: Scalars['ID'];
}

export interface SetHirerAccountUsersJobAccessInput {
  /** The advertiser id that users tied to the account. */
  advertiserId: Scalars['ID'];
  /** The job id to set users access to */
  jobId: Scalars['ID'];
  /** List of user job access settings */
  accessList: Array<UserJobAccessInput>;
  /**
   * If set true, delay updating to wait position is synchronized in adCentre.
   * If set false, update job access immediately
   */
  delayUpdate?: Maybe<Scalars['Boolean']>;
}

export interface SetHirerAccountUsersJobAccessPayload {
  /** Success status of set user job access operation. Returns true if set job access operation is accepted. */
  success: Scalars['Boolean'];
}

export interface SetNotificationPreferencesInput {
  /** Id of the job to set notification preferences. */
  jobId: Scalars['ID'];
  /** The job advertiser id */
  advertiserId: Scalars['ID'];
  /** Notification preferences to be set for account users. */
  preferences: Array<NotificationPreferenceInput>;
  /**
   * If set true, delay updating to wait position is synchronized in adCentre.
   * If set false, update notification preferences immediately
   */
  delayUpdate?: Maybe<Scalars['Boolean']>;
}

export interface SetNotificationPreferencesPayload {
  /** Success status of setting notification preferences operation. Returns true if set notification preferences operation is accepted. */
  success: Scalars['Boolean'];
}

export interface SimilarAdsDetails {
  advertiserName: Scalars['String'];
  adType: AnalyticsAdType;
  hasKeySellingPoints: Scalars['Boolean'];
  isPrivateAdvertiser: Scalars['Boolean'];
  isSalaryHidden: Scalars['Boolean'];
  jobCreatedTimestampUTC: Scalars['DateTime'];
  jobId: Scalars['String'];
  jobLocationLabel: Scalars['String'];
  jobTitle: Scalars['String'];
  productDisplayName: Scalars['String'];
  serpLogoUrl?: Maybe<Scalars['String']>;
}

export interface SimilarAdsResponse {
  ads: Array<SimilarAdsDetails>;
  benchmarkLocationLabel?: Maybe<Scalars['String']>;
  benchmarkScenario?: Maybe<AnalyticsBenchmarkScenario>;
  classificationName: Scalars['String'];
  normalisedRoleTitle?: Maybe<Scalars['String']>;
  subClassificationName: Scalars['String'];
}

export interface SimilarAdsWidgetInput {
  jobId: Scalars['String'];
  hirerOriginZone: Scalars['String'];
  locale: Scalars['String'];
}

export interface SimplifiedAnswers {
  /** The simplified answer text */
  text: Scalars['String'];
  /**
   * The simplified answer local id
   * @deprecated Use globalId instead. This id will be retired from FEB 13 2023. This value will only be returned for some legacy jobs.
   */
  id?: Maybe<Scalars['String']>;
  /** The simplified answer global id */
  globalId?: Maybe<Scalars['String']>;
  /** The local ids of the granular answers that the given simplified answer maps to */
  answerIds?: Maybe<Array<Scalars['String']>>;
  /** The global ids of the granular answers that the given simplified answer maps to */
  answerGlobalIds?: Maybe<Array<Scalars['String']>>;
  /** True if the hirer selected the answer during CAJA */
  isPreferred: Scalars['Boolean'];
  /** The display order of the answer, low to high */
  order: Scalars['Int'];
}

/** Product feature that indicates Sponsored Slot feature properties available for ad product */
export interface SponsoredSlotFeature extends CatalogueProductFeature {
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
}

export interface StripePaymentIntentPayload {
  /** The client secret of this PaymentIntent. Used for client-side retrieval using a publishable key. */
  clientSecret: Scalars['String'];
  /** Unique identifier for payment intent object */
  id: Scalars['String'];
  /** The base64 image data for the QR code */
  qrCodeUrl?: Maybe<Scalars['String']>;
  /** A url that the customer can use to complete payment. If a url has not been set up for that market, this field will be omitted. */
  paymentLink?: Maybe<Scalars['String']>;
  /** The payment reference for the payment intent. To be used only on invoices. */
  reference?: Maybe<Scalars['String']>;
}

export enum StripePaymentMethod {
  Card = 'card',
  Fpx = 'fpx',
  IdBankTransfer = 'id_bank_transfer',
  Paynow = 'paynow',
  Promptpay = 'promptpay',
}

/** Product feature that indicates border available for ad product */
export interface StyleEnhancementFeature extends CatalogueProductFeature {
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
  style: Scalars['String'];
}

export interface SubTitle {
  style: Scalars['String'];
  text: Scalars['String'];
}

export interface Subject {
  /** The Auth0 user id of the subject, eg 'auth0|12eb841a02c489d1'. */
  id: Scalars['String'];
  /**
   * The associated adcentre user id. This may be null if a corresponding adcentre user has not yet been
   * created, or accounts are not associated correctly.
   */
  userId?: Maybe<Scalars['String']>;
  /**
   * Email address of the subject.
   * @deprecated Use email query
   */
  email: Scalars['String'];
  /**
   * Indicates whether a subject has verified their email address.
   * @deprecated Use emailVerified query
   */
  emailVerified: Scalars['Boolean'];
}

export interface SuggestedSalary {
  range?: Maybe<SuggestedSalaryRange>;
  averageRange?: Maybe<SuggestedSalaryAverageRange>;
  countryCode?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  roleTitle?: Maybe<Scalars['String']>;
  workType?: Maybe<WorkType>;
  salaryType?: Maybe<SalaryType>;
}

export interface SuggestedSalaryAverageRange {
  min?: Maybe<Scalars['SafeInt']>;
  median?: Maybe<Scalars['SafeInt']>;
  max?: Maybe<Scalars['SafeInt']>;
}

export interface SuggestedSalaryRange {
  min?: Maybe<Scalars['SafeInt']>;
  max?: Maybe<Scalars['SafeInt']>;
}

export interface Surcharge {
  /** The type of surcharge. Ex, AmexSurcharge */
  surchargeType: Scalars['String'];
  /** The surcharge amount. */
  amount: Money;
}

export interface TalentSearch {
  type: RecommendationType;
  jobId: Scalars['String'];
  hirerId: Scalars['String'];
}

export interface TalentSearchAtsJob {
  jobId: Scalars['String'];
  jobTitle: Scalars['String'];
  externalReferenceId: Scalars['String'];
  seekHirerJobReference?: Maybe<Scalars['String']>;
  positionProfileName?: Maybe<Scalars['String']>;
  jobOwners?: Maybe<Array<TalentSearchJobOwner>>;
  jobLocations?: Maybe<Array<Scalars['String']>>;
}

export interface TalentSearchAtsJobEdge {
  node: TalentSearchAtsJob;
  cursor?: Maybe<Scalars['String']>;
}

export interface TalentSearchAtsJobs {
  count: Scalars['Int'];
  items?: Maybe<Array<TalentSearchAtsJob>>;
}

export interface TalentSearchAtsJobsConnection {
  pageInfo: PageInfo;
  edges: Array<TalentSearchAtsJobEdge>;
  totalCount: Scalars['Int'];
}

export interface TalentSearchAtsJobsInput {
  jobTitle: Scalars['String'];
  jobOwnerName?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}

export enum TalentSearchAccess {
  Standard = 'STANDARD',
  Premium = 'PREMIUM',
  Disabled = 'DISABLED',
  SystemDown = 'SYSTEM_DOWN',
}

export interface TalentSearchAccount {
  advertiserId: Scalars['Int'];
  advertiserName: Scalars['String'];
  seekBillingAccountId: Scalars['String'];
  hasPremiumTalentSearchAccess: Scalars['Boolean'];
  hasConnectedTalentSearchAccess: Scalars['Boolean'];
  parentAdvertiserId?: Maybe<Scalars['Int']>;
  companyAddress?: Maybe<CompanyAddress>;
}

export interface TalentSearchAccounts {
  count: Scalars['Int'];
  items: Array<TalentSearchAccount>;
}

export interface TalentSearchAccountsInput {
  query?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<TalentSearchAccountsOrderBy>;
}

export interface TalentSearchAccountsOrderBy {
  field: TalentSearchAccountsOrderByField;
  direction: TalentSearchAccountsOrderByDirection;
}

export enum TalentSearchAccountsOrderByDirection {
  Desc = 'desc',
  Asc = 'asc',
}

export enum TalentSearchAccountsOrderByField {
  AdvertiserName = 'advertiserName',
  AdvertiserId = 'advertiserId',
}

export interface TalentSearchAddOrRemoveProfileTalentPoolsInput {
  profileId: Scalars['String'];
  values: Array<Maybe<TalentSearchAddOrRemoveProfileTalentPoolsValuesInput>>;
}

export interface TalentSearchAddOrRemoveProfileTalentPoolsValuesInput {
  poolId: Scalars['String'];
  addToPool: Scalars['Boolean'];
  searchId?: Maybe<Scalars['String']>;
}

export interface TalentSearchAddProfileToTalentPoolInput {
  poolId: Scalars['String'];
  profileId: Scalars['String'];
  searchId?: Maybe<Scalars['String']>;
}

export interface TalentSearchAdvertiser {
  id: Scalars['Int'];
  name: Scalars['String'];
  accountNumber: Scalars['Int'];
  parentAdvertiserId?: Maybe<Scalars['Int']>;
  isApproved: Scalars['Boolean'];
  tags?: Maybe<TalentSearchHirerAccountTags>;
}

export interface TalentSearchAssignedPool {
  id: Scalars['ID'];
  name: Scalars['String'];
}

export interface TalentSearchAssignedPools {
  profileId: Scalars['ID'];
  assignedPools: Array<TalentSearchAssignedPool>;
}

export interface TalentSearchAssignedPoolsInput {
  profileIds: Array<Scalars['Int']>;
}

export interface TalentSearchAtsHirerRelationship {
  id: Scalars['Int'];
  name: Scalars['String'];
}

export interface TalentSearchAvailableJobV2 {
  id: Scalars['String'];
  expiry: Scalars['String'];
  title: Scalars['String'];
  reference?: Maybe<Scalars['String']>;
}

export interface TalentSearchAvailableJobsInput {
  /** Filters jobs based on the title and reference fields. */
  query?: Maybe<Scalars['String']>;
  /** The profile id to retrieve sent jobs with. */
  profileId: Scalars['Int'];
  /** Limits the number of available jobs in the response. Default is set to 10. */
  maxJobs?: Maybe<Scalars['Int']>;
}

export interface TalentSearchBudgetBalance {
  /** Initial budget balance for an advertiser or job. */
  initialBalance: Scalars['Int'];
  /** Current budget balance for an advertiser or job. */
  currentBalance: Scalars['Int'];
}

export enum TalentSearchClaim {
  StandardTalentSearch = 'STANDARD_TALENT_SEARCH',
  PremiumTalentSearch = 'PREMIUM_TALENT_SEARCH',
  ConnectedTalentSearch = 'CONNECTED_TALENT_SEARCH',
}

export interface TalentSearchConnectionAvailability {
  connectionType: Scalars['Int'];
  connectionTypeName: Scalars['String'];
  isUsed: Scalars['Boolean'];
  connectionId?: Maybe<Scalars['String']>;
  createdDateUtc?: Maybe<Scalars['String']>;
  nextAvailableDateUtc?: Maybe<Scalars['String']>;
}

export enum TalentSearchConnectionStatus {
  Pending = 'pending',
  Redeemed = 'redeemed',
  Rejected = 'rejected',
}

export enum TalentSearchConnectionType {
  Unknown = 'UNKNOWN',
  Jsp = 'JSP',
  Ita = 'ITA',
  Msg = 'MSG',
  Job = 'JOB',
  AtsMsg = 'ATS_MSG',
  ProfileExportAts = 'PROFILE_EXPORT_ATS',
}

export interface TalentSearchContract {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  productType: TalentSearchContractType;
}

export enum TalentSearchContractType {
  PremiumTalentSearch = 'PREMIUM_TALENT_SEARCH',
  PremiumTalentSearchTrial = 'PREMIUM_TALENT_SEARCH_TRIAL',
  ConnectedTalentSearch = 'CONNECTED_TALENT_SEARCH',
}

export interface TalentSearchCountResult {
  id: Scalars['String'];
  count: Scalars['Int'];
}

export interface TalentSearchCountResultWithLabel {
  id: Scalars['String'];
  count: Scalars['Int'];
  label?: Maybe<Scalars['String']>;
}

export interface TalentSearchCountsResults {
  approachabilitysignal?: Maybe<Array<TalentSearchCountResult>>;
  company?: Maybe<Array<TalentSearchCountResult>>;
  currentLocation?: Maybe<Array<TalentSearchCountResult>>;
  industry?: Maybe<Array<TalentSearchCountResult>>;
  salary?: Maybe<Array<TalentSearchCountResult>>;
  rightToWork?: Maybe<Array<TalentSearchCountResultWithLabel>>;
}

export interface TalentSearchCountsResults_RightToWorkArgs {
  language?: Maybe<Scalars['LanguageCodeIso']>;
}

export interface TalentSearchCreateTalentPoolInput {
  name: Scalars['String'];
}

export interface TalentSearchCriteria {
  approachable?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Array<Scalars['String']>>;
  countryCode?: Maybe<Scalars['String']>;
  industry?: Maybe<Array<Scalars['String']>>;
  keywords?: Maybe<Scalars['String']>;
  lastUpdated?: Maybe<Scalars['String']>;
  locationList: Array<Scalars['Int']>;
  locationDisplayTexts: Array<Scalars['String']>;
  nation?: Maybe<Scalars['Int']>;
  maxSalary?: Maybe<Scalars['Int']>;
  minSalary?: Maybe<Scalars['Int']>;
  salaryNation?: Maybe<Scalars['Int']>;
  salaryType?: Maybe<Scalars['String']>;
  salaryUnspecified?: Maybe<Scalars['Boolean']>;
  uncoupledFreeText: Scalars['String'];
  /** @deprecated Will be removed once granular right to work is implemented */
  visa?: Maybe<Array<Scalars['String']>>;
  rightToWorkList?: Maybe<Array<RightToWorkInSavedSearch>>;
  rightToWorkUnspecified?: Maybe<Scalars['Boolean']>;
  willingToRelocate?: Maybe<Scalars['Boolean']>;
  workType?: Maybe<Array<Scalars['String']>>;
  cv?: Maybe<Scalars['Boolean']>;
  profileTypes?: Maybe<Array<ProfileSource>>;
}

export interface TalentSearchCriteria_LocationDisplayTextsArgs {
  language?: Maybe<Scalars['LanguageCodeIso']>;
}

export interface TalentSearchCriteriaInput {
  approachable?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Array<Scalars['String']>>;
  countryCode?: Maybe<Scalars['String']>;
  industry?: Maybe<Array<Scalars['String']>>;
  keywords?: Maybe<Scalars['String']>;
  lastUpdated?: Maybe<Scalars['String']>;
  locationList: Array<Scalars['Int']>;
  nation?: Maybe<Scalars['Int']>;
  maxSalary?: Maybe<Scalars['Int']>;
  minSalary?: Maybe<Scalars['Int']>;
  salaryNation?: Maybe<Scalars['Int']>;
  salaryType?: Maybe<Scalars['String']>;
  salaryUnspecified?: Maybe<Scalars['Boolean']>;
  uncoupledFreeText: Scalars['String'];
  visa?: Maybe<Array<Scalars['String']>>;
  willingToRelocate?: Maybe<Scalars['Boolean']>;
  workType?: Maybe<Array<Scalars['String']>>;
  cv?: Maybe<Scalars['Boolean']>;
  profileTypes?: Maybe<Array<ProfileSource>>;
  rightToWorkIds?: Maybe<Array<Scalars['String']>>;
  rightToWorkUnspecified?: Maybe<Scalars['Boolean']>;
}

export interface TalentSearchDailyUsageV2 {
  cvPreviewCount: Array<Scalars['Int']>;
  cvDownloadCount: Array<Scalars['Int']>;
  profileViewCount: Array<Scalars['Int']>;
  searchCount: Array<Scalars['Int']>;
  sendJobAppsCompleted: Array<Scalars['Int']>;
  sendJobAppsStarted: Array<Scalars['Int']>;
  sendJobCount: Array<Scalars['Int']>;
  sendMessageCount: Array<Scalars['Int']>;
  sendMessageOpened: Array<Scalars['Int']>;
}

export interface TalentSearchDeleteTalentPoolInput {
  poolId: Scalars['String'];
}

export interface TalentSearchFeature {
  /** Unique identifier of feature flag */
  key: Scalars['String'];
  /** Name of feature flag */
  name: Scalars['String'];
  /** Whether or not the feature flag is active */
  active: Scalars['Boolean'];
}

export interface TalentSearchHirerAccountTags {
  testRecord: Scalars['String'];
  testScope?: Maybe<Scalars['String']>;
  recordExpiry?: Maybe<Scalars['String']>;
  testBehaviours?: Maybe<Scalars['String']>;
}

export interface TalentSearchHirerContactDetails {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  position: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
}

export interface TalentSearchInteraction {
  id: Scalars['String'];
  interactionType: Scalars['String'];
  positionProfileId?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['String']>;
  seekSourceName?: Maybe<Scalars['String']>;
  associatedParties?: Maybe<Array<Maybe<AssociatedParty>>>;
  associatedAction: AssociatedAction;
}

export interface TalentSearchInteractionEdge {
  node: TalentSearchInteraction;
  cursor?: Maybe<Scalars['String']>;
}

export interface TalentSearchInteractionsConnection {
  pageInfo: PageInfo;
  edges: Array<TalentSearchInteractionEdge>;
  totalCount: Scalars['Int'];
}

export interface TalentSearchJobLocationV2 {
  id: Scalars['String'];
  countryCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
}

export interface TalentSearchJobOwner {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  formattedName: Scalars['String'];
}

export interface TalentSearchJobSalaryV2 {
  frequency: SalaryFrequency;
}

export interface TalentSearchJobV2 {
  /** The projected job id. */
  id: Scalars['String'];
  /** The associated advertiser id for the job. */
  advertiserId: Scalars['String'];
  /** Whether the job is Active or Inactive. */
  status: Scalars['String'];
  /** The title of the job. */
  title: Scalars['String'];
  /** An optional reference provided for the job. */
  reference?: Maybe<Scalars['String']>;
  /** Date in ISO format when job will expire */
  expiryDate: Scalars['String'];
  /** Date in ISO format when job was originally posted */
  createdDate: Scalars['String'];
  /** Current balance for this job retrieved from budgets API */
  balance: TalentSearchBudgetBalance;
  /** Location of the job */
  location: TalentSearchJobLocationV2;
  /** Salary details of the job */
  salary: TalentSearchJobSalaryV2;
}

export interface TalentSearchJobV2_LocationArgs {
  language?: Maybe<Scalars['LanguageCodeIso']>;
}

export interface TalentSearchJobsInput {
  /** Returns jobs with both "Active" and "Expired" status. */
  includeExpired?: Maybe<Scalars['Boolean']>;
  /** Limits the number of jobs in the response. */
  maxJobs?: Maybe<Scalars['Int']>;
  /** Filters jobs based on the title and reference fields. */
  query?: Maybe<Scalars['String']>;
  /** Order jobs by field and direction (asc, desc) */
  orderBy?: Maybe<TalentSearchJobsOrderBy>;
  /** Specify langugage for translated location */
  language?: Maybe<Scalars['LanguageCodeIso']>;
}

export interface TalentSearchJobsOrderBy {
  field: TalentSearchJobsOrderByField;
  direction: TalentSearchJobsOrderByDirection;
}

export enum TalentSearchJobsOrderByDirection {
  Desc = 'desc',
  Asc = 'asc',
}

export enum TalentSearchJobsOrderByField {
  Listed = 'listed',
}

export interface TalentSearchJobsV2 {
  totalJobs: Scalars['Int'];
  jobs: Array<TalentSearchJobV2>;
}

export interface TalentSearchLatestInteraction {
  profileId: Scalars['Int'];
  count: Scalars['Int'];
  interaction?: Maybe<TalentSearchInteraction>;
}

export interface TalentSearchMarket {
  countryCode: Scalars['String'];
  countryName: Scalars['String'];
}

export interface TalentSearchMessageDetails {
  subject: Scalars['String'];
  body: Scalars['String'];
  hirer: TalentSearchHirerContactDetails;
}

export interface TalentSearchMetadataCompanySuggestInput {
  query: Scalars['String'];
  size?: Maybe<Scalars['Int']>;
}

export enum TalentSearchMetadataCulture {
  Au = 'au',
  Nz = 'nz',
}

export interface TalentSearchMetadataIndustriesInput {
  locale?: Maybe<Scalars['String']>;
}

export interface TalentSearchMetadataIndustry {
  id: Scalars['ID'];
  name: Scalars['String'];
  subIndustries?: Maybe<Array<TalentSearchMetadataSubIndustry>>;
}

export interface TalentSearchMetadataMarketsInput {
  language?: Maybe<Scalars['LanguageCodeIso']>;
}

export interface TalentSearchMetadataQueryValidations {
  stopwords: Array<Scalars['String']>;
  whitelistRegexPattern: Scalars['String'];
}

export interface TalentSearchMetadataQueryValidationsInput {
  region?: Maybe<Scalars['String']>;
}

export interface TalentSearchMetadataSubIndustry {
  id: Scalars['ID'];
  name: Scalars['String'];
}

export interface TalentSearchPool {
  id: Scalars['String'];
  userId: Scalars['Int'];
  name: Scalars['String'];
  lastUpdated: Scalars['String'];
  profileCount: Scalars['Int'];
  policy?: Maybe<Scalars['String']>;
}

export interface TalentSearchPoolCandidatesInputV2 {
  countryCode: Scalars['String'];
  poolId: Scalars['String'];
  pageNumber?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
}

export interface TalentSearchPoolProfile {
  poolId: Scalars['String'];
  userId: Scalars['Int'];
  profileId: Scalars['String'];
  lastUpdated: Scalars['String'];
  searchId?: Maybe<Scalars['String']>;
}

export interface TalentSearchProfileConnectionsAvailability {
  profileId: Scalars['Int'];
  connectionsAvailability: Array<TalentSearchConnectionAvailability>;
}

export interface TalentSearchProfileInput {
  profileId?: Maybe<Scalars['Int']>;
  keywords?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
}

export interface TalentSearchProfileResultV2 {
  salary?: Maybe<ProfileSalaryV2>;
  currentInferredIndustry?: Maybe<Scalars['String']>;
  currentIndustry?: Maybe<Scalars['String']>;
  currentIndustryId?: Maybe<Scalars['String']>;
  currentSubindustry?: Maybe<Scalars['String']>;
  currentSubindustryId?: Maybe<Scalars['String']>;
  currentJobTitle?: Maybe<Scalars['String']>;
  currentLocation?: Maybe<Scalars['String']>;
  homeLocationId?: Maybe<Scalars['String']>;
  pastJobTitles?: Maybe<Array<Scalars['String']>>;
  suburb?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  hasResume: Scalars['Boolean'];
  resumeCoreDataAvailable?: Maybe<Scalars['Boolean']>;
  jobSeekerId: Scalars['Int'];
  noticePeriodDays?: Maybe<Scalars['Int']>;
  lastModifiedDate?: Maybe<Scalars['String']>;
  atsLastUpdatedDateUtc?: Maybe<Scalars['String']>;
  profileId: Scalars['Int'];
  profileType: ProfileTypes;
  profilePrivacy: PrivacyTypes;
  resumeSnippet?: Maybe<Scalars['String']>;
  skills?: Maybe<Array<Scalars['String']>>;
  workTypes: Array<Scalars['String']>;
  workHistories: Array<WorkHistory>;
  highlight?: Maybe<ProfileResultHighlight>;
  approachabilitySignal?: Maybe<Scalars['Int']>;
  actionLinks?: Maybe<ActionLink>;
  partnerDoNotContact: Scalars['Boolean'];
  profileEducation?: Maybe<Array<ProfileEducation>>;
  ahpraRegistrations?: Maybe<Array<AhpraRegistration>>;
  driversLicences?: Maybe<Array<DriversLicence>>;
  sensitiveWordsFoundInResumeSnippet: Scalars['Boolean'];
  sensitiveSearchTermsInResumeSnippet: Scalars['Boolean'];
  languages?: Maybe<Array<Scalars['String']>>;
  rightToWork?: Maybe<RightToWork>;
  hasVerifiedCredentials: Scalars['Boolean'];
}

export interface TalentSearchProfileResultV2_CurrentLocationArgs {
  language?: Maybe<Scalars['LanguageCodeIso']>;
}

export interface TalentSearchProfileResultV2_RightToWorkArgs {
  language?: Maybe<Scalars['LanguageCodeIso']>;
}

export interface TalentSearchProfileResultsV2 {
  count: Scalars['Int'];
  items: Array<TalentSearchProfileResultV2>;
  searchIdentifier?: Maybe<Scalars['String']>;
}

export interface TalentSearchProfileResumeInput {
  profileId?: Maybe<Scalars['Int']>;
  keywords?: Maybe<Scalars['String']>;
}

export interface TalentSearchProfileResumeResult {
  resumeWorkHistories: Array<ResumeWorkHistory>;
  resumeEducation: Array<ResumeEducation>;
  resumeSkills: Array<Scalars['String']>;
}

export interface TalentSearchProfileSendJobStatus {
  profileId: Scalars['Int'];
  status: TalentSearchSendJobStatus;
}

export enum TalentSearchQuerySalaryType {
  Annual = 'ANNUAL',
  Monthly = 'MONTHLY',
  HourlyRate = 'HOURLY_RATE',
}

export enum TalentSearchQuerySortBy {
  Relevance = 'RELEVANCE',
  CreatedDate = 'CREATED_DATE',
  ModifiedDate = 'MODIFIED_DATE',
  Freshness = 'FRESHNESS',
}

export interface TalentSearchRecommendedCandidatesInputV2 {
  countryCode: Scalars['String'];
  jobId: Scalars['Int'];
  page: Scalars['Int'];
  size?: Maybe<Scalars['Int']>;
  searchExperiment?: Maybe<Scalars['String']>;
  userSessionId?: Maybe<Scalars['String']>;
  searchId?: Maybe<Scalars['String']>;
}

export interface TalentSearchSavedSearch {
  name: Scalars['String'];
  criteria: TalentSearchCriteria;
  criteriaHash: Scalars['String'];
  createdDate: Scalars['String'];
}

export enum TalentSearchSendJobStatus {
  AllJobsSent = 'ALL_JOBS_SENT',
  NoActiveJobs = 'NO_ACTIVE_JOBS',
  HasAvailableJobs = 'HAS_AVAILABLE_JOBS',
}

export interface TalentSearchShareTalentPoolInput {
  poolId: Scalars['String'];
  policy: Scalars['String'];
}

export interface TalentSearchSimilarCandidatesByIdInputV2 {
  countryCode: Scalars['String'];
  profileId?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  isSeedProfileLocationOnly?: Maybe<Scalars['Boolean']>;
  profileTypes?: Maybe<Array<Scalars['String']>>;
  first?: Maybe<Scalars['Int']>;
  searchId?: Maybe<Scalars['String']>;
  searchExperiment?: Maybe<Scalars['String']>;
}

export interface TalentSearchSimilarCandidatesResultV2 {
  seedProfile?: Maybe<TalentSearchProfileResultV2>;
  profiles: TalentSearchProfileResultsV2;
}

export interface TalentSearchUncoupledCountForQueryInputV2 {
  countryCode: Scalars['String'];
  salary?: Maybe<Salary>;
  workType?: Maybe<Array<Scalars['String']>>;
  location?: Maybe<Array<Scalars['String']>>;
  willingToRelocate?: Maybe<Scalars['Boolean']>;
  visa?: Maybe<Array<Scalars['String']>>;
  rightToWork?: Maybe<RightToWorkInput>;
  cv?: Maybe<Scalars['Boolean']>;
  industry?: Maybe<Array<Scalars['String']>>;
  company?: Maybe<Scalars['String']>;
  sortBy?: Maybe<TalentSearchQuerySortBy>;
  keywords?: Maybe<Scalars['String']>;
  locationList?: Maybe<Array<Scalars['String']>>;
  locations?: Maybe<Array<Scalars['String']>>;
  uncoupledFreeText: Scalars['String'];
  approachable?: Maybe<Scalars['Boolean']>;
  profileTypes?: Maybe<Array<Scalars['String']>>;
  lastUpdated?: Maybe<Scalars['Int']>;
}

export interface TalentSearchUncoupledSearchInputV2 {
  countryCode: Scalars['String'];
  salary?: Maybe<Salary>;
  workType?: Maybe<Array<Scalars['String']>>;
  willingToRelocate?: Maybe<Scalars['Boolean']>;
  visa?: Maybe<Array<Scalars['String']>>;
  rightToWork?: Maybe<RightToWorkInput>;
  cv?: Maybe<Scalars['Boolean']>;
  industry?: Maybe<Array<Scalars['String']>>;
  company?: Maybe<Scalars['String']>;
  sortBy?: Maybe<TalentSearchQuerySortBy>;
  keywords?: Maybe<Scalars['String']>;
  locationList?: Maybe<Array<Scalars['String']>>;
  locations?: Maybe<Array<Scalars['String']>>;
  uncoupledFreeText: Scalars['String'];
  approachable?: Maybe<Scalars['Boolean']>;
  profileTypes?: Maybe<Array<Scalars['String']>>;
  lastUpdated?: Maybe<Scalars['Int']>;
  pageNumber?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  searchExperiment?: Maybe<Scalars['String']>;
  userSessionId?: Maybe<Scalars['String']>;
  searchId?: Maybe<Scalars['String']>;
}

export interface TalentSearchUpdateTalentPoolInput {
  poolId: Scalars['String'];
  name: Scalars['String'];
}

export interface TalentSearchUsageAdvertiserDetails {
  accountNumber: Scalars['String'];
  advertiserName: Scalars['String'];
  parentAccountNumber?: Maybe<Scalars['String']>;
  parentAdvertiserName?: Maybe<Scalars['String']>;
  parentAdvertiserId?: Maybe<Scalars['String']>;
}

export interface TalentSearchUsageAggregateComparisonV2 {
  cvDownloadCount: Scalars['Int'];
  cvPreviewCount: Scalars['Int'];
  profileViewCount: Scalars['Int'];
  searchCount: Scalars['Int'];
  sendJobAppsCompleted: Scalars['Int'];
  sendJobAppsStarted: Scalars['Int'];
  sendJobCount: Scalars['Int'];
  sendMessageCount: Scalars['Int'];
  sendMessageOpened: Scalars['Int'];
}

export interface TalentSearchUsageAggregateInput {
  activityDateFrom?: Maybe<Scalars['String']>;
  activityDateTo?: Maybe<Scalars['String']>;
  advertiserId: Scalars['String'];
  dataSource?: Maybe<Scalars['String']>;
  filters?: Maybe<TalentSearchUsageFilters>;
}

export interface TalentSearchUsageAggregateV2 {
  comparison: TalentSearchUsageAggregateComparisonV2;
  cvDownloadCount: Scalars['Int'];
  cvPreviewCount: Scalars['Int'];
  dailyUsage: TalentSearchDailyUsageV2;
  profileViewCount: Scalars['Int'];
  searchCount: Scalars['Int'];
  sendJobAppsCompleted: Scalars['Int'];
  sendJobAppsStarted: Scalars['Int'];
  sendJobCount: Scalars['Int'];
  sendMessageCount: Scalars['Int'];
  sendMessageOpened: Scalars['Int'];
}

export interface TalentSearchUsageConnectionInput {
  activityDateFrom?: Maybe<Scalars['String']>;
  activityDateTo?: Maybe<Scalars['String']>;
  advertiserId: Scalars['String'];
  after?: Maybe<Scalars['String']>;
  dataSource?: Maybe<Scalars['String']>;
  filters?: Maybe<TalentSearchUsageFilters>;
  first?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  orderByDirection?: Maybe<Scalars['String']>;
}

export interface TalentSearchUsageFilters {
  advertiserName?: Maybe<Array<Scalars['String']>>;
  userFullName?: Maybe<Array<Scalars['String']>>;
}

export interface TalentSearchUsageHirerActivityV2 {
  cvPreviewCount: Scalars['Int'];
  cvDownloadCount: Scalars['Int'];
  cvDownloadCreditsUsed: Scalars['Int'];
  lastUpdated: Scalars['String'];
  profileViewCount: Scalars['Int'];
  searchCount: Scalars['Int'];
  sendJobCount: Scalars['Int'];
  sendJobCreditsUsed: Scalars['Int'];
  sendJobAppsCompleted: Scalars['Int'];
  sendJobAppsStarted: Scalars['Int'];
  sendMessageCount: Scalars['Int'];
  sendMessageCreditsUsed: Scalars['Int'];
  sendMessageOpened: Scalars['Int'];
  totalConnections: Scalars['Int'];
  totalCredits: Scalars['Int'];
  userFullName?: Maybe<Scalars['String']>;
}

export interface TalentSearchUsageHirerActivityV2Connection {
  id: Scalars['ID'];
  advertiserDetails: TalentSearchUsageAdvertiserDetails;
  edges: Array<TalentSearchUsageHirerActivityV2Edge>;
  searchFacets?: Maybe<Array<TalentSearchUsageSearchFacet>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

export interface TalentSearchUsageHirerActivityV2Edge {
  cursor: Scalars['String'];
  node: TalentSearchUsageHirerActivityV2;
}

export interface TalentSearchUsageSearchFacet {
  count: Scalars['Int'];
  key: Scalars['String'];
  value: Scalars['String'];
}

export interface TalentSearchUsageSpendAggregate {
  averageCreditsPerDay: Scalars['Int'];
  averageCreditsPerUser: Scalars['Int'];
  dailyCreditsUsed: Array<Scalars['Int']>;
  cvDownloadCount: Scalars['Int'];
  cvDownloadCreditsUsed: Scalars['Int'];
  comparison: TalentSearchUsageSpendAggregateComparison;
  sendMessageCount: Scalars['Int'];
  sendMessageCreditsUsed: Scalars['Int'];
  sendJobCount: Scalars['Int'];
  sendJobCreditsUsed: Scalars['Int'];
  totalCreditsUsed: Scalars['Int'];
  totalUsers: Scalars['Int'];
}

export interface TalentSearchUsageSpendAggregateComparison {
  cvDownloadCount: Scalars['Int'];
  cvDownloadCreditsUsed: Scalars['Int'];
  sendMessageCount: Scalars['Int'];
  sendMessageCreditsUsed: Scalars['Int'];
  sendJobCount: Scalars['Int'];
  sendJobCreditsUsed: Scalars['Int'];
  totalCreditsUsed: Scalars['Int'];
  totalUsers: Scalars['Int'];
}

export interface TalentSearchUser {
  userId: Scalars['Int'];
  firstName: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  status: Scalars['String'];
  updatedAt: Scalars['String'];
}

export interface TalentSearchUserContext {
  user: TalentSearchUser;
  claims: Array<TalentSearchClaim>;
  advertiser: TalentSearchAdvertiser;
  ats?: Maybe<TalentSearchAtsHirerRelationship>;
  contracts: Array<TalentSearchContract>;
  productType?: Maybe<TalentSearchClaim>;
}

export interface TalentSearchUserContextInput {
  advertiserId?: Maybe<Scalars['Int']>;
  preferredAdvertiserId?: Maybe<Scalars['Int']>;
  jobId?: Maybe<Scalars['Int']>;
  productType?: Maybe<Scalars['String']>;
}

export interface Tax {
  amount?: Maybe<Money>;
  rate?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  code: TaxCode;
  displayRecommendation?: Maybe<DisplayRecommendation>;
}

export enum TaxCode {
  Gst = 'GST',
  Vat = 'VAT',
  Sst = 'SST',
  Ppn = 'PPN',
}

export interface TipToImproveAds {
  type: TipsToImproveAdsType;
  adCount: Scalars['Int'];
}

export interface TipsToImproveAdsResponse {
  tips?: Maybe<Array<Maybe<TipToImproveAds>>>;
  numOfAds: Scalars['Int'];
}

export enum TipsToImproveAdsType {
  ReviewAdSummary = 'REVIEW_AD_SUMMARY',
  ReviewAdDetails = 'REVIEW_AD_DETAILS',
  RevealSalary = 'REVEAL_SALARY',
  MaximiseBranding = 'MAXIMISE_BRANDING',
  AddSpecificLocation = 'ADD_SPECIFIC_LOCATION',
  AddQuestions = 'ADD_QUESTIONS',
}

export interface TipsToImproveAdsWidgetInput {
  locale: Scalars['String'];
  filters: AdUsageQueryFilters;
  specificLocationShortId: Scalars['Int'];
  nonSpecificLocationShortId: Scalars['Int'];
}

export interface TrackLocationSuggestionInput {
  /** Same Session ID as the one used during 'locations()' Query */
  sessionId: Scalars['ID'];
  /** Location ID (ie, "18579") */
  id?: Maybe<Scalars['ID']>;
  queryText?: Maybe<Scalars['String']>;
}

export interface TrackLocationSuggestionInvalidInputPayload {
  reason: Scalars['String'];
}

export type TrackLocationSuggestionPayload =
  | TrackLocationSuggestionSuccessPayload
  | TrackLocationSuggestionInvalidInputPayload;

export interface TrackLocationSuggestionSuccessPayload {
  responseStatus: Scalars['String'];
}

export interface TrendsAdTypeOption {
  key?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
}

export interface TriggerActivationPayload {
  success: Scalars['Boolean'];
}

export interface UpdateBrandInput {
  id: Scalars['String'];
  owner?: Maybe<BrandOwnerInput>;
  legacyId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  assets?: Maybe<BrandAssetsInput>;
}

export type UpdateBrandPayload =
  | UpdateBrandSuccessPayload
  | InvalidInputPayload;

export interface UpdateBrandSuccessPayload {
  id: Scalars['String'];
  legacyId?: Maybe<Scalars['Int']>;
  owner: BrandOwner;
  name: Scalars['String'];
  assets: BrandAssets;
  state?: Maybe<BrandState>;
  isDefault?: Maybe<Scalars['Boolean']>;
  createdOn?: Maybe<Scalars['String']>;
}

export interface UpdateDraftJobExpressPayload {
  /** The updated draft job id from the express api */
  draftId: Scalars['Int'];
}

export interface UpdateDraftJobInput {
  /** the id of the draft to be update */
  id: Scalars['String'];
  /** Adcentre advertiserId */
  adcentreAdvertiserId?: Maybe<Scalars['String']>;
  /** aka LinkOut url, is used to apply via external website */
  applicationFormUrl?: Maybe<Scalars['String']>;
  /** Selected branding info */
  branding?: Maybe<DraftJobBrandingInput>;
  /** Selected category and sub-category */
  categories?: Maybe<Array<DraftJobCategoryInput>>;
  /** The Job Description, aka "Write my own" */
  details?: Maybe<Scalars['String']>;
  /**
   * The draft progress made in the job posting's create flow
   * Edit flow and refresh flow do not have draftProgress
   */
  draftProgress?: Maybe<DraftJobProgress>;
  /** Selected expire data */
  expiresAt?: Maybe<Scalars['String']>;
  /** The flow a draft is created from (e.g. create, edit) */
  flowType: DraftJobFlowType;
  /** The pre-defined Guided Job description, aka "Help me write my ad" */
  guidedDetails?: Maybe<Array<DraftJobGuidedDetailsInput>>;
  /** The flag to tell whether the hirer is posting a private or a public Ad. */
  isConfidential?: Maybe<Scalars['Boolean']>;
  /** Standard or Guided Mode for writing job description */
  jobWritingMode?: Maybe<DraftJobWritingMode>;
  /** Reference number is displayed on invoices and used for linkout ads */
  jobAdReference?: Maybe<Scalars['String']>;
  /** Selling points - used in the search description for standout/premium ads */
  keySellingPoints?: Maybe<Array<Scalars['String']>>;
  /** The job location info */
  locations?: Maybe<Array<DraftJobLocationInput>>;
  /** Selected products info */
  products?: Maybe<DraftJobProductsInput>;
  /** The id for a role requirements questionnaire */
  questionnaireId?: Maybe<Scalars['String']>;
  /** Min/Max salaries info */
  salary?: Maybe<DraftJobSalaryDetailsInput>;
  /** The status of the draft */
  status: DraftStatus;
  /** The job summary to appear in search */
  summary?: Maybe<Scalars['String']>;
  /** The job title(s) */
  titles?: Maybe<Array<DraftJobRoleTitleInput>>;
  /** The video info, e.g. video url */
  video?: Maybe<DraftJobVideoInput>;
  /** The work type(s) (e.g. full time, part time) */
  workTypes?: Maybe<Array<WorkType>>;
  /** The selected payment option (e.g. invoice, credit card etc.) */
  paymentOption?: Maybe<DraftJobPaymentOption>;
  /** Hirer's phone number */
  phoneNumber?: Maybe<Scalars['String']>;
}

export interface UpdateDraftJobPayload {
  /** The updated draft job id from the new drafts api */
  draftId: Scalars['String'];
}

export type UpdateDraftPayload =
  | UpdateDraftJobExpressPayload
  | UpdateDraftJobPayload;

export interface UpdateInvoiceStatusInput {
  invoices: Array<Invoice>;
  seekBillingAccountId: Scalars['ID'];
}

export interface UpdateInvoiceStatusPayload {
  success: Scalars['Boolean'];
  message: Scalars['String'];
}

export interface UpdateUserDetailsResponse {
  message?: Maybe<Scalars['String']>;
}

export interface UpdateUserPermissionsInput {
  /** The info of the user in AdCentre */
  userInfo: UserInfoInput;
}

export interface UpdateUserPermissionsResponse {
  userId: Scalars['String'];
  permissions: UserPermissions;
}

export interface UpgradePath {
  /** Type of upgrade path */
  type: UpgradePathType;
  /** Upgrade product available for AdProduct to which upgrade path is associated */
  upgradeProduct: AdProductUpgrade;
  /** Target ad product selected when upgrade product is selected */
  targetProduct: AdProduct;
}

export enum UpgradePathType {
  UpgradeOnly = 'UpgradeOnly',
}

export interface UpgradeToPremiumInput {
  advertiserId?: Maybe<Scalars['String']>;
  jobId: Scalars['String'];
  advertiserNation: Scalars['String'];
  idempotencyKey: Scalars['String'];
}

export type UpgradeToPremiumPayload =
  | UpgradeToPremiumSuccessPayload
  | JobAdNotUpgradableToPremium;

export interface UpgradeToPremiumSuccessPayload {
  orderId: Scalars['String'];
  revisionId: Scalars['String'];
}

export interface User {
  id: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  status: Scalars['String'];
  permissions: UserPermissions;
}

export interface UserDetails {
  /** User ID of the user */
  id: Scalars['String'];
  /** Email address of the user */
  email: Scalars['String'];
  /** First name of the user */
  firstName: Scalars['String'];
  /** Last name of the user */
  lastName: Scalars['String'];
  /** Status of the user */
  status: UserStatus;
  /** Permissions of the user on the advertiser account */
  permissions?: Maybe<UserPermissions>;
}

export interface UserDetailsFailureMessage {
  /** Response message we receive when an error occurs retrieving the user details */
  message?: Maybe<Scalars['String']>;
}

export interface UserDetailsInput {
  /** First name of the user */
  firstName: Scalars['String'];
  /** Last name of the user */
  lastName: Scalars['String'];
  /** Advertiser ID which the user is currently signed in as */
  advertiserId?: Maybe<Scalars['String']>;
  /** AdCentre user ID of the user that is currently signed in */
  userId: Scalars['Int'];
}

export interface UserDetailsQueryInput {
  /** Advertiser ID which the user is currently signed in as */
  advertiserId?: Maybe<Scalars['String']>;
}

export interface UserEmailDetails {
  /** ID of the user in AdCentre */
  id: Scalars['String'];
  /** Email of the user in AdCentre */
  email: Scalars['String'];
  /** Current status of the user in AdCentre */
  status?: Maybe<UserStatus>;
}

export interface UserHeaderContext {
  /** Adcentre User ID */
  id: Scalars['ID'];
  /** Full name of user. */
  name: Scalars['String'];
  /** First name of user. Used for header dropdown text when advertiser context unavailable. */
  firstName: Scalars['String'];
}

export interface UserInfo {
  /** First name of the user */
  firstName: Scalars['String'];
  /** Last name of the user */
  lastName: Scalars['String'];
  /**
   * ID of the user currently signed in.
   * If ID is the only field being requested, the response will be faster due to caching of user IDs in hirer-personalisation-api
   */
  id: Scalars['ID'];
  /** Email of the user currently signed in */
  email: Scalars['String'];
  /** Status of the user currently signed in */
  status: Scalars['String'];
  /** Status of Profile Properties related to the account of the user currently signed in */
  profileProperties?: Maybe<ProfileProperties>;
  /** Effective Talent Search access for this user against this advertiser */
  talentSearchAccess?: Maybe<TalentSearchAccess>;
  /** Role of user relevant to the specified advertiser */
  role: Scalars['String'];
  /** User claims relevant to the specified advertiser */
  claims?: Maybe<Array<Maybe<Claim>>>;
}

export interface UserInfoInput {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  permissions: UserPermissionsInput;
}

export enum UserIntentType {
  Preview = 'PREVIEW',
  Purchase = 'PURCHASE',
}

export interface UserJobAccessInput {
  /** Id of the user */
  userId: Scalars['ID'];
  /** User access to job */
  jobAccess: Scalars['Boolean'];
}

export interface UserListResponse {
  users: Array<User>;
}

export interface UserOption {
  key: Scalars['String'];
  value: Scalars['String'];
  count: Scalars['Int'];
}

export interface UserPermissions {
  hasPremiumTalentSearch: Scalars['Boolean'];
  hasAllPositionAccess: Scalars['Boolean'];
  hasAnalytics: Scalars['Boolean'];
  canCreatePosition: Scalars['Boolean'];
  role: Scalars['String'];
}

export interface UserPermissionsInput {
  hasPremiumTalentSearch: Scalars['Boolean'];
  hasAllPositionAccess: Scalars['Boolean'];
  hasAnalytics: Scalars['Boolean'];
  canCreatePosition: Scalars['Boolean'];
  role: Role;
}

export enum UserStatus {
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED',
  Pending = 'PENDING',
  Fraudulent = 'FRAUDULENT',
  Suspended = 'SUSPENDED',
  Unverified = 'UNVERIFIED',
}

export interface UserWithJobPermission {
  /** Id of the user on the advertiser account. */
  userId: Scalars['ID'];
  /** The users first name. */
  firstName: Scalars['String'];
  /** The users last name. */
  lastName: Scalars['String'];
  /** The users email address. */
  email: Scalars['String'];
  /** A flag denoting whether the user is still required to activate their user account. */
  isPending: Scalars['Boolean'];
  /** A flag denoting whether the user has access to all jobs created within the advertiser account. */
  hasAccessToAllJobs: Scalars['Boolean'];
  /** A flag denoting whether the user has access to querying job. */
  hasAccessToCurrentJob: Scalars['Boolean'];
  /** A flag denoting whether the user is job creator. */
  isJobCreator: Scalars['Boolean'];
  /** A flag denoting whether the user is an Administrator. */
  isAdmin: Scalars['Boolean'];
}

export enum UsersParamsFilter {
  Active = 'ACTIVE',
  Pending = 'PENDING',
  Deactivated = 'DEACTIVATED',
  Unverified = 'UNVERIFIED',
}

export enum UsersParamsInclude {
  AccessDetails = 'ACCESS_DETAILS',
}

export interface UsersParamsInput {
  /** Filter array to narrow down the returned list of users based on user status. Can be include [ACTIVE | PENDING | DEACTIVATED] */
  usersFilter?: Maybe<Array<Maybe<UsersParamsFilter>>>;
  /** Filter array to narrow down the returned list of users based on access right. Can be include [ACTIVE | PENDING | DEACTIVATED | ADMIN_ROLE | USER_ROLE] */
  accessRightFilter?: Maybe<Array<Maybe<AccessRightFilter>>>;
  /** Add on properties to returned users. Can be "ACCESS_DETAILS" */
  include?: Maybe<UsersParamsInclude>;
}

export interface UsersWithJobPermissionInput {
  /** The advertiser id to fetch users tied to the account. */
  advertiserId: Scalars['ID'];
  /** The job id to check users access permission */
  jobId: Scalars['ID'];
}

export interface Verification {
  isVerified: Scalars['Boolean'];
  viewFields?: Maybe<Array<VerificationViewField>>;
}

export interface VerificationViewField {
  name: Scalars['String'];
  value: Scalars['String'];
  fieldType: VerificationViewFieldType;
}

export enum VerificationViewFieldType {
  String = 'String',
  Date = 'Date',
  Number = 'Number',
}

export interface ViewsInJobSearchResponse {
  adRatingConfidence?: Maybe<AnalyticsAdRatingConfidence>;
  clickConversionInPercentage?: Maybe<Scalars['Float']>;
  mobileViewsInPercentage: Scalars['Float'];
  similarAdsAverage?: Maybe<Scalars['Int']>;
  similarAdsInPercentage?: Maybe<Scalars['Float']>;
  views: Scalars['Int'];
  hasAdRating: Scalars['Boolean'];
}

export interface ViewsInJobSearchWidgetInput {
  jobId: Scalars['String'];
  hirerOriginZone: Scalars['String'];
}

export interface VirtualAccount {
  accountNo?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  bankCode?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  expires?: Maybe<Scalars['Int']>;
}

export interface WindowLocation {
  /** The current URLs path */
  pathname: Scalars['String'];
  /** The current URLs query (includes leading “?” if non-empty) */
  search: Scalars['String'];
}

export interface WorkHistory {
  companyName: Scalars['String'];
  duration?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  jobTitle?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  foundInCV: Scalars['Boolean'];
  highlight?: Maybe<WorkHistoryHighlight>;
}

export interface WorkHistoryHighlight {
  companyName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
}

export enum WorkType {
  Fulltime = 'FULLTIME',
  Parttime = 'PARTTIME',
  Casual = 'CASUAL',
  ContractTemp = 'CONTRACT_TEMP',
}

export interface YourCandidate {
  id: Scalars['ID'];
  name: Scalars['String'];
  emailAddress: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  lastRoleCompanyName?: Maybe<Scalars['String']>;
  lastRoleJobTitle?: Maybe<Scalars['String']>;
  rating: Scalars['Int'];
  status: YourCandidateStatus;
  statusText: Scalars['String'];
  lastUpdated: Scalars['String'];
  applicationTime: Scalars['String'];
  prospectId: Scalars['Int'];
  candidateId: Scalars['Int'];
  adcentreCandidateId: Scalars['Int'];
  jobId: Scalars['ID'];
  channel: YourCandidateChannel;
  totalApplications: Scalars['Int'];
  jobTitle: Scalars['String'];
  hasResume: Scalars['Boolean'];
  hasCoverLetter: Scalars['Boolean'];
  hasSelectionCriteria: Scalars['Boolean'];
}

export enum YourCandidateChannel {
  Native = 'NATIVE',
  Uploaded = 'UPLOADED',
}

export interface YourCandidateEdge {
  node: YourCandidate;
}

export enum YourCandidateStatus {
  New = 'NEW',
  Viewed = 'VIEWED',
  Prescreen = 'PRESCREEN',
  Accept = 'ACCEPT',
  Offer = 'OFFER',
  Interview = 'INTERVIEW',
  Shortlist = 'SHORTLIST',
  Rejected = 'REJECTED',
}

export interface YourCandidatesInput {
  pageNumber?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
}

export interface YourCandidatesPayloadError {
  error: YourCandidatesPayloadErrorType;
}

export enum YourCandidatesPayloadErrorType {
  NotFound = 'NOT_FOUND',
  InputValidationError = 'INPUT_VALIDATION_ERROR',
  ForbiddenError = 'FORBIDDEN_ERROR',
  UnexpectedError = 'UNEXPECTED_ERROR',
}

export type YourCandidatesResponse =
  | YourCandidatesResults
  | YourCandidatesPayloadError;

export interface YourCandidatesResults {
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
  edges: Array<YourCandidateEdge>;
}

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs,
> {
  subscribe: SubscriptionSubscribeFn<
    { [key in TKey]: TResult },
    TParent,
    TContext,
    TArgs
  >;
  resolve?: SubscriptionResolveFn<
    TResult,
    { [key in TKey]: TResult },
    TContext,
    TArgs
  >;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs,
> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {},
> =
  | ((
      ...args: any[]
    ) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo,
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo,
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<
  TResult = {},
  TParent = {},
  TContext = {},
  TArgs = {},
> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AccessCvInput: AccessCvInput;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  String: ResolverTypeWrapper<Scalars['String']>;
  AccessEmailReceiverInput: AccessEmailReceiverInput;
  AccessRightFilter: AccessRightFilter;
  AccessRightStatus: AccessRightStatus;
  AccountActivationStatus: AccountActivationStatus;
  AccountBillingAddressInput: AccountBillingAddressInput;
  AccountBillingEmailAddressInput: AccountBillingEmailAddressInput;
  AccountDetailsUpdateResponse: ResolverTypeWrapper<AccountDetailsUpdateResponse>;
  AccountHierarchyOption: ResolverTypeWrapper<AccountHierarchyOption>;
  AccountPostalAddressInput: AccountPostalAddressInput;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  AccountPrimaryContactInput: AccountPrimaryContactInput;
  AccountSelectionEdge: ResolverTypeWrapper<AccountSelectionEdge>;
  AccountSelectionOptions: ResolverTypeWrapper<AccountSelectionOptions>;
  AccountSelectionQueryInput: AccountSelectionQueryInput;
  AccountSelectionResults: ResolverTypeWrapper<AccountSelectionResults>;
  AccountSelectionV2Response:
    | ResolversTypes['AccountSelectionResults']
    | ResolversTypes['ResponseError'];
  ActionLink: ResolverTypeWrapper<ActionLink>;
  Actor: ResolverTypeWrapper<Actor>;
  ActorContext: ResolverTypeWrapper<ActorContext>;
  Ad: ResolverTypeWrapper<Ad>;
  AdBudget: ResolverTypeWrapper<AdBudget>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  AdBudgetAttributes: AdBudgetAttributes;
  AdBudgetMetadata: ResolverTypeWrapper<AdBudgetMetadata>;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  AdBudgetProduct: ResolverTypeWrapper<
    Omit<AdBudgetProduct, 'price' | 'purchasableProducts'> & {
      price: ResolversTypes['CatalogueProductPrice'];
      purchasableProducts: ResolversTypes['PurchasableProducts'];
    }
  >;
  AdBudgetProductList: ResolverTypeWrapper<AdBudgetProductList>;
  AdBudgetProductsForUpsellInput: AdBudgetProductsForUpsellInput;
  AdBudgetProductsForUpsellResponse:
    | ResolversTypes['AdBudgetProductList']
    | ResolversTypes['ProductSetExpiredError']
    | ResolversTypes['ProductSetNotFoundError'];
  AdBudgetResponse: ResolverTypeWrapper<AdBudgetResponse>;
  AdBudgetType: AdBudgetType;
  AdBudgetsByAdvertiserInput: AdBudgetsByAdvertiserInput;
  AdMetaData: ResolverTypeWrapper<AdMetaData>;
  AdPerformanceReportResponse: ResolverTypeWrapper<AdPerformanceReportResponse>;
  AdPerformanceReportWidgetInput: AdPerformanceReportWidgetInput;
  AdProduct: ResolverTypeWrapper<
    Omit<AdProduct, 'price' | 'pricing'> & {
      price: ResolversTypes['CatalogueProductPrice'];
      pricing?: Maybe<ResolversTypes['Pricing']>;
    }
  >;
  AdProductDisplayMetadata: ResolverTypeWrapper<AdProductDisplayMetadata>;
  AdProductSetResponse:
    | ResolversTypes['ProductList']
    | ResolversTypes['ProductSetExpiredError']
    | ResolversTypes['ProductSetNotFoundError']
    | ResolversTypes['DisplayMetadataError'];
  AdProductUpgrade: ResolverTypeWrapper<
    Omit<AdProductUpgrade, 'price'> & {
      price: ResolversTypes['CatalogueProductPrice'];
    }
  >;
  AdProductsForAdPostingInput: AdProductsForAdPostingInput;
  AdRatingLabels: ResolverTypeWrapper<AdRatingLabels>;
  AdRatingResponse: ResolverTypeWrapper<AdRatingResponse>;
  AdRatingType: AdRatingType;
  AdRatingTypeOption: ResolverTypeWrapper<AdRatingTypeOption>;
  AdRatingWidgetInput: AdRatingWidgetInput;
  AdRepost: AdRepost;
  AdSpendFeature: ResolverTypeWrapper<AdSpendFeature>;
  AdStatusOption: ResolverTypeWrapper<AdStatusOption>;
  AdTipsOption: ResolverTypeWrapper<AdTipsOption>;
  AdTipsType: AdTipsType;
  AdType: AdType;
  AdTypeAverageCost: ResolverTypeWrapper<AdTypeAverageCost>;
  AdTypeDiscount: ResolverTypeWrapper<AdTypeDiscount>;
  AdTypeDiscountsFeature: ResolverTypeWrapper<AdTypeDiscountsFeature>;
  AdTypeOption: ResolverTypeWrapper<AdTypeOption>;
  AdTypePostCount: ResolverTypeWrapper<AdTypePostCount>;
  AdTypeTheme: AdTypeTheme;
  AdUsageAdStatus: AdUsageAdStatus;
  AdUsageAdType: AdUsageAdType;
  AdUsageAdTypeTrendInput: AdUsageAdTypeTrendInput;
  AdUsageAdTypeTrendResponse: ResolverTypeWrapper<AdUsageAdTypeTrendResponse>;
  AdUsageConversionResponse: ResolverTypeWrapper<AdUsageConversionResponse>;
  AdUsageConversionWidgetInput: AdUsageConversionWidgetInput;
  AdUsageFiltersResponse: ResolverTypeWrapper<AdUsageFiltersResponse>;
  AdUsageFiltersWidgetInput: AdUsageFiltersWidgetInput;
  AdUsageQueryFilters: AdUsageQueryFilters;
  AdUsageQueryFiltersDates: AdUsageQueryFiltersDates;
  AdUsageSpendResponse: ResolverTypeWrapper<AdUsageSpendResponse>;
  AdUsageSpendWidgetInput: AdUsageSpendWidgetInput;
  AdUsageTrendClassification: ResolverTypeWrapper<AdUsageTrendClassification>;
  AdUsageTrendClassificationsAndUsersInput: AdUsageTrendClassificationsAndUsersInput;
  AdUsageTrendClassificationsAndUsersResponse: ResolverTypeWrapper<AdUsageTrendClassificationsAndUsersResponse>;
  AdUsageTrendDaily: ResolverTypeWrapper<AdUsageTrendDaily>;
  AdUsageTrendMonthly: ResolverTypeWrapper<AdUsageTrendMonthly>;
  AdUsageTrendUser: ResolverTypeWrapper<AdUsageTrendUser>;
  AddQuestions: ResolverTypeWrapper<AddQuestions>;
  AddSpecificLocation: ResolverTypeWrapper<AddSpecificLocation>;
  AddUserToAccountInput: AddUserToAccountInput;
  AddUserToAccountResponse: ResolverTypeWrapper<AddUserToAccountResponse>;
  AdvertiserAccount: ResolverTypeWrapper<AdvertiserAccount>;
  AdvertiserAccountOption: ResolverTypeWrapper<AdvertiserAccountOption>;
  AdvertiserHeaderContext: ResolverTypeWrapper<AdvertiserHeaderContext>;
  AdvertiserStatus: AdvertiserStatus;
  AhpraRegistration: ResolverTypeWrapper<AhpraRegistration>;
  AnalyticsAdRating: ResolverTypeWrapper<AnalyticsAdRating>;
  AnalyticsAdRatingConfidence: AnalyticsAdRatingConfidence;
  AnalyticsAdRatingScore: AnalyticsAdRatingScore;
  AnalyticsAdRatingStatus: AnalyticsAdRatingStatus;
  AnalyticsAdType: AnalyticsAdType;
  AnalyticsBenchmarkScenario: AnalyticsBenchmarkScenario;
  AnalyticsLatestJobs: ResolverTypeWrapper<AnalyticsLatestJobs>;
  AnalyticsMarketRoleInsightsJobTitlesInput: AnalyticsMarketRoleInsightsJobTitlesInput;
  AnalyticsMarketRoleInsightsJobTitlesResult: ResolverTypeWrapper<AnalyticsMarketRoleInsightsJobTitlesResult>;
  AnalyticsMarketRoleInsightsLocationsInput: AnalyticsMarketRoleInsightsLocationsInput;
  AnalyticsMarketRoleInsightsLocationsResult: ResolverTypeWrapper<AnalyticsMarketRoleInsightsLocationsResult>;
  AnalyticsMarketRoleInsightsSearchInput: AnalyticsMarketRoleInsightsSearchInput;
  AnalyticsMarketRoleInsightsSearchResult: ResolverTypeWrapper<AnalyticsMarketRoleInsightsSearchResult>;
  AnalyticsMarketRoleInsightsSuggestion: ResolverTypeWrapper<AnalyticsMarketRoleInsightsSuggestion>;
  AnalyticsPostTypeCategory: AnalyticsPostTypeCategory;
  AnalyticsSalaryType: AnalyticsSalaryType;
  AnalyticsTalentSearchUsageAccount: ResolverTypeWrapper<AnalyticsTalentSearchUsageAccount>;
  AnalyticsTalentSearchUsageCreditsUsage: ResolverTypeWrapper<AnalyticsTalentSearchUsageCreditsUsage>;
  AnalyticsTalentSearchUsageCreditsUsageListInput: AnalyticsTalentSearchUsageCreditsUsageListInput;
  AnalyticsTalentSearchUsageCreditsUsageListResponse: ResolverTypeWrapper<AnalyticsTalentSearchUsageCreditsUsageListResponse>;
  AnalyticsTalentSearchUsageDateRangeInput: AnalyticsTalentSearchUsageDateRangeInput;
  AnalyticsTalentSearchUsageFilters: AnalyticsTalentSearchUsageFilters;
  AnalyticsTalentSearchUsageFiltersInput: AnalyticsTalentSearchUsageFiltersInput;
  AnalyticsTalentSearchUsageFiltersResponse: ResolverTypeWrapper<AnalyticsTalentSearchUsageFiltersResponse>;
  AnalyticsTalentSearchUsageLastUpdatedResponse: ResolverTypeWrapper<AnalyticsTalentSearchUsageLastUpdatedResponse>;
  AnalyticsTalentSearchUsageUser: ResolverTypeWrapper<AnalyticsTalentSearchUsageUser>;
  AnalyticsWorkType: AnalyticsWorkType;
  ApplicationPerformanceResponse: ResolverTypeWrapper<ApplicationPerformanceResponse>;
  ApplicationPerformanceWidgetInput: ApplicationPerformanceWidgetInput;
  AssociatedAction: ResolverTypeWrapper<AssociatedAction>;
  AssociatedParty: ResolverTypeWrapper<AssociatedParty>;
  Bank2C2P: Bank2C2P;
  BillableApplicantsData: ResolverTypeWrapper<BillableApplicantsData>;
  BillableApplicantsResponse:
    | ResolversTypes['BillableApplicantsResponseSuccess']
    | ResolversTypes['ResponseError'];
  BillableApplicantsResponseSuccess: ResolverTypeWrapper<BillableApplicantsResponseSuccess>;
  BillingContact: ResolverTypeWrapper<BillingContact>;
  BillingStatus: BillingStatus;
  Brand: ResolverTypeWrapper<Brand>;
  BrandAssetStrategies:
    | ResolversTypes['LogoStrategies']
    | ResolversTypes['CoverStrategies'];
  BrandAssetType: ResolverTypeWrapper<BrandAssetType>;
  BrandAssetTypeEnum: BrandAssetTypeEnum;
  BrandAssetTypeInput: BrandAssetTypeInput;
  BrandAssets: ResolverTypeWrapper<BrandAssets>;
  BrandAssetsInput: BrandAssetsInput;
  BrandOwner: ResolverTypeWrapper<BrandOwner>;
  BrandOwnerInput: BrandOwnerInput;
  BrandOwnerType: BrandOwnerType;
  BrandState: BrandState;
  BrandedAd: ResolverTypeWrapper<BrandedAd>;
  BrandedFeaturesFeature: ResolverTypeWrapper<BrandedFeaturesFeature>;
  BrandingAsset: ResolverTypeWrapper<
    Omit<BrandingAsset, 'strategies'> & {
      strategies: ResolversTypes['BrandAssetStrategies'];
    }
  >;
  BrandingFeature: ResolverTypeWrapper<BrandingFeature>;
  BrandingInput: BrandingInput;
  BulletPointsFeature: ResolverTypeWrapper<BulletPointsFeature>;
  CacheControlScope: CacheControlScope;
  CandidateAnswerType: CandidateAnswerType;
  CandidateDemandResponse: ResolverTypeWrapper<CandidateDemandResponse>;
  CandidateDemandWidgetInput: CandidateDemandWidgetInput;
  CandidateLabel: CandidateLabel;
  CandidateSupplyResponse: ResolverTypeWrapper<CandidateSupplyResponse>;
  CandidateSupplyWidgetInput: CandidateSupplyWidgetInput;
  CappedUnitPrice: ResolverTypeWrapper<
    Omit<CappedUnitPrice, 'unitPrice' | 'cappedPrice'> & {
      unitPrice: ResolversTypes['CatalogueProductPrice'];
      cappedPrice: ResolversTypes['CatalogueProductPrice'];
    }
  >;
  CappedUnitPriceMetadata: ResolverTypeWrapper<CappedUnitPriceMetadata>;
  CatalogueProduct:
    | ResolversTypes['AdBudgetProduct']
    | ResolversTypes['AdProduct']
    | ResolversTypes['AdProductUpgrade'];
  CatalogueProductAttributes: CatalogueProductAttributes;
  CatalogueProductFeature:
    | ResolversTypes['AdSpendFeature']
    | ResolversTypes['AdTypeDiscountsFeature']
    | ResolversTypes['BrandedFeaturesFeature']
    | ResolversTypes['BrandingFeature']
    | ResolversTypes['BulletPointsFeature']
    | ResolversTypes['DurationFeature']
    | ResolversTypes['JobAdEditFeature']
    | ResolversTypes['PerApplicantBillingFeature']
    | ResolversTypes['PerUnitBillingFeature']
    | ResolversTypes['RoleRequirementsMandatoryQuestionsFeature']
    | ResolversTypes['SponsoredSlotFeature']
    | ResolversTypes['StyleEnhancementFeature'];
  CatalogueProductPrice:
    | ResolversTypes['Price']
    | ResolversTypes['HiddenPrice'];
  Category: ResolverTypeWrapper<Category>;
  CategorySuggestion: ResolverTypeWrapper<CategorySuggestion>;
  ChannelCode: ChannelCode;
  ChildAccountBannerResponse: ResolverTypeWrapper<ChildAccountBannerResponse>;
  Claim: ResolverTypeWrapper<Claim>;
  ClassificationOption: ResolverTypeWrapper<ClassificationOption>;
  ClicksOnYourAdResponse: ResolverTypeWrapper<ClicksOnYourAdResponse>;
  ClicksOnYourAdWidgetInput: ClicksOnYourAdWidgetInput;
  CompanyAddress: ResolverTypeWrapper<CompanyAddress>;
  CompanyProfile: ResolverTypeWrapper<CompanyProfile>;
  CompanyProfileAward: ResolverTypeWrapper<CompanyProfileAward>;
  CompanyProfileAwardInput: CompanyProfileAwardInput;
  CompanyProfileBranding: ResolverTypeWrapper<CompanyProfileBranding>;
  CompanyProfileBrandingInput: CompanyProfileBrandingInput;
  CompanyProfileCultureAndValues: ResolverTypeWrapper<CompanyProfileCultureAndValues>;
  CompanyProfileCultureAndValuesInput: CompanyProfileCultureAndValuesInput;
  CompanyProfileCultureValue: ResolverTypeWrapper<CompanyProfileCultureValue>;
  CompanyProfileCultureValueInput: CompanyProfileCultureValueInput;
  CompanyProfileCustomStatement: ResolverTypeWrapper<CompanyProfileCustomStatement>;
  CompanyProfileCustomStatementInput: CompanyProfileCustomStatementInput;
  CompanyProfileFeatured: ResolverTypeWrapper<CompanyProfileFeatured>;
  CompanyProfileFeaturedInput: CompanyProfileFeaturedInput;
  CompanyProfileIds: ResolverTypeWrapper<CompanyProfileIds>;
  CompanyProfileInput: CompanyProfileInput;
  CompanyProfileOverview: ResolverTypeWrapper<CompanyProfileOverview>;
  CompanyProfileOverviewInput: CompanyProfileOverviewInput;
  CompanyProfilePerksAndBenefits: ResolverTypeWrapper<CompanyProfilePerksAndBenefits>;
  CompanyProfilePerksAndBenefitsInput: CompanyProfilePerksAndBenefitsInput;
  CompanyProfilePhoto: ResolverTypeWrapper<CompanyProfilePhoto>;
  CompanyProfilePhotoInput: CompanyProfilePhotoInput;
  CompanyProfileWebsite: ResolverTypeWrapper<CompanyProfileWebsite>;
  CompanyProfileWebsiteInput: CompanyProfileWebsiteInput;
  ConfirmInvoiceOrderInput: ConfirmInvoiceOrderInput;
  ConfirmInvoiceOrderPayload:
    | ResolversTypes['InvoiceOrderSuccessPayload']
    | ResolversTypes['InvoiceOrderNotFoundPayload']
    | ResolversTypes['OrderNotFoundError']
    | ResolversTypes['OrderExpiredError']
    | ResolversTypes['OrderInvalidError']
    | ResolversTypes['PromotionInvalidError'];
  ConnectionStatusPayload: ResolverTypeWrapper<ConnectionStatusPayload>;
  Contract: ResolverTypeWrapper<Contract>;
  ContractAdDiscount: ResolverTypeWrapper<ContractAdDiscount>;
  ContractBalance: ResolverTypeWrapper<ContractBalance>;
  ContractDollarValue: ResolverTypeWrapper<ContractDollarValue>;
  ContractType: ContractType;
  ContractUnitValue: ResolverTypeWrapper<ContractUnitValue>;
  ContractValue: ResolverTypeWrapper<ContractValue>;
  ConversionRateData: ResolverTypeWrapper<ConversionRateData>;
  CountriesInput: CountriesInput;
  Country: ResolverTypeWrapper<Country>;
  CountrySelectionOptions: ResolverTypeWrapper<CountrySelectionOptions>;
  CoverStrategies: ResolverTypeWrapper<CoverStrategies>;
  Create2C2PPaymentIntentForInvoiceInput: Create2C2PPaymentIntentForInvoiceInput;
  Create2C2PPaymentIntentInput: Create2C2PPaymentIntentInput;
  Create2c2pPaymentIntentPayload:
    | ResolversTypes['PaymentIntent2C2PPayload']
    | ResolversTypes['PaymentMethodError']
    | ResolversTypes['PaymentIntentChargeError']
    | ResolversTypes['OrderNotFoundError']
    | ResolversTypes['OrderExpiredError']
    | ResolversTypes['OrderInvalidError']
    | ResolversTypes['PromotionInvalidError']
    | ResolversTypes['InvoiceError'];
  CreateBrandInput: CreateBrandInput;
  CreateBrandPayload:
    | ResolversTypes['CreateBrandSuccessPayload']
    | ResolversTypes['InvalidInputPayload'];
  CreateBrandSuccessPayload: ResolverTypeWrapper<CreateBrandSuccessPayload>;
  CreateCustomQuestionErrorResult: ResolverTypeWrapper<CreateCustomQuestionErrorResult>;
  CreateCustomQuestionInput: CreateCustomQuestionInput;
  CreateCustomQuestionOutputUnion:
    | ResolversTypes['CreateCustomQuestionResult']
    | ResolversTypes['CreateCustomQuestionErrorResult'];
  CreateCustomQuestionResult: ResolverTypeWrapper<CreateCustomQuestionResult>;
  CreateDraftJobInput: CreateDraftJobInput;
  CreateDraftJobPayload: ResolverTypeWrapper<CreateDraftJobPayload>;
  CreateIntegrationRelationshipsInput: CreateIntegrationRelationshipsInput;
  CreateJobInput: CreateJobInput;
  CreateJobResponse: ResolverTypeWrapper<CreateJobResponse>;
  CreateOrderInput: CreateOrderInput;
  CreateOrderPayload: ResolverTypeWrapper<CreateOrderPayload>;
  CreateOrderSuccessPayload: ResolverTypeWrapper<CreateOrderSuccessPayload>;
  CreateOrderWithPromotionPayload:
    | ResolversTypes['CreateOrderSuccessPayload']
    | ResolversTypes['PromotionInvalidError'];
  CreatePaymentIntentInput: CreatePaymentIntentInput;
  CreatePaymentIntentPayload:
    | ResolversTypes['PaymentIntentPayload']
    | ResolversTypes['OrderNotFoundError']
    | ResolversTypes['OrderExpiredError']
    | ResolversTypes['OrderInvalidError']
    | ResolversTypes['PromotionInvalidError'];
  CreateProductSetForAdEditingInput: CreateProductSetForAdEditingInput;
  CreateProductSetForAdEditingPayload: ResolverTypeWrapper<CreateProductSetForAdEditingPayload>;
  CreateProductSetForAdPostingInput: CreateProductSetForAdPostingInput;
  CreateProductSetForAdPostingPayload: ResolverTypeWrapper<CreateProductSetForAdPostingPayload>;
  CreateProductSetForAdRefreshInput: CreateProductSetForAdRefreshInput;
  CreateProductSetForAdRefreshPayload: ResolverTypeWrapper<CreateProductSetForAdRefreshPayload>;
  CreateProductSetForBudgetsPurchaseForHirerPayload: ResolverTypeWrapper<CreateProductSetForBudgetsPurchaseForHirerPayload>;
  CreateProductSetForBudgetsPurchaseForVisitorInput: CreateProductSetForBudgetsPurchaseForVisitorInput;
  CreateProductSetForBudgetsPurchaseForVisitorPayload: ResolverTypeWrapper<CreateProductSetForBudgetsPurchaseForVisitorPayload>;
  CreateProductSetForMultiLocationInput: CreateProductSetForMultiLocationInput;
  CreateProductSetForMultiLocationPayload: ResolverTypeWrapper<CreateProductSetForMultiLocationPayload>;
  CreateProductSetForPriceCheckerPreviewInput: CreateProductSetForPriceCheckerPreviewInput;
  CreateProductSetForPriceCheckerPreviewPayload: ResolverTypeWrapper<CreateProductSetForPriceCheckerPreviewPayload>;
  CreateQuestionnaireInput: CreateQuestionnaireInput;
  CreateQuestionnaireResponse: ResolverTypeWrapper<CreateQuestionnaireResponse>;
  CreateSavedSearchInput: CreateSavedSearchInput;
  CreateSavedSearchPayload: ResolverTypeWrapper<CreateSavedSearchPayload>;
  CreateStripePaymentIntentForInvoiceInput: CreateStripePaymentIntentForInvoiceInput;
  CreateStripePaymentIntentInput: CreateStripePaymentIntentInput;
  CreateStripePaymentIntentPayload:
    | ResolversTypes['StripePaymentIntentPayload']
    | ResolversTypes['PaymentMethodError']
    | ResolversTypes['OrderNotFoundError']
    | ResolversTypes['OrderExpiredError']
    | ResolversTypes['OrderInvalidError']
    | ResolversTypes['PromotionInvalidError']
    | ResolversTypes['InvoiceError'];
  CreditCard: ResolverTypeWrapper<CreditCard>;
  CreditCardBrandInput: CreditCardBrandInput;
  Currency: Currency;
  CurrentAdvertiserContext: ResolverTypeWrapper<CurrentAdvertiserContext>;
  CurrentAdvertiserResponse:
    | ResolversTypes['CurrentAdvertiserContext']
    | ResolversTypes['MissingAdvertiserIdClaimError'];
  CurrentUserContext: ResolverTypeWrapper<CurrentUserContext>;
  CurrentUserResponse:
    | ResolversTypes['CurrentUserContext']
    | ResolversTypes['MissingUserIdClaimError'];
  CustomQuestion: CustomQuestion;
  CustomQuestionValidationFailure: ResolverTypeWrapper<CustomQuestionValidationFailure>;
  Date: ResolverTypeWrapper<Scalars['Date']>;
  DateAxisLabel: ResolverTypeWrapper<DateAxisLabel>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
  DeleteBrandingAssetInput: DeleteBrandingAssetInput;
  DeleteBrandingAssetPayload: ResolverTypeWrapper<DeleteBrandingAssetPayload>;
  DeleteDraftJobInput: DeleteDraftJobInput;
  DeleteDraftJobPayload: ResolverTypeWrapper<DeleteDraftJobPayload>;
  DeleteIntegrationRelationshipsInput: DeleteIntegrationRelationshipsInput;
  DeleteSavedCreditCardInput: DeleteSavedCreditCardInput;
  DeleteSavedCreditCardPayload: ResolverTypeWrapper<DeleteSavedCreditCardPayload>;
  DeleteSavedSearchPayload: ResolverTypeWrapper<DeleteSavedSearchPayload>;
  DeleteStripeSavedCreditCardInput: DeleteStripeSavedCreditCardInput;
  DeleteStripeSavedCreditCardPayload: ResolverTypeWrapper<DeleteStripeSavedCreditCardPayload>;
  DisableMessageType: ResolverTypeWrapper<DisableMessageType>;
  Discount: ResolverTypeWrapper<Discount>;
  DisplayMetadataError: ResolverTypeWrapper<DisplayMetadataError>;
  DisplayRecommendation: DisplayRecommendation;
  DraftJob: ResolverTypeWrapper<DraftJob>;
  DraftJobBranding: ResolverTypeWrapper<DraftJobBranding>;
  DraftJobBrandingInput: DraftJobBrandingInput;
  DraftJobCategory: ResolverTypeWrapper<DraftJobCategory>;
  DraftJobCategoryInput: DraftJobCategoryInput;
  DraftJobEdge: ResolverTypeWrapper<DraftJobEdge>;
  DraftJobFlowType: DraftJobFlowType;
  DraftJobGuidedDetails: ResolverTypeWrapper<DraftJobGuidedDetails>;
  DraftJobGuidedDetailsInput: DraftJobGuidedDetailsInput;
  DraftJobLocation: ResolverTypeWrapper<DraftJobLocation>;
  DraftJobLocationInput: DraftJobLocationInput;
  DraftJobNode: ResolverTypeWrapper<DraftJobNode>;
  DraftJobNotFoundPayload: ResolverTypeWrapper<DraftJobNotFoundPayload>;
  DraftJobPayload:
    | ResolversTypes['DraftJobSuccessPayload']
    | ResolversTypes['DraftJobNotFoundPayload'];
  DraftJobPaymentOption: DraftJobPaymentOption;
  DraftJobProducts: ResolverTypeWrapper<DraftJobProducts>;
  DraftJobProductsInput: DraftJobProductsInput;
  DraftJobProgress: DraftJobProgress;
  DraftJobRoleTitle: ResolverTypeWrapper<DraftJobRoleTitle>;
  DraftJobRoleTitleInput: DraftJobRoleTitleInput;
  DraftJobSalaryCurrency: DraftJobSalaryCurrency;
  DraftJobSalaryDetails: ResolverTypeWrapper<DraftJobSalaryDetails>;
  DraftJobSalaryDetailsInput: DraftJobSalaryDetailsInput;
  DraftJobSuccessPayload: ResolverTypeWrapper<DraftJobSuccessPayload>;
  DraftJobVideo: ResolverTypeWrapper<DraftJobVideo>;
  DraftJobVideoInput: DraftJobVideoInput;
  DraftJobWritingMode: DraftJobWritingMode;
  DraftJobsInput: DraftJobsInput;
  DraftJobsPayload: ResolverTypeWrapper<DraftJobsPayload>;
  DraftStatus: DraftStatus;
  DrawdownContractDetails: ResolverTypeWrapper<DrawdownContractDetails>;
  DrawdownContractValue: ResolverTypeWrapper<DrawdownContractValue>;
  DrawdownItem: ResolverTypeWrapper<DrawdownItem>;
  DrawdownType: DrawdownType;
  DriversLicence: ResolverTypeWrapper<DriversLicence>;
  DurationFeature: ResolverTypeWrapper<DurationFeature>;
  DurationUnit: DurationUnit;
  EditJobInput: EditJobInput;
  EditJobResponse: ResolverTypeWrapper<EditJobResponse>;
  Error:
    | ResolversTypes['DisplayMetadataError']
    | ResolversTypes['InvoiceError']
    | ResolversTypes['MissingAdvertiserIdClaimError']
    | ResolversTypes['MissingUserIdClaimError']
    | ResolversTypes['OrderExpiredError']
    | ResolversTypes['OrderInvalidError']
    | ResolversTypes['OrderNotFoundError']
    | ResolversTypes['Payment2c2pInquiryError']
    | ResolversTypes['PaymentIntentChargeError']
    | ResolversTypes['PaymentMethodError']
    | ResolversTypes['ProductSetExpiredError']
    | ResolversTypes['ProductSetNotFoundError']
    | ResolversTypes['PromotionInvalidError']
    | ResolversTypes['SavedCreditCardPaymentError']
    | ResolversTypes['SecureLinkExpiredError']
    | ResolversTypes['SecureLinkInvalidError']
    | ResolversTypes['SecureLinkUsedError'];
  ExpectedSalaryV2: ResolverTypeWrapper<ExpectedSalaryV2>;
  ExpireJobInput: ExpireJobInput;
  ExpireJobResponse: ResolverTypeWrapper<ExpireJobResponse>;
  ExpireReason: ExpireReason;
  Expiry: ResolverTypeWrapper<Expiry>;
  ExportProfileInput: ExportProfileInput;
  FiltersAdRatingType: FiltersAdRatingType;
  Frequency: Frequency;
  GetBrandingAssetsInput: GetBrandingAssetsInput;
  GetProductSetInput: GetProductSetInput;
  GetProductSetResponse:
    | ResolversTypes['ProductSet']
    | ResolversTypes['ProductSetExpiredError']
    | ResolversTypes['ProductSetNotFoundError'];
  GetSurchargeAmountInput: GetSurchargeAmountInput;
  GetSurchargeAmountPayload: ResolverTypeWrapper<GetSurchargeAmountPayload>;
  GranularLocation: ResolverTypeWrapper<GranularLocation>;
  GuidedDetailsType: GuidedDetailsType;
  HeaderFooterContext: ResolverTypeWrapper<HeaderFooterContext>;
  HeaderFooterContextInput: HeaderFooterContextInput;
  HelpTip: ResolverTypeWrapper<HelpTip>;
  HelpTipImage: ResolverTypeWrapper<HelpTipImage>;
  HiddenPrice: ResolverTypeWrapper<HiddenPrice>;
  HirerAccount: ResolverTypeWrapper<HirerAccount>;
  HirerAccountDetails: ResolverTypeWrapper<HirerAccountDetails>;
  HirerAccountDetailsQueryInput: HirerAccountDetailsQueryInput;
  HirerAccountIntegrationsInput: HirerAccountIntegrationsInput;
  HirerAccountLoginUrlInput: HirerAccountLoginUrlInput;
  HirerAccountStatus: HirerAccountStatus;
  HirerAccountUserQueryInput: HirerAccountUserQueryInput;
  HirerActivitiesByJobInput: HirerActivitiesByJobInput;
  HirerActivity: ResolverTypeWrapper<HirerActivity>;
  HirerActivityConnection: ResolverTypeWrapper<HirerActivityConnection>;
  HirerActivityData: ResolverTypeWrapper<HirerActivityData>;
  HirerActivityEdge: ResolverTypeWrapper<HirerActivityEdge>;
  HirerActivityJobApplication: ResolverTypeWrapper<HirerActivityJobApplication>;
  HirerActivityType: HirerActivityType;
  HirerDashboardArticle: ResolverTypeWrapper<HirerDashboardArticle>;
  HirerDashboardBudgetUpsellInput: HirerDashboardBudgetUpsellInput;
  HirerDashboardBudgetUpsellResponse: ResolverTypeWrapper<HirerDashboardBudgetUpsellResponse>;
  HirerDashboardHiringAdviceList: ResolverTypeWrapper<HirerDashboardHiringAdviceList>;
  HirerDashboardJob: ResolverTypeWrapper<HirerDashboardJob>;
  HirerDashboardJobCounts: ResolverTypeWrapper<HirerDashboardJobCounts>;
  HirerDashboardJobs: ResolverTypeWrapper<HirerDashboardJobs>;
  HirerDashboardJobsQueryInput: HirerDashboardJobsQueryInput;
  HirerDashboardTalentSearch: ResolverTypeWrapper<HirerDashboardTalentSearch>;
  HirerDashboardUserDetails: ResolverTypeWrapper<HirerDashboardUserDetails>;
  HirerDashboardWelcome: ResolverTypeWrapper<HirerDashboardWelcome>;
  HirerExperience: ResolverTypeWrapper<HirerExperience>;
  HirerGroup: HirerGroup;
  HirerJob: ResolverTypeWrapper<HirerJob>;
  HirerJobConnection: ResolverTypeWrapper<HirerJobConnection>;
  HirerJobEdge: ResolverTypeWrapper<HirerJobEdge>;
  HirerJobPostChannel: HirerJobPostChannel;
  HirerJobStatus: HirerJobStatus;
  HirerJobStatusFilter: HirerJobStatusFilter;
  HirerJobsInput: HirerJobsInput;
  HirerPrimaryContact: ResolverTypeWrapper<HirerPrimaryContact>;
  HirerQuestionType: HirerQuestionType;
  HirerType: HirerType;
  HirerUserDetails: ResolverTypeWrapper<HirerUserDetails>;
  HirerUserEmailInput: ResolverTypeWrapper<HirerUserEmailInput>;
  HirerUserEmailQueryInput: HirerUserEmailQueryInput;
  Identity: ResolverTypeWrapper<Identity>;
  IdentityHeaderContext: ResolverTypeWrapper<IdentityHeaderContext>;
  IdentityIds: ResolverTypeWrapper<IdentityIds>;
  Image: ResolverTypeWrapper<Image>;
  InitiateConnectionPayload: ResolverTypeWrapper<InitiateConnectionPayload>;
  Integration: ResolverTypeWrapper<Integration>;
  IntegrationConnection: ResolverTypeWrapper<IntegrationConnection>;
  IntegrationEdge: ResolverTypeWrapper<IntegrationEdge>;
  IntegrationPartner: ResolverTypeWrapper<IntegrationPartner>;
  IntegrationRelationship: ResolverTypeWrapper<IntegrationRelationship>;
  IntegrationRelationshipType: ResolverTypeWrapper<IntegrationRelationshipType>;
  InteractedJob: ResolverTypeWrapper<InteractedJob>;
  InvalidInputPayload: ResolverTypeWrapper<InvalidInputPayload>;
  Invoice: Invoice;
  InvoiceError: ResolverTypeWrapper<InvoiceError>;
  InvoiceInput: InvoiceInput;
  InvoiceOrderNotFoundPayload: ResolverTypeWrapper<InvoiceOrderNotFoundPayload>;
  InvoiceOrderSuccessPayload: ResolverTypeWrapper<InvoiceOrderSuccessPayload>;
  InvoiceStatus: InvoiceStatus;
  JSON: ResolverTypeWrapper<Scalars['JSON']>;
  Job: ResolverTypeWrapper<
    Omit<Job, 'billableApplicants'> & {
      billableApplicants: ResolversTypes['BillableApplicantsResponse'];
    }
  >;
  JobAd: ResolverTypeWrapper<JobAd>;
  JobAdBookingCountryCode: JobAdBookingCountryCode;
  JobAdDetails: JobAdDetails;
  JobAdEditFeature: ResolverTypeWrapper<JobAdEditFeature>;
  JobAdListResponse: ResolverTypeWrapper<JobAdListResponse>;
  JobAdListWidgetInput: JobAdListWidgetInput;
  JobAdNotUpgradableToPremium: ResolverTypeWrapper<JobAdNotUpgradableToPremium>;
  JobAdRevisionAttributes: JobAdRevisionAttributes;
  JobAdType: JobAdType;
  JobSalaryCurrency: JobSalaryCurrency;
  JobStatus: JobStatus;
  JobTitleOption: ResolverTypeWrapper<JobTitleOption>;
  LanguageCodeIso: ResolverTypeWrapper<Scalars['LanguageCodeIso']>;
  LastUpdatedResponse: ResolverTypeWrapper<LastUpdatedResponse>;
  LineItemProduct: ResolverTypeWrapper<LineItemProduct>;
  Link: ResolverTypeWrapper<Link>;
  LocalisedContactInfoContext: ResolverTypeWrapper<LocalisedContactInfoContext>;
  Location: ResolverTypeWrapper<Location>;
  LocationContext: LocationContext;
  LocationOption: ResolverTypeWrapper<LocationOption>;
  LocationSearchResult: ResolverTypeWrapper<LocationSearchResult>;
  LogoStrategies: ResolverTypeWrapper<LogoStrategies>;
  LowApply: ResolverTypeWrapper<LowApply>;
  LowClick: ResolverTypeWrapper<LowClick>;
  LowSalary: ResolverTypeWrapper<LowSalary>;
  LowestPricedProductForAdPostingInput: LowestPricedProductForAdPostingInput;
  LowestPricedProductForAdPostingResponse:
    | ResolversTypes['AdProduct']
    | ResolversTypes['ProductSetExpiredError']
    | ResolversTypes['ProductSetNotFoundError'];
  Maintenance: ResolverTypeWrapper<Maintenance>;
  ManagingAgency: ResolverTypeWrapper<ManagingAgency>;
  MandatoryData: ResolverTypeWrapper<MandatoryData>;
  MarketInsightMonth: ResolverTypeWrapper<MarketInsightMonth>;
  MarketInsightsTitleResponse: ResolverTypeWrapper<MarketInsightsTitleResponse>;
  MarketInsightsTitleWidgetInput: MarketInsightsTitleWidgetInput;
  MarketPerformanceResponse: ResolverTypeWrapper<MarketPerformanceResponse>;
  MarketPerformanceWidgetInput: MarketPerformanceWidgetInput;
  MatchedSearchString: ResolverTypeWrapper<MatchedSearchString>;
  MaximiseBranding: ResolverTypeWrapper<MaximiseBranding>;
  MissingAdvertiserIdClaimError: ResolverTypeWrapper<MissingAdvertiserIdClaimError>;
  MissingUserIdClaimError: ResolverTypeWrapper<MissingUserIdClaimError>;
  Money: ResolverTypeWrapper<Money>;
  MostAdsByPerformanceCategory: ResolverTypeWrapper<MostAdsByPerformanceCategory>;
  MostAdsByPerformanceEntity: ResolverTypeWrapper<MostAdsByPerformanceEntity>;
  MostAdsByPerformanceResponse: ResolverTypeWrapper<MostAdsByPerformanceResponse>;
  MostAdsByPerformanceWidgetInput: MostAdsByPerformanceWidgetInput;
  Mutation: ResolverTypeWrapper<{}>;
  MyJobInformationJobEdit: ResolverTypeWrapper<MyJobInformationJobEdit>;
  MyJobInformationResponse: ResolverTypeWrapper<MyJobInformationResponse>;
  MyJobInformationSalary: ResolverTypeWrapper<MyJobInformationSalary>;
  MyJobInformationWidgetInput: MyJobInformationWidgetInput;
  NewAdvertiser: NewAdvertiser;
  NewUser: NewUser;
  NotificationPreference: ResolverTypeWrapper<NotificationPreference>;
  NotificationPreferenceInput: NotificationPreferenceInput;
  Option: Option;
  OptionWithId: ResolverTypeWrapper<OptionWithId>;
  Order: ResolverTypeWrapper<Order>;
  OrderExpiredError: ResolverTypeWrapper<OrderExpiredError>;
  OrderInvalidError: ResolverTypeWrapper<OrderInvalidError>;
  OrderItem: OrderItem;
  OrderLineItem: ResolverTypeWrapper<
    Omit<OrderLineItem, 'pricing'> & {
      pricing?: Maybe<ResolversTypes['Pricing']>;
    }
  >;
  OrderNotFoundError: ResolverTypeWrapper<OrderNotFoundError>;
  OrderProductAttributesInput: OrderProductAttributesInput;
  OrderRequestInputItem: OrderRequestInputItem;
  OrderSummary: ResolverTypeWrapper<OrderSummary>;
  OutputCustomQuestion: ResolverTypeWrapper<OutputCustomQuestion>;
  PageInfo: ResolverTypeWrapper<PageInfo>;
  PageTitleActionsResponse: ResolverTypeWrapper<PageTitleActionsResponse>;
  PageTitleActionsWidgetInput: PageTitleActionsWidgetInput;
  Parent: ResolverTypeWrapper<Parent>;
  ParentAdvertiser: ResolverTypeWrapper<ParentAdvertiser>;
  ParentLocation: ResolverTypeWrapper<ParentLocation>;
  PatchCompanyProfileResponse: ResolverTypeWrapper<PatchCompanyProfileResponse>;
  PayBySavedCreditCardInput: PayBySavedCreditCardInput;
  PayBySavedCreditCardPayload:
    | ResolversTypes['SavedCreditCardPaymentSuccessPayload']
    | ResolversTypes['SavedCreditCardPaymentError']
    | ResolversTypes['OrderNotFoundError']
    | ResolversTypes['OrderExpiredError']
    | ResolversTypes['OrderInvalidError']
    | ResolversTypes['PromotionInvalidError'];
  PayByStripeSavedCreditCardForInvoiceInput: PayByStripeSavedCreditCardForInvoiceInput;
  PayByStripeSavedCreditCardInput: PayByStripeSavedCreditCardInput;
  PayByStripeSavedCreditCardPayload:
    | ResolversTypes['SavedCreditCardPaymentSuccessPayload']
    | ResolversTypes['SavedCreditCardPaymentError']
    | ResolversTypes['OrderNotFoundError']
    | ResolversTypes['OrderExpiredError']
    | ResolversTypes['OrderInvalidError']
    | ResolversTypes['PromotionInvalidError'];
  Payment2c2pInquiry: ResolverTypeWrapper<Payment2c2pInquiry>;
  Payment2c2pInquiryError: ResolverTypeWrapper<Payment2c2pInquiryError>;
  Payment2c2pInquiryPayload:
    | ResolversTypes['Payment2c2pInquiry']
    | ResolversTypes['Payment2c2pInquiryError'];
  Payment2c2pInquiryStatus: Payment2c2pInquiryStatus;
  PaymentConfigPayload: ResolverTypeWrapper<PaymentConfigPayload>;
  PaymentIntent2C2PPayload: ResolverTypeWrapper<PaymentIntent2C2PPayload>;
  PaymentIntentChargeError: ResolverTypeWrapper<PaymentIntentChargeError>;
  PaymentIntentPayload: ResolverTypeWrapper<PaymentIntentPayload>;
  PaymentMediumType: ResolverTypeWrapper<PaymentMediumType>;
  PaymentMethod: PaymentMethod;
  PaymentMethodError: ResolverTypeWrapper<PaymentMethodError>;
  PaymentMethodPayload: ResolverTypeWrapper<PaymentMethodPayload>;
  PaymentMethodType2C2P: PaymentMethodType2C2P;
  PerApplicantBillingFeature: ResolverTypeWrapper<PerApplicantBillingFeature>;
  PerUnitBillingFeature: ResolverTypeWrapper<PerUnitBillingFeature>;
  PerformancePredictionChartEvent: ResolverTypeWrapper<PerformancePredictionChartEvent>;
  PerformancePredictionResponse: ResolverTypeWrapper<PerformancePredictionResponse>;
  PerformancePredictionWidgetInput: PerformancePredictionWidgetInput;
  Permission: Permission;
  PhysicalAddress: ResolverTypeWrapper<PhysicalAddress>;
  PredictionDayOptions: ResolverTypeWrapper<PredictionDayOptions>;
  Price: ResolverTypeWrapper<Price>;
  PriceAttributes: PriceAttributes;
  PriceType: ResolverTypeWrapper<PriceType>;
  PriceVisibility: PriceVisibility;
  Pricing: ResolversTypes['CappedUnitPrice'] | ResolversTypes['PriceType'];
  PricingModel: PricingModel;
  PrimaryContact: ResolverTypeWrapper<PrimaryContact>;
  PrivacyTypes: PrivacyTypes;
  Product:
    | ResolversTypes['Ad']
    | ResolversTypes['AdBudget']
    | ResolversTypes['LineItemProduct'];
  ProductFeature: ResolverTypeWrapper<ProductFeature>;
  ProductList: ResolverTypeWrapper<ProductList>;
  ProductReference: ResolverTypeWrapper<ProductReference>;
  ProductSet: ResolverTypeWrapper<ProductSet>;
  ProductSetExpiredError: ResolverTypeWrapper<ProductSetExpiredError>;
  ProductSetNotFoundError: ResolverTypeWrapper<ProductSetNotFoundError>;
  ProductType: ProductType;
  ProductTypeInput: ProductTypeInput;
  ProfileEducation: ResolverTypeWrapper<ProfileEducation>;
  ProfileProperties: ResolverTypeWrapper<ProfileProperties>;
  ProfileResultHighlight: ResolverTypeWrapper<ProfileResultHighlight>;
  ProfileSalaryV2: ResolverTypeWrapper<ProfileSalaryV2>;
  ProfileSource: ProfileSource;
  ProfileTypes: ProfileTypes;
  ProfilesByEmailInput: ProfilesByEmailInput;
  PromoCode: ResolverTypeWrapper<
    Omit<PromoCode, 'discount'> & {
      discount: ResolversTypes['PromoCodeDiscount'];
    }
  >;
  PromoCodeDiscount: ResolversTypes['PromoCodeDiscountRate'];
  PromoCodeDiscountRate: ResolverTypeWrapper<PromoCodeDiscountRate>;
  PromoCodeProduct: ResolverTypeWrapper<PromoCodeProduct>;
  Promotion: ResolverTypeWrapper<Promotion>;
  PromotionInvalidError: ResolverTypeWrapper<PromotionInvalidError>;
  PublishCPQAEventInput: PublishCpqaEventInput;
  PublishCPQAEventPayload: ResolverTypeWrapper<PublishCpqaEventPayload>;
  PublishJobAdBookingEventInput: PublishJobAdBookingEventInput;
  PublishJobAdBookingEventPayload: ResolverTypeWrapper<PublishJobAdBookingEventPayload>;
  PublishJobAdProductCode: PublishJobAdProductCode;
  PublishJobPostedEventInput: PublishJobPostedEventInput;
  PublishJobPostedEventPayload: ResolverTypeWrapper<PublishJobPostedEventPayload>;
  PurchasableProducts:
    | ResolversTypes['PurchasableProductsList']
    | ResolversTypes['PurchasableProductsString'];
  PurchasableProductsList: ResolverTypeWrapper<PurchasableProductsList>;
  PurchasableProductsString: ResolverTypeWrapper<PurchasableProductsString>;
  Query: ResolverTypeWrapper<{}>;
  Questionnaire: ResolverTypeWrapper<Questionnaire>;
  QuestionnaireAnswer: ResolverTypeWrapper<QuestionnaireAnswer>;
  QuestionnaireQuestion: ResolverTypeWrapper<QuestionnaireQuestion>;
  QuestionnaireQuestionCategory: QuestionnaireQuestionCategory;
  QuestionnaireQuestionFilter: QuestionnaireQuestionFilter;
  Range: ResolverTypeWrapper<Range>;
  Recommendation:
    | ResolversTypes['RevealSalary']
    | ResolversTypes['GranularLocation']
    | ResolversTypes['LowClick']
    | ResolversTypes['LowApply']
    | ResolversTypes['BrandedAd']
    | ResolversTypes['RoleRequirements']
    | ResolversTypes['LowSalary']
    | ResolversTypes['TalentSearch'];
  RecommendationType: RecommendationType;
  RecommendationsResponse: ResolverTypeWrapper<
    Omit<RecommendationsResponse, 'recommendations'> & {
      recommendations: Array<ResolversTypes['Recommendation']>;
    }
  >;
  RecommendationsWidgetInput: RecommendationsWidgetInput;
  RedirectUrlQueryInput: RedirectUrlQueryInput;
  RefreshJobInput: RefreshJobInput;
  RefreshJobResponse: ResolverTypeWrapper<RefreshJobResponse>;
  RegisterAdditionalAdvertiserInput: RegisterAdditionalAdvertiserInput;
  RegisterNewUserInput: RegisterNewUserInput;
  RegisterPayload: ResolverTypeWrapper<RegisterPayload>;
  RegistrationOptions: ResolverTypeWrapper<RegistrationOptions>;
  RegistrationStatusInfo: ResolverTypeWrapper<RegistrationStatusInfo>;
  RegistrationStatusInput: RegistrationStatusInput;
  ReinstateInput: ReinstateInput;
  ReinstateResponse: ResolverTypeWrapper<ReinstateResponse>;
  RemoveUserFromAccountInput: RemoveUserFromAccountInput;
  RepostOption: ResolverTypeWrapper<RepostOption>;
  ResponseError: ResolverTypeWrapper<ResponseError>;
  ResponseErrorReason: ResponseErrorReason;
  ResumeEducation: ResolverTypeWrapper<ResumeEducation>;
  ResumeWorkHistory: ResolverTypeWrapper<ResumeWorkHistory>;
  ResumeWorkHistoryDisplayDescription: ResolverTypeWrapper<ResumeWorkHistoryDisplayDescription>;
  RetrievePaymentIntentPayload: ResolverTypeWrapper<RetrievePaymentIntentPayload>;
  RevealAdSalary: ResolverTypeWrapper<RevealAdSalary>;
  RevealSalary: ResolverTypeWrapper<RevealSalary>;
  ReviewAdDetails: ResolverTypeWrapper<ReviewAdDetails>;
  ReviewAdSummary: ResolverTypeWrapper<ReviewAdSummary>;
  RightToWork: ResolverTypeWrapper<RightToWork>;
  RightToWorkInSavedSearch: ResolverTypeWrapper<RightToWorkInSavedSearch>;
  RightToWorkInput: RightToWorkInput;
  Role: Role;
  RoleRequirements: ResolverTypeWrapper<RoleRequirements>;
  RoleRequirementsMandatoryQuestionsFeature: ResolverTypeWrapper<RoleRequirementsMandatoryQuestionsFeature>;
  Rule: ResolverTypeWrapper<Rule>;
  SafeInt: ResolverTypeWrapper<Scalars['SafeInt']>;
  Salary: Salary;
  SalaryAmountV2: ResolverTypeWrapper<SalaryAmountV2>;
  SalaryFrequency: SalaryFrequency;
  SalaryRange: SalaryRange;
  SalaryType: SalaryType;
  SavedCreditCardPaymentError: ResolverTypeWrapper<SavedCreditCardPaymentError>;
  SavedCreditCardPaymentSuccessPayload: ResolverTypeWrapper<SavedCreditCardPaymentSuccessPayload>;
  SearchIntegrationPartnersInput: SearchIntegrationPartnersInput;
  SecureLinkExpiredError: ResolverTypeWrapper<SecureLinkExpiredError>;
  SecureLinkInput: SecureLinkInput;
  SecureLinkInvalidError: ResolverTypeWrapper<SecureLinkInvalidError>;
  SecureLinkResponse:
    | ResolversTypes['SecureLinkSuccessResponse']
    | ResolversTypes['SecureLinkUsedError']
    | ResolversTypes['SecureLinkExpiredError']
    | ResolversTypes['SecureLinkInvalidError'];
  SecureLinkSuccessResponse: ResolverTypeWrapper<SecureLinkSuccessResponse>;
  SecureLinkUsedError: ResolverTypeWrapper<SecureLinkUsedError>;
  SellingPoint: ResolverTypeWrapper<SellingPoint>;
  SendEmailResponse: ResolverTypeWrapper<SendEmailResponse>;
  SendEmailVerificationEmailPayload: ResolverTypeWrapper<SendEmailVerificationEmailPayload>;
  SendEnrolmentEmailPayload: ResolverTypeWrapper<SendEnrolmentEmailPayload>;
  SendHirerAccountUsersJobAccessEmailInput: SendHirerAccountUsersJobAccessEmailInput;
  SendHirerAccountUsersJobAccessEmailPayload: ResolverTypeWrapper<SendHirerAccountUsersJobAccessEmailPayload>;
  SendJobInput: SendJobInput;
  SendLoginCallbackEventPayload: ResolverTypeWrapper<SendLoginCallbackEventPayload>;
  SendMessageInput: SendMessageInput;
  SendPasswordResetEmailInput: SendPasswordResetEmailInput;
  SendRegistrationEmailInput: SendRegistrationEmailInput;
  SetDefaultNotificationPreferenceInput: SetDefaultNotificationPreferenceInput;
  SetHirerAccountUsersJobAccessInput: SetHirerAccountUsersJobAccessInput;
  SetHirerAccountUsersJobAccessPayload: ResolverTypeWrapper<SetHirerAccountUsersJobAccessPayload>;
  SetNotificationPreferencesInput: SetNotificationPreferencesInput;
  SetNotificationPreferencesPayload: ResolverTypeWrapper<SetNotificationPreferencesPayload>;
  SimilarAdsDetails: ResolverTypeWrapper<SimilarAdsDetails>;
  SimilarAdsResponse: ResolverTypeWrapper<SimilarAdsResponse>;
  SimilarAdsWidgetInput: SimilarAdsWidgetInput;
  SimplifiedAnswers: ResolverTypeWrapper<SimplifiedAnswers>;
  SponsoredSlotFeature: ResolverTypeWrapper<SponsoredSlotFeature>;
  StripePaymentIntentPayload: ResolverTypeWrapper<StripePaymentIntentPayload>;
  StripePaymentMethod: StripePaymentMethod;
  StyleEnhancementFeature: ResolverTypeWrapper<StyleEnhancementFeature>;
  SubTitle: ResolverTypeWrapper<SubTitle>;
  Subject: ResolverTypeWrapper<Subject>;
  SuggestedSalary: ResolverTypeWrapper<SuggestedSalary>;
  SuggestedSalaryAverageRange: ResolverTypeWrapper<SuggestedSalaryAverageRange>;
  SuggestedSalaryRange: ResolverTypeWrapper<SuggestedSalaryRange>;
  Surcharge: ResolverTypeWrapper<Surcharge>;
  TalentSearch: ResolverTypeWrapper<TalentSearch>;
  TalentSearchATSJob: ResolverTypeWrapper<TalentSearchAtsJob>;
  TalentSearchATSJobEdge: ResolverTypeWrapper<TalentSearchAtsJobEdge>;
  TalentSearchATSJobs: ResolverTypeWrapper<TalentSearchAtsJobs>;
  TalentSearchATSJobsConnection: ResolverTypeWrapper<TalentSearchAtsJobsConnection>;
  TalentSearchATSJobsInput: TalentSearchAtsJobsInput;
  TalentSearchAccess: TalentSearchAccess;
  TalentSearchAccount: ResolverTypeWrapper<TalentSearchAccount>;
  TalentSearchAccounts: ResolverTypeWrapper<TalentSearchAccounts>;
  TalentSearchAccountsInput: TalentSearchAccountsInput;
  TalentSearchAccountsOrderBy: TalentSearchAccountsOrderBy;
  TalentSearchAccountsOrderByDirection: TalentSearchAccountsOrderByDirection;
  TalentSearchAccountsOrderByField: TalentSearchAccountsOrderByField;
  TalentSearchAddOrRemoveProfileTalentPoolsInput: TalentSearchAddOrRemoveProfileTalentPoolsInput;
  TalentSearchAddOrRemoveProfileTalentPoolsValuesInput: TalentSearchAddOrRemoveProfileTalentPoolsValuesInput;
  TalentSearchAddProfileToTalentPoolInput: TalentSearchAddProfileToTalentPoolInput;
  TalentSearchAdvertiser: ResolverTypeWrapper<TalentSearchAdvertiser>;
  TalentSearchAssignedPool: ResolverTypeWrapper<TalentSearchAssignedPool>;
  TalentSearchAssignedPools: ResolverTypeWrapper<TalentSearchAssignedPools>;
  TalentSearchAssignedPoolsInput: TalentSearchAssignedPoolsInput;
  TalentSearchAtsHirerRelationship: ResolverTypeWrapper<TalentSearchAtsHirerRelationship>;
  TalentSearchAvailableJobV2: ResolverTypeWrapper<TalentSearchAvailableJobV2>;
  TalentSearchAvailableJobsInput: TalentSearchAvailableJobsInput;
  TalentSearchBudgetBalance: ResolverTypeWrapper<TalentSearchBudgetBalance>;
  TalentSearchClaim: TalentSearchClaim;
  TalentSearchConnectionAvailability: ResolverTypeWrapper<TalentSearchConnectionAvailability>;
  TalentSearchConnectionStatus: TalentSearchConnectionStatus;
  TalentSearchConnectionType: TalentSearchConnectionType;
  TalentSearchContract: ResolverTypeWrapper<TalentSearchContract>;
  TalentSearchContractType: TalentSearchContractType;
  TalentSearchCountResult: ResolverTypeWrapper<TalentSearchCountResult>;
  TalentSearchCountResultWithLabel: ResolverTypeWrapper<TalentSearchCountResultWithLabel>;
  TalentSearchCountsResults: ResolverTypeWrapper<TalentSearchCountsResults>;
  TalentSearchCreateTalentPoolInput: TalentSearchCreateTalentPoolInput;
  TalentSearchCriteria: ResolverTypeWrapper<TalentSearchCriteria>;
  TalentSearchCriteriaInput: TalentSearchCriteriaInput;
  TalentSearchDailyUsageV2: ResolverTypeWrapper<TalentSearchDailyUsageV2>;
  TalentSearchDeleteTalentPoolInput: TalentSearchDeleteTalentPoolInput;
  TalentSearchFeature: ResolverTypeWrapper<TalentSearchFeature>;
  TalentSearchHirerAccountTags: ResolverTypeWrapper<TalentSearchHirerAccountTags>;
  TalentSearchHirerContactDetails: ResolverTypeWrapper<TalentSearchHirerContactDetails>;
  TalentSearchInteraction: ResolverTypeWrapper<TalentSearchInteraction>;
  TalentSearchInteractionEdge: ResolverTypeWrapper<TalentSearchInteractionEdge>;
  TalentSearchInteractionsConnection: ResolverTypeWrapper<TalentSearchInteractionsConnection>;
  TalentSearchJobLocationV2: ResolverTypeWrapper<TalentSearchJobLocationV2>;
  TalentSearchJobOwner: ResolverTypeWrapper<TalentSearchJobOwner>;
  TalentSearchJobSalaryV2: ResolverTypeWrapper<TalentSearchJobSalaryV2>;
  TalentSearchJobV2: ResolverTypeWrapper<TalentSearchJobV2>;
  TalentSearchJobsInput: TalentSearchJobsInput;
  TalentSearchJobsOrderBy: TalentSearchJobsOrderBy;
  TalentSearchJobsOrderByDirection: TalentSearchJobsOrderByDirection;
  TalentSearchJobsOrderByField: TalentSearchJobsOrderByField;
  TalentSearchJobsV2: ResolverTypeWrapper<TalentSearchJobsV2>;
  TalentSearchLatestInteraction: ResolverTypeWrapper<TalentSearchLatestInteraction>;
  TalentSearchMarket: ResolverTypeWrapper<TalentSearchMarket>;
  TalentSearchMessageDetails: ResolverTypeWrapper<TalentSearchMessageDetails>;
  TalentSearchMetadataCompanySuggestInput: TalentSearchMetadataCompanySuggestInput;
  TalentSearchMetadataCulture: TalentSearchMetadataCulture;
  TalentSearchMetadataIndustriesInput: TalentSearchMetadataIndustriesInput;
  TalentSearchMetadataIndustry: ResolverTypeWrapper<TalentSearchMetadataIndustry>;
  TalentSearchMetadataMarketsInput: TalentSearchMetadataMarketsInput;
  TalentSearchMetadataQueryValidations: ResolverTypeWrapper<TalentSearchMetadataQueryValidations>;
  TalentSearchMetadataQueryValidationsInput: TalentSearchMetadataQueryValidationsInput;
  TalentSearchMetadataSubIndustry: ResolverTypeWrapper<TalentSearchMetadataSubIndustry>;
  TalentSearchPool: ResolverTypeWrapper<TalentSearchPool>;
  TalentSearchPoolCandidatesInputV2: TalentSearchPoolCandidatesInputV2;
  TalentSearchPoolProfile: ResolverTypeWrapper<TalentSearchPoolProfile>;
  TalentSearchProfileConnectionsAvailability: ResolverTypeWrapper<TalentSearchProfileConnectionsAvailability>;
  TalentSearchProfileInput: TalentSearchProfileInput;
  TalentSearchProfileResultV2: ResolverTypeWrapper<TalentSearchProfileResultV2>;
  TalentSearchProfileResultsV2: ResolverTypeWrapper<TalentSearchProfileResultsV2>;
  TalentSearchProfileResumeInput: TalentSearchProfileResumeInput;
  TalentSearchProfileResumeResult: ResolverTypeWrapper<TalentSearchProfileResumeResult>;
  TalentSearchProfileSendJobStatus: ResolverTypeWrapper<TalentSearchProfileSendJobStatus>;
  TalentSearchQuerySalaryType: TalentSearchQuerySalaryType;
  TalentSearchQuerySortBy: TalentSearchQuerySortBy;
  TalentSearchRecommendedCandidatesInputV2: TalentSearchRecommendedCandidatesInputV2;
  TalentSearchSavedSearch: ResolverTypeWrapper<TalentSearchSavedSearch>;
  TalentSearchSendJobStatus: TalentSearchSendJobStatus;
  TalentSearchShareTalentPoolInput: TalentSearchShareTalentPoolInput;
  TalentSearchSimilarCandidatesByIdInputV2: TalentSearchSimilarCandidatesByIdInputV2;
  TalentSearchSimilarCandidatesResultV2: ResolverTypeWrapper<TalentSearchSimilarCandidatesResultV2>;
  TalentSearchUncoupledCountForQueryInputV2: TalentSearchUncoupledCountForQueryInputV2;
  TalentSearchUncoupledSearchInputV2: TalentSearchUncoupledSearchInputV2;
  TalentSearchUpdateTalentPoolInput: TalentSearchUpdateTalentPoolInput;
  TalentSearchUsageAdvertiserDetails: ResolverTypeWrapper<TalentSearchUsageAdvertiserDetails>;
  TalentSearchUsageAggregateComparisonV2: ResolverTypeWrapper<TalentSearchUsageAggregateComparisonV2>;
  TalentSearchUsageAggregateInput: TalentSearchUsageAggregateInput;
  TalentSearchUsageAggregateV2: ResolverTypeWrapper<TalentSearchUsageAggregateV2>;
  TalentSearchUsageConnectionInput: TalentSearchUsageConnectionInput;
  TalentSearchUsageFilters: TalentSearchUsageFilters;
  TalentSearchUsageHirerActivityV2: ResolverTypeWrapper<TalentSearchUsageHirerActivityV2>;
  TalentSearchUsageHirerActivityV2Connection: ResolverTypeWrapper<TalentSearchUsageHirerActivityV2Connection>;
  TalentSearchUsageHirerActivityV2Edge: ResolverTypeWrapper<TalentSearchUsageHirerActivityV2Edge>;
  TalentSearchUsageSearchFacet: ResolverTypeWrapper<TalentSearchUsageSearchFacet>;
  TalentSearchUsageSpendAggregate: ResolverTypeWrapper<TalentSearchUsageSpendAggregate>;
  TalentSearchUsageSpendAggregateComparison: ResolverTypeWrapper<TalentSearchUsageSpendAggregateComparison>;
  TalentSearchUser: ResolverTypeWrapper<TalentSearchUser>;
  TalentSearchUserContext: ResolverTypeWrapper<TalentSearchUserContext>;
  TalentSearchUserContextInput: TalentSearchUserContextInput;
  Tax: ResolverTypeWrapper<Tax>;
  TaxCode: TaxCode;
  TipToImproveAds:
    | ResolversTypes['AddQuestions']
    | ResolversTypes['AddSpecificLocation']
    | ResolversTypes['MaximiseBranding']
    | ResolversTypes['RevealAdSalary']
    | ResolversTypes['ReviewAdDetails']
    | ResolversTypes['ReviewAdSummary'];
  TipsToImproveAdsResponse: ResolverTypeWrapper<TipsToImproveAdsResponse>;
  TipsToImproveAdsType: TipsToImproveAdsType;
  TipsToImproveAdsWidgetInput: TipsToImproveAdsWidgetInput;
  TrackLocationSuggestionInput: TrackLocationSuggestionInput;
  TrackLocationSuggestionInvalidInputPayload: ResolverTypeWrapper<TrackLocationSuggestionInvalidInputPayload>;
  TrackLocationSuggestionPayload:
    | ResolversTypes['TrackLocationSuggestionSuccessPayload']
    | ResolversTypes['TrackLocationSuggestionInvalidInputPayload'];
  TrackLocationSuggestionSuccessPayload: ResolverTypeWrapper<TrackLocationSuggestionSuccessPayload>;
  TrendsAdTypeOption: ResolverTypeWrapper<TrendsAdTypeOption>;
  TriggerActivationPayload: ResolverTypeWrapper<TriggerActivationPayload>;
  UpdateBrandInput: UpdateBrandInput;
  UpdateBrandPayload:
    | ResolversTypes['UpdateBrandSuccessPayload']
    | ResolversTypes['InvalidInputPayload'];
  UpdateBrandSuccessPayload: ResolverTypeWrapper<UpdateBrandSuccessPayload>;
  UpdateDraftJobExpressPayload: ResolverTypeWrapper<UpdateDraftJobExpressPayload>;
  UpdateDraftJobInput: UpdateDraftJobInput;
  UpdateDraftJobPayload: ResolverTypeWrapper<UpdateDraftJobPayload>;
  UpdateDraftPayload:
    | ResolversTypes['UpdateDraftJobExpressPayload']
    | ResolversTypes['UpdateDraftJobPayload'];
  UpdateInvoiceStatusInput: UpdateInvoiceStatusInput;
  UpdateInvoiceStatusPayload: ResolverTypeWrapper<UpdateInvoiceStatusPayload>;
  UpdateUserDetailsResponse: ResolverTypeWrapper<UpdateUserDetailsResponse>;
  UpdateUserPermissionsInput: UpdateUserPermissionsInput;
  UpdateUserPermissionsResponse: ResolverTypeWrapper<UpdateUserPermissionsResponse>;
  UpgradePath: ResolverTypeWrapper<UpgradePath>;
  UpgradePathType: UpgradePathType;
  UpgradeToPremiumInput: UpgradeToPremiumInput;
  UpgradeToPremiumPayload:
    | ResolversTypes['UpgradeToPremiumSuccessPayload']
    | ResolversTypes['JobAdNotUpgradableToPremium'];
  UpgradeToPremiumSuccessPayload: ResolverTypeWrapper<UpgradeToPremiumSuccessPayload>;
  User: ResolverTypeWrapper<User>;
  UserDetails: ResolverTypeWrapper<UserDetails>;
  UserDetailsFailureMessage: ResolverTypeWrapper<UserDetailsFailureMessage>;
  UserDetailsInput: UserDetailsInput;
  UserDetailsQueryInput: UserDetailsQueryInput;
  UserEmailDetails: ResolverTypeWrapper<UserEmailDetails>;
  UserHeaderContext: ResolverTypeWrapper<UserHeaderContext>;
  UserInfo: ResolverTypeWrapper<UserInfo>;
  UserInfoInput: UserInfoInput;
  UserIntentType: UserIntentType;
  UserJobAccessInput: UserJobAccessInput;
  UserListResponse: ResolverTypeWrapper<UserListResponse>;
  UserOption: ResolverTypeWrapper<UserOption>;
  UserPermissions: ResolverTypeWrapper<UserPermissions>;
  UserPermissionsInput: UserPermissionsInput;
  UserStatus: UserStatus;
  UserWithJobPermission: ResolverTypeWrapper<UserWithJobPermission>;
  UsersParamsFilter: UsersParamsFilter;
  UsersParamsInclude: UsersParamsInclude;
  UsersParamsInput: UsersParamsInput;
  UsersWithJobPermissionInput: UsersWithJobPermissionInput;
  Verification: ResolversTypes['RightToWork'];
  VerificationViewField: ResolverTypeWrapper<VerificationViewField>;
  VerificationViewFieldType: VerificationViewFieldType;
  ViewsInJobSearchResponse: ResolverTypeWrapper<ViewsInJobSearchResponse>;
  ViewsInJobSearchWidgetInput: ViewsInJobSearchWidgetInput;
  VirtualAccount: ResolverTypeWrapper<VirtualAccount>;
  WindowLocation: WindowLocation;
  WorkHistory: ResolverTypeWrapper<WorkHistory>;
  WorkHistoryHighlight: ResolverTypeWrapper<WorkHistoryHighlight>;
  WorkType: WorkType;
  YourCandidate: ResolverTypeWrapper<YourCandidate>;
  YourCandidateChannel: YourCandidateChannel;
  YourCandidateEdge: ResolverTypeWrapper<YourCandidateEdge>;
  YourCandidateStatus: YourCandidateStatus;
  YourCandidatesInput: YourCandidatesInput;
  YourCandidatesPayloadError: ResolverTypeWrapper<YourCandidatesPayloadError>;
  YourCandidatesPayloadErrorType: YourCandidatesPayloadErrorType;
  YourCandidatesResponse:
    | ResolversTypes['YourCandidatesResults']
    | ResolversTypes['YourCandidatesPayloadError'];
  YourCandidatesResults: ResolverTypeWrapper<YourCandidatesResults>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AccessCvInput: AccessCvInput;
  Int: Scalars['Int'];
  String: Scalars['String'];
  AccessEmailReceiverInput: AccessEmailReceiverInput;
  AccountBillingAddressInput: AccountBillingAddressInput;
  AccountBillingEmailAddressInput: AccountBillingEmailAddressInput;
  AccountDetailsUpdateResponse: AccountDetailsUpdateResponse;
  AccountHierarchyOption: AccountHierarchyOption;
  AccountPostalAddressInput: AccountPostalAddressInput;
  Boolean: Scalars['Boolean'];
  AccountPrimaryContactInput: AccountPrimaryContactInput;
  AccountSelectionEdge: AccountSelectionEdge;
  AccountSelectionOptions: AccountSelectionOptions;
  AccountSelectionQueryInput: AccountSelectionQueryInput;
  AccountSelectionResults: AccountSelectionResults;
  AccountSelectionV2Response:
    | ResolversParentTypes['AccountSelectionResults']
    | ResolversParentTypes['ResponseError'];
  ActionLink: ActionLink;
  Actor: Actor;
  ActorContext: ActorContext;
  Ad: Ad;
  AdBudget: AdBudget;
  ID: Scalars['ID'];
  AdBudgetAttributes: AdBudgetAttributes;
  AdBudgetMetadata: AdBudgetMetadata;
  Float: Scalars['Float'];
  AdBudgetProduct: Omit<AdBudgetProduct, 'price' | 'purchasableProducts'> & {
    price: ResolversParentTypes['CatalogueProductPrice'];
    purchasableProducts: ResolversParentTypes['PurchasableProducts'];
  };
  AdBudgetProductList: AdBudgetProductList;
  AdBudgetProductsForUpsellInput: AdBudgetProductsForUpsellInput;
  AdBudgetProductsForUpsellResponse:
    | ResolversParentTypes['AdBudgetProductList']
    | ResolversParentTypes['ProductSetExpiredError']
    | ResolversParentTypes['ProductSetNotFoundError'];
  AdBudgetResponse: AdBudgetResponse;
  AdBudgetsByAdvertiserInput: AdBudgetsByAdvertiserInput;
  AdMetaData: AdMetaData;
  AdPerformanceReportResponse: AdPerformanceReportResponse;
  AdPerformanceReportWidgetInput: AdPerformanceReportWidgetInput;
  AdProduct: Omit<AdProduct, 'price' | 'pricing'> & {
    price: ResolversParentTypes['CatalogueProductPrice'];
    pricing?: Maybe<ResolversParentTypes['Pricing']>;
  };
  AdProductDisplayMetadata: AdProductDisplayMetadata;
  AdProductSetResponse:
    | ResolversParentTypes['ProductList']
    | ResolversParentTypes['ProductSetExpiredError']
    | ResolversParentTypes['ProductSetNotFoundError']
    | ResolversParentTypes['DisplayMetadataError'];
  AdProductUpgrade: Omit<AdProductUpgrade, 'price'> & {
    price: ResolversParentTypes['CatalogueProductPrice'];
  };
  AdProductsForAdPostingInput: AdProductsForAdPostingInput;
  AdRatingLabels: AdRatingLabels;
  AdRatingResponse: AdRatingResponse;
  AdRatingTypeOption: AdRatingTypeOption;
  AdRatingWidgetInput: AdRatingWidgetInput;
  AdSpendFeature: AdSpendFeature;
  AdStatusOption: AdStatusOption;
  AdTipsOption: AdTipsOption;
  AdTypeAverageCost: AdTypeAverageCost;
  AdTypeDiscount: AdTypeDiscount;
  AdTypeDiscountsFeature: AdTypeDiscountsFeature;
  AdTypeOption: AdTypeOption;
  AdTypePostCount: AdTypePostCount;
  AdUsageAdTypeTrendInput: AdUsageAdTypeTrendInput;
  AdUsageAdTypeTrendResponse: AdUsageAdTypeTrendResponse;
  AdUsageConversionResponse: AdUsageConversionResponse;
  AdUsageConversionWidgetInput: AdUsageConversionWidgetInput;
  AdUsageFiltersResponse: AdUsageFiltersResponse;
  AdUsageFiltersWidgetInput: AdUsageFiltersWidgetInput;
  AdUsageQueryFilters: AdUsageQueryFilters;
  AdUsageQueryFiltersDates: AdUsageQueryFiltersDates;
  AdUsageSpendResponse: AdUsageSpendResponse;
  AdUsageSpendWidgetInput: AdUsageSpendWidgetInput;
  AdUsageTrendClassification: AdUsageTrendClassification;
  AdUsageTrendClassificationsAndUsersInput: AdUsageTrendClassificationsAndUsersInput;
  AdUsageTrendClassificationsAndUsersResponse: AdUsageTrendClassificationsAndUsersResponse;
  AdUsageTrendDaily: AdUsageTrendDaily;
  AdUsageTrendMonthly: AdUsageTrendMonthly;
  AdUsageTrendUser: AdUsageTrendUser;
  AddQuestions: AddQuestions;
  AddSpecificLocation: AddSpecificLocation;
  AddUserToAccountInput: AddUserToAccountInput;
  AddUserToAccountResponse: AddUserToAccountResponse;
  AdvertiserAccount: AdvertiserAccount;
  AdvertiserAccountOption: AdvertiserAccountOption;
  AdvertiserHeaderContext: AdvertiserHeaderContext;
  AhpraRegistration: AhpraRegistration;
  AnalyticsAdRating: AnalyticsAdRating;
  AnalyticsLatestJobs: AnalyticsLatestJobs;
  AnalyticsMarketRoleInsightsJobTitlesInput: AnalyticsMarketRoleInsightsJobTitlesInput;
  AnalyticsMarketRoleInsightsJobTitlesResult: AnalyticsMarketRoleInsightsJobTitlesResult;
  AnalyticsMarketRoleInsightsLocationsInput: AnalyticsMarketRoleInsightsLocationsInput;
  AnalyticsMarketRoleInsightsLocationsResult: AnalyticsMarketRoleInsightsLocationsResult;
  AnalyticsMarketRoleInsightsSearchInput: AnalyticsMarketRoleInsightsSearchInput;
  AnalyticsMarketRoleInsightsSearchResult: AnalyticsMarketRoleInsightsSearchResult;
  AnalyticsMarketRoleInsightsSuggestion: AnalyticsMarketRoleInsightsSuggestion;
  AnalyticsTalentSearchUsageAccount: AnalyticsTalentSearchUsageAccount;
  AnalyticsTalentSearchUsageCreditsUsage: AnalyticsTalentSearchUsageCreditsUsage;
  AnalyticsTalentSearchUsageCreditsUsageListInput: AnalyticsTalentSearchUsageCreditsUsageListInput;
  AnalyticsTalentSearchUsageCreditsUsageListResponse: AnalyticsTalentSearchUsageCreditsUsageListResponse;
  AnalyticsTalentSearchUsageDateRangeInput: AnalyticsTalentSearchUsageDateRangeInput;
  AnalyticsTalentSearchUsageFilters: AnalyticsTalentSearchUsageFilters;
  AnalyticsTalentSearchUsageFiltersInput: AnalyticsTalentSearchUsageFiltersInput;
  AnalyticsTalentSearchUsageFiltersResponse: AnalyticsTalentSearchUsageFiltersResponse;
  AnalyticsTalentSearchUsageLastUpdatedResponse: AnalyticsTalentSearchUsageLastUpdatedResponse;
  AnalyticsTalentSearchUsageUser: AnalyticsTalentSearchUsageUser;
  ApplicationPerformanceResponse: ApplicationPerformanceResponse;
  ApplicationPerformanceWidgetInput: ApplicationPerformanceWidgetInput;
  AssociatedAction: AssociatedAction;
  AssociatedParty: AssociatedParty;
  BillableApplicantsData: BillableApplicantsData;
  BillableApplicantsResponse:
    | ResolversParentTypes['BillableApplicantsResponseSuccess']
    | ResolversParentTypes['ResponseError'];
  BillableApplicantsResponseSuccess: BillableApplicantsResponseSuccess;
  BillingContact: BillingContact;
  Brand: Brand;
  BrandAssetStrategies:
    | ResolversParentTypes['LogoStrategies']
    | ResolversParentTypes['CoverStrategies'];
  BrandAssetType: BrandAssetType;
  BrandAssetTypeInput: BrandAssetTypeInput;
  BrandAssets: BrandAssets;
  BrandAssetsInput: BrandAssetsInput;
  BrandOwner: BrandOwner;
  BrandOwnerInput: BrandOwnerInput;
  BrandedAd: BrandedAd;
  BrandedFeaturesFeature: BrandedFeaturesFeature;
  BrandingAsset: Omit<BrandingAsset, 'strategies'> & {
    strategies: ResolversParentTypes['BrandAssetStrategies'];
  };
  BrandingFeature: BrandingFeature;
  BrandingInput: BrandingInput;
  BulletPointsFeature: BulletPointsFeature;
  CandidateDemandResponse: CandidateDemandResponse;
  CandidateDemandWidgetInput: CandidateDemandWidgetInput;
  CandidateSupplyResponse: CandidateSupplyResponse;
  CandidateSupplyWidgetInput: CandidateSupplyWidgetInput;
  CappedUnitPrice: Omit<CappedUnitPrice, 'unitPrice' | 'cappedPrice'> & {
    unitPrice: ResolversParentTypes['CatalogueProductPrice'];
    cappedPrice: ResolversParentTypes['CatalogueProductPrice'];
  };
  CappedUnitPriceMetadata: CappedUnitPriceMetadata;
  CatalogueProduct:
    | ResolversParentTypes['AdBudgetProduct']
    | ResolversParentTypes['AdProduct']
    | ResolversParentTypes['AdProductUpgrade'];
  CatalogueProductAttributes: CatalogueProductAttributes;
  CatalogueProductFeature:
    | ResolversParentTypes['AdSpendFeature']
    | ResolversParentTypes['AdTypeDiscountsFeature']
    | ResolversParentTypes['BrandedFeaturesFeature']
    | ResolversParentTypes['BrandingFeature']
    | ResolversParentTypes['BulletPointsFeature']
    | ResolversParentTypes['DurationFeature']
    | ResolversParentTypes['JobAdEditFeature']
    | ResolversParentTypes['PerApplicantBillingFeature']
    | ResolversParentTypes['PerUnitBillingFeature']
    | ResolversParentTypes['RoleRequirementsMandatoryQuestionsFeature']
    | ResolversParentTypes['SponsoredSlotFeature']
    | ResolversParentTypes['StyleEnhancementFeature'];
  CatalogueProductPrice:
    | ResolversParentTypes['Price']
    | ResolversParentTypes['HiddenPrice'];
  Category: Category;
  CategorySuggestion: CategorySuggestion;
  ChildAccountBannerResponse: ChildAccountBannerResponse;
  Claim: Claim;
  ClassificationOption: ClassificationOption;
  ClicksOnYourAdResponse: ClicksOnYourAdResponse;
  ClicksOnYourAdWidgetInput: ClicksOnYourAdWidgetInput;
  CompanyAddress: CompanyAddress;
  CompanyProfile: CompanyProfile;
  CompanyProfileAward: CompanyProfileAward;
  CompanyProfileAwardInput: CompanyProfileAwardInput;
  CompanyProfileBranding: CompanyProfileBranding;
  CompanyProfileBrandingInput: CompanyProfileBrandingInput;
  CompanyProfileCultureAndValues: CompanyProfileCultureAndValues;
  CompanyProfileCultureAndValuesInput: CompanyProfileCultureAndValuesInput;
  CompanyProfileCultureValue: CompanyProfileCultureValue;
  CompanyProfileCultureValueInput: CompanyProfileCultureValueInput;
  CompanyProfileCustomStatement: CompanyProfileCustomStatement;
  CompanyProfileCustomStatementInput: CompanyProfileCustomStatementInput;
  CompanyProfileFeatured: CompanyProfileFeatured;
  CompanyProfileFeaturedInput: CompanyProfileFeaturedInput;
  CompanyProfileIds: CompanyProfileIds;
  CompanyProfileInput: CompanyProfileInput;
  CompanyProfileOverview: CompanyProfileOverview;
  CompanyProfileOverviewInput: CompanyProfileOverviewInput;
  CompanyProfilePerksAndBenefits: CompanyProfilePerksAndBenefits;
  CompanyProfilePerksAndBenefitsInput: CompanyProfilePerksAndBenefitsInput;
  CompanyProfilePhoto: CompanyProfilePhoto;
  CompanyProfilePhotoInput: CompanyProfilePhotoInput;
  CompanyProfileWebsite: CompanyProfileWebsite;
  CompanyProfileWebsiteInput: CompanyProfileWebsiteInput;
  ConfirmInvoiceOrderInput: ConfirmInvoiceOrderInput;
  ConfirmInvoiceOrderPayload:
    | ResolversParentTypes['InvoiceOrderSuccessPayload']
    | ResolversParentTypes['InvoiceOrderNotFoundPayload']
    | ResolversParentTypes['OrderNotFoundError']
    | ResolversParentTypes['OrderExpiredError']
    | ResolversParentTypes['OrderInvalidError']
    | ResolversParentTypes['PromotionInvalidError'];
  ConnectionStatusPayload: ConnectionStatusPayload;
  Contract: Contract;
  ContractAdDiscount: ContractAdDiscount;
  ContractBalance: ContractBalance;
  ContractDollarValue: ContractDollarValue;
  ContractUnitValue: ContractUnitValue;
  ContractValue: ContractValue;
  ConversionRateData: ConversionRateData;
  CountriesInput: CountriesInput;
  Country: Country;
  CountrySelectionOptions: CountrySelectionOptions;
  CoverStrategies: CoverStrategies;
  Create2C2PPaymentIntentForInvoiceInput: Create2C2PPaymentIntentForInvoiceInput;
  Create2C2PPaymentIntentInput: Create2C2PPaymentIntentInput;
  Create2c2pPaymentIntentPayload:
    | ResolversParentTypes['PaymentIntent2C2PPayload']
    | ResolversParentTypes['PaymentMethodError']
    | ResolversParentTypes['PaymentIntentChargeError']
    | ResolversParentTypes['OrderNotFoundError']
    | ResolversParentTypes['OrderExpiredError']
    | ResolversParentTypes['OrderInvalidError']
    | ResolversParentTypes['PromotionInvalidError']
    | ResolversParentTypes['InvoiceError'];
  CreateBrandInput: CreateBrandInput;
  CreateBrandPayload:
    | ResolversParentTypes['CreateBrandSuccessPayload']
    | ResolversParentTypes['InvalidInputPayload'];
  CreateBrandSuccessPayload: CreateBrandSuccessPayload;
  CreateCustomQuestionErrorResult: CreateCustomQuestionErrorResult;
  CreateCustomQuestionInput: CreateCustomQuestionInput;
  CreateCustomQuestionOutputUnion:
    | ResolversParentTypes['CreateCustomQuestionResult']
    | ResolversParentTypes['CreateCustomQuestionErrorResult'];
  CreateCustomQuestionResult: CreateCustomQuestionResult;
  CreateDraftJobInput: CreateDraftJobInput;
  CreateDraftJobPayload: CreateDraftJobPayload;
  CreateIntegrationRelationshipsInput: CreateIntegrationRelationshipsInput;
  CreateJobInput: CreateJobInput;
  CreateJobResponse: CreateJobResponse;
  CreateOrderInput: CreateOrderInput;
  CreateOrderPayload: CreateOrderPayload;
  CreateOrderSuccessPayload: CreateOrderSuccessPayload;
  CreateOrderWithPromotionPayload:
    | ResolversParentTypes['CreateOrderSuccessPayload']
    | ResolversParentTypes['PromotionInvalidError'];
  CreatePaymentIntentInput: CreatePaymentIntentInput;
  CreatePaymentIntentPayload:
    | ResolversParentTypes['PaymentIntentPayload']
    | ResolversParentTypes['OrderNotFoundError']
    | ResolversParentTypes['OrderExpiredError']
    | ResolversParentTypes['OrderInvalidError']
    | ResolversParentTypes['PromotionInvalidError'];
  CreateProductSetForAdEditingInput: CreateProductSetForAdEditingInput;
  CreateProductSetForAdEditingPayload: CreateProductSetForAdEditingPayload;
  CreateProductSetForAdPostingInput: CreateProductSetForAdPostingInput;
  CreateProductSetForAdPostingPayload: CreateProductSetForAdPostingPayload;
  CreateProductSetForAdRefreshInput: CreateProductSetForAdRefreshInput;
  CreateProductSetForAdRefreshPayload: CreateProductSetForAdRefreshPayload;
  CreateProductSetForBudgetsPurchaseForHirerPayload: CreateProductSetForBudgetsPurchaseForHirerPayload;
  CreateProductSetForBudgetsPurchaseForVisitorInput: CreateProductSetForBudgetsPurchaseForVisitorInput;
  CreateProductSetForBudgetsPurchaseForVisitorPayload: CreateProductSetForBudgetsPurchaseForVisitorPayload;
  CreateProductSetForMultiLocationInput: CreateProductSetForMultiLocationInput;
  CreateProductSetForMultiLocationPayload: CreateProductSetForMultiLocationPayload;
  CreateProductSetForPriceCheckerPreviewInput: CreateProductSetForPriceCheckerPreviewInput;
  CreateProductSetForPriceCheckerPreviewPayload: CreateProductSetForPriceCheckerPreviewPayload;
  CreateQuestionnaireInput: CreateQuestionnaireInput;
  CreateQuestionnaireResponse: CreateQuestionnaireResponse;
  CreateSavedSearchInput: CreateSavedSearchInput;
  CreateSavedSearchPayload: CreateSavedSearchPayload;
  CreateStripePaymentIntentForInvoiceInput: CreateStripePaymentIntentForInvoiceInput;
  CreateStripePaymentIntentInput: CreateStripePaymentIntentInput;
  CreateStripePaymentIntentPayload:
    | ResolversParentTypes['StripePaymentIntentPayload']
    | ResolversParentTypes['PaymentMethodError']
    | ResolversParentTypes['OrderNotFoundError']
    | ResolversParentTypes['OrderExpiredError']
    | ResolversParentTypes['OrderInvalidError']
    | ResolversParentTypes['PromotionInvalidError']
    | ResolversParentTypes['InvoiceError'];
  CreditCard: CreditCard;
  CurrentAdvertiserContext: CurrentAdvertiserContext;
  CurrentAdvertiserResponse:
    | ResolversParentTypes['CurrentAdvertiserContext']
    | ResolversParentTypes['MissingAdvertiserIdClaimError'];
  CurrentUserContext: CurrentUserContext;
  CurrentUserResponse:
    | ResolversParentTypes['CurrentUserContext']
    | ResolversParentTypes['MissingUserIdClaimError'];
  CustomQuestion: CustomQuestion;
  CustomQuestionValidationFailure: CustomQuestionValidationFailure;
  Date: Scalars['Date'];
  DateAxisLabel: DateAxisLabel;
  DateTime: Scalars['DateTime'];
  DeleteBrandingAssetInput: DeleteBrandingAssetInput;
  DeleteBrandingAssetPayload: DeleteBrandingAssetPayload;
  DeleteDraftJobInput: DeleteDraftJobInput;
  DeleteDraftJobPayload: DeleteDraftJobPayload;
  DeleteIntegrationRelationshipsInput: DeleteIntegrationRelationshipsInput;
  DeleteSavedCreditCardInput: DeleteSavedCreditCardInput;
  DeleteSavedCreditCardPayload: DeleteSavedCreditCardPayload;
  DeleteSavedSearchPayload: DeleteSavedSearchPayload;
  DeleteStripeSavedCreditCardInput: DeleteStripeSavedCreditCardInput;
  DeleteStripeSavedCreditCardPayload: DeleteStripeSavedCreditCardPayload;
  DisableMessageType: DisableMessageType;
  Discount: Discount;
  DisplayMetadataError: DisplayMetadataError;
  DraftJob: DraftJob;
  DraftJobBranding: DraftJobBranding;
  DraftJobBrandingInput: DraftJobBrandingInput;
  DraftJobCategory: DraftJobCategory;
  DraftJobCategoryInput: DraftJobCategoryInput;
  DraftJobEdge: DraftJobEdge;
  DraftJobGuidedDetails: DraftJobGuidedDetails;
  DraftJobGuidedDetailsInput: DraftJobGuidedDetailsInput;
  DraftJobLocation: DraftJobLocation;
  DraftJobLocationInput: DraftJobLocationInput;
  DraftJobNode: DraftJobNode;
  DraftJobNotFoundPayload: DraftJobNotFoundPayload;
  DraftJobPayload:
    | ResolversParentTypes['DraftJobSuccessPayload']
    | ResolversParentTypes['DraftJobNotFoundPayload'];
  DraftJobProducts: DraftJobProducts;
  DraftJobProductsInput: DraftJobProductsInput;
  DraftJobRoleTitle: DraftJobRoleTitle;
  DraftJobRoleTitleInput: DraftJobRoleTitleInput;
  DraftJobSalaryDetails: DraftJobSalaryDetails;
  DraftJobSalaryDetailsInput: DraftJobSalaryDetailsInput;
  DraftJobSuccessPayload: DraftJobSuccessPayload;
  DraftJobVideo: DraftJobVideo;
  DraftJobVideoInput: DraftJobVideoInput;
  DraftJobsInput: DraftJobsInput;
  DraftJobsPayload: DraftJobsPayload;
  DrawdownContractDetails: DrawdownContractDetails;
  DrawdownContractValue: DrawdownContractValue;
  DrawdownItem: DrawdownItem;
  DriversLicence: DriversLicence;
  DurationFeature: DurationFeature;
  EditJobInput: EditJobInput;
  EditJobResponse: EditJobResponse;
  Error:
    | ResolversParentTypes['DisplayMetadataError']
    | ResolversParentTypes['InvoiceError']
    | ResolversParentTypes['MissingAdvertiserIdClaimError']
    | ResolversParentTypes['MissingUserIdClaimError']
    | ResolversParentTypes['OrderExpiredError']
    | ResolversParentTypes['OrderInvalidError']
    | ResolversParentTypes['OrderNotFoundError']
    | ResolversParentTypes['Payment2c2pInquiryError']
    | ResolversParentTypes['PaymentIntentChargeError']
    | ResolversParentTypes['PaymentMethodError']
    | ResolversParentTypes['ProductSetExpiredError']
    | ResolversParentTypes['ProductSetNotFoundError']
    | ResolversParentTypes['PromotionInvalidError']
    | ResolversParentTypes['SavedCreditCardPaymentError']
    | ResolversParentTypes['SecureLinkExpiredError']
    | ResolversParentTypes['SecureLinkInvalidError']
    | ResolversParentTypes['SecureLinkUsedError'];
  ExpectedSalaryV2: ExpectedSalaryV2;
  ExpireJobInput: ExpireJobInput;
  ExpireJobResponse: ExpireJobResponse;
  Expiry: Expiry;
  ExportProfileInput: ExportProfileInput;
  GetBrandingAssetsInput: GetBrandingAssetsInput;
  GetProductSetInput: GetProductSetInput;
  GetProductSetResponse:
    | ResolversParentTypes['ProductSet']
    | ResolversParentTypes['ProductSetExpiredError']
    | ResolversParentTypes['ProductSetNotFoundError'];
  GetSurchargeAmountInput: GetSurchargeAmountInput;
  GetSurchargeAmountPayload: GetSurchargeAmountPayload;
  GranularLocation: GranularLocation;
  HeaderFooterContext: HeaderFooterContext;
  HeaderFooterContextInput: HeaderFooterContextInput;
  HelpTip: HelpTip;
  HelpTipImage: HelpTipImage;
  HiddenPrice: HiddenPrice;
  HirerAccount: HirerAccount;
  HirerAccountDetails: HirerAccountDetails;
  HirerAccountDetailsQueryInput: HirerAccountDetailsQueryInput;
  HirerAccountIntegrationsInput: HirerAccountIntegrationsInput;
  HirerAccountLoginUrlInput: HirerAccountLoginUrlInput;
  HirerAccountUserQueryInput: HirerAccountUserQueryInput;
  HirerActivitiesByJobInput: HirerActivitiesByJobInput;
  HirerActivity: HirerActivity;
  HirerActivityConnection: HirerActivityConnection;
  HirerActivityData: HirerActivityData;
  HirerActivityEdge: HirerActivityEdge;
  HirerActivityJobApplication: HirerActivityJobApplication;
  HirerDashboardArticle: HirerDashboardArticle;
  HirerDashboardBudgetUpsellInput: HirerDashboardBudgetUpsellInput;
  HirerDashboardBudgetUpsellResponse: HirerDashboardBudgetUpsellResponse;
  HirerDashboardHiringAdviceList: HirerDashboardHiringAdviceList;
  HirerDashboardJob: HirerDashboardJob;
  HirerDashboardJobCounts: HirerDashboardJobCounts;
  HirerDashboardJobs: HirerDashboardJobs;
  HirerDashboardJobsQueryInput: HirerDashboardJobsQueryInput;
  HirerDashboardTalentSearch: HirerDashboardTalentSearch;
  HirerDashboardUserDetails: HirerDashboardUserDetails;
  HirerDashboardWelcome: HirerDashboardWelcome;
  HirerExperience: HirerExperience;
  HirerJob: HirerJob;
  HirerJobConnection: HirerJobConnection;
  HirerJobEdge: HirerJobEdge;
  HirerJobsInput: HirerJobsInput;
  HirerPrimaryContact: HirerPrimaryContact;
  HirerUserDetails: HirerUserDetails;
  HirerUserEmailInput: HirerUserEmailInput;
  HirerUserEmailQueryInput: HirerUserEmailQueryInput;
  Identity: Identity;
  IdentityHeaderContext: IdentityHeaderContext;
  IdentityIds: IdentityIds;
  Image: Image;
  InitiateConnectionPayload: InitiateConnectionPayload;
  Integration: Integration;
  IntegrationConnection: IntegrationConnection;
  IntegrationEdge: IntegrationEdge;
  IntegrationPartner: IntegrationPartner;
  IntegrationRelationship: IntegrationRelationship;
  IntegrationRelationshipType: IntegrationRelationshipType;
  InteractedJob: InteractedJob;
  InvalidInputPayload: InvalidInputPayload;
  Invoice: Invoice;
  InvoiceError: InvoiceError;
  InvoiceInput: InvoiceInput;
  InvoiceOrderNotFoundPayload: InvoiceOrderNotFoundPayload;
  InvoiceOrderSuccessPayload: InvoiceOrderSuccessPayload;
  JSON: Scalars['JSON'];
  Job: Omit<Job, 'billableApplicants'> & {
    billableApplicants: ResolversParentTypes['BillableApplicantsResponse'];
  };
  JobAd: JobAd;
  JobAdDetails: JobAdDetails;
  JobAdEditFeature: JobAdEditFeature;
  JobAdListResponse: JobAdListResponse;
  JobAdListWidgetInput: JobAdListWidgetInput;
  JobAdNotUpgradableToPremium: JobAdNotUpgradableToPremium;
  JobAdRevisionAttributes: JobAdRevisionAttributes;
  JobTitleOption: JobTitleOption;
  LanguageCodeIso: Scalars['LanguageCodeIso'];
  LastUpdatedResponse: LastUpdatedResponse;
  LineItemProduct: LineItemProduct;
  Link: Link;
  LocalisedContactInfoContext: LocalisedContactInfoContext;
  Location: Location;
  LocationOption: LocationOption;
  LocationSearchResult: LocationSearchResult;
  LogoStrategies: LogoStrategies;
  LowApply: LowApply;
  LowClick: LowClick;
  LowSalary: LowSalary;
  LowestPricedProductForAdPostingInput: LowestPricedProductForAdPostingInput;
  LowestPricedProductForAdPostingResponse:
    | ResolversParentTypes['AdProduct']
    | ResolversParentTypes['ProductSetExpiredError']
    | ResolversParentTypes['ProductSetNotFoundError'];
  Maintenance: Maintenance;
  ManagingAgency: ManagingAgency;
  MandatoryData: MandatoryData;
  MarketInsightMonth: MarketInsightMonth;
  MarketInsightsTitleResponse: MarketInsightsTitleResponse;
  MarketInsightsTitleWidgetInput: MarketInsightsTitleWidgetInput;
  MarketPerformanceResponse: MarketPerformanceResponse;
  MarketPerformanceWidgetInput: MarketPerformanceWidgetInput;
  MatchedSearchString: MatchedSearchString;
  MaximiseBranding: MaximiseBranding;
  MissingAdvertiserIdClaimError: MissingAdvertiserIdClaimError;
  MissingUserIdClaimError: MissingUserIdClaimError;
  Money: Money;
  MostAdsByPerformanceCategory: MostAdsByPerformanceCategory;
  MostAdsByPerformanceEntity: MostAdsByPerformanceEntity;
  MostAdsByPerformanceResponse: MostAdsByPerformanceResponse;
  MostAdsByPerformanceWidgetInput: MostAdsByPerformanceWidgetInput;
  Mutation: {};
  MyJobInformationJobEdit: MyJobInformationJobEdit;
  MyJobInformationResponse: MyJobInformationResponse;
  MyJobInformationSalary: MyJobInformationSalary;
  MyJobInformationWidgetInput: MyJobInformationWidgetInput;
  NewAdvertiser: NewAdvertiser;
  NewUser: NewUser;
  NotificationPreference: NotificationPreference;
  NotificationPreferenceInput: NotificationPreferenceInput;
  Option: Option;
  OptionWithId: OptionWithId;
  Order: Order;
  OrderExpiredError: OrderExpiredError;
  OrderInvalidError: OrderInvalidError;
  OrderItem: OrderItem;
  OrderLineItem: Omit<OrderLineItem, 'pricing'> & {
    pricing?: Maybe<ResolversParentTypes['Pricing']>;
  };
  OrderNotFoundError: OrderNotFoundError;
  OrderProductAttributesInput: OrderProductAttributesInput;
  OrderRequestInputItem: OrderRequestInputItem;
  OrderSummary: OrderSummary;
  OutputCustomQuestion: OutputCustomQuestion;
  PageInfo: PageInfo;
  PageTitleActionsResponse: PageTitleActionsResponse;
  PageTitleActionsWidgetInput: PageTitleActionsWidgetInput;
  Parent: Parent;
  ParentAdvertiser: ParentAdvertiser;
  ParentLocation: ParentLocation;
  PatchCompanyProfileResponse: PatchCompanyProfileResponse;
  PayBySavedCreditCardInput: PayBySavedCreditCardInput;
  PayBySavedCreditCardPayload:
    | ResolversParentTypes['SavedCreditCardPaymentSuccessPayload']
    | ResolversParentTypes['SavedCreditCardPaymentError']
    | ResolversParentTypes['OrderNotFoundError']
    | ResolversParentTypes['OrderExpiredError']
    | ResolversParentTypes['OrderInvalidError']
    | ResolversParentTypes['PromotionInvalidError'];
  PayByStripeSavedCreditCardForInvoiceInput: PayByStripeSavedCreditCardForInvoiceInput;
  PayByStripeSavedCreditCardInput: PayByStripeSavedCreditCardInput;
  PayByStripeSavedCreditCardPayload:
    | ResolversParentTypes['SavedCreditCardPaymentSuccessPayload']
    | ResolversParentTypes['SavedCreditCardPaymentError']
    | ResolversParentTypes['OrderNotFoundError']
    | ResolversParentTypes['OrderExpiredError']
    | ResolversParentTypes['OrderInvalidError']
    | ResolversParentTypes['PromotionInvalidError'];
  Payment2c2pInquiry: Payment2c2pInquiry;
  Payment2c2pInquiryError: Payment2c2pInquiryError;
  Payment2c2pInquiryPayload:
    | ResolversParentTypes['Payment2c2pInquiry']
    | ResolversParentTypes['Payment2c2pInquiryError'];
  PaymentConfigPayload: PaymentConfigPayload;
  PaymentIntent2C2PPayload: PaymentIntent2C2PPayload;
  PaymentIntentChargeError: PaymentIntentChargeError;
  PaymentIntentPayload: PaymentIntentPayload;
  PaymentMediumType: PaymentMediumType;
  PaymentMethodError: PaymentMethodError;
  PaymentMethodPayload: PaymentMethodPayload;
  PerApplicantBillingFeature: PerApplicantBillingFeature;
  PerUnitBillingFeature: PerUnitBillingFeature;
  PerformancePredictionChartEvent: PerformancePredictionChartEvent;
  PerformancePredictionResponse: PerformancePredictionResponse;
  PerformancePredictionWidgetInput: PerformancePredictionWidgetInput;
  PhysicalAddress: PhysicalAddress;
  PredictionDayOptions: PredictionDayOptions;
  Price: Price;
  PriceAttributes: PriceAttributes;
  PriceType: PriceType;
  Pricing:
    | ResolversParentTypes['CappedUnitPrice']
    | ResolversParentTypes['PriceType'];
  PrimaryContact: PrimaryContact;
  Product:
    | ResolversParentTypes['Ad']
    | ResolversParentTypes['AdBudget']
    | ResolversParentTypes['LineItemProduct'];
  ProductFeature: ProductFeature;
  ProductList: ProductList;
  ProductReference: ProductReference;
  ProductSet: ProductSet;
  ProductSetExpiredError: ProductSetExpiredError;
  ProductSetNotFoundError: ProductSetNotFoundError;
  ProfileEducation: ProfileEducation;
  ProfileProperties: ProfileProperties;
  ProfileResultHighlight: ProfileResultHighlight;
  ProfileSalaryV2: ProfileSalaryV2;
  ProfilesByEmailInput: ProfilesByEmailInput;
  PromoCode: Omit<PromoCode, 'discount'> & {
    discount: ResolversParentTypes['PromoCodeDiscount'];
  };
  PromoCodeDiscount: ResolversParentTypes['PromoCodeDiscountRate'];
  PromoCodeDiscountRate: PromoCodeDiscountRate;
  PromoCodeProduct: PromoCodeProduct;
  Promotion: Promotion;
  PromotionInvalidError: PromotionInvalidError;
  PublishCPQAEventInput: PublishCpqaEventInput;
  PublishCPQAEventPayload: PublishCpqaEventPayload;
  PublishJobAdBookingEventInput: PublishJobAdBookingEventInput;
  PublishJobAdBookingEventPayload: PublishJobAdBookingEventPayload;
  PublishJobPostedEventInput: PublishJobPostedEventInput;
  PublishJobPostedEventPayload: PublishJobPostedEventPayload;
  PurchasableProducts:
    | ResolversParentTypes['PurchasableProductsList']
    | ResolversParentTypes['PurchasableProductsString'];
  PurchasableProductsList: PurchasableProductsList;
  PurchasableProductsString: PurchasableProductsString;
  Query: {};
  Questionnaire: Questionnaire;
  QuestionnaireAnswer: QuestionnaireAnswer;
  QuestionnaireQuestion: QuestionnaireQuestion;
  QuestionnaireQuestionFilter: QuestionnaireQuestionFilter;
  Range: Range;
  Recommendation:
    | ResolversParentTypes['RevealSalary']
    | ResolversParentTypes['GranularLocation']
    | ResolversParentTypes['LowClick']
    | ResolversParentTypes['LowApply']
    | ResolversParentTypes['BrandedAd']
    | ResolversParentTypes['RoleRequirements']
    | ResolversParentTypes['LowSalary']
    | ResolversParentTypes['TalentSearch'];
  RecommendationsResponse: Omit<RecommendationsResponse, 'recommendations'> & {
    recommendations: Array<ResolversParentTypes['Recommendation']>;
  };
  RecommendationsWidgetInput: RecommendationsWidgetInput;
  RedirectUrlQueryInput: RedirectUrlQueryInput;
  RefreshJobInput: RefreshJobInput;
  RefreshJobResponse: RefreshJobResponse;
  RegisterAdditionalAdvertiserInput: RegisterAdditionalAdvertiserInput;
  RegisterNewUserInput: RegisterNewUserInput;
  RegisterPayload: RegisterPayload;
  RegistrationOptions: RegistrationOptions;
  RegistrationStatusInfo: RegistrationStatusInfo;
  RegistrationStatusInput: RegistrationStatusInput;
  ReinstateInput: ReinstateInput;
  ReinstateResponse: ReinstateResponse;
  RemoveUserFromAccountInput: RemoveUserFromAccountInput;
  RepostOption: RepostOption;
  ResponseError: ResponseError;
  ResumeEducation: ResumeEducation;
  ResumeWorkHistory: ResumeWorkHistory;
  ResumeWorkHistoryDisplayDescription: ResumeWorkHistoryDisplayDescription;
  RetrievePaymentIntentPayload: RetrievePaymentIntentPayload;
  RevealAdSalary: RevealAdSalary;
  RevealSalary: RevealSalary;
  ReviewAdDetails: ReviewAdDetails;
  ReviewAdSummary: ReviewAdSummary;
  RightToWork: RightToWork;
  RightToWorkInSavedSearch: RightToWorkInSavedSearch;
  RightToWorkInput: RightToWorkInput;
  RoleRequirements: RoleRequirements;
  RoleRequirementsMandatoryQuestionsFeature: RoleRequirementsMandatoryQuestionsFeature;
  Rule: Rule;
  SafeInt: Scalars['SafeInt'];
  Salary: Salary;
  SalaryAmountV2: SalaryAmountV2;
  SalaryRange: SalaryRange;
  SavedCreditCardPaymentError: SavedCreditCardPaymentError;
  SavedCreditCardPaymentSuccessPayload: SavedCreditCardPaymentSuccessPayload;
  SearchIntegrationPartnersInput: SearchIntegrationPartnersInput;
  SecureLinkExpiredError: SecureLinkExpiredError;
  SecureLinkInput: SecureLinkInput;
  SecureLinkInvalidError: SecureLinkInvalidError;
  SecureLinkResponse:
    | ResolversParentTypes['SecureLinkSuccessResponse']
    | ResolversParentTypes['SecureLinkUsedError']
    | ResolversParentTypes['SecureLinkExpiredError']
    | ResolversParentTypes['SecureLinkInvalidError'];
  SecureLinkSuccessResponse: SecureLinkSuccessResponse;
  SecureLinkUsedError: SecureLinkUsedError;
  SellingPoint: SellingPoint;
  SendEmailResponse: SendEmailResponse;
  SendEmailVerificationEmailPayload: SendEmailVerificationEmailPayload;
  SendEnrolmentEmailPayload: SendEnrolmentEmailPayload;
  SendHirerAccountUsersJobAccessEmailInput: SendHirerAccountUsersJobAccessEmailInput;
  SendHirerAccountUsersJobAccessEmailPayload: SendHirerAccountUsersJobAccessEmailPayload;
  SendJobInput: SendJobInput;
  SendLoginCallbackEventPayload: SendLoginCallbackEventPayload;
  SendMessageInput: SendMessageInput;
  SendPasswordResetEmailInput: SendPasswordResetEmailInput;
  SendRegistrationEmailInput: SendRegistrationEmailInput;
  SetDefaultNotificationPreferenceInput: SetDefaultNotificationPreferenceInput;
  SetHirerAccountUsersJobAccessInput: SetHirerAccountUsersJobAccessInput;
  SetHirerAccountUsersJobAccessPayload: SetHirerAccountUsersJobAccessPayload;
  SetNotificationPreferencesInput: SetNotificationPreferencesInput;
  SetNotificationPreferencesPayload: SetNotificationPreferencesPayload;
  SimilarAdsDetails: SimilarAdsDetails;
  SimilarAdsResponse: SimilarAdsResponse;
  SimilarAdsWidgetInput: SimilarAdsWidgetInput;
  SimplifiedAnswers: SimplifiedAnswers;
  SponsoredSlotFeature: SponsoredSlotFeature;
  StripePaymentIntentPayload: StripePaymentIntentPayload;
  StyleEnhancementFeature: StyleEnhancementFeature;
  SubTitle: SubTitle;
  Subject: Subject;
  SuggestedSalary: SuggestedSalary;
  SuggestedSalaryAverageRange: SuggestedSalaryAverageRange;
  SuggestedSalaryRange: SuggestedSalaryRange;
  Surcharge: Surcharge;
  TalentSearch: TalentSearch;
  TalentSearchATSJob: TalentSearchAtsJob;
  TalentSearchATSJobEdge: TalentSearchAtsJobEdge;
  TalentSearchATSJobs: TalentSearchAtsJobs;
  TalentSearchATSJobsConnection: TalentSearchAtsJobsConnection;
  TalentSearchATSJobsInput: TalentSearchAtsJobsInput;
  TalentSearchAccount: TalentSearchAccount;
  TalentSearchAccounts: TalentSearchAccounts;
  TalentSearchAccountsInput: TalentSearchAccountsInput;
  TalentSearchAccountsOrderBy: TalentSearchAccountsOrderBy;
  TalentSearchAddOrRemoveProfileTalentPoolsInput: TalentSearchAddOrRemoveProfileTalentPoolsInput;
  TalentSearchAddOrRemoveProfileTalentPoolsValuesInput: TalentSearchAddOrRemoveProfileTalentPoolsValuesInput;
  TalentSearchAddProfileToTalentPoolInput: TalentSearchAddProfileToTalentPoolInput;
  TalentSearchAdvertiser: TalentSearchAdvertiser;
  TalentSearchAssignedPool: TalentSearchAssignedPool;
  TalentSearchAssignedPools: TalentSearchAssignedPools;
  TalentSearchAssignedPoolsInput: TalentSearchAssignedPoolsInput;
  TalentSearchAtsHirerRelationship: TalentSearchAtsHirerRelationship;
  TalentSearchAvailableJobV2: TalentSearchAvailableJobV2;
  TalentSearchAvailableJobsInput: TalentSearchAvailableJobsInput;
  TalentSearchBudgetBalance: TalentSearchBudgetBalance;
  TalentSearchConnectionAvailability: TalentSearchConnectionAvailability;
  TalentSearchContract: TalentSearchContract;
  TalentSearchCountResult: TalentSearchCountResult;
  TalentSearchCountResultWithLabel: TalentSearchCountResultWithLabel;
  TalentSearchCountsResults: TalentSearchCountsResults;
  TalentSearchCreateTalentPoolInput: TalentSearchCreateTalentPoolInput;
  TalentSearchCriteria: TalentSearchCriteria;
  TalentSearchCriteriaInput: TalentSearchCriteriaInput;
  TalentSearchDailyUsageV2: TalentSearchDailyUsageV2;
  TalentSearchDeleteTalentPoolInput: TalentSearchDeleteTalentPoolInput;
  TalentSearchFeature: TalentSearchFeature;
  TalentSearchHirerAccountTags: TalentSearchHirerAccountTags;
  TalentSearchHirerContactDetails: TalentSearchHirerContactDetails;
  TalentSearchInteraction: TalentSearchInteraction;
  TalentSearchInteractionEdge: TalentSearchInteractionEdge;
  TalentSearchInteractionsConnection: TalentSearchInteractionsConnection;
  TalentSearchJobLocationV2: TalentSearchJobLocationV2;
  TalentSearchJobOwner: TalentSearchJobOwner;
  TalentSearchJobSalaryV2: TalentSearchJobSalaryV2;
  TalentSearchJobV2: TalentSearchJobV2;
  TalentSearchJobsInput: TalentSearchJobsInput;
  TalentSearchJobsOrderBy: TalentSearchJobsOrderBy;
  TalentSearchJobsV2: TalentSearchJobsV2;
  TalentSearchLatestInteraction: TalentSearchLatestInteraction;
  TalentSearchMarket: TalentSearchMarket;
  TalentSearchMessageDetails: TalentSearchMessageDetails;
  TalentSearchMetadataCompanySuggestInput: TalentSearchMetadataCompanySuggestInput;
  TalentSearchMetadataIndustriesInput: TalentSearchMetadataIndustriesInput;
  TalentSearchMetadataIndustry: TalentSearchMetadataIndustry;
  TalentSearchMetadataMarketsInput: TalentSearchMetadataMarketsInput;
  TalentSearchMetadataQueryValidations: TalentSearchMetadataQueryValidations;
  TalentSearchMetadataQueryValidationsInput: TalentSearchMetadataQueryValidationsInput;
  TalentSearchMetadataSubIndustry: TalentSearchMetadataSubIndustry;
  TalentSearchPool: TalentSearchPool;
  TalentSearchPoolCandidatesInputV2: TalentSearchPoolCandidatesInputV2;
  TalentSearchPoolProfile: TalentSearchPoolProfile;
  TalentSearchProfileConnectionsAvailability: TalentSearchProfileConnectionsAvailability;
  TalentSearchProfileInput: TalentSearchProfileInput;
  TalentSearchProfileResultV2: TalentSearchProfileResultV2;
  TalentSearchProfileResultsV2: TalentSearchProfileResultsV2;
  TalentSearchProfileResumeInput: TalentSearchProfileResumeInput;
  TalentSearchProfileResumeResult: TalentSearchProfileResumeResult;
  TalentSearchProfileSendJobStatus: TalentSearchProfileSendJobStatus;
  TalentSearchRecommendedCandidatesInputV2: TalentSearchRecommendedCandidatesInputV2;
  TalentSearchSavedSearch: TalentSearchSavedSearch;
  TalentSearchShareTalentPoolInput: TalentSearchShareTalentPoolInput;
  TalentSearchSimilarCandidatesByIdInputV2: TalentSearchSimilarCandidatesByIdInputV2;
  TalentSearchSimilarCandidatesResultV2: TalentSearchSimilarCandidatesResultV2;
  TalentSearchUncoupledCountForQueryInputV2: TalentSearchUncoupledCountForQueryInputV2;
  TalentSearchUncoupledSearchInputV2: TalentSearchUncoupledSearchInputV2;
  TalentSearchUpdateTalentPoolInput: TalentSearchUpdateTalentPoolInput;
  TalentSearchUsageAdvertiserDetails: TalentSearchUsageAdvertiserDetails;
  TalentSearchUsageAggregateComparisonV2: TalentSearchUsageAggregateComparisonV2;
  TalentSearchUsageAggregateInput: TalentSearchUsageAggregateInput;
  TalentSearchUsageAggregateV2: TalentSearchUsageAggregateV2;
  TalentSearchUsageConnectionInput: TalentSearchUsageConnectionInput;
  TalentSearchUsageFilters: TalentSearchUsageFilters;
  TalentSearchUsageHirerActivityV2: TalentSearchUsageHirerActivityV2;
  TalentSearchUsageHirerActivityV2Connection: TalentSearchUsageHirerActivityV2Connection;
  TalentSearchUsageHirerActivityV2Edge: TalentSearchUsageHirerActivityV2Edge;
  TalentSearchUsageSearchFacet: TalentSearchUsageSearchFacet;
  TalentSearchUsageSpendAggregate: TalentSearchUsageSpendAggregate;
  TalentSearchUsageSpendAggregateComparison: TalentSearchUsageSpendAggregateComparison;
  TalentSearchUser: TalentSearchUser;
  TalentSearchUserContext: TalentSearchUserContext;
  TalentSearchUserContextInput: TalentSearchUserContextInput;
  Tax: Tax;
  TipToImproveAds:
    | ResolversParentTypes['AddQuestions']
    | ResolversParentTypes['AddSpecificLocation']
    | ResolversParentTypes['MaximiseBranding']
    | ResolversParentTypes['RevealAdSalary']
    | ResolversParentTypes['ReviewAdDetails']
    | ResolversParentTypes['ReviewAdSummary'];
  TipsToImproveAdsResponse: TipsToImproveAdsResponse;
  TipsToImproveAdsWidgetInput: TipsToImproveAdsWidgetInput;
  TrackLocationSuggestionInput: TrackLocationSuggestionInput;
  TrackLocationSuggestionInvalidInputPayload: TrackLocationSuggestionInvalidInputPayload;
  TrackLocationSuggestionPayload:
    | ResolversParentTypes['TrackLocationSuggestionSuccessPayload']
    | ResolversParentTypes['TrackLocationSuggestionInvalidInputPayload'];
  TrackLocationSuggestionSuccessPayload: TrackLocationSuggestionSuccessPayload;
  TrendsAdTypeOption: TrendsAdTypeOption;
  TriggerActivationPayload: TriggerActivationPayload;
  UpdateBrandInput: UpdateBrandInput;
  UpdateBrandPayload:
    | ResolversParentTypes['UpdateBrandSuccessPayload']
    | ResolversParentTypes['InvalidInputPayload'];
  UpdateBrandSuccessPayload: UpdateBrandSuccessPayload;
  UpdateDraftJobExpressPayload: UpdateDraftJobExpressPayload;
  UpdateDraftJobInput: UpdateDraftJobInput;
  UpdateDraftJobPayload: UpdateDraftJobPayload;
  UpdateDraftPayload:
    | ResolversParentTypes['UpdateDraftJobExpressPayload']
    | ResolversParentTypes['UpdateDraftJobPayload'];
  UpdateInvoiceStatusInput: UpdateInvoiceStatusInput;
  UpdateInvoiceStatusPayload: UpdateInvoiceStatusPayload;
  UpdateUserDetailsResponse: UpdateUserDetailsResponse;
  UpdateUserPermissionsInput: UpdateUserPermissionsInput;
  UpdateUserPermissionsResponse: UpdateUserPermissionsResponse;
  UpgradePath: UpgradePath;
  UpgradeToPremiumInput: UpgradeToPremiumInput;
  UpgradeToPremiumPayload:
    | ResolversParentTypes['UpgradeToPremiumSuccessPayload']
    | ResolversParentTypes['JobAdNotUpgradableToPremium'];
  UpgradeToPremiumSuccessPayload: UpgradeToPremiumSuccessPayload;
  User: User;
  UserDetails: UserDetails;
  UserDetailsFailureMessage: UserDetailsFailureMessage;
  UserDetailsInput: UserDetailsInput;
  UserDetailsQueryInput: UserDetailsQueryInput;
  UserEmailDetails: UserEmailDetails;
  UserHeaderContext: UserHeaderContext;
  UserInfo: UserInfo;
  UserInfoInput: UserInfoInput;
  UserJobAccessInput: UserJobAccessInput;
  UserListResponse: UserListResponse;
  UserOption: UserOption;
  UserPermissions: UserPermissions;
  UserPermissionsInput: UserPermissionsInput;
  UserWithJobPermission: UserWithJobPermission;
  UsersParamsInput: UsersParamsInput;
  UsersWithJobPermissionInput: UsersWithJobPermissionInput;
  Verification: ResolversParentTypes['RightToWork'];
  VerificationViewField: VerificationViewField;
  ViewsInJobSearchResponse: ViewsInJobSearchResponse;
  ViewsInJobSearchWidgetInput: ViewsInJobSearchWidgetInput;
  VirtualAccount: VirtualAccount;
  WindowLocation: WindowLocation;
  WorkHistory: WorkHistory;
  WorkHistoryHighlight: WorkHistoryHighlight;
  YourCandidate: YourCandidate;
  YourCandidateEdge: YourCandidateEdge;
  YourCandidatesInput: YourCandidatesInput;
  YourCandidatesPayloadError: YourCandidatesPayloadError;
  YourCandidatesResponse:
    | ResolversParentTypes['YourCandidatesResults']
    | ResolversParentTypes['YourCandidatesPayloadError'];
  YourCandidatesResults: YourCandidatesResults;
};

export type BurstRateLimitDirectiveArgs = {
  limit?: Scalars['Int'];
  duration?: Scalars['Int'];
};

export type BurstRateLimitDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = BurstRateLimitDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type CacheControlDirectiveArgs = {
  maxAge?: Maybe<Scalars['Int']>;
  scope?: Maybe<CacheControlScope>;
  inheritMaxAge?: Maybe<Scalars['Boolean']>;
};

export type CacheControlDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = CacheControlDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type IpRateLimitDirectiveArgs = {
  limit?: Scalars['Int'];
  duration?: Scalars['Int'];
};

export type IpRateLimitDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = IpRateLimitDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type SustainedRateLimitDirectiveArgs = {
  limit?: Scalars['Int'];
  duration?: Scalars['Int'];
};

export type SustainedRateLimitDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = SustainedRateLimitDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type AccountDetailsUpdateResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccountDetailsUpdateResponse'] = ResolversParentTypes['AccountDetailsUpdateResponse'],
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountHierarchyOptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccountHierarchyOption'] = ResolversParentTypes['AccountHierarchyOption'],
> = {
  key?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountSelectionEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccountSelectionEdge'] = ResolversParentTypes['AccountSelectionEdge'],
> = {
  node?: Resolver<
    ResolversTypes['AdvertiserAccountOption'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountSelectionOptionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccountSelectionOptions'] = ResolversParentTypes['AccountSelectionOptions'],
> = {
  defaultAccount?: Resolver<
    Maybe<ResolversTypes['AdvertiserAccountOption']>,
    ParentType,
    ContextType
  >;
  accounts?: Resolver<
    Maybe<Array<ResolversTypes['AdvertiserAccountOption']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountSelectionResultsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccountSelectionResults'] = ResolversParentTypes['AccountSelectionResults'],
> = {
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  defaultAccount?: Resolver<
    Maybe<ResolversTypes['AdvertiserAccountOption']>,
    ParentType,
    ContextType
  >;
  edges?: Resolver<
    Array<ResolversTypes['AccountSelectionEdge']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountSelectionV2ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccountSelectionV2Response'] = ResolversParentTypes['AccountSelectionV2Response'],
> = {
  __resolveType: TypeResolveFn<
    'AccountSelectionResults' | 'ResponseError',
    ParentType,
    ContextType
  >;
};

export type ActionLinkResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActionLink'] = ResolversParentTypes['ActionLink'],
> = {
  viewProfile?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Actor'] = ResolversParentTypes['Actor'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActorContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ActorContext'] = ResolversParentTypes['ActorContext'],
> = {
  operatorEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  csToggleHref?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  csToggleText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  isCsView?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Ad'] = ResolversParentTypes['Ad'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productType?: Resolver<
    ResolversTypes['ProductType'],
    ParentType,
    ContextType
  >;
  adType?: Resolver<ResolversTypes['AdType'], ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['AdMetaData'], ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  hidePrice?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdBudgetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdBudget'] = ResolversParentTypes['AdBudget'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productType?: Resolver<
    ResolversTypes['ProductType'],
    ParentType,
    ContextType
  >;
  value?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  metadata?: Resolver<
    ResolversTypes['AdBudgetMetadata'],
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  ads?: Resolver<ResolversTypes['AdBudgetType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdBudgetMetadataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdBudgetMetadata'] = ResolversParentTypes['AdBudgetMetadata'],
> = {
  displayItemTitle?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  adPostingFrequency?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  percentageSavings?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >;
  discountDurationMonths?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  hasStandOutDiscount?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdBudgetProductResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdBudgetProduct'] = ResolversParentTypes['AdBudgetProduct'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<
    ResolversTypes['CatalogueProductPrice'],
    ParentType,
    ContextType
  >;
  features?: Resolver<
    Array<Maybe<ResolversTypes['CatalogueProductFeature']>>,
    ParentType,
    ContextType
  >;
  adPostingFrequency?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  adRange?: Resolver<ResolversTypes['Range'], ParentType, ContextType>;
  purchasableProducts?: Resolver<
    ResolversTypes['PurchasableProducts'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdBudgetProductListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdBudgetProductList'] = ResolversParentTypes['AdBudgetProductList'],
> = {
  items?: Resolver<
    Array<ResolversTypes['AdBudgetProduct']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdBudgetProductsForUpsellResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdBudgetProductsForUpsellResponse'] = ResolversParentTypes['AdBudgetProductsForUpsellResponse'],
> = {
  __resolveType: TypeResolveFn<
    | 'AdBudgetProductList'
    | 'ProductSetExpiredError'
    | 'ProductSetNotFoundError',
    ParentType,
    ContextType
  >;
};

export type AdBudgetResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdBudgetResponse'] = ResolversParentTypes['AdBudgetResponse'],
> = {
  adBudgets?: Resolver<
    Array<ResolversTypes['AdBudget']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdMetaDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdMetaData'] = ResolversParentTypes['AdMetaData'],
> = {
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  theme?: Resolver<
    Maybe<ResolversTypes['AdTypeTheme']>,
    ParentType,
    ContextType
  >;
  subTitle?: Resolver<
    Maybe<ResolversTypes['SubTitle']>,
    ParentType,
    ContextType
  >;
  highlightText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  features?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ProductFeature']>>>,
    ParentType,
    ContextType
  >;
  extendsAdType?: Resolver<
    Maybe<ResolversTypes['AdType']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdPerformanceReportResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdPerformanceReportResponse'] = ResolversParentTypes['AdPerformanceReportResponse'],
> = {
  hirerOriginZone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hirerId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdProductResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdProduct'] = ResolversParentTypes['AdProduct'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<
    ResolversTypes['CatalogueProductPrice'],
    ParentType,
    ContextType
  >;
  pricing?: Resolver<Maybe<ResolversTypes['Pricing']>, ParentType, ContextType>;
  features?: Resolver<
    Array<Maybe<ResolversTypes['CatalogueProductFeature']>>,
    ParentType,
    ContextType
  >;
  upgradePaths?: Resolver<
    Array<ResolversTypes['UpgradePath']>,
    ParentType,
    ContextType
  >;
  displayMetadata?: Resolver<
    Maybe<ResolversTypes['AdProductDisplayMetadata']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdProductDisplayMetadataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdProductDisplayMetadata'] = ResolversParentTypes['AdProductDisplayMetadata'],
> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tagline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sellingPoints?: Resolver<
    Maybe<Array<ResolversTypes['SellingPoint']>>,
    ParentType,
    ContextType
  >;
  image?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
  priceDescription?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  qualifiedName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  redirectUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdProductSetResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdProductSetResponse'] = ResolversParentTypes['AdProductSetResponse'],
> = {
  __resolveType: TypeResolveFn<
    | 'ProductList'
    | 'ProductSetExpiredError'
    | 'ProductSetNotFoundError'
    | 'DisplayMetadataError',
    ParentType,
    ContextType
  >;
};

export type AdProductUpgradeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdProductUpgrade'] = ResolversParentTypes['AdProductUpgrade'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<
    ResolversTypes['CatalogueProductPrice'],
    ParentType,
    ContextType
  >;
  features?: Resolver<
    Array<Maybe<ResolversTypes['CatalogueProductFeature']>>,
    ParentType,
    ContextType
  >;
  displayMetadata?: Resolver<
    Maybe<ResolversTypes['AdProductDisplayMetadata']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdRatingLabelsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdRatingLabels'] = ResolversParentTypes['AdRatingLabels'],
> = {
  groupLabel?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  locationLabel?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdRatingResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdRatingResponse'] = ResolversParentTypes['AdRatingResponse'],
> = {
  adRatingConfidence?: Resolver<
    Maybe<ResolversTypes['AnalyticsAdRatingConfidence']>,
    ParentType,
    ContextType
  >;
  adRatingScore?: Resolver<
    Maybe<ResolversTypes['AnalyticsAdRatingScore']>,
    ParentType,
    ContextType
  >;
  applications?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  applicationsBenchmarkHigh?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  applicationsBenchmarkLow?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  benchmarkLocationLabel?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  benchmarkScenario?: Resolver<
    Maybe<ResolversTypes['AnalyticsBenchmarkScenario']>,
    ParentType,
    ContextType
  >;
  classificationName?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  incompleteApplications?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  isLinkOutAd?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  jobCreatedTimestampUTC?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType
  >;
  lastUpdatedTimestampUTC?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType
  >;
  normalisedRoleTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  subClassificationName?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdRatingTypeOptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdRatingTypeOption'] = ResolversParentTypes['AdRatingTypeOption'],
> = {
  key?: Resolver<
    ResolversTypes['FiltersAdRatingType'],
    ParentType,
    ContextType
  >;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdSpendFeatureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdSpendFeature'] = ResolversParentTypes['AdSpendFeature'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  allowance?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdStatusOptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdStatusOption'] = ResolversParentTypes['AdStatusOption'],
> = {
  key?: Resolver<ResolversTypes['AdUsageAdStatus'], ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdTipsOptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdTipsOption'] = ResolversParentTypes['AdTipsOption'],
> = {
  key?: Resolver<ResolversTypes['AdTipsType'], ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdTypeAverageCostResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdTypeAverageCost'] = ResolversParentTypes['AdTypeAverageCost'],
> = {
  type?: Resolver<ResolversTypes['AdUsageAdType'], ParentType, ContextType>;
  cost?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdTypeDiscountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdTypeDiscount'] = ResolversParentTypes['AdTypeDiscount'],
> = {
  adType?: Resolver<ResolversTypes['AdType'], ParentType, ContextType>;
  discount?: Resolver<ResolversTypes['Discount'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdTypeDiscountsFeatureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdTypeDiscountsFeature'] = ResolversParentTypes['AdTypeDiscountsFeature'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  discounts?: Resolver<
    Array<ResolversTypes['AdTypeDiscount']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdTypeOptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdTypeOption'] = ResolversParentTypes['AdTypeOption'],
> = {
  key?: Resolver<ResolversTypes['AdUsageAdType'], ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdTypePostCountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdTypePostCount'] = ResolversParentTypes['AdTypePostCount'],
> = {
  type?: Resolver<ResolversTypes['AdUsageAdType'], ParentType, ContextType>;
  adsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdUsageAdTypeTrendResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdUsageAdTypeTrendResponse'] = ResolversParentTypes['AdUsageAdTypeTrendResponse'],
> = {
  daily?: Resolver<
    Array<ResolversTypes['AdUsageTrendDaily']>,
    ParentType,
    ContextType
  >;
  monthly?: Resolver<
    Array<ResolversTypes['AdUsageTrendMonthly']>,
    ParentType,
    ContextType
  >;
  startDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  endDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalAdTypes?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TrendsAdTypeOption']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdUsageConversionResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdUsageConversionResponse'] = ResolversParentTypes['AdUsageConversionResponse'],
> = {
  jobSearchViews?: Resolver<
    ResolversTypes['ConversionRateData'],
    ParentType,
    ContextType
  >;
  adClicks?: Resolver<
    ResolversTypes['ConversionRateData'],
    ParentType,
    ContextType
  >;
  applicationsStarted?: Resolver<
    ResolversTypes['ConversionRateData'],
    ParentType,
    ContextType
  >;
  applicationsCompleted?: Resolver<
    ResolversTypes['ConversionRateData'],
    ParentType,
    ContextType
  >;
  adsWithoutRatingPercentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  linkOutAds?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdUsageFiltersResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdUsageFiltersResponse'] = ResolversParentTypes['AdUsageFiltersResponse'],
> = {
  locations?: Resolver<
    Array<Maybe<ResolversTypes['LocationOption']>>,
    ParentType,
    ContextType
  >;
  classifications?: Resolver<
    Array<Maybe<ResolversTypes['ClassificationOption']>>,
    ParentType,
    ContextType
  >;
  adIds?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  adTypes?: Resolver<
    Array<Maybe<ResolversTypes['AdTypeOption']>>,
    ParentType,
    ContextType
  >;
  jobTitles?: Resolver<
    Array<Maybe<ResolversTypes['JobTitleOption']>>,
    ParentType,
    ContextType
  >;
  users?: Resolver<
    Array<Maybe<ResolversTypes['UserOption']>>,
    ParentType,
    ContextType
  >;
  adRatings?: Resolver<
    Array<Maybe<ResolversTypes['AdRatingTypeOption']>>,
    ParentType,
    ContextType
  >;
  accountHierarchies?: Resolver<
    Array<Maybe<ResolversTypes['AccountHierarchyOption']>>,
    ParentType,
    ContextType
  >;
  adStatus?: Resolver<
    Array<Maybe<ResolversTypes['AdStatusOption']>>,
    ParentType,
    ContextType
  >;
  tips?: Resolver<
    Array<Maybe<ResolversTypes['AdTipsOption']>>,
    ParentType,
    ContextType
  >;
  reposts?: Resolver<
    Array<Maybe<ResolversTypes['RepostOption']>>,
    ParentType,
    ContextType
  >;
  isChildAccount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdUsageSpendResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdUsageSpendResponse'] = ResolversParentTypes['AdUsageSpendResponse'],
> = {
  totalPostByAdTypes?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['AdTypePostCount']>>>,
    ParentType,
    ContextType
  >;
  totalSpend?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >;
  averageCostByAdTypes?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['AdTypeAverageCost']>>>,
    ParentType,
    ContextType
  >;
  averageApplications?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  averageCostPerAd?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >;
  hidePrice?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  totalApplications?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  averageCostPerApplication?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >;
  repostedAdsCount?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  repostedAdsSpend?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >;
  otherCurrencyAdsCount?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  totalAds?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdUsageTrendClassificationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdUsageTrendClassification'] = ResolversParentTypes['AdUsageTrendClassification'],
> = {
  classificationName?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  totalAds?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  percentage?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdUsageTrendClassificationsAndUsersResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdUsageTrendClassificationsAndUsersResponse'] = ResolversParentTypes['AdUsageTrendClassificationsAndUsersResponse'],
> = {
  classifications?: Resolver<
    Array<ResolversTypes['AdUsageTrendClassification']>,
    ParentType,
    ContextType
  >;
  users?: Resolver<
    Array<ResolversTypes['AdUsageTrendUser']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdUsageTrendDailyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdUsageTrendDaily'] = ResolversParentTypes['AdUsageTrendDaily'],
> = {
  items?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TrendsAdTypeOption']>>>,
    ParentType,
    ContextType
  >;
  day?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdUsageTrendMonthlyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdUsageTrendMonthly'] = ResolversParentTypes['AdUsageTrendMonthly'],
> = {
  items?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TrendsAdTypeOption']>>>,
    ParentType,
    ContextType
  >;
  month?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdUsageTrendUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdUsageTrendUser'] = ResolversParentTypes['AdUsageTrendUser'],
> = {
  userFullname?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  totalAds?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  percentage?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddQuestionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddQuestions'] = ResolversParentTypes['AddQuestions'],
> = {
  type?: Resolver<
    ResolversTypes['TipsToImproveAdsType'],
    ParentType,
    ContextType
  >;
  adCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  questions?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddSpecificLocationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddSpecificLocation'] = ResolversParentTypes['AddSpecificLocation'],
> = {
  type?: Resolver<
    ResolversTypes['TipsToImproveAdsType'],
    ParentType,
    ContextType
  >;
  adCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  specificLocation?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  nonSpecificLocation?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddUserToAccountResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddUserToAccountResponse'] = ResolversParentTypes['AddUserToAccountResponse'],
> = {
  statusId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdDateUtc?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastDailyCandidateMailDateUtc?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  lastWeeklyCandidateMailDateUtc?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  links?: Resolver<Array<ResolversTypes['Link']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdvertiserAccountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdvertiserAccount'] = ResolversParentTypes['AdvertiserAccount'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  advertiserId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  seekAdvertiserId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isOnCreditHold?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isApproved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  seekBillingAccountId?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  agencyAdvertiserId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  hasPremiumTalentSearch?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  accessRightStatus?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  role?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hasPostalAddress?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdvertiserAccountOptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdvertiserAccountOption'] = ResolversParentTypes['AdvertiserAccountOption'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  advertiserId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  seekId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  billingId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  childAccounts?: Resolver<
    Array<ResolversTypes['AdvertiserAccountOption']>,
    ParentType,
    ContextType
  >;
  hirerAccountStatus?: Resolver<
    ResolversTypes['AdvertiserStatus'],
    ParentType,
    ContextType
  >;
  accessRightStatus?: Resolver<
    ResolversTypes['AccessRightStatus'],
    ParentType,
    ContextType
  >;
  role?: Resolver<ResolversTypes['Role'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdvertiserHeaderContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdvertiserHeaderContext'] = ResolversParentTypes['AdvertiserHeaderContext'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  billingId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  permissions?: Resolver<
    Array<ResolversTypes['Permission']>,
    ParentType,
    ContextType
  >;
  showOnCreditHoldMessage?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  switchAccountsHref?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  hasMultipleAccounts?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  isActivationPending?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  isAgency?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AhpraRegistrationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AhpraRegistration'] = ResolversParentTypes['AhpraRegistration'],
> = {
  profession?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  specialty?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnalyticsAdRatingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AnalyticsAdRating'] = ResolversParentTypes['AnalyticsAdRating'],
> = {
  status?: Resolver<
    ResolversTypes['AnalyticsAdRatingStatus'],
    ParentType,
    ContextType
  >;
  score?: Resolver<
    Maybe<ResolversTypes['AnalyticsAdRatingScore']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnalyticsLatestJobsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AnalyticsLatestJobs'] = ResolversParentTypes['AnalyticsLatestJobs'],
> = {
  jobCreatedTimestampUTC?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType
  >;
  jobId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  jobLocationLabel?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  jobTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userFullName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnalyticsMarketRoleInsightsJobTitlesResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AnalyticsMarketRoleInsightsJobTitlesResult'] = ResolversParentTypes['AnalyticsMarketRoleInsightsJobTitlesResult'],
> = {
  results?: Resolver<
    Maybe<
      Array<Maybe<ResolversTypes['AnalyticsMarketRoleInsightsSuggestion']>>
    >,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnalyticsMarketRoleInsightsLocationsResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AnalyticsMarketRoleInsightsLocationsResult'] = ResolversParentTypes['AnalyticsMarketRoleInsightsLocationsResult'],
> = {
  results?: Resolver<
    Maybe<
      Array<Maybe<ResolversTypes['AnalyticsMarketRoleInsightsSuggestion']>>
    >,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnalyticsMarketRoleInsightsSearchResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AnalyticsMarketRoleInsightsSearchResult'] = ResolversParentTypes['AnalyticsMarketRoleInsightsSearchResult'],
> = {
  results?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnalyticsMarketRoleInsightsSuggestionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AnalyticsMarketRoleInsightsSuggestion'] = ResolversParentTypes['AnalyticsMarketRoleInsightsSuggestion'],
> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  parentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnalyticsTalentSearchUsageAccountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AnalyticsTalentSearchUsageAccount'] = ResolversParentTypes['AnalyticsTalentSearchUsageAccount'],
> = {
  key?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnalyticsTalentSearchUsageCreditsUsageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AnalyticsTalentSearchUsageCreditsUsage'] = ResolversParentTypes['AnalyticsTalentSearchUsageCreditsUsage'],
> = {
  userHash?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  userFullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  profileSearchCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  profileViewCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cvDownloadCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sendMessageCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sendJobCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cvDownloadCredits?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sendMessageCredits?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sendJobCredits?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalCredits?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnalyticsTalentSearchUsageCreditsUsageListResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AnalyticsTalentSearchUsageCreditsUsageListResponse'] = ResolversParentTypes['AnalyticsTalentSearchUsageCreditsUsageListResponse'],
> = {
  userCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  creditsUsageList?: Resolver<
    Array<ResolversTypes['AnalyticsTalentSearchUsageCreditsUsage']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnalyticsTalentSearchUsageFiltersResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AnalyticsTalentSearchUsageFiltersResponse'] = ResolversParentTypes['AnalyticsTalentSearchUsageFiltersResponse'],
> = {
  users?: Resolver<
    Array<Maybe<ResolversTypes['AnalyticsTalentSearchUsageUser']>>,
    ParentType,
    ContextType
  >;
  accounts?: Resolver<
    Array<Maybe<ResolversTypes['AnalyticsTalentSearchUsageAccount']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnalyticsTalentSearchUsageLastUpdatedResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AnalyticsTalentSearchUsageLastUpdatedResponse'] = ResolversParentTypes['AnalyticsTalentSearchUsageLastUpdatedResponse'],
> = {
  lastUpdated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnalyticsTalentSearchUsageUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AnalyticsTalentSearchUsageUser'] = ResolversParentTypes['AnalyticsTalentSearchUsageUser'],
> = {
  key?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationPerformanceResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ApplicationPerformanceResponse'] = ResolversParentTypes['ApplicationPerformanceResponse'],
> = {
  applications?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isLinkOutAd?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  adRatingConfidence?: Resolver<
    ResolversTypes['AnalyticsAdRatingConfidence'],
    ParentType,
    ContextType
  >;
  mobileViewsInPercentage?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >;
  similarAdsInPercentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  similarAdsAverage?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  hasAdRating?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssociatedActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AssociatedAction'] = ResolversParentTypes['AssociatedAction'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  job?: Resolver<
    Maybe<ResolversTypes['InteractedJob']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssociatedPartyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AssociatedParty'] = ResolversParentTypes['AssociatedParty'],
> = {
  organizationName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  firstName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  formattedName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillableApplicantsDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BillableApplicantsData'] = ResolversParentTypes['BillableApplicantsData'],
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillableApplicantsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BillableApplicantsResponse'] = ResolversParentTypes['BillableApplicantsResponse'],
> = {
  __resolveType: TypeResolveFn<
    'BillableApplicantsResponseSuccess' | 'ResponseError',
    ParentType,
    ContextType
  >;
};

export type BillableApplicantsResponseSuccessResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BillableApplicantsResponseSuccess'] = ResolversParentTypes['BillableApplicantsResponseSuccess'],
> = {
  data?: Resolver<
    Maybe<ResolversTypes['BillableApplicantsData']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingContactResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BillingContact'] = ResolversParentTypes['BillingContact'],
> = {
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BrandResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Brand'] = ResolversParentTypes['Brand'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  legacyId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  owner?: Resolver<ResolversTypes['BrandOwner'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  assets?: Resolver<ResolversTypes['BrandAssets'], ParentType, ContextType>;
  state?: Resolver<
    Maybe<ResolversTypes['BrandState']>,
    ParentType,
    ContextType
  >;
  isDefault?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  isLiveOnAd?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  createdOn?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BrandAssetStrategiesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BrandAssetStrategies'] = ResolversParentTypes['BrandAssetStrategies'],
> = {
  __resolveType: TypeResolveFn<
    'LogoStrategies' | 'CoverStrategies',
    ParentType,
    ContextType
  >;
};

export type BrandAssetTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BrandAssetType'] = ResolversParentTypes['BrandAssetType'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  strategies?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BrandAssetsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BrandAssets'] = ResolversParentTypes['BrandAssets'],
> = {
  logo?: Resolver<ResolversTypes['BrandAssetType'], ParentType, ContextType>;
  cover?: Resolver<
    Maybe<ResolversTypes['BrandAssetType']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BrandOwnerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BrandOwner'] = ResolversParentTypes['BrandOwner'],
> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<
    Maybe<ResolversTypes['BrandOwnerType']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BrandedAdResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BrandedAd'] = ResolversParentTypes['BrandedAd'],
> = {
  type?: Resolver<
    ResolversTypes['RecommendationType'],
    ParentType,
    ContextType
  >;
  bulletPointsUsed?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  logoUsed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  coverImageUsed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BrandedFeaturesFeatureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BrandedFeaturesFeature'] = ResolversParentTypes['BrandedFeaturesFeature'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BrandingAssetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BrandingAsset'] = ResolversParentTypes['BrandingAsset'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  strategies?: Resolver<
    ResolversTypes['BrandAssetStrategies'],
    ParentType,
    ContextType
  >;
  isDeletable?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  isLiveOnAd?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  deleteWarning?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  isOnDefaultBrand?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  numberOfBrands?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  numberOfLiveAds?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BrandingFeatureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BrandingFeature'] = ResolversParentTypes['BrandingFeature'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hasLogo?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasCoverImage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  bulletPoints?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  borderForSearchListing?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BulletPointsFeatureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BulletPointsFeature'] = ResolversParentTypes['BulletPointsFeature'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CandidateDemandResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CandidateDemandResponse'] = ResolversParentTypes['CandidateDemandResponse'],
> = {
  comparisonPeriod?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['MarketInsightMonth']>>>,
    ParentType,
    ContextType
  >;
  currentPeriod?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['MarketInsightMonth']>>>,
    ParentType,
    ContextType
  >;
  hasCandidateDemand?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CandidateSupplyResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CandidateSupplyResponse'] = ResolversParentTypes['CandidateSupplyResponse'],
> = {
  comparisonPeriod?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['MarketInsightMonth']>>>,
    ParentType,
    ContextType
  >;
  currentPeriod?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['MarketInsightMonth']>>>,
    ParentType,
    ContextType
  >;
  hasCandidateSupply?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CappedUnitPriceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CappedUnitPrice'] = ResolversParentTypes['CappedUnitPrice'],
> = {
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  maxBillableUnits?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  unitPrice?: Resolver<
    ResolversTypes['CatalogueProductPrice'],
    ParentType,
    ContextType
  >;
  cappedPrice?: Resolver<
    ResolversTypes['CatalogueProductPrice'],
    ParentType,
    ContextType
  >;
  metadata?: Resolver<
    ResolversTypes['CappedUnitPriceMetadata'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CappedUnitPriceMetadataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CappedUnitPriceMetadata'] = ResolversParentTypes['CappedUnitPriceMetadata'],
> = {
  unitType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CatalogueProductResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CatalogueProduct'] = ResolversParentTypes['CatalogueProduct'],
> = {
  __resolveType: TypeResolveFn<
    'AdBudgetProduct' | 'AdProduct' | 'AdProductUpgrade',
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<
    ResolversTypes['CatalogueProductPrice'],
    ParentType,
    ContextType
  >;
  features?: Resolver<
    Array<Maybe<ResolversTypes['CatalogueProductFeature']>>,
    ParentType,
    ContextType
  >;
};

export type CatalogueProductFeatureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CatalogueProductFeature'] = ResolversParentTypes['CatalogueProductFeature'],
> = {
  __resolveType: TypeResolveFn<
    | 'AdSpendFeature'
    | 'AdTypeDiscountsFeature'
    | 'BrandedFeaturesFeature'
    | 'BrandingFeature'
    | 'BulletPointsFeature'
    | 'DurationFeature'
    | 'JobAdEditFeature'
    | 'PerApplicantBillingFeature'
    | 'PerUnitBillingFeature'
    | 'RoleRequirementsMandatoryQuestionsFeature'
    | 'SponsoredSlotFeature'
    | 'StyleEnhancementFeature',
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type CatalogueProductPriceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CatalogueProductPrice'] = ResolversParentTypes['CatalogueProductPrice'],
> = {
  __resolveType: TypeResolveFn<
    'Price' | 'HiddenPrice',
    ParentType,
    ContextType
  >;
};

export type CategoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Category'] = ResolversParentTypes['Category'],
> = {
  categoryId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  categoryName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subCategoryId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  subCategoryName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategorySuggestionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CategorySuggestion'] = ResolversParentTypes['CategorySuggestion'],
> = {
  category?: Resolver<ResolversTypes['Category'], ParentType, ContextType>;
  probability?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChildAccountBannerResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChildAccountBannerResponse'] = ResolversParentTypes['ChildAccountBannerResponse'],
> = {
  isChild?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClaimResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Claim'] = ResolversParentTypes['Claim'],
> = {
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassificationOptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassificationOption'] = ResolversParentTypes['ClassificationOption'],
> = {
  key?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  subClassifications?: Resolver<
    Maybe<Array<ResolversTypes['ClassificationOption']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClicksOnYourAdResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClicksOnYourAdResponse'] = ResolversParentTypes['ClicksOnYourAdResponse'],
> = {
  adRatingConfidence?: Resolver<
    Maybe<ResolversTypes['AnalyticsAdRatingConfidence']>,
    ParentType,
    ContextType
  >;
  appConversionInPercentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  hasAdRating?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isLinkOutAd?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  mobileClicksInPercentage?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >;
  similarAdsAverage?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  similarAdsInPercentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalClicks?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyAddressResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyAddress'] = ResolversParentTypes['CompanyAddress'],
> = {
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyProfileResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyProfile'] = ResolversParentTypes['CompanyProfile'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  branding?: Resolver<
    Maybe<ResolversTypes['CompanyProfileBranding']>,
    ParentType,
    ContextType
  >;
  overview?: Resolver<
    Maybe<ResolversTypes['CompanyProfileOverview']>,
    ParentType,
    ContextType
  >;
  missionStatement?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  featured?: Resolver<
    Maybe<ResolversTypes['CompanyProfileFeatured']>,
    ParentType,
    ContextType
  >;
  awards?: Resolver<
    Maybe<Array<ResolversTypes['CompanyProfileAward']>>,
    ParentType,
    ContextType
  >;
  cultureAndValues?: Resolver<
    Maybe<ResolversTypes['CompanyProfileCultureAndValues']>,
    ParentType,
    ContextType
  >;
  customStatement?: Resolver<
    Maybe<ResolversTypes['CompanyProfileCustomStatement']>,
    ParentType,
    ContextType
  >;
  perksAndBenefits?: Resolver<
    Maybe<Array<ResolversTypes['CompanyProfilePerksAndBenefits']>>,
    ParentType,
    ContextType
  >;
  gallery?: Resolver<
    Maybe<Array<ResolversTypes['CompanyProfilePhoto']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyProfileAwardResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyProfileAward'] = ResolversParentTypes['CompanyProfileAward'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  year?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  imageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyProfileBrandingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyProfileBranding'] = ResolversParentTypes['CompanyProfileBranding'],
> = {
  brandId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  cover?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyProfileCultureAndValuesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyProfileCultureAndValues'] = ResolversParentTypes['CompanyProfileCultureAndValues'],
> = {
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  values?: Resolver<
    Maybe<Array<ResolversTypes['CompanyProfileCultureValue']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyProfileCultureValueResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyProfileCultureValue'] = ResolversParentTypes['CompanyProfileCultureValue'],
> = {
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyProfileCustomStatementResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyProfileCustomStatement'] = ResolversParentTypes['CompanyProfileCustomStatement'],
> = {
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyProfileFeaturedResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyProfileFeatured'] = ResolversParentTypes['CompanyProfileFeatured'],
> = {
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  videoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyProfileIdsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyProfileIds'] = ResolversParentTypes['CompanyProfileIds'],
> = {
  companyIds?: Resolver<
    Maybe<Array<ResolversTypes['ID']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyProfileOverviewResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyProfileOverview'] = ResolversParentTypes['CompanyProfileOverview'],
> = {
  website?: Resolver<
    Maybe<ResolversTypes['CompanyProfileWebsite']>,
    ParentType,
    ContextType
  >;
  industry?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  size?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryLocation?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  specialities?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  description?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyProfilePerksAndBenefitsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyProfilePerksAndBenefits'] = ResolversParentTypes['CompanyProfilePerksAndBenefits'],
> = {
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyProfilePhotoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyProfilePhoto'] = ResolversParentTypes['CompanyProfilePhoto'],
> = {
  link?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyProfileWebsiteResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyProfileWebsite'] = ResolversParentTypes['CompanyProfileWebsite'],
> = {
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConfirmInvoiceOrderPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConfirmInvoiceOrderPayload'] = ResolversParentTypes['ConfirmInvoiceOrderPayload'],
> = {
  __resolveType: TypeResolveFn<
    | 'InvoiceOrderSuccessPayload'
    | 'InvoiceOrderNotFoundPayload'
    | 'OrderNotFoundError'
    | 'OrderExpiredError'
    | 'OrderInvalidError'
    | 'PromotionInvalidError',
    ParentType,
    ContextType
  >;
};

export type ConnectionStatusPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConnectionStatusPayload'] = ResolversParentTypes['ConnectionStatusPayload'],
> = {
  connectionStatus?: Resolver<
    ResolversTypes['TalentSearchConnectionStatus'],
    ParentType,
    ContextType
  >;
  creditsRedeemedDateUtc?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  nextAvailableDateUtc?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Contract'] = ResolversParentTypes['Contract'],
> = {
  expiry?: Resolver<ResolversTypes['Expiry'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['ContractValue'], ParentType, ContextType>;
  current?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  adDiscounts?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['ContractAdDiscount']>>>,
    ParentType,
    ContextType
  >;
  validFromDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractAdDiscountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContractAdDiscount'] = ResolversParentTypes['ContractAdDiscount'],
> = {
  adType?: Resolver<ResolversTypes['AdType'], ParentType, ContextType>;
  minimumPercentageSavings?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractBalanceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContractBalance'] = ResolversParentTypes['ContractBalance'],
> = {
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  contractType?: Resolver<
    ResolversTypes['ContractType'],
    ParentType,
    ContextType
  >;
  dollarBased?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  adType?: Resolver<ResolversTypes['AdType'], ParentType, ContextType>;
  adTypeDescription?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  contracts?: Resolver<
    Maybe<Array<ResolversTypes['Contract']>>,
    ParentType,
    ContextType
  >;
  totalValue?: Resolver<
    ResolversTypes['ContractValue'],
    ParentType,
    ContextType
  >;
  lowBalance?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  recommendUpSell?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  hideBalance?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractDollarValueResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContractDollarValue'] = ResolversParentTypes['ContractDollarValue'],
> = {
  remaining?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  original?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractUnitValueResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContractUnitValue'] = ResolversParentTypes['ContractUnitValue'],
> = {
  remaining?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  original?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractValueResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContractValue'] = ResolversParentTypes['ContractValue'],
> = {
  dollars?: Resolver<
    ResolversTypes['ContractDollarValue'],
    ParentType,
    ContextType
  >;
  units?: Resolver<
    Maybe<ResolversTypes['ContractUnitValue']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConversionRateDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConversionRateData'] = ResolversParentTypes['ConversionRateData'],
> = {
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  similarAdAverage?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  adConversionRate?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  similarAdConversionRate?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  mobileRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Country'] = ResolversParentTypes['Country'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  countryCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountrySelectionOptionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CountrySelectionOptions'] = ResolversParentTypes['CountrySelectionOptions'],
> = {
  options?: Resolver<Array<ResolversTypes['Country']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CoverStrategiesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CoverStrategies'] = ResolversParentTypes['CoverStrategies'],
> = {
  jdpCoverThumbnail?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  jdpCover?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Create2c2pPaymentIntentPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Create2c2pPaymentIntentPayload'] = ResolversParentTypes['Create2c2pPaymentIntentPayload'],
> = {
  __resolveType: TypeResolveFn<
    | 'PaymentIntent2C2PPayload'
    | 'PaymentMethodError'
    | 'PaymentIntentChargeError'
    | 'OrderNotFoundError'
    | 'OrderExpiredError'
    | 'OrderInvalidError'
    | 'PromotionInvalidError'
    | 'InvoiceError',
    ParentType,
    ContextType
  >;
};

export type CreateBrandPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateBrandPayload'] = ResolversParentTypes['CreateBrandPayload'],
> = {
  __resolveType: TypeResolveFn<
    'CreateBrandSuccessPayload' | 'InvalidInputPayload',
    ParentType,
    ContextType
  >;
};

export type CreateBrandSuccessPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateBrandSuccessPayload'] = ResolversParentTypes['CreateBrandSuccessPayload'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  legacyId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  owner?: Resolver<ResolversTypes['BrandOwner'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  assets?: Resolver<ResolversTypes['BrandAssets'], ParentType, ContextType>;
  state?: Resolver<
    Maybe<ResolversTypes['BrandState']>,
    ParentType,
    ContextType
  >;
  isDefault?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  createdOn?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateCustomQuestionErrorResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateCustomQuestionErrorResult'] = ResolversParentTypes['CreateCustomQuestionErrorResult'],
> = {
  reason?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateCustomQuestionOutputUnionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateCustomQuestionOutputUnion'] = ResolversParentTypes['CreateCustomQuestionOutputUnion'],
> = {
  __resolveType: TypeResolveFn<
    'CreateCustomQuestionResult' | 'CreateCustomQuestionErrorResult',
    ParentType,
    ContextType
  >;
};

export type CreateCustomQuestionResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateCustomQuestionResult'] = ResolversParentTypes['CreateCustomQuestionResult'],
> = {
  question?: Resolver<
    ResolversTypes['OutputCustomQuestion'],
    ParentType,
    ContextType
  >;
  validationFailures?: Resolver<
    Array<ResolversTypes['CustomQuestionValidationFailure']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateDraftJobPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateDraftJobPayload'] = ResolversParentTypes['CreateDraftJobPayload'],
> = {
  draftId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateJobResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateJobResponse'] = ResolversParentTypes['CreateJobResponse'],
> = {
  jobId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  revisionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  idempotencyKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateOrderPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateOrderPayload'] = ResolversParentTypes['CreateOrderPayload'],
> = {
  order?: Resolver<Maybe<ResolversTypes['Order']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateOrderSuccessPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateOrderSuccessPayload'] = ResolversParentTypes['CreateOrderSuccessPayload'],
> = {
  order?: Resolver<Maybe<ResolversTypes['Order']>, ParentType, ContextType>;
  promotion?: Resolver<
    Maybe<ResolversTypes['Promotion']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateOrderWithPromotionPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateOrderWithPromotionPayload'] = ResolversParentTypes['CreateOrderWithPromotionPayload'],
> = {
  __resolveType: TypeResolveFn<
    'CreateOrderSuccessPayload' | 'PromotionInvalidError',
    ParentType,
    ContextType
  >;
};

export type CreatePaymentIntentPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreatePaymentIntentPayload'] = ResolversParentTypes['CreatePaymentIntentPayload'],
> = {
  __resolveType: TypeResolveFn<
    | 'PaymentIntentPayload'
    | 'OrderNotFoundError'
    | 'OrderExpiredError'
    | 'OrderInvalidError'
    | 'PromotionInvalidError',
    ParentType,
    ContextType
  >;
};

export type CreateProductSetForAdEditingPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateProductSetForAdEditingPayload'] = ResolversParentTypes['CreateProductSetForAdEditingPayload'],
> = {
  productSet?: Resolver<ResolversTypes['ProductSet'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateProductSetForAdPostingPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateProductSetForAdPostingPayload'] = ResolversParentTypes['CreateProductSetForAdPostingPayload'],
> = {
  productSet?: Resolver<ResolversTypes['ProductSet'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateProductSetForAdRefreshPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateProductSetForAdRefreshPayload'] = ResolversParentTypes['CreateProductSetForAdRefreshPayload'],
> = {
  productSet?: Resolver<ResolversTypes['ProductSet'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateProductSetForBudgetsPurchaseForHirerPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateProductSetForBudgetsPurchaseForHirerPayload'] = ResolversParentTypes['CreateProductSetForBudgetsPurchaseForHirerPayload'],
> = {
  productSet?: Resolver<ResolversTypes['ProductSet'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateProductSetForBudgetsPurchaseForVisitorPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateProductSetForBudgetsPurchaseForVisitorPayload'] = ResolversParentTypes['CreateProductSetForBudgetsPurchaseForVisitorPayload'],
> = {
  productSet?: Resolver<ResolversTypes['ProductSet'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateProductSetForMultiLocationPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateProductSetForMultiLocationPayload'] = ResolversParentTypes['CreateProductSetForMultiLocationPayload'],
> = {
  productSet?: Resolver<ResolversTypes['ProductSet'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateProductSetForPriceCheckerPreviewPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateProductSetForPriceCheckerPreviewPayload'] = ResolversParentTypes['CreateProductSetForPriceCheckerPreviewPayload'],
> = {
  productSet?: Resolver<ResolversTypes['ProductSet'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateQuestionnaireResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateQuestionnaireResponse'] = ResolversParentTypes['CreateQuestionnaireResponse'],
> = {
  questionnaireId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  questionnaireUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateSavedSearchPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateSavedSearchPayload'] = ResolversParentTypes['CreateSavedSearchPayload'],
> = {
  savedSearch?: Resolver<
    Maybe<ResolversTypes['TalentSearchSavedSearch']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateStripePaymentIntentPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateStripePaymentIntentPayload'] = ResolversParentTypes['CreateStripePaymentIntentPayload'],
> = {
  __resolveType: TypeResolveFn<
    | 'StripePaymentIntentPayload'
    | 'PaymentMethodError'
    | 'OrderNotFoundError'
    | 'OrderExpiredError'
    | 'OrderInvalidError'
    | 'PromotionInvalidError'
    | 'InvoiceError',
    ParentType,
    ContextType
  >;
};

export type CreditCardResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreditCard'] = ResolversParentTypes['CreditCard'],
> = {
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastDigits?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  brand?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  expiryMonth?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  expiryYear?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrentAdvertiserContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CurrentAdvertiserContext'] = ResolversParentTypes['CurrentAdvertiserContext'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  advertiserId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  seekId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  billingId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  permissions?: Resolver<
    Array<ResolversTypes['Permission']>,
    ParentType,
    ContextType
  >;
  isAgency?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  claims?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Claim']>>>,
    ParentType,
    ContextType
  >;
  profileProperties?: Resolver<
    Maybe<ResolversTypes['ProfileProperties']>,
    ParentType,
    ContextType
  >;
  role?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  talentSearchAccess?: Resolver<
    Maybe<ResolversTypes['TalentSearchAccess']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<
    ResolversTypes['AdvertiserStatus'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrentAdvertiserResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CurrentAdvertiserResponse'] = ResolversParentTypes['CurrentAdvertiserResponse'],
> = {
  __resolveType: TypeResolveFn<
    'CurrentAdvertiserContext' | 'MissingAdvertiserIdClaimError',
    ParentType,
    ContextType
  >;
};

export type CurrentUserContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CurrentUserContext'] = ResolversParentTypes['CurrentUserContext'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrentUserResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CurrentUserResponse'] = ResolversParentTypes['CurrentUserResponse'],
> = {
  __resolveType: TypeResolveFn<
    'CurrentUserContext' | 'MissingUserIdClaimError',
    ParentType,
    ContextType
  >;
};

export type CustomQuestionValidationFailureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomQuestionValidationFailure'] = ResolversParentTypes['CustomQuestionValidationFailure'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export type DateAxisLabelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DateAxisLabel'] = ResolversParentTypes['DateAxisLabel'],
> = {
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateTimeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type DeleteBrandingAssetPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteBrandingAssetPayload'] = ResolversParentTypes['DeleteBrandingAssetPayload'],
> = {
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  successMessage?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteDraftJobPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteDraftJobPayload'] = ResolversParentTypes['DeleteDraftJobPayload'],
> = {
  successful?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteSavedCreditCardPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteSavedCreditCardPayload'] = ResolversParentTypes['DeleteSavedCreditCardPayload'],
> = {
  successful?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteSavedSearchPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteSavedSearchPayload'] = ResolversParentTypes['DeleteSavedSearchPayload'],
> = {
  deletedCriteriaHash?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteStripeSavedCreditCardPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeleteStripeSavedCreditCardPayload'] = ResolversParentTypes['DeleteStripeSavedCreditCardPayload'],
> = {
  successful?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DisableMessageTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DisableMessageType'] = ResolversParentTypes['DisableMessageType'],
> = {
  en?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DiscountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Discount'] = ResolversParentTypes['Discount'],
> = {
  discountPercentage?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >;
  discountAmount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DisplayMetadataErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DisplayMetadataError'] = ResolversParentTypes['DisplayMetadataError'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DraftJobResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DraftJob'] = ResolversParentTypes['DraftJob'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  applicationFormUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  branding?: Resolver<
    Maybe<ResolversTypes['DraftJobBranding']>,
    ParentType,
    ContextType
  >;
  categories?: Resolver<
    Maybe<Array<ResolversTypes['DraftJobCategory']>>,
    ParentType,
    ContextType
  >;
  details?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expiresAt?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  guidedDetails?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['DraftJobGuidedDetails']>>>,
    ParentType,
    ContextType
  >;
  jobWritingMode?: Resolver<
    Maybe<ResolversTypes['DraftJobWritingMode']>,
    ParentType,
    ContextType
  >;
  jobAdReference?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  isConfidential?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  keySellingPoints?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  locations?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['DraftJobLocation']>>>,
    ParentType,
    ContextType
  >;
  adType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  products?: Resolver<
    Maybe<ResolversTypes['DraftJobProducts']>,
    ParentType,
    ContextType
  >;
  questionnaireId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  salary?: Resolver<
    Maybe<ResolversTypes['DraftJobSalaryDetails']>,
    ParentType,
    ContextType
  >;
  summary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  titles?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['DraftJobRoleTitle']>>>,
    ParentType,
    ContextType
  >;
  video?: Resolver<
    Maybe<ResolversTypes['DraftJobVideo']>,
    ParentType,
    ContextType
  >;
  workTypes?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['WorkType']>>>,
    ParentType,
    ContextType
  >;
  draftProgress?: Resolver<
    Maybe<ResolversTypes['DraftJobProgress']>,
    ParentType,
    ContextType
  >;
  paymentOption?: Resolver<
    Maybe<ResolversTypes['DraftJobPaymentOption']>,
    ParentType,
    ContextType
  >;
  phoneNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DraftJobBrandingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DraftJobBranding'] = ResolversParentTypes['DraftJobBranding'],
> = {
  legacyId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DraftJobCategoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DraftJobCategory'] = ResolversParentTypes['DraftJobCategory'],
> = {
  categoryId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  subCategoryId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  adcentreCategoryId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  adcentreSubCategoryId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DraftJobEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DraftJobEdge'] = ResolversParentTypes['DraftJobEdge'],
> = {
  node?: Resolver<
    Maybe<ResolversTypes['DraftJobNode']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DraftJobGuidedDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DraftJobGuidedDetails'] = ResolversParentTypes['DraftJobGuidedDetails'],
> = {
  type?: Resolver<ResolversTypes['GuidedDetailsType'], ParentType, ContextType>;
  values?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DraftJobLocationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DraftJobLocation'] = ResolversParentTypes['DraftJobLocation'],
> = {
  seekId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DraftJobNodeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DraftJobNode'] = ResolversParentTypes['DraftJobNode'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  advertiserJobReference?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  agencyExternalReference?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  locationDisplayNames?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  locations?: Resolver<
    Array<ResolversTypes['Location']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<ResolversTypes['DraftStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DraftJobNotFoundPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DraftJobNotFoundPayload'] = ResolversParentTypes['DraftJobNotFoundPayload'],
> = {
  reason?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DraftJobPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DraftJobPayload'] = ResolversParentTypes['DraftJobPayload'],
> = {
  __resolveType: TypeResolveFn<
    'DraftJobSuccessPayload' | 'DraftJobNotFoundPayload',
    ParentType,
    ContextType
  >;
};

export type DraftJobProductsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DraftJobProducts'] = ResolversParentTypes['DraftJobProducts'],
> = {
  productIds?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  productSetId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DraftJobRoleTitleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DraftJobRoleTitle'] = ResolversParentTypes['DraftJobRoleTitle'],
> = {
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  language?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DraftJobSalaryDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DraftJobSalaryDetails'] = ResolversParentTypes['DraftJobSalaryDetails'],
> = {
  currency?: Resolver<
    Maybe<ResolversTypes['DraftJobSalaryCurrency']>,
    ParentType,
    ContextType
  >;
  displayValue?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  isHidden?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  minimum?: Resolver<Maybe<ResolversTypes['SafeInt']>, ParentType, ContextType>;
  maximum?: Resolver<Maybe<ResolversTypes['SafeInt']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['SalaryType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DraftJobSuccessPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DraftJobSuccessPayload'] = ResolversParentTypes['DraftJobSuccessPayload'],
> = {
  flowType?: Resolver<
    Maybe<ResolversTypes['DraftJobFlowType']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<ResolversTypes['DraftStatus'], ParentType, ContextType>;
  draft?: Resolver<ResolversTypes['DraftJob'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DraftJobVideoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DraftJobVideo'] = ResolversParentTypes['DraftJobVideo'],
> = {
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DraftJobsPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DraftJobsPayload'] = ResolversParentTypes['DraftJobsPayload'],
> = {
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalPageCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<
    Array<ResolversTypes['DraftJobEdge']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DrawdownContractDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DrawdownContractDetails'] = ResolversParentTypes['DrawdownContractDetails'],
> = {
  contractType?: Resolver<
    ResolversTypes['ContractType'],
    ParentType,
    ContextType
  >;
  adType?: Resolver<ResolversTypes['AdType'], ParentType, ContextType>;
  dollarBased?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  expiryDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  currentValue?: Resolver<
    Maybe<ResolversTypes['DrawdownContractValue']>,
    ParentType,
    ContextType
  >;
  remainingValue?: Resolver<
    Maybe<ResolversTypes['DrawdownContractValue']>,
    ParentType,
    ContextType
  >;
  contractId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  contractName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DrawdownContractValueResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DrawdownContractValue'] = ResolversParentTypes['DrawdownContractValue'],
> = {
  dollars?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DrawdownItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DrawdownItem'] = ResolversParentTypes['DrawdownItem'],
> = {
  drawdownContractDetails?: Resolver<
    ResolversTypes['DrawdownContractDetails'],
    ParentType,
    ContextType
  >;
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DriversLicenceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DriversLicence'] = ResolversParentTypes['DriversLicence'],
> = {
  verified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DurationFeatureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DurationFeature'] = ResolversParentTypes['DurationFeature'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  unit?: Resolver<ResolversTypes['DurationUnit'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EditJobResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EditJobResponse'] = ResolversParentTypes['EditJobResponse'],
> = {
  revisionId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Error'] = ResolversParentTypes['Error'],
> = {
  __resolveType: TypeResolveFn<
    | 'DisplayMetadataError'
    | 'InvoiceError'
    | 'MissingAdvertiserIdClaimError'
    | 'MissingUserIdClaimError'
    | 'OrderExpiredError'
    | 'OrderInvalidError'
    | 'OrderNotFoundError'
    | 'Payment2c2pInquiryError'
    | 'PaymentIntentChargeError'
    | 'PaymentMethodError'
    | 'ProductSetExpiredError'
    | 'ProductSetNotFoundError'
    | 'PromotionInvalidError'
    | 'SavedCreditCardPaymentError'
    | 'SecureLinkExpiredError'
    | 'SecureLinkInvalidError'
    | 'SecureLinkUsedError',
    ParentType,
    ContextType
  >;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type ExpectedSalaryV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ExpectedSalaryV2'] = ResolversParentTypes['ExpectedSalaryV2'],
> = {
  amount?: Resolver<
    Array<ResolversTypes['SalaryAmountV2']>,
    ParentType,
    ContextType
  >;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExpireJobResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ExpireJobResponse'] = ResolversParentTypes['ExpireJobResponse'],
> = {
  jobId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExpiryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Expiry'] = ResolversParentTypes['Expiry'],
> = {
  expiryDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  expiresSoon?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  daysRemaining?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetProductSetResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetProductSetResponse'] = ResolversParentTypes['GetProductSetResponse'],
> = {
  __resolveType: TypeResolveFn<
    'ProductSet' | 'ProductSetExpiredError' | 'ProductSetNotFoundError',
    ParentType,
    ContextType
  >;
};

export type GetSurchargeAmountPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetSurchargeAmountPayload'] = ResolversParentTypes['GetSurchargeAmountPayload'],
> = {
  surchargeApplicable?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  surchargeAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GranularLocationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GranularLocation'] = ResolversParentTypes['GranularLocation'],
> = {
  type?: Resolver<
    ResolversTypes['RecommendationType'],
    ParentType,
    ContextType
  >;
  nonSpecificLocation?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  specificLocation?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HeaderFooterContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HeaderFooterContext'] = ResolversParentTypes['HeaderFooterContext'],
> = {
  identityContext?: Resolver<
    Maybe<ResolversTypes['IdentityHeaderContext']>,
    ParentType,
    ContextType
  >;
  user?: Resolver<
    Maybe<ResolversTypes['UserHeaderContext']>,
    ParentType,
    ContextType
  >;
  advertiser?: Resolver<
    Maybe<ResolversTypes['AdvertiserHeaderContext']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HelpTipResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HelpTip'] = ResolversParentTypes['HelpTip'],
> = {
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  image?: Resolver<
    Maybe<ResolversTypes['HelpTipImage']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HelpTipImageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HelpTipImage'] = ResolversParentTypes['HelpTipImage'],
> = {
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  altText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HiddenPriceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HiddenPrice'] = ResolversParentTypes['HiddenPrice'],
> = {
  priceVisibility?: Resolver<
    ResolversTypes['PriceVisibility'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HirerAccountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HirerAccount'] = ResolversParentTypes['HirerAccount'],
> = {
  billingStatus?: Resolver<
    Maybe<ResolversTypes['BillingStatus']>,
    ParentType,
    ContextType
  >;
  nation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['Parent']>, ParentType, ContextType>;
  billingAccountId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  billingAddress?: Resolver<
    ResolversTypes['PhysicalAddress'],
    ParentType,
    ContextType
  >;
  postalAddress?: Resolver<
    ResolversTypes['PhysicalAddress'],
    ParentType,
    ContextType
  >;
  primaryContact?: Resolver<
    ResolversTypes['PrimaryContact'],
    ParentType,
    ContextType
  >;
  billingContact?: Resolver<
    ResolversTypes['BillingContact'],
    ParentType,
    ContextType
  >;
  status?: Resolver<
    ResolversTypes['HirerAccountStatus'],
    ParentType,
    ContextType
  >;
  activationStatus?: Resolver<
    ResolversTypes['AccountActivationStatus'],
    ParentType,
    ContextType
  >;
  businessName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  businessIdentifier?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  managingAgency?: Resolver<
    Maybe<ResolversTypes['ManagingAgency']>,
    ParentType,
    ContextType
  >;
  hasAgent?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  hasLinkOutEnabled?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  isArchived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isEsmAccount?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  hidePrice?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  hirerGroup?: Resolver<
    Maybe<ResolversTypes['HirerGroup']>,
    ParentType,
    ContextType
  >;
  hirerType?: Resolver<
    Maybe<ResolversTypes['HirerType']>,
    ParentType,
    ContextType
  >;
  originZone?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  isPrivateAdvertiser?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  isApproved?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  isTalentSearchApproved?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  isTestAccount?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  isManagedAccount?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  priceVisibility?: Resolver<
    Maybe<ResolversTypes['PriceVisibility']>,
    ParentType,
    ContextType
  >;
  pricingModel?: Resolver<
    Maybe<ResolversTypes['PricingModel']>,
    ParentType,
    ContextType
  >;
  rules?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Rule']>>>,
    ParentType,
    ContextType
  >;
  pricingGroupContractExists?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  integrations?: Resolver<
    ResolversTypes['IntegrationConnection'],
    ParentType,
    ContextType,
    RequireFields<HirerAccount_IntegrationsArgs, 'input'>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HirerAccountDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HirerAccountDetails'] = ResolversParentTypes['HirerAccountDetails'],
> = {
  billingAccountId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  billingAddress?: Resolver<
    ResolversTypes['PhysicalAddress'],
    ParentType,
    ContextType
  >;
  postalAddress?: Resolver<
    ResolversTypes['PhysicalAddress'],
    ParentType,
    ContextType
  >;
  primaryContact?: Resolver<
    ResolversTypes['PrimaryContact'],
    ParentType,
    ContextType
  >;
  billingContact?: Resolver<
    ResolversTypes['BillingContact'],
    ParentType,
    ContextType
  >;
  status?: Resolver<
    ResolversTypes['HirerAccountStatus'],
    ParentType,
    ContextType
  >;
  businessName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  businessIdentifier?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  managingAgency?: Resolver<
    ResolversTypes['ManagingAgency'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HirerActivityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HirerActivity'] = ResolversParentTypes['HirerActivity'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  activityType?: Resolver<
    ResolversTypes['HirerActivityType'],
    ParentType,
    ContextType
  >;
  activityData?: Resolver<
    Array<ResolversTypes['HirerActivityData']>,
    ParentType,
    ContextType
  >;
  activityTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  userFirstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userLastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  partialJobApplicationList?: Resolver<
    Array<ResolversTypes['HirerActivityJobApplication']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HirerActivityConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HirerActivityConnection'] = ResolversParentTypes['HirerActivityConnection'],
> = {
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  edges?: Resolver<
    Array<ResolversTypes['HirerActivityEdge']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HirerActivityDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HirerActivityData'] = ResolversParentTypes['HirerActivityData'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HirerActivityEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HirerActivityEdge'] = ResolversParentTypes['HirerActivityEdge'],
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['HirerActivity'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HirerActivityJobApplicationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HirerActivityJobApplication'] = ResolversParentTypes['HirerActivityJobApplication'],
> = {
  candidateFirstName?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  candidateLastName?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  adcentreProspectId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HirerDashboardArticleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HirerDashboardArticle'] = ResolversParentTypes['HirerDashboardArticle'],
> = {
  imageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  imageTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timeToRead?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HirerDashboardBudgetUpsellResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HirerDashboardBudgetUpsellResponse'] = ResolversParentTypes['HirerDashboardBudgetUpsellResponse'],
> = {
  upsellEligible?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  maxContractDiscountRate?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HirerDashboardHiringAdviceListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HirerDashboardHiringAdviceList'] = ResolversParentTypes['HirerDashboardHiringAdviceList'],
> = {
  articles?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['HirerDashboardArticle']>>>,
    ParentType,
    ContextType
  >;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HirerDashboardJobResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HirerDashboardJob'] = ResolversParentTypes['HirerDashboardJob'],
> = {
  positionId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  listingId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  jobId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  draftId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numberOfCandidates?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  numberOfNewCandidates?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDateUtc?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  endDateUtc?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  daysRemaining?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  kind?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  jobAdRating?: Resolver<
    Maybe<ResolversTypes['AnalyticsAdRatingScore']>,
    ParentType,
    ContextType
  >;
  performanceRating?: Resolver<
    Maybe<ResolversTypes['AnalyticsAdRating']>,
    ParentType,
    ContextType
  >;
  unifiedLocationId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  location?: Resolver<
    Maybe<ResolversTypes['Location']>,
    ParentType,
    ContextType
  >;
  listPosition?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  canBeRefreshed?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  adType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  talentSearchJobHref?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HirerDashboardJobCountsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HirerDashboardJobCounts'] = ResolversParentTypes['HirerDashboardJobCounts'],
> = {
  draft?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  posted?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  expired?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HirerDashboardJobsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HirerDashboardJobs'] = ResolversParentTypes['HirerDashboardJobs'],
> = {
  jobs?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['HirerDashboardJob']>>>,
    ParentType,
    ContextType
  >;
  lastDraft?: Resolver<
    Maybe<ResolversTypes['HirerDashboardJob']>,
    ParentType,
    ContextType
  >;
  directCounts?: Resolver<
    Maybe<ResolversTypes['HirerDashboardJobCounts']>,
    ParentType,
    ContextType
  >;
  indirectCounts?: Resolver<
    Maybe<ResolversTypes['HirerDashboardJobCounts']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HirerDashboardTalentSearchResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HirerDashboardTalentSearch'] = ResolversParentTypes['HirerDashboardTalentSearch'],
> = {
  premiumTalentSearchHref?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HirerDashboardUserDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HirerDashboardUserDetails'] = ResolversParentTypes['HirerDashboardUserDetails'],
> = {
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HirerDashboardWelcomeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HirerDashboardWelcome'] = ResolversParentTypes['HirerDashboardWelcome'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HirerExperienceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HirerExperience'] = ResolversParentTypes['HirerExperience'],
> = {
  selectionType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  simplifiedAnswers?: Resolver<
    Maybe<Array<ResolversTypes['SimplifiedAnswers']>>,
    ParentType,
    ContextType
  >;
  controlType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  mandatory?: Resolver<
    Maybe<ResolversTypes['MandatoryData']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HirerJobResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HirerJob'] = ResolversParentTypes['HirerJob'],
> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  adCentrePositionId?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  adCentreListingId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdBy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  listingDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  daysRemaining?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<ResolversTypes['HirerJobStatus'], ParentType, ContextType>;
  postChannel?: Resolver<
    ResolversTypes['HirerJobPostChannel'],
    ParentType,
    ContextType
  >;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  advertiserJobReference?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  agencyExternalReference?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  locationDisplayNames?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  locations?: Resolver<
    Array<ResolversTypes['Location']>,
    ParentType,
    ContextType
  >;
  numberOfCandidates?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  numberOfNewCandidates?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  jobAdRating?: Resolver<
    Maybe<ResolversTypes['AnalyticsAdRatingScore']>,
    ParentType,
    ContextType
  >;
  performanceRating?: Resolver<
    Maybe<ResolversTypes['AnalyticsAdRating']>,
    ParentType,
    ContextType
  >;
  talentSearchJobHref?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  canExtendAd?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  canUpgradeToPremium?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  isPromoted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HirerJobConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HirerJobConnection'] = ResolversParentTypes['HirerJobConnection'],
> = {
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<
    Array<ResolversTypes['HirerJobEdge']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HirerJobEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HirerJobEdge'] = ResolversParentTypes['HirerJobEdge'],
> = {
  node?: Resolver<Maybe<ResolversTypes['HirerJob']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HirerPrimaryContactResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HirerPrimaryContact'] = ResolversParentTypes['HirerPrimaryContact'],
> = {
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HirerUserDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HirerUserDetails'] = ResolversParentTypes['HirerUserDetails'],
> = {
  userDeets?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  hirerUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  familyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  givenName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isArchived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isVerified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HirerUserEmailInputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HirerUserEmailInput'] = ResolversParentTypes['HirerUserEmailInput'],
> = {
  userEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Identity'] = ResolversParentTypes['Identity'],
> = {
  subject?: Resolver<ResolversTypes['Subject'], ParentType, ContextType>;
  actor?: Resolver<Maybe<ResolversTypes['Actor']>, ParentType, ContextType>;
  allowImpersonation?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  showCustomerServiceView?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityHeaderContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentityHeaderContext'] = ResolversParentTypes['IdentityHeaderContext'],
> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  act?: Resolver<
    Maybe<ResolversTypes['ActorContext']>,
    ParentType,
    ContextType,
    Partial<IdentityHeaderContext_ActArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityIdsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IdentityIds'] = ResolversParentTypes['IdentityIds'],
> = {
  advertiserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Image'] = ResolversParentTypes['Image'],
> = {
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InitiateConnectionPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InitiateConnectionPayload'] = ResolversParentTypes['InitiateConnectionPayload'],
> = {
  connectionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IntegrationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Integration'] = ResolversParentTypes['Integration'],
> = {
  partner?: Resolver<
    ResolversTypes['IntegrationPartner'],
    ParentType,
    ContextType
  >;
  relationships?: Resolver<
    Array<ResolversTypes['IntegrationRelationship']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IntegrationConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IntegrationConnection'] = ResolversParentTypes['IntegrationConnection'],
> = {
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<
    Array<ResolversTypes['IntegrationEdge']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IntegrationEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IntegrationEdge'] = ResolversParentTypes['IntegrationEdge'],
> = {
  node?: Resolver<ResolversTypes['Integration'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IntegrationPartnerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IntegrationPartner'] = ResolversParentTypes['IntegrationPartner'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  configurableRelationships?: Resolver<
    Array<ResolversTypes['IntegrationRelationshipType']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IntegrationRelationshipResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IntegrationRelationship'] = ResolversParentTypes['IntegrationRelationship'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  relationshipType?: Resolver<
    ResolversTypes['IntegrationRelationshipType'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IntegrationRelationshipTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IntegrationRelationshipType'] = ResolversParentTypes['IntegrationRelationshipType'],
> = {
  typeCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InteractedJobResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InteractedJob'] = ResolversParentTypes['InteractedJob'],
> = {
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  locationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  location?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<InteractedJob_LocationArgs, 'language'>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvalidInputPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InvalidInputPayload'] = ResolversParentTypes['InvalidInputPayload'],
> = {
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InvoiceError'] = ResolversParentTypes['InvoiceError'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceOrderNotFoundPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InvoiceOrderNotFoundPayload'] = ResolversParentTypes['InvoiceOrderNotFoundPayload'],
> = {
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceOrderSuccessPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InvoiceOrderSuccessPayload'] = ResolversParentTypes['InvoiceOrderSuccessPayload'],
> = {
  responseStatus?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export type JobResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Job'] = ResolversParentTypes['Job'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postChannel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  adType?: Resolver<
    Maybe<ResolversTypes['JobAdType']>,
    ParentType,
    ContextType
  >;
  productDisplayName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  advertiserJobReference?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  applicationFormUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  branding?: Resolver<Maybe<ResolversTypes['Brand']>, ParentType, ContextType>;
  createdDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  listingDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  expiryDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  isConfidential?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  jobDescription?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  jobId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  jobSummary?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  keySellingPoints?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  locationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  locations?: Resolver<
    Array<ResolversTypes['Location']>,
    ParentType,
    ContextType
  >;
  maxSalary?: Resolver<
    Maybe<ResolversTypes['SafeInt']>,
    ParentType,
    ContextType
  >;
  minSalary?: Resolver<
    Maybe<ResolversTypes['SafeInt']>,
    ParentType,
    ContextType
  >;
  payType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  questionnaireUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  salaryCurrency?: Resolver<
    Maybe<ResolversTypes['JobSalaryCurrency']>,
    ParentType,
    ContextType
  >;
  salaryText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<ResolversTypes['JobStatus'], ParentType, ContextType>;
  subCategoryId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  categoryName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  subCategoryName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  videoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workType?: Resolver<
    Maybe<ResolversTypes['WorkType']>,
    ParentType,
    ContextType
  >;
  jobAdRating?: Resolver<
    Maybe<ResolversTypes['AnalyticsAdRatingScore']>,
    ParentType,
    ContextType
  >;
  productFeatures?: Resolver<
    Maybe<Array<ResolversTypes['CatalogueProductFeature']>>,
    ParentType,
    ContextType
  >;
  performanceRating?: Resolver<
    Maybe<ResolversTypes['AnalyticsAdRating']>,
    ParentType,
    ContextType
  >;
  billableApplicants?: Resolver<
    ResolversTypes['BillableApplicantsResponse'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobAdResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JobAd'] = ResolversParentTypes['JobAd'],
> = {
  adRating?: Resolver<
    Maybe<ResolversTypes['AdRatingType']>,
    ParentType,
    ContextType
  >;
  adType?: Resolver<ResolversTypes['AdUsageAdType'], ParentType, ContextType>;
  applications?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  locationLabel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  groupKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hidePrice?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isLatestGroup?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isRefresh?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isRepost?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  jobId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  jobTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  jobCreatedTimestampUTC?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  status?: Resolver<ResolversTypes['AdUsageAdStatus'], ParentType, ContextType>;
  totalNetAmount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  userFullName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobAdEditFeatureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JobAdEditFeature'] = ResolversParentTypes['JobAdEditFeature'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobAdListResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JobAdListResponse'] = ResolversParentTypes['JobAdListResponse'],
> = {
  lastUpdated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  jobAds?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['JobAd']>>>,
    ParentType,
    ContextType
  >;
  hasMultipleCurrencies?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobAdNotUpgradableToPremiumResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JobAdNotUpgradableToPremium'] = ResolversParentTypes['JobAdNotUpgradableToPremium'],
> = {
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobTitleOptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JobTitleOption'] = ResolversParentTypes['JobTitleOption'],
> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface LanguageCodeIsoScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['LanguageCodeIso'], any> {
  name: 'LanguageCodeIso';
}

export type LastUpdatedResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LastUpdatedResponse'] = ResolversParentTypes['LastUpdatedResponse'],
> = {
  lastUpdated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LineItemProductResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LineItemProduct'] = ResolversParentTypes['LineItemProduct'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productType?: Resolver<
    ResolversTypes['ProductType'],
    ParentType,
    ContextType
  >;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LinkResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Link'] = ResolversParentTypes['Link'],
> = {
  rel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  href?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocalisedContactInfoContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LocalisedContactInfoContext'] = ResolversParentTypes['LocalisedContactInfoContext'],
> = {
  contactUsLink?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  helpCentreLink?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  contactNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  businessHours?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Location'] = ResolversParentTypes['Location'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayDescription?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  parent?: Resolver<
    Maybe<ResolversTypes['ParentLocation']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationOptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LocationOption'] = ResolversParentTypes['LocationOption'],
> = {
  key?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  level?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  parentId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationSearchResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LocationSearchResult'] = ResolversParentTypes['LocationSearchResult'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  countryId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parents?: Resolver<
    Array<Maybe<ResolversTypes['LocationSearchResult']>>,
    ParentType,
    ContextType
  >;
  matchedSearchString?: Resolver<
    Maybe<Array<ResolversTypes['MatchedSearchString']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LogoStrategiesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LogoStrategies'] = ResolversParentTypes['LogoStrategies'],
> = {
  serpLogo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  jdpLogo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LowApplyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LowApply'] = ResolversParentTypes['LowApply'],
> = {
  type?: Resolver<
    ResolversTypes['RecommendationType'],
    ParentType,
    ContextType
  >;
  conversionRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  averageConversionRate?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LowClickResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LowClick'] = ResolversParentTypes['LowClick'],
> = {
  type?: Resolver<
    ResolversTypes['RecommendationType'],
    ParentType,
    ContextType
  >;
  conversionRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  averageConversionRate?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LowSalaryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LowSalary'] = ResolversParentTypes['LowSalary'],
> = {
  type?: Resolver<
    ResolversTypes['RecommendationType'],
    ParentType,
    ContextType
  >;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  salaryType?: Resolver<
    ResolversTypes['AnalyticsSalaryType'],
    ParentType,
    ContextType
  >;
  salaryRangeMin?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  salaryRangeMax?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  suggestedRangeMin?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  suggestedRangeMax?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  suggestedAverageRangeMin?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  suggestedAverageRangeMax?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  normalisedRoleTitle?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  benchmarkLocationLabel?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LowestPricedProductForAdPostingResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LowestPricedProductForAdPostingResponse'] = ResolversParentTypes['LowestPricedProductForAdPostingResponse'],
> = {
  __resolveType: TypeResolveFn<
    'AdProduct' | 'ProductSetExpiredError' | 'ProductSetNotFoundError',
    ParentType,
    ContextType
  >;
};

export type MaintenanceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Maintenance'] = ResolversParentTypes['Maintenance'],
> = {
  paymentMethod?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  disablePayment?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  showMaintenance?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  disableMessage?: Resolver<
    Maybe<ResolversTypes['DisableMessageType']>,
    ParentType,
    ContextType
  >;
  paymentMedium?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['PaymentMediumType']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManagingAgencyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ManagingAgency'] = ResolversParentTypes['ManagingAgency'],
> = {
  advertiserId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MandatoryDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MandatoryData'] = ResolversParentTypes['MandatoryData'],
> = {
  mandatoryAnswerText?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketInsightMonthResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MarketInsightMonth'] = ResolversParentTypes['MarketInsightMonth'],
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  calendarMonth?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketInsightsTitleResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MarketInsightsTitleResponse'] = ResolversParentTypes['MarketInsightsTitleResponse'],
> = {
  benchmarkLocationLabel?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  benchmarkScenario?: Resolver<
    Maybe<ResolversTypes['AnalyticsBenchmarkScenario']>,
    ParentType,
    ContextType
  >;
  classificationName?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  normalisedRoleTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  subClassificationName?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketPerformanceResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MarketPerformanceResponse'] = ResolversParentTypes['MarketPerformanceResponse'],
> = {
  highAds?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  lowAds?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  normalAds?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  noRatingAds?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalAds?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MatchedSearchStringResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MatchedSearchString'] = ResolversParentTypes['MatchedSearchString'],
> = {
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  matches?: Resolver<
    Maybe<Array<Maybe<Array<Maybe<ResolversTypes['Int']>>>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MaximiseBrandingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MaximiseBranding'] = ResolversParentTypes['MaximiseBranding'],
> = {
  type?: Resolver<
    ResolversTypes['TipsToImproveAdsType'],
    ParentType,
    ContextType
  >;
  adCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  bulletPointsUsed?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  logoUsed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  coverImageUsed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MissingAdvertiserIdClaimErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MissingAdvertiserIdClaimError'] = ResolversParentTypes['MissingAdvertiserIdClaimError'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MissingUserIdClaimErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MissingUserIdClaimError'] = ResolversParentTypes['MissingUserIdClaimError'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MoneyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Money'] = ResolversParentTypes['Money'],
> = {
  value?: Resolver<ResolversTypes['SafeInt'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MostAdsByPerformanceCategoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MostAdsByPerformanceCategory'] = ResolversParentTypes['MostAdsByPerformanceCategory'],
> = {
  user?: Resolver<
    ResolversTypes['MostAdsByPerformanceEntity'],
    ParentType,
    ContextType
  >;
  classification?: Resolver<
    ResolversTypes['MostAdsByPerformanceEntity'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MostAdsByPerformanceEntityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MostAdsByPerformanceEntity'] = ResolversParentTypes['MostAdsByPerformanceEntity'],
> = {
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  number?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  percentage?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MostAdsByPerformanceResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MostAdsByPerformanceResponse'] = ResolversParentTypes['MostAdsByPerformanceResponse'],
> = {
  low?: Resolver<
    ResolversTypes['MostAdsByPerformanceCategory'],
    ParentType,
    ContextType
  >;
  high?: Resolver<
    ResolversTypes['MostAdsByPerformanceCategory'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation'],
> = {
  _empty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  applyCoverToAllBrands?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Brand']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_ApplyCoverToAllBrandsArgs, 'brandId'>
  >;
  createBrand?: Resolver<
    Maybe<ResolversTypes['CreateBrandPayload']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_CreateBrandArgs, 'input'>
  >;
  updateBrand?: Resolver<
    Maybe<ResolversTypes['UpdateBrandPayload']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_UpdateBrandArgs, 'input'>
  >;
  clearDefaultBrand?: Resolver<
    Maybe<ResolversTypes['Brand']>,
    ParentType,
    ContextType,
    Partial<Mutation_ClearDefaultBrandArgs>
  >;
  setDefaultBrand?: Resolver<
    Maybe<ResolversTypes['Brand']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_SetDefaultBrandArgs, 'id'>
  >;
  deleteBrand?: Resolver<
    Maybe<ResolversTypes['Brand']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_DeleteBrandArgs, 'id'>
  >;
  deleteBrandingAsset?: Resolver<
    Maybe<ResolversTypes['DeleteBrandingAssetPayload']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_DeleteBrandingAssetArgs, 'input'>
  >;
  patchCompanyProfile?: Resolver<
    Maybe<ResolversTypes['PatchCompanyProfileResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_PatchCompanyProfileArgs, 'input'>
  >;
  createPaymentIntent?: Resolver<
    Maybe<ResolversTypes['CreatePaymentIntentPayload']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_CreatePaymentIntentArgs, 'input'>
  >;
  payBySavedCreditCard?: Resolver<
    Maybe<ResolversTypes['PayBySavedCreditCardPayload']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_PayBySavedCreditCardArgs, 'input'>
  >;
  deleteSavedCreditCard?: Resolver<
    Maybe<ResolversTypes['DeleteSavedCreditCardPayload']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_DeleteSavedCreditCardArgs, 'input'>
  >;
  deleteDraftJob?: Resolver<
    Maybe<ResolversTypes['DeleteDraftJobPayload']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_DeleteDraftJobArgs, 'input'>
  >;
  createDraftJob?: Resolver<
    ResolversTypes['CreateDraftJobPayload'],
    ParentType,
    ContextType,
    RequireFields<Mutation_CreateDraftJobArgs, 'input'>
  >;
  updateDraftJob?: Resolver<
    ResolversTypes['UpdateDraftPayload'],
    ParentType,
    ContextType,
    RequireFields<Mutation_UpdateDraftJobArgs, 'input'>
  >;
  updateAccountPostalAddress?: Resolver<
    Maybe<ResolversTypes['AccountDetailsUpdateResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_UpdateAccountPostalAddressArgs>
  >;
  updateAccountPrimaryContact?: Resolver<
    Maybe<ResolversTypes['AccountDetailsUpdateResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_UpdateAccountPrimaryContactArgs>
  >;
  updateAccountBillingAddress?: Resolver<
    Maybe<ResolversTypes['AccountDetailsUpdateResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_UpdateAccountBillingAddressArgs>
  >;
  updateAccountBillingEmailAddress?: Resolver<
    Maybe<ResolversTypes['AccountDetailsUpdateResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_UpdateAccountBillingEmailAddressArgs>
  >;
  publishJobPostedEvent?: Resolver<
    Maybe<ResolversTypes['PublishJobPostedEventPayload']>,
    ParentType,
    ContextType,
    Partial<Mutation_PublishJobPostedEventArgs>
  >;
  triggerActivation?: Resolver<
    ResolversTypes['TriggerActivationPayload'],
    ParentType,
    ContextType
  >;
  sendEmailVerificationEmail?: Resolver<
    Maybe<ResolversTypes['SendEmailVerificationEmailPayload']>,
    ParentType,
    ContextType
  >;
  sendLoginCallbackEvent?: Resolver<
    Maybe<ResolversTypes['SendLoginCallbackEventPayload']>,
    ParentType,
    ContextType
  >;
  sendEnrolmentEmail?: Resolver<
    Maybe<ResolversTypes['SendEnrolmentEmailPayload']>,
    ParentType,
    ContextType
  >;
  createIntegrationRelationships?: Resolver<
    Array<ResolversTypes['IntegrationRelationship']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_CreateIntegrationRelationshipsArgs, 'input'>
  >;
  deleteIntegrationRelationships?: Resolver<
    Array<ResolversTypes['IntegrationRelationship']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_DeleteIntegrationRelationshipsArgs, 'input'>
  >;
  updateInvoiceStatus?: Resolver<
    ResolversTypes['UpdateInvoiceStatusPayload'],
    ParentType,
    ContextType,
    RequireFields<Mutation_UpdateInvoiceStatusArgs, 'input'>
  >;
  createJob?: Resolver<
    Maybe<ResolversTypes['CreateJobResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_CreateJobArgs>
  >;
  editJob?: Resolver<
    Maybe<ResolversTypes['EditJobResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_EditJobArgs>
  >;
  expireJob?: Resolver<
    Maybe<ResolversTypes['ExpireJobResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_ExpireJobArgs>
  >;
  refreshJob?: Resolver<
    Maybe<ResolversTypes['RefreshJobResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_RefreshJobArgs>
  >;
  upgradeToPremium?: Resolver<
    Maybe<ResolversTypes['UpgradeToPremiumPayload']>,
    ParentType,
    ContextType,
    Partial<Mutation_UpgradeToPremiumArgs>
  >;
  publishJobAdBookingEvent?: Resolver<
    Maybe<ResolversTypes['PublishJobAdBookingEventPayload']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_PublishJobAdBookingEventArgs, 'input'>
  >;
  publishCPQAEvent?: Resolver<
    Maybe<ResolversTypes['PublishCPQAEventPayload']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_PublishCpqaEventArgs, 'input'>
  >;
  trackLocationSuggestion?: Resolver<
    ResolversTypes['TrackLocationSuggestionPayload'],
    ParentType,
    ContextType,
    RequireFields<Mutation_TrackLocationSuggestionArgs, 'input'>
  >;
  setNotificationPreferences?: Resolver<
    ResolversTypes['SetNotificationPreferencesPayload'],
    ParentType,
    ContextType,
    RequireFields<Mutation_SetNotificationPreferencesArgs, 'input'>
  >;
  setDefaultNotificationPreference?: Resolver<
    ResolversTypes['NotificationPreference'],
    ParentType,
    ContextType,
    RequireFields<Mutation_SetDefaultNotificationPreferenceArgs, 'input'>
  >;
  createOrder?: Resolver<
    Maybe<ResolversTypes['CreateOrderPayload']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_CreateOrderArgs, 'input'>
  >;
  createOrderWithPromotion?: Resolver<
    Maybe<ResolversTypes['CreateOrderWithPromotionPayload']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_CreateOrderWithPromotionArgs, 'input'>
  >;
  confirmInvoiceOrder?: Resolver<
    Maybe<ResolversTypes['ConfirmInvoiceOrderPayload']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_ConfirmInvoiceOrderArgs, 'input'>
  >;
  createStripePaymentIntent?: Resolver<
    ResolversTypes['CreateStripePaymentIntentPayload'],
    ParentType,
    ContextType,
    RequireFields<Mutation_CreateStripePaymentIntentArgs, 'input'>
  >;
  createStripePaymentIntentForInvoice?: Resolver<
    ResolversTypes['CreateStripePaymentIntentPayload'],
    ParentType,
    ContextType,
    RequireFields<Mutation_CreateStripePaymentIntentForInvoiceArgs, 'input'>
  >;
  payByStripeSavedCreditCard?: Resolver<
    Maybe<ResolversTypes['PayByStripeSavedCreditCardPayload']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_PayByStripeSavedCreditCardArgs, 'input'>
  >;
  payByStripeSavedCreditCardForInvoice?: Resolver<
    Maybe<ResolversTypes['PayByStripeSavedCreditCardPayload']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_PayByStripeSavedCreditCardForInvoiceArgs, 'input'>
  >;
  deleteStripeSavedCreditCard?: Resolver<
    Maybe<ResolversTypes['DeleteStripeSavedCreditCardPayload']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_DeleteStripeSavedCreditCardArgs, 'input'>
  >;
  create2C2PPaymentIntent?: Resolver<
    ResolversTypes['Create2c2pPaymentIntentPayload'],
    ParentType,
    ContextType,
    RequireFields<Mutation_Create2C2PPaymentIntentArgs, 'input'>
  >;
  create2C2PPaymentIntentForInvoices?: Resolver<
    ResolversTypes['Create2c2pPaymentIntentPayload'],
    ParentType,
    ContextType,
    RequireFields<Mutation_Create2C2PPaymentIntentForInvoicesArgs, 'input'>
  >;
  createProductSetForAdPosting?: Resolver<
    ResolversTypes['CreateProductSetForAdPostingPayload'],
    ParentType,
    ContextType,
    RequireFields<Mutation_CreateProductSetForAdPostingArgs, 'input'>
  >;
  createProductSetForPriceCheckerPreview?: Resolver<
    ResolversTypes['CreateProductSetForAdPostingPayload'],
    ParentType,
    ContextType,
    RequireFields<Mutation_CreateProductSetForPriceCheckerPreviewArgs, 'input'>
  >;
  createProductSetForMultiLocation?: Resolver<
    ResolversTypes['CreateProductSetForMultiLocationPayload'],
    ParentType,
    ContextType,
    RequireFields<Mutation_CreateProductSetForMultiLocationArgs, 'input'>
  >;
  createProductSetForAdEditing?: Resolver<
    ResolversTypes['CreateProductSetForAdEditingPayload'],
    ParentType,
    ContextType,
    RequireFields<Mutation_CreateProductSetForAdEditingArgs, 'input'>
  >;
  createProductSetForAdRefresh?: Resolver<
    ResolversTypes['CreateProductSetForAdRefreshPayload'],
    ParentType,
    ContextType,
    RequireFields<Mutation_CreateProductSetForAdRefreshArgs, 'input'>
  >;
  createProductSetForBudgetsPurchaseForHirer?: Resolver<
    ResolversTypes['CreateProductSetForBudgetsPurchaseForHirerPayload'],
    ParentType,
    ContextType
  >;
  createProductSetForBudgetsPurchaseForVisitor?: Resolver<
    ResolversTypes['CreateProductSetForBudgetsPurchaseForVisitorPayload'],
    ParentType,
    ContextType,
    RequireFields<
      Mutation_CreateProductSetForBudgetsPurchaseForVisitorArgs,
      'input'
    >
  >;
  createQuestionnaire?: Resolver<
    Maybe<ResolversTypes['CreateQuestionnaireResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_CreateQuestionnaireArgs, 'input'>
  >;
  createCustomQuestion?: Resolver<
    ResolversTypes['CreateCustomQuestionOutputUnion'],
    ParentType,
    ContextType,
    RequireFields<Mutation_CreateCustomQuestionArgs, 'input'>
  >;
  registerNewUser?: Resolver<
    Maybe<ResolversTypes['RegisterPayload']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RegisterNewUserArgs, 'input'>
  >;
  registerAdditionalAdvertiser?: Resolver<
    Maybe<ResolversTypes['RegisterPayload']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RegisterAdditionalAdvertiserArgs, 'input'>
  >;
  completeUserRegistration?: Resolver<
    ResolversTypes['SecureLinkResponse'],
    ParentType,
    ContextType,
    RequireFields<Mutation_CompleteUserRegistrationArgs, 'input'>
  >;
  completeAdditionalAdvertiserRegistration?: Resolver<
    ResolversTypes['SecureLinkResponse'],
    ParentType,
    ContextType,
    RequireFields<
      Mutation_CompleteAdditionalAdvertiserRegistrationArgs,
      'input'
    >
  >;
  initiateSendJob?: Resolver<
    Maybe<ResolversTypes['InitiateConnectionPayload']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_InitiateSendJobArgs, 'input'>
  >;
  initiateSendMessage?: Resolver<
    Maybe<ResolversTypes['InitiateConnectionPayload']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_InitiateSendMessageArgs, 'input'>
  >;
  initiateAccessCv?: Resolver<
    Maybe<ResolversTypes['InitiateConnectionPayload']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_InitiateAccessCvArgs, 'input'>
  >;
  initiateExportProfile?: Resolver<
    Maybe<ResolversTypes['InitiateConnectionPayload']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_InitiateExportProfileArgs, 'input'>
  >;
  createTalentPool?: Resolver<
    ResolversTypes['TalentSearchPool'],
    ParentType,
    ContextType,
    RequireFields<Mutation_CreateTalentPoolArgs, 'input'>
  >;
  deleteTalentPool?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<Mutation_DeleteTalentPoolArgs, 'input'>
  >;
  updateTalentPool?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<Mutation_UpdateTalentPoolArgs, 'input'>
  >;
  addProfileToTalentPool?: Resolver<
    ResolversTypes['TalentSearchPoolProfile'],
    ParentType,
    ContextType,
    Partial<Mutation_AddProfileToTalentPoolArgs>
  >;
  addOrRemoveProfileToTalentPools?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<Mutation_AddOrRemoveProfileToTalentPoolsArgs, 'input'>
  >;
  shareTalentPool?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<Mutation_ShareTalentPoolArgs, 'input'>
  >;
  createSavedSearch?: Resolver<
    Maybe<ResolversTypes['CreateSavedSearchPayload']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_CreateSavedSearchArgs, 'input'>
  >;
  deleteSavedSearch?: Resolver<
    Maybe<ResolversTypes['DeleteSavedSearchPayload']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_DeleteSavedSearchArgs, 'criteriaHash'>
  >;
  updateUserDetails?: Resolver<
    Maybe<ResolversTypes['UpdateUserDetailsResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_UpdateUserDetailsArgs, 'input'>
  >;
  sendPasswordResetEmail?: Resolver<
    Maybe<ResolversTypes['SendEmailResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_SendPasswordResetEmailArgs>
  >;
  sendRegistrationEmail?: Resolver<
    Maybe<ResolversTypes['SendEmailResponse']>,
    ParentType,
    ContextType,
    Partial<Mutation_SendRegistrationEmailArgs>
  >;
  setHirerAccountUsersJobAccess?: Resolver<
    Maybe<ResolversTypes['SetHirerAccountUsersJobAccessPayload']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_SetHirerAccountUsersJobAccessArgs, 'input'>
  >;
  sendHirerAccountUsersJobAccessEmail?: Resolver<
    Maybe<ResolversTypes['SendHirerAccountUsersJobAccessEmailPayload']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_SendHirerAccountUsersJobAccessEmailArgs, 'input'>
  >;
  addUserToAccount?: Resolver<
    Maybe<ResolversTypes['AddUserToAccountResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_AddUserToAccountArgs, 'input'>
  >;
  updateUserPermissions?: Resolver<
    Maybe<ResolversTypes['UpdateUserPermissionsResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_UpdateUserPermissionsArgs, 'input'>
  >;
  removeUserFromAccount?: Resolver<
    Maybe<ResolversTypes['AccountDetailsUpdateResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RemoveUserFromAccountArgs, 'input'>
  >;
  reinstate?: Resolver<
    Maybe<ResolversTypes['ReinstateResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_ReinstateArgs, 'input'>
  >;
};

export type MyJobInformationJobEditResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyJobInformationJobEdit'] = ResolversParentTypes['MyJobInformationJobEdit'],
> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timestampUTC?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyJobInformationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyJobInformationResponse'] = ResolversParentTypes['MyJobInformationResponse'],
> = {
  advertiserName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  applyUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  classificationName?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  createdByName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdByEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isLinkOut?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isSalaryHidden?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPrivateAdvertiser?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  jobCreatedTimestampUTC?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType
  >;
  jobLocationLabel?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  jobTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  latestEdit?: Resolver<
    Maybe<ResolversTypes['MyJobInformationJobEdit']>,
    ParentType,
    ContextType
  >;
  mirrorClassificationName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  mirrorSubClassificationName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  postTypeCategory?: Resolver<
    ResolversTypes['AnalyticsPostTypeCategory'],
    ParentType,
    ContextType
  >;
  productDisplayName?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  salary?: Resolver<
    ResolversTypes['MyJobInformationSalary'],
    ParentType,
    ContextType
  >;
  subClassificationName?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  workType?: Resolver<
    ResolversTypes['AnalyticsWorkType'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MyJobInformationSalaryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MyJobInformationSalary'] = ResolversParentTypes['MyJobInformationSalary'],
> = {
  additionalText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  maximum?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  minimum?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  type?: Resolver<
    Maybe<ResolversTypes['AnalyticsSalaryType']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationPreferenceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NotificationPreference'] = ResolversParentTypes['NotificationPreference'],
> = {
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  frequency?: Resolver<ResolversTypes['Frequency'], ParentType, ContextType>;
  applicationEmail?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OptionWithIdResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OptionWithId'] = ResolversParentTypes['OptionWithId'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Order'] = ResolversParentTypes['Order'],
> = {
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderExpiredErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrderExpiredError'] = ResolversParentTypes['OrderExpiredError'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderInvalidErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrderInvalidError'] = ResolversParentTypes['OrderInvalidError'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderLineItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrderLineItem'] = ResolversParentTypes['OrderLineItem'],
> = {
  product?: Resolver<ResolversTypes['Product'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  pricing?: Resolver<Maybe<ResolversTypes['Pricing']>, ParentType, ContextType>;
  drawdownItems?: Resolver<
    Array<ResolversTypes['DrawdownItem']>,
    ParentType,
    ContextType
  >;
  drawdownType?: Resolver<
    ResolversTypes['DrawdownType'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderNotFoundErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrderNotFoundError'] = ResolversParentTypes['OrderNotFoundError'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrderSummaryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrderSummary'] = ResolversParentTypes['OrderSummary'],
> = {
  orderId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lineItems?: Resolver<
    Array<ResolversTypes['OrderLineItem']>,
    ParentType,
    ContextType
  >;
  tax?: Resolver<Maybe<ResolversTypes['Tax']>, ParentType, ContextType>;
  subTotal?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  surcharges?: Resolver<
    Array<ResolversTypes['Surcharge']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OutputCustomQuestionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OutputCustomQuestion'] = ResolversParentTypes['OutputCustomQuestion'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  questionType?: Resolver<
    ResolversTypes['HirerQuestionType'],
    ParentType,
    ContextType
  >;
  answerType?: Resolver<
    ResolversTypes['CandidateAnswerType'],
    ParentType,
    ContextType
  >;
  options?: Resolver<
    Array<ResolversTypes['OptionWithId']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo'],
> = {
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  startCursor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  endCursor?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageTitleActionsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PageTitleActionsResponse'] = ResolversParentTypes['PageTitleActionsResponse'],
> = {
  adType?: Resolver<ResolversTypes['AnalyticsAdType'], ParentType, ContextType>;
  advertiserName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  classificationName?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  displayCurrency?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  jobCreatedTimestampUTC?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType
  >;
  jobExpiryTimestampUTC?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  isExpired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPrivateAdvertiser?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  jobId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  jobLocationLabel?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  jobSummary?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  jobTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  keySellingPoints?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  lastUpdatedTimestampUTC?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  latestJobs?: Resolver<
    Array<ResolversTypes['AnalyticsLatestJobs']>,
    ParentType,
    ContextType
  >;
  postTypeCategory?: Resolver<
    ResolversTypes['AnalyticsPostTypeCategory'],
    ParentType,
    ContextType
  >;
  salaryAdditionalText?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  serpLogoUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  subClassificationName?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Parent'] = ResolversParentTypes['Parent'],
> = {
  advertiserId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  nation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParentAdvertiserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ParentAdvertiser'] = ResolversParentTypes['ParentAdvertiser'],
> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ParentLocationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ParentLocation'] = ResolversParentTypes['ParentLocation'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  displayDescription?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PatchCompanyProfileResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PatchCompanyProfileResponse'] = ResolversParentTypes['PatchCompanyProfileResponse'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayBySavedCreditCardPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PayBySavedCreditCardPayload'] = ResolversParentTypes['PayBySavedCreditCardPayload'],
> = {
  __resolveType: TypeResolveFn<
    | 'SavedCreditCardPaymentSuccessPayload'
    | 'SavedCreditCardPaymentError'
    | 'OrderNotFoundError'
    | 'OrderExpiredError'
    | 'OrderInvalidError'
    | 'PromotionInvalidError',
    ParentType,
    ContextType
  >;
};

export type PayByStripeSavedCreditCardPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PayByStripeSavedCreditCardPayload'] = ResolversParentTypes['PayByStripeSavedCreditCardPayload'],
> = {
  __resolveType: TypeResolveFn<
    | 'SavedCreditCardPaymentSuccessPayload'
    | 'SavedCreditCardPaymentError'
    | 'OrderNotFoundError'
    | 'OrderExpiredError'
    | 'OrderInvalidError'
    | 'PromotionInvalidError',
    ParentType,
    ContextType
  >;
};

export type Payment2c2pInquiryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Payment2c2pInquiry'] = ResolversParentTypes['Payment2c2pInquiry'],
> = {
  amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  currency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reference?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<
    Maybe<ResolversTypes['Payment2c2pInquiryStatus']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Payment2c2pInquiryErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Payment2c2pInquiryError'] = ResolversParentTypes['Payment2c2pInquiryError'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Payment2c2pInquiryPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Payment2c2pInquiryPayload'] = ResolversParentTypes['Payment2c2pInquiryPayload'],
> = {
  __resolveType: TypeResolveFn<
    'Payment2c2pInquiry' | 'Payment2c2pInquiryError',
    ParentType,
    ContextType
  >;
};

export type PaymentConfigPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentConfigPayload'] = ResolversParentTypes['PaymentConfigPayload'],
> = {
  features?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentIntent2C2PPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentIntent2C2PPayload'] = ResolversParentTypes['PaymentIntent2C2PPayload'],
> = {
  paymentLink?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  paymentToken?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  expiryTimer?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentIntentChargeErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentIntentChargeError'] = ResolversParentTypes['PaymentIntentChargeError'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentIntentPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentIntentPayload'] = ResolversParentTypes['PaymentIntentPayload'],
> = {
  clientSecret?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMediumTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentMediumType'] = ResolversParentTypes['PaymentMediumType'],
> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  disablePayment?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentMethodError'] = ResolversParentTypes['PaymentMethodError'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentMethodPayload'] = ResolversParentTypes['PaymentMethodPayload'],
> = {
  maintenance?: Resolver<
    ResolversTypes['Maintenance'],
    ParentType,
    ContextType
  >;
  paymentMethods?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Maintenance']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PerApplicantBillingFeatureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PerApplicantBillingFeature'] = ResolversParentTypes['PerApplicantBillingFeature'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PerUnitBillingFeatureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PerUnitBillingFeature'] = ResolversParentTypes['PerUnitBillingFeature'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  unit?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PerformancePredictionChartEventResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PerformancePredictionChartEvent'] = ResolversParentTypes['PerformancePredictionChartEvent'],
> = {
  comparisonValue?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  day?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  editMade?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PerformancePredictionResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PerformancePredictionResponse'] = ResolversParentTypes['PerformancePredictionResponse'],
> = {
  events?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['PerformancePredictionChartEvent']>>>,
    ParentType,
    ContextType
  >;
  predictionDate?: Resolver<
    Maybe<ResolversTypes['Date']>,
    ParentType,
    ContextType
  >;
  predictionDayOptions?: Resolver<
    Array<ResolversTypes['PredictionDayOptions']>,
    ParentType,
    ContextType
  >;
  valueLabel?: Resolver<
    Maybe<ResolversTypes['CandidateLabel']>,
    ParentType,
    ContextType
  >;
  xAxisLabels?: Resolver<
    Maybe<Array<ResolversTypes['DateAxisLabel']>>,
    ParentType,
    ContextType
  >;
  xRange?: Resolver<
    Maybe<Array<ResolversTypes['Date']>>,
    ParentType,
    ContextType
  >;
  yRange?: Resolver<
    Maybe<Array<ResolversTypes['Int']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PhysicalAddressResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PhysicalAddress'] = ResolversParentTypes['PhysicalAddress'],
> = {
  addressLines?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  addressLine1?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  addressLine2?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  suburb?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PredictionDayOptionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PredictionDayOptions'] = ResolversParentTypes['PredictionDayOptions'],
> = {
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  prediction?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Price'] = ResolversParentTypes['Price'],
> = {
  retailPrice?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  netPrice?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  discount?: Resolver<
    Maybe<ResolversTypes['Discount']>,
    ParentType,
    ContextType
  >;
  isVariable?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  tax?: Resolver<Maybe<ResolversTypes['Tax']>, ParentType, ContextType>;
  retailTax?: Resolver<Maybe<ResolversTypes['Tax']>, ParentType, ContextType>;
  costPerApplicant?: Resolver<
    Maybe<ResolversTypes['Money']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PriceType'] = ResolversParentTypes['PriceType'],
> = {
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PricingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Pricing'] = ResolversParentTypes['Pricing'],
> = {
  __resolveType: TypeResolveFn<
    'CappedUnitPrice' | 'PriceType',
    ParentType,
    ContextType
  >;
};

export type PrimaryContactResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PrimaryContact'] = ResolversParentTypes['PrimaryContact'],
> = {
  firstName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Product'] = ResolversParentTypes['Product'],
> = {
  __resolveType: TypeResolveFn<
    'Ad' | 'AdBudget' | 'LineItemProduct',
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productType?: Resolver<
    ResolversTypes['ProductType'],
    ParentType,
    ContextType
  >;
};

export type ProductFeatureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductFeature'] = ResolversParentTypes['ProductFeature'],
> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductList'] = ResolversParentTypes['ProductList'],
> = {
  items?: Resolver<
    Array<ResolversTypes['CatalogueProduct']>,
    ParentType,
    ContextType
  >;
  products?: Resolver<
    Array<ResolversTypes['CatalogueProduct']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductReferenceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductReference'] = ResolversParentTypes['ProductReference'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductSetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductSet'] = ResolversParentTypes['ProductSet'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  items?: Resolver<
    Array<Maybe<ResolversTypes['CatalogueProduct']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductSetExpiredErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductSetExpiredError'] = ResolversParentTypes['ProductSetExpiredError'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductSetNotFoundErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductSetNotFoundError'] = ResolversParentTypes['ProductSetNotFoundError'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProfileEducationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProfileEducation'] = ResolversParentTypes['ProfileEducation'],
> = {
  qualificationName?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  institutionName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isCompleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  completionYear?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  completionMonth?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProfilePropertiesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProfileProperties'] = ResolversParentTypes['ProfileProperties'],
> = {
  HasOnlyUsedExpressCaja?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  HasLoggedInBefore?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  HasSeenJobDetailsTour?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  UserStatusBeforeDeactivation?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  HasSeenGuidedCallOut?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  ExpressCajaOptOut?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProfileResultHighlightResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProfileResultHighlight'] = ResolversParentTypes['ProfileResultHighlight'],
> = {
  resumeSnippet?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  skills?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProfileSalaryV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProfileSalaryV2'] = ResolversParentTypes['ProfileSalaryV2'],
> = {
  expected?: Resolver<
    Maybe<ResolversTypes['ExpectedSalaryV2']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PromoCodeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PromoCode'] = ResolversParentTypes['PromoCode'],
> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  discount?: Resolver<
    ResolversTypes['PromoCodeDiscount'],
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  validFrom?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  validTo?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  validProducts?: Resolver<
    Array<ResolversTypes['PromoCodeProduct']>,
    ParentType,
    ContextType
  >;
  campaignId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PromoCodeDiscountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PromoCodeDiscount'] = ResolversParentTypes['PromoCodeDiscount'],
> = {
  __resolveType: TypeResolveFn<
    'PromoCodeDiscountRate',
    ParentType,
    ContextType
  >;
};

export type PromoCodeDiscountRateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PromoCodeDiscountRate'] = ResolversParentTypes['PromoCodeDiscountRate'],
> = {
  discountRate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PromoCodeProductResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PromoCodeProduct'] = ResolversParentTypes['PromoCodeProduct'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PromotionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Promotion'] = ResolversParentTypes['Promotion'],
> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PromotionInvalidErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PromotionInvalidError'] = ResolversParentTypes['PromotionInvalidError'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublishCpqaEventPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishCPQAEventPayload'] = ResolversParentTypes['PublishCPQAEventPayload'],
> = {
  published?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublishJobAdBookingEventPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishJobAdBookingEventPayload'] = ResolversParentTypes['PublishJobAdBookingEventPayload'],
> = {
  published?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublishJobPostedEventPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PublishJobPostedEventPayload'] = ResolversParentTypes['PublishJobPostedEventPayload'],
> = {
  jobId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchasableProductsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PurchasableProducts'] = ResolversParentTypes['PurchasableProducts'],
> = {
  __resolveType: TypeResolveFn<
    'PurchasableProductsList' | 'PurchasableProductsString',
    ParentType,
    ContextType
  >;
};

export type PurchasableProductsListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PurchasableProductsList'] = ResolversParentTypes['PurchasableProductsList'],
> = {
  products?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PurchasableProductsStringResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PurchasableProductsString'] = ResolversParentTypes['PurchasableProductsString'],
> = {
  product?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query'],
> = {
  currentAdvertiser?: Resolver<
    ResolversTypes['CurrentAdvertiserResponse'],
    ParentType,
    ContextType
  >;
  currentUser?: Resolver<
    ResolversTypes['CurrentUserResponse'],
    ParentType,
    ContextType
  >;
  analyticsMyJobInformation?: Resolver<
    Maybe<ResolversTypes['MyJobInformationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Query_AnalyticsMyJobInformationArgs, 'input'>
  >;
  analyticsAdPerformanceReport?: Resolver<
    Maybe<ResolversTypes['AdPerformanceReportResponse']>,
    ParentType,
    ContextType,
    RequireFields<Query_AnalyticsAdPerformanceReportArgs, 'input'>
  >;
  analyticsApplicationPerformance?: Resolver<
    Maybe<ResolversTypes['ApplicationPerformanceResponse']>,
    ParentType,
    ContextType,
    RequireFields<Query_AnalyticsApplicationPerformanceArgs, 'input'>
  >;
  analyticsCandidateDemand?: Resolver<
    Maybe<ResolversTypes['CandidateDemandResponse']>,
    ParentType,
    ContextType,
    RequireFields<Query_AnalyticsCandidateDemandArgs, 'input'>
  >;
  analyticsCandidateSupply?: Resolver<
    Maybe<ResolversTypes['CandidateSupplyResponse']>,
    ParentType,
    ContextType,
    RequireFields<Query_AnalyticsCandidateSupplyArgs, 'input'>
  >;
  analyticsClicksOnYourAd?: Resolver<
    Maybe<ResolversTypes['ClicksOnYourAdResponse']>,
    ParentType,
    ContextType,
    RequireFields<Query_AnalyticsClicksOnYourAdArgs, 'input'>
  >;
  analyticsMarketInsightsTitle?: Resolver<
    Maybe<ResolversTypes['MarketInsightsTitleResponse']>,
    ParentType,
    ContextType,
    RequireFields<Query_AnalyticsMarketInsightsTitleArgs, 'input'>
  >;
  analyticsAdRating?: Resolver<
    Maybe<ResolversTypes['AdRatingResponse']>,
    ParentType,
    ContextType,
    RequireFields<Query_AnalyticsAdRatingArgs, 'input'>
  >;
  analyticsPageTitleActions?: Resolver<
    Maybe<ResolversTypes['PageTitleActionsResponse']>,
    ParentType,
    ContextType,
    RequireFields<Query_AnalyticsPageTitleActionsArgs, 'input'>
  >;
  analyticsPerformancePrediction?: Resolver<
    Maybe<ResolversTypes['PerformancePredictionResponse']>,
    ParentType,
    ContextType,
    RequireFields<Query_AnalyticsPerformancePredictionArgs, 'input'>
  >;
  analyticsRecommendations?: Resolver<
    Maybe<ResolversTypes['RecommendationsResponse']>,
    ParentType,
    ContextType,
    RequireFields<Query_AnalyticsRecommendationsArgs, 'input'>
  >;
  analyticsSimilarAdsDetails?: Resolver<
    Maybe<ResolversTypes['SimilarAdsResponse']>,
    ParentType,
    ContextType,
    RequireFields<Query_AnalyticsSimilarAdsDetailsArgs, 'input'>
  >;
  analyticsViewsInJobSearch?: Resolver<
    Maybe<ResolversTypes['ViewsInJobSearchResponse']>,
    ParentType,
    ContextType,
    RequireFields<Query_AnalyticsViewsInJobSearchArgs, 'input'>
  >;
  analyticsJobAdList?: Resolver<
    Maybe<ResolversTypes['JobAdListResponse']>,
    ParentType,
    ContextType,
    RequireFields<Query_AnalyticsJobAdListArgs, 'input'>
  >;
  analyticsAdUsageFilters?: Resolver<
    Maybe<ResolversTypes['AdUsageFiltersResponse']>,
    ParentType,
    ContextType,
    RequireFields<Query_AnalyticsAdUsageFiltersArgs, 'input'>
  >;
  analyticsMarketPerformance?: Resolver<
    Maybe<ResolversTypes['MarketPerformanceResponse']>,
    ParentType,
    ContextType,
    RequireFields<Query_AnalyticsMarketPerformanceArgs, 'input'>
  >;
  analyticsAdUsageSpend?: Resolver<
    Maybe<ResolversTypes['AdUsageSpendResponse']>,
    ParentType,
    ContextType,
    RequireFields<Query_AnalyticsAdUsageSpendArgs, 'input'>
  >;
  analyticsAdUsageConversion?: Resolver<
    Maybe<ResolversTypes['AdUsageConversionResponse']>,
    ParentType,
    ContextType,
    RequireFields<Query_AnalyticsAdUsageConversionArgs, 'input'>
  >;
  analyticsClassificationsAndUsersTrend?: Resolver<
    Maybe<ResolversTypes['AdUsageTrendClassificationsAndUsersResponse']>,
    ParentType,
    ContextType,
    RequireFields<Query_AnalyticsClassificationsAndUsersTrendArgs, 'input'>
  >;
  analyticsAdTypeTrend?: Resolver<
    Maybe<ResolversTypes['AdUsageAdTypeTrendResponse']>,
    ParentType,
    ContextType,
    RequireFields<Query_AnalyticsAdTypeTrendArgs, 'input'>
  >;
  analyticsAdUsageTipsToImproveAds?: Resolver<
    Maybe<ResolversTypes['TipsToImproveAdsResponse']>,
    ParentType,
    ContextType,
    RequireFields<Query_AnalyticsAdUsageTipsToImproveAdsArgs, 'input'>
  >;
  analyticsChildAccountBanner?: Resolver<
    Maybe<ResolversTypes['ChildAccountBannerResponse']>,
    ParentType,
    ContextType
  >;
  analyticsMostAdsByPerformance?: Resolver<
    Maybe<ResolversTypes['MostAdsByPerformanceResponse']>,
    ParentType,
    ContextType,
    RequireFields<Query_AnalyticsMostAdsByPerformanceArgs, 'input'>
  >;
  analyticsAdUsageLastUpdated?: Resolver<
    Maybe<ResolversTypes['LastUpdatedResponse']>,
    ParentType,
    ContextType
  >;
  analyticsMarketRoleInsightsSearch?: Resolver<
    Maybe<ResolversTypes['AnalyticsMarketRoleInsightsSearchResult']>,
    ParentType,
    ContextType,
    Partial<Query_AnalyticsMarketRoleInsightsSearchArgs>
  >;
  analyticsMarketRoleInsightsLocations?: Resolver<
    Maybe<ResolversTypes['AnalyticsMarketRoleInsightsLocationsResult']>,
    ParentType,
    ContextType,
    Partial<Query_AnalyticsMarketRoleInsightsLocationsArgs>
  >;
  analyticsMarketRoleInsightsJobTitles?: Resolver<
    Maybe<ResolversTypes['AnalyticsMarketRoleInsightsJobTitlesResult']>,
    ParentType,
    ContextType,
    Partial<Query_AnalyticsMarketRoleInsightsJobTitlesArgs>
  >;
  analyticsTalentSearchUsageFilters?: Resolver<
    Maybe<ResolversTypes['AnalyticsTalentSearchUsageFiltersResponse']>,
    ParentType,
    ContextType,
    RequireFields<Query_AnalyticsTalentSearchUsageFiltersArgs, 'input'>
  >;
  analyticsTalentSearchUsageCreditsUsageList?: Resolver<
    Maybe<ResolversTypes['AnalyticsTalentSearchUsageCreditsUsageListResponse']>,
    ParentType,
    ContextType,
    RequireFields<Query_AnalyticsTalentSearchUsageCreditsUsageListArgs, 'input'>
  >;
  analyticsTalentSearchUsageLastUpdated?: Resolver<
    Maybe<ResolversTypes['AnalyticsTalentSearchUsageLastUpdatedResponse']>,
    ParentType,
    ContextType
  >;
  analyticsUserParentAdvertiserId?: Resolver<
    ResolversTypes['ParentAdvertiser'],
    ParentType,
    ContextType
  >;
  contractBalances?: Resolver<
    Array<ResolversTypes['ContractBalance']>,
    ParentType,
    ContextType,
    RequireFields<Query_ContractBalancesArgs, 'advertiserNation'>
  >;
  _empty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  brand?: Resolver<
    Maybe<ResolversTypes['Brand']>,
    ParentType,
    ContextType,
    RequireFields<Query_BrandArgs, 'id'>
  >;
  defaultBrand?: Resolver<
    Maybe<ResolversTypes['Brand']>,
    ParentType,
    ContextType,
    Partial<Query_DefaultBrandArgs>
  >;
  brands?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Brand']>>>,
    ParentType,
    ContextType,
    Partial<Query_BrandsArgs>
  >;
  brandingAssets?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['BrandingAsset']>>>,
    ParentType,
    ContextType,
    RequireFields<Query_BrandingAssetsArgs, 'input'>
  >;
  suggestedCategories?: Resolver<
    Array<ResolversTypes['CategorySuggestion']>,
    ParentType,
    ContextType,
    RequireFields<Query_SuggestedCategoriesArgs, 'jobTitle' | 'country'>
  >;
  companyProfile?: Resolver<
    Maybe<ResolversTypes['CompanyProfile']>,
    ParentType,
    ContextType,
    RequireFields<Query_CompanyProfileArgs, 'id'>
  >;
  companyProfileIds?: Resolver<
    Maybe<ResolversTypes['CompanyProfileIds']>,
    ParentType,
    ContextType
  >;
  SavedCreditCards?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CreditCard']>>>,
    ParentType,
    ContextType,
    RequireFields<Query_SavedCreditCardsArgs, 'countryCode'>
  >;
  draftJob?: Resolver<
    Maybe<ResolversTypes['DraftJobPayload']>,
    ParentType,
    ContextType,
    RequireFields<Query_DraftJobArgs, 'id'>
  >;
  draftJobs?: Resolver<
    Maybe<ResolversTypes['DraftJobsPayload']>,
    ParentType,
    ContextType,
    RequireFields<Query_DraftJobsArgs, 'input'>
  >;
  hirerOriginZone?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  headerFooterContext?: Resolver<
    Maybe<ResolversTypes['HeaderFooterContext']>,
    ParentType,
    ContextType,
    Partial<Query_HeaderFooterContextArgs>
  >;
  hirerAccount?: Resolver<
    Maybe<ResolversTypes['HirerAccount']>,
    ParentType,
    ContextType,
    Partial<Query_HirerAccountArgs>
  >;
  hirerAccountDetails?: Resolver<
    Maybe<ResolversTypes['HirerAccountDetails']>,
    ParentType,
    ContextType,
    Partial<Query_HirerAccountDetailsArgs>
  >;
  hirerActivitiesByJob?: Resolver<
    ResolversTypes['HirerActivityConnection'],
    ParentType,
    ContextType,
    RequireFields<Query_HirerActivitiesByJobArgs, 'input'>
  >;
  hirerDashboardJobs?: Resolver<
    Maybe<ResolversTypes['HirerDashboardJobs']>,
    ParentType,
    ContextType,
    Partial<Query_HirerDashboardJobsArgs>
  >;
  hirerDashboardHiringAdviceList?: Resolver<
    Maybe<ResolversTypes['HirerDashboardHiringAdviceList']>,
    ParentType,
    ContextType
  >;
  hirerDashboardTalentSearch?: Resolver<
    ResolversTypes['HirerDashboardTalentSearch'],
    ParentType,
    ContextType
  >;
  hirerJobs?: Resolver<
    ResolversTypes['HirerJobConnection'],
    ParentType,
    ContextType,
    RequireFields<Query_HirerJobsArgs, 'input'>
  >;
  testUser?: Resolver<
    Maybe<ResolversTypes['HirerUserDetails']>,
    ParentType,
    ContextType
  >;
  identity?: Resolver<ResolversTypes['Identity'], ParentType, ContextType>;
  isMfaEnroled?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  emailVerified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  searchIntegrationPartners?: Resolver<
    Array<ResolversTypes['IntegrationPartner']>,
    ParentType,
    ContextType,
    RequireFields<Query_SearchIntegrationPartnersArgs, 'input'>
  >;
  getJobDetails?: Resolver<
    Maybe<ResolversTypes['Job']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetJobDetailsArgs, 'jobId'>
  >;
  location?: Resolver<
    Maybe<ResolversTypes['Location']>,
    ParentType,
    ContextType,
    RequireFields<Query_LocationArgs, 'id' | 'language'>
  >;
  locationsByIds?: Resolver<
    Array<Maybe<ResolversTypes['Location']>>,
    ParentType,
    ContextType,
    RequireFields<Query_LocationsByIdsArgs, 'ids' | 'language'>
  >;
  locations?: Resolver<
    Array<Maybe<ResolversTypes['LocationSearchResult']>>,
    ParentType,
    ContextType,
    Partial<Query_LocationsArgs>
  >;
  notificationPreference?: Resolver<
    ResolversTypes['NotificationPreference'],
    ParentType,
    ContextType,
    RequireFields<Query_NotificationPreferenceArgs, 'jobId' | 'advertiserId'>
  >;
  order?: Resolver<
    Maybe<ResolversTypes['Order']>,
    ParentType,
    ContextType,
    Partial<Query_OrderArgs>
  >;
  orderSummary?: Resolver<
    Maybe<ResolversTypes['OrderSummary']>,
    ParentType,
    ContextType,
    RequireFields<Query_OrderSummaryArgs, 'orderId' | 'advertiserNation'>
  >;
  SavedStripeCreditCards?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CreditCard']>>>,
    ParentType,
    ContextType,
    RequireFields<Query_SavedStripeCreditCardsArgs, 'countryCode'>
  >;
  payment2c2pInquiry?: Resolver<
    Maybe<ResolversTypes['Payment2c2pInquiryPayload']>,
    ParentType,
    ContextType,
    RequireFields<Query_Payment2c2pInquiryArgs, 'currency'>
  >;
  payment2c2pInquiryForInvoices?: Resolver<
    Maybe<ResolversTypes['Payment2c2pInquiryPayload']>,
    ParentType,
    ContextType,
    RequireFields<Query_Payment2c2pInquiryForInvoicesArgs, 'currency'>
  >;
  retrievePaymentIntent?: Resolver<
    Maybe<ResolversTypes['RetrievePaymentIntentPayload']>,
    ParentType,
    ContextType,
    RequireFields<Query_RetrievePaymentIntentArgs, 'id' | 'country'>
  >;
  getPaymentConfig?: Resolver<
    Maybe<ResolversTypes['PaymentConfigPayload']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetPaymentConfigArgs, 'site' | 'country'>
  >;
  getPaymentMethodForMaintenance?: Resolver<
    Maybe<ResolversTypes['PaymentMethodPayload']>,
    ParentType,
    ContextType,
    RequireFields<Query_GetPaymentMethodForMaintenanceArgs, 'site' | 'country'>
  >;
  getSurchargeAmount?: Resolver<
    ResolversTypes['GetSurchargeAmountPayload'],
    ParentType,
    ContextType,
    RequireFields<Query_GetSurchargeAmountArgs, 'input'>
  >;
  adBudgets?: Resolver<
    ResolversTypes['AdBudgetResponse'],
    ParentType,
    ContextType
  >;
  adBudgetById?: Resolver<
    ResolversTypes['AdBudget'],
    ParentType,
    ContextType,
    RequireFields<Query_AdBudgetByIdArgs, 'id'>
  >;
  getProductSet?: Resolver<
    ResolversTypes['GetProductSetResponse'],
    ParentType,
    ContextType,
    RequireFields<Query_GetProductSetArgs, 'input'>
  >;
  adProductsForAdPosting?: Resolver<
    ResolversTypes['AdProductSetResponse'],
    ParentType,
    ContextType,
    RequireFields<Query_AdProductsForAdPostingArgs, 'input'>
  >;
  adBudgetProductsForUpsell?: Resolver<
    ResolversTypes['AdBudgetProductsForUpsellResponse'],
    ParentType,
    ContextType,
    RequireFields<Query_AdBudgetProductsForUpsellArgs, 'input'>
  >;
  lowestPricedProductForAdPosting?: Resolver<
    ResolversTypes['LowestPricedProductForAdPostingResponse'],
    ParentType,
    ContextType,
    RequireFields<Query_LowestPricedProductForAdPostingArgs, 'input'>
  >;
  hirerDashboardBudgetUpsell?: Resolver<
    ResolversTypes['HirerDashboardBudgetUpsellResponse'],
    ParentType,
    ContextType,
    RequireFields<Query_HirerDashboardBudgetUpsellArgs, 'input'>
  >;
  promoCodes?: Resolver<
    Array<ResolversTypes['PromoCode']>,
    ParentType,
    ContextType
  >;
  questionnaire?: Resolver<
    ResolversTypes['Questionnaire'],
    ParentType,
    ContextType,
    RequireFields<Query_QuestionnaireArgs, 'id' | 'adcentreAdvertiserId'>
  >;
  suggestedSalary?: Resolver<
    Maybe<ResolversTypes['SuggestedSalary']>,
    ParentType,
    ContextType,
    RequireFields<
      Query_SuggestedSalaryArgs,
      'countryCode' | 'locationId' | 'roleTitle'
    >
  >;
  accountSelection?: Resolver<
    ResolversTypes['AccountSelectionOptions'],
    ParentType,
    ContextType,
    Partial<Query_AccountSelectionArgs>
  >;
  accountSelectionV2?: Resolver<
    ResolversTypes['AccountSelectionV2Response'],
    ParentType,
    ContextType
  >;
  countrySelectionOptions?: Resolver<
    ResolversTypes['CountrySelectionOptions'],
    ParentType,
    ContextType
  >;
  localisedContactInfo?: Resolver<
    Maybe<ResolversTypes['LocalisedContactInfoContext']>,
    ParentType,
    ContextType,
    RequireFields<Query_LocalisedContactInfoArgs, 'locale'>
  >;
  registrationOptions?: Resolver<
    ResolversTypes['RegistrationOptions'],
    ParentType,
    ContextType
  >;
  registrationStatus?: Resolver<
    ResolversTypes['RegistrationStatusInfo'],
    ParentType,
    ContextType,
    Partial<Query_RegistrationStatusArgs>
  >;
  redirectUrl?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    Partial<Query_RedirectUrlArgs>
  >;
  hirerAccountLoginUrl?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<Query_HirerAccountLoginUrlArgs, 'input'>
  >;
  talentSearchAccounts?: Resolver<
    Maybe<ResolversTypes['TalentSearchAccounts']>,
    ParentType,
    ContextType,
    Partial<Query_TalentSearchAccountsArgs>
  >;
  talentSearchATSJobs?: Resolver<
    Maybe<ResolversTypes['TalentSearchATSJobsConnection']>,
    ParentType,
    ContextType,
    RequireFields<Query_TalentSearchAtsJobsArgs, 'input'>
  >;
  talentSearchBudgetBalance?: Resolver<
    ResolversTypes['TalentSearchBudgetBalance'],
    ParentType,
    ContextType
  >;
  talentSearchCvUrl?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<Query_TalentSearchCvUrlArgs, 'profileId'>
  >;
  talentSearchConnectionStatus?: Resolver<
    ResolversTypes['ConnectionStatusPayload'],
    ParentType,
    ContextType,
    RequireFields<Query_TalentSearchConnectionStatusArgs, 'connectionId'>
  >;
  talentSearchConnectionsAvailabilities?: Resolver<
    Array<ResolversTypes['TalentSearchProfileConnectionsAvailability']>,
    ParentType,
    ContextType,
    RequireFields<Query_TalentSearchConnectionsAvailabilitiesArgs, 'profileIds'>
  >;
  talentSearchMessageDetails?: Resolver<
    ResolversTypes['TalentSearchMessageDetails'],
    ParentType,
    ContextType,
    RequireFields<Query_TalentSearchMessageDetailsArgs, 'connectionId'>
  >;
  talentSearchSendJobStatus?: Resolver<
    Array<ResolversTypes['TalentSearchProfileSendJobStatus']>,
    ParentType,
    ContextType,
    RequireFields<Query_TalentSearchSendJobStatusArgs, 'profileIds'>
  >;
  talentSearchUserFeatures?: Resolver<
    Array<Maybe<ResolversTypes['TalentSearchFeature']>>,
    ParentType,
    ContextType
  >;
  talentSearchInteractions?: Resolver<
    Maybe<ResolversTypes['TalentSearchInteractionsConnection']>,
    ParentType,
    ContextType,
    RequireFields<Query_TalentSearchInteractionsArgs, 'profileId'>
  >;
  talentSearchLatestInteractions?: Resolver<
    Array<ResolversTypes['TalentSearchLatestInteraction']>,
    ParentType,
    ContextType,
    RequireFields<Query_TalentSearchLatestInteractionsArgs, 'profileIds'>
  >;
  talentSearchJobsV2?: Resolver<
    Maybe<ResolversTypes['TalentSearchJobsV2']>,
    ParentType,
    ContextType,
    Partial<Query_TalentSearchJobsV2Args>
  >;
  talentSearchJobByIdV2?: Resolver<
    Maybe<ResolversTypes['TalentSearchJobV2']>,
    ParentType,
    ContextType,
    RequireFields<Query_TalentSearchJobByIdV2Args, 'id' | 'language'>
  >;
  talentSearchAvailableJobsV2?: Resolver<
    Array<ResolversTypes['TalentSearchAvailableJobV2']>,
    ParentType,
    ContextType,
    RequireFields<Query_TalentSearchAvailableJobsV2Args, 'input'>
  >;
  talentSearchMetadataIndustries?: Resolver<
    Array<ResolversTypes['TalentSearchMetadataIndustry']>,
    ParentType,
    ContextType,
    Partial<Query_TalentSearchMetadataIndustriesArgs>
  >;
  talentSearchMetadataCompanySuggest?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    Partial<Query_TalentSearchMetadataCompanySuggestArgs>
  >;
  talentSearchMetadataQueryValidations?: Resolver<
    Maybe<ResolversTypes['TalentSearchMetadataQueryValidations']>,
    ParentType,
    ContextType,
    Partial<Query_TalentSearchMetadataQueryValidationsArgs>
  >;
  talentSearchMetadataMarkets?: Resolver<
    Array<ResolversTypes['TalentSearchMarket']>,
    ParentType,
    ContextType,
    Partial<Query_TalentSearchMetadataMarketsArgs>
  >;
  talentSearchPools?: Resolver<
    Array<ResolversTypes['TalentSearchPool']>,
    ParentType,
    ContextType
  >;
  talentSearchAssignedPools?: Resolver<
    Array<ResolversTypes['TalentSearchAssignedPools']>,
    ParentType,
    ContextType,
    Partial<Query_TalentSearchAssignedPoolsArgs>
  >;
  talentSearchProfilesByEmail?: Resolver<
    ResolversTypes['TalentSearchProfileResultsV2'],
    ParentType,
    ContextType,
    RequireFields<Query_TalentSearchProfilesByEmailArgs, 'input'>
  >;
  talentSearchProfileV2?: Resolver<
    ResolversTypes['TalentSearchProfileResultV2'],
    ParentType,
    ContextType,
    RequireFields<Query_TalentSearchProfileV2Args, 'input'>
  >;
  talentSearchProfileResume?: Resolver<
    ResolversTypes['TalentSearchProfileResumeResult'],
    ParentType,
    ContextType,
    RequireFields<Query_TalentSearchProfileResumeArgs, 'input'>
  >;
  talentSearchSimilarCandidatesByIdV2?: Resolver<
    Maybe<ResolversTypes['TalentSearchSimilarCandidatesResultV2']>,
    ParentType,
    ContextType,
    RequireFields<Query_TalentSearchSimilarCandidatesByIdV2Args, 'input'>
  >;
  talentSearchUncoupledSearchV2?: Resolver<
    ResolversTypes['TalentSearchProfileResultsV2'],
    ParentType,
    ContextType,
    RequireFields<Query_TalentSearchUncoupledSearchV2Args, 'input'>
  >;
  talentSearchUncoupledCountForQueryV2?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    RequireFields<Query_TalentSearchUncoupledCountForQueryV2Args, 'input'>
  >;
  talentSearchUncoupledCountsV2?: Resolver<
    ResolversTypes['TalentSearchCountsResults'],
    ParentType,
    ContextType,
    RequireFields<Query_TalentSearchUncoupledCountsV2Args, 'input'>
  >;
  talentSearchRecommendedCandidatesV2?: Resolver<
    Maybe<ResolversTypes['TalentSearchProfileResultsV2']>,
    ParentType,
    ContextType,
    RequireFields<Query_TalentSearchRecommendedCandidatesV2Args, 'input'>
  >;
  talentSearchPoolCandidatesV2?: Resolver<
    ResolversTypes['TalentSearchProfileResultsV2'],
    ParentType,
    ContextType,
    RequireFields<Query_TalentSearchPoolCandidatesV2Args, 'input'>
  >;
  talentSearchSavedSearches?: Resolver<
    Array<ResolversTypes['TalentSearchSavedSearch']>,
    ParentType,
    ContextType
  >;
  talentSearchUsageHirerActivityV2?: Resolver<
    ResolversTypes['TalentSearchUsageHirerActivityV2Connection'],
    ParentType,
    ContextType,
    RequireFields<Query_TalentSearchUsageHirerActivityV2Args, 'input'>
  >;
  talentSearchUsageAggregateV2?: Resolver<
    ResolversTypes['TalentSearchUsageAggregateV2'],
    ParentType,
    ContextType,
    RequireFields<Query_TalentSearchUsageAggregateV2Args, 'input'>
  >;
  talentSearchUsageSpendAggregate?: Resolver<
    ResolversTypes['TalentSearchUsageSpendAggregate'],
    ParentType,
    ContextType,
    RequireFields<Query_TalentSearchUsageSpendAggregateArgs, 'input'>
  >;
  talentSearchUserContext?: Resolver<
    Maybe<ResolversTypes['TalentSearchUserContext']>,
    ParentType,
    ContextType
  >;
  talentSearchUserContextV2?: Resolver<
    ResolversTypes['TalentSearchUserContext'],
    ParentType,
    ContextType,
    RequireFields<Query_TalentSearchUserContextV2Args, 'input'>
  >;
  user?: Resolver<
    Maybe<ResolversTypes['UserInfo']>,
    ParentType,
    ContextType,
    Partial<Query_UserArgs>
  >;
  hirerAccountUser?: Resolver<
    Maybe<ResolversTypes['UserDetails']>,
    ParentType,
    ContextType,
    RequireFields<Query_HirerAccountUserArgs, 'input'>
  >;
  hirerUserEmail?: Resolver<
    ResolversTypes['UserEmailDetails'],
    ParentType,
    ContextType,
    RequireFields<Query_HirerUserEmailArgs, 'input'>
  >;
  advertiserAccounts?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['AdvertiserAccount']>>>,
    ParentType,
    ContextType,
    RequireFields<Query_AdvertiserAccountsArgs, 'input'>
  >;
  hirerAccountUsersWithJobPermissions?: Resolver<
    Array<ResolversTypes['UserWithJobPermission']>,
    ParentType,
    ContextType,
    RequireFields<Query_HirerAccountUsersWithJobPermissionsArgs, 'input'>
  >;
  yourCandidates?: Resolver<
    ResolversTypes['YourCandidatesResponse'],
    ParentType,
    ContextType,
    RequireFields<Query_YourCandidatesArgs, 'input'>
  >;
};

export type QuestionnaireResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Questionnaire'] = ResolversParentTypes['Questionnaire'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  published?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  questions?: Resolver<
    Maybe<Array<ResolversTypes['QuestionnaireQuestion']>>,
    ParentType,
    ContextType,
    Partial<Questionnaire_QuestionsArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestionnaireAnswerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['QuestionnaireAnswer'] = ResolversParentTypes['QuestionnaireAnswer'],
> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  globalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isNegative?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPreferred?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestionnaireQuestionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['QuestionnaireQuestion'] = ResolversParentTypes['QuestionnaireQuestion'],
> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  globalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  source?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  answerType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  statement?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  essential?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  answers?: Resolver<
    Maybe<Array<ResolversTypes['QuestionnaireAnswer']>>,
    ParentType,
    ContextType
  >;
  hirerExperience?: Resolver<
    Maybe<ResolversTypes['HirerExperience']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RangeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Range'] = ResolversParentTypes['Range'],
> = {
  minimum?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maximum?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecommendationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Recommendation'] = ResolversParentTypes['Recommendation'],
> = {
  __resolveType: TypeResolveFn<
    | 'RevealSalary'
    | 'GranularLocation'
    | 'LowClick'
    | 'LowApply'
    | 'BrandedAd'
    | 'RoleRequirements'
    | 'LowSalary'
    | 'TalentSearch',
    ParentType,
    ContextType
  >;
};

export type RecommendationsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RecommendationsResponse'] = ResolversParentTypes['RecommendationsResponse'],
> = {
  isExpired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  postTypeCategory?: Resolver<
    ResolversTypes['AnalyticsPostTypeCategory'],
    ParentType,
    ContextType
  >;
  recommendations?: Resolver<
    Array<ResolversTypes['Recommendation']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RefreshJobResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RefreshJobResponse'] = ResolversParentTypes['RefreshJobResponse'],
> = {
  jobId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  revisionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  idempotencyKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RegisterPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RegisterPayload'] = ResolversParentTypes['RegisterPayload'],
> = {
  advertiserId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  seekId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  redirectUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RegistrationOptionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RegistrationOptions'] = ResolversParentTypes['RegistrationOptions'],
> = {
  allowAgencyRegistration?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RegistrationStatusInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RegistrationStatusInfo'] = ResolversParentTypes['RegistrationStatusInfo'],
> = {
  userExists?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReinstateResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReinstateResponse'] = ResolversParentTypes['ReinstateResponse'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RepostOptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RepostOption'] = ResolversParentTypes['RepostOption'],
> = {
  key?: Resolver<ResolversTypes['AdRepost'], ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResponseErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResponseError'] = ResolversParentTypes['ResponseError'],
> = {
  error?: Resolver<
    ResolversTypes['ResponseErrorReason'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResumeEducationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResumeEducation'] = ResolversParentTypes['ResumeEducation'],
> = {
  qualificationName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  institutionName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  displayDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResumeWorkHistoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResumeWorkHistory'] = ResolversParentTypes['ResumeWorkHistory'],
> = {
  companyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  jobTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  foundInCV?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  highlight?: Resolver<
    Maybe<ResolversTypes['WorkHistoryHighlight']>,
    ParentType,
    ContextType
  >;
  displayDescription?: Resolver<
    Array<ResolversTypes['ResumeWorkHistoryDisplayDescription']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResumeWorkHistoryDisplayDescriptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResumeWorkHistoryDisplayDescription'] = ResolversParentTypes['ResumeWorkHistoryDisplayDescription'],
> = {
  isBullet?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RetrievePaymentIntentPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RetrievePaymentIntentPayload'] = ResolversParentTypes['RetrievePaymentIntentPayload'],
> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  currency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reference?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  virtualAccount?: Resolver<
    Maybe<ResolversTypes['VirtualAccount']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RevealAdSalaryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RevealAdSalary'] = ResolversParentTypes['RevealAdSalary'],
> = {
  type?: Resolver<
    ResolversTypes['TipsToImproveAdsType'],
    ParentType,
    ContextType
  >;
  adCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RevealSalaryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RevealSalary'] = ResolversParentTypes['RevealSalary'],
> = {
  type?: Resolver<
    ResolversTypes['RecommendationType'],
    ParentType,
    ContextType
  >;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReviewAdDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReviewAdDetails'] = ResolversParentTypes['ReviewAdDetails'],
> = {
  type?: Resolver<
    ResolversTypes['TipsToImproveAdsType'],
    ParentType,
    ContextType
  >;
  adCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  conversionRate?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  benchmarkConversionRate?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReviewAdSummaryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReviewAdSummary'] = ResolversParentTypes['ReviewAdSummary'],
> = {
  type?: Resolver<
    ResolversTypes['TipsToImproveAdsType'],
    ParentType,
    ContextType
  >;
  adCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  conversionRate?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  benchmarkConversionRate?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RightToWorkResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RightToWork'] = ResolversParentTypes['RightToWork'],
> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isVerified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  viewFields?: Resolver<
    Maybe<Array<ResolversTypes['VerificationViewField']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RightToWorkInSavedSearchResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RightToWorkInSavedSearch'] = ResolversParentTypes['RightToWorkInSavedSearch'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<RightToWorkInSavedSearch_LabelArgs, 'language'>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoleRequirementsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RoleRequirements'] = ResolversParentTypes['RoleRequirements'],
> = {
  type?: Resolver<
    ResolversTypes['RecommendationType'],
    ParentType,
    ContextType
  >;
  roleReqsQuestions?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoleRequirementsMandatoryQuestionsFeatureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RoleRequirementsMandatoryQuestionsFeature'] = ResolversParentTypes['RoleRequirementsMandatoryQuestionsFeature'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  questionAnswerSet?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RuleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Rule'] = ResolversParentTypes['Rule'],
> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface SafeIntScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['SafeInt'], any> {
  name: 'SafeInt';
}

export type SalaryAmountV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SalaryAmountV2'] = ResolversParentTypes['SalaryAmountV2'],
> = {
  frequency?: Resolver<
    ResolversTypes['SalaryFrequency'],
    ParentType,
    ContextType
  >;
  value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SavedCreditCardPaymentErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SavedCreditCardPaymentError'] = ResolversParentTypes['SavedCreditCardPaymentError'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  errorCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  declineCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  param?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paymentIntentId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentIntentStatus?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SavedCreditCardPaymentSuccessPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SavedCreditCardPaymentSuccessPayload'] = ResolversParentTypes['SavedCreditCardPaymentSuccessPayload'],
> = {
  paymentIntentId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clientSecret?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SecureLinkExpiredErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SecureLinkExpiredError'] = ResolversParentTypes['SecureLinkExpiredError'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SecureLinkInvalidErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SecureLinkInvalidError'] = ResolversParentTypes['SecureLinkInvalidError'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SecureLinkResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SecureLinkResponse'] = ResolversParentTypes['SecureLinkResponse'],
> = {
  __resolveType: TypeResolveFn<
    | 'SecureLinkSuccessResponse'
    | 'SecureLinkUsedError'
    | 'SecureLinkExpiredError'
    | 'SecureLinkInvalidError',
    ParentType,
    ContextType
  >;
};

export type SecureLinkSuccessResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SecureLinkSuccessResponse'] = ResolversParentTypes['SecureLinkSuccessResponse'],
> = {
  redirectUri?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SecureLinkUsedErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SecureLinkUsedError'] = ResolversParentTypes['SecureLinkUsedError'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SellingPointResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SellingPoint'] = ResolversParentTypes['SellingPoint'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isHighlighted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  helpTip?: Resolver<Maybe<ResolversTypes['HelpTip']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SendEmailResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SendEmailResponse'] = ResolversParentTypes['SendEmailResponse'],
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SendEmailVerificationEmailPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SendEmailVerificationEmailPayload'] = ResolversParentTypes['SendEmailVerificationEmailPayload'],
> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  emailVerified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SendEnrolmentEmailPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SendEnrolmentEmailPayload'] = ResolversParentTypes['SendEnrolmentEmailPayload'],
> = {
  isEnrolmentEmailSent?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SendHirerAccountUsersJobAccessEmailPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SendHirerAccountUsersJobAccessEmailPayload'] = ResolversParentTypes['SendHirerAccountUsersJobAccessEmailPayload'],
> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SendLoginCallbackEventPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SendLoginCallbackEventPayload'] = ResolversParentTypes['SendLoginCallbackEventPayload'],
> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SetHirerAccountUsersJobAccessPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SetHirerAccountUsersJobAccessPayload'] = ResolversParentTypes['SetHirerAccountUsersJobAccessPayload'],
> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SetNotificationPreferencesPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SetNotificationPreferencesPayload'] = ResolversParentTypes['SetNotificationPreferencesPayload'],
> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SimilarAdsDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SimilarAdsDetails'] = ResolversParentTypes['SimilarAdsDetails'],
> = {
  advertiserName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  adType?: Resolver<ResolversTypes['AnalyticsAdType'], ParentType, ContextType>;
  hasKeySellingPoints?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  isPrivateAdvertiser?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  isSalaryHidden?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  jobCreatedTimestampUTC?: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType
  >;
  jobId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  jobLocationLabel?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  jobTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productDisplayName?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  serpLogoUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SimilarAdsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SimilarAdsResponse'] = ResolversParentTypes['SimilarAdsResponse'],
> = {
  ads?: Resolver<
    Array<ResolversTypes['SimilarAdsDetails']>,
    ParentType,
    ContextType
  >;
  benchmarkLocationLabel?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  benchmarkScenario?: Resolver<
    Maybe<ResolversTypes['AnalyticsBenchmarkScenario']>,
    ParentType,
    ContextType
  >;
  classificationName?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  normalisedRoleTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  subClassificationName?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SimplifiedAnswersResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SimplifiedAnswers'] = ResolversParentTypes['SimplifiedAnswers'],
> = {
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  globalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  answerIds?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  answerGlobalIds?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  isPreferred?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SponsoredSlotFeatureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SponsoredSlotFeature'] = ResolversParentTypes['SponsoredSlotFeature'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StripePaymentIntentPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StripePaymentIntentPayload'] = ResolversParentTypes['StripePaymentIntentPayload'],
> = {
  clientSecret?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  qrCodeUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  paymentLink?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  reference?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StyleEnhancementFeatureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StyleEnhancementFeature'] = ResolversParentTypes['StyleEnhancementFeature'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  style?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubTitleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SubTitle'] = ResolversParentTypes['SubTitle'],
> = {
  style?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubjectResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Subject'] = ResolversParentTypes['Subject'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  emailVerified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SuggestedSalaryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SuggestedSalary'] = ResolversParentTypes['SuggestedSalary'],
> = {
  range?: Resolver<
    Maybe<ResolversTypes['SuggestedSalaryRange']>,
    ParentType,
    ContextType
  >;
  averageRange?: Resolver<
    Maybe<ResolversTypes['SuggestedSalaryAverageRange']>,
    ParentType,
    ContextType
  >;
  countryCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  currency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locationId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roleTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  workType?: Resolver<
    Maybe<ResolversTypes['WorkType']>,
    ParentType,
    ContextType
  >;
  salaryType?: Resolver<
    Maybe<ResolversTypes['SalaryType']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SuggestedSalaryAverageRangeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SuggestedSalaryAverageRange'] = ResolversParentTypes['SuggestedSalaryAverageRange'],
> = {
  min?: Resolver<Maybe<ResolversTypes['SafeInt']>, ParentType, ContextType>;
  median?: Resolver<Maybe<ResolversTypes['SafeInt']>, ParentType, ContextType>;
  max?: Resolver<Maybe<ResolversTypes['SafeInt']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SuggestedSalaryRangeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SuggestedSalaryRange'] = ResolversParentTypes['SuggestedSalaryRange'],
> = {
  min?: Resolver<Maybe<ResolversTypes['SafeInt']>, ParentType, ContextType>;
  max?: Resolver<Maybe<ResolversTypes['SafeInt']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SurchargeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Surcharge'] = ResolversParentTypes['Surcharge'],
> = {
  surchargeType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearch'] = ResolversParentTypes['TalentSearch'],
> = {
  type?: Resolver<
    ResolversTypes['RecommendationType'],
    ParentType,
    ContextType
  >;
  jobId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hirerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchAtsJobResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchATSJob'] = ResolversParentTypes['TalentSearchATSJob'],
> = {
  jobId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  jobTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  externalReferenceId?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  seekHirerJobReference?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  positionProfileName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  jobOwners?: Resolver<
    Maybe<Array<ResolversTypes['TalentSearchJobOwner']>>,
    ParentType,
    ContextType
  >;
  jobLocations?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchAtsJobEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchATSJobEdge'] = ResolversParentTypes['TalentSearchATSJobEdge'],
> = {
  node?: Resolver<
    ResolversTypes['TalentSearchATSJob'],
    ParentType,
    ContextType
  >;
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchAtsJobsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchATSJobs'] = ResolversParentTypes['TalentSearchATSJobs'],
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  items?: Resolver<
    Maybe<Array<ResolversTypes['TalentSearchATSJob']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchAtsJobsConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchATSJobsConnection'] = ResolversParentTypes['TalentSearchATSJobsConnection'],
> = {
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  edges?: Resolver<
    Array<ResolversTypes['TalentSearchATSJobEdge']>,
    ParentType,
    ContextType
  >;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchAccountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchAccount'] = ResolversParentTypes['TalentSearchAccount'],
> = {
  advertiserId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  advertiserName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  seekBillingAccountId?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  hasPremiumTalentSearchAccess?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  hasConnectedTalentSearchAccess?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  parentAdvertiserId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  companyAddress?: Resolver<
    Maybe<ResolversTypes['CompanyAddress']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchAccountsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchAccounts'] = ResolversParentTypes['TalentSearchAccounts'],
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  items?: Resolver<
    Array<ResolversTypes['TalentSearchAccount']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchAdvertiserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchAdvertiser'] = ResolversParentTypes['TalentSearchAdvertiser'],
> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  accountNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  parentAdvertiserId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  isApproved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  tags?: Resolver<
    Maybe<ResolversTypes['TalentSearchHirerAccountTags']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchAssignedPoolResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchAssignedPool'] = ResolversParentTypes['TalentSearchAssignedPool'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchAssignedPoolsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchAssignedPools'] = ResolversParentTypes['TalentSearchAssignedPools'],
> = {
  profileId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  assignedPools?: Resolver<
    Array<ResolversTypes['TalentSearchAssignedPool']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchAtsHirerRelationshipResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchAtsHirerRelationship'] = ResolversParentTypes['TalentSearchAtsHirerRelationship'],
> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchAvailableJobV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchAvailableJobV2'] = ResolversParentTypes['TalentSearchAvailableJobV2'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  expiry?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchBudgetBalanceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchBudgetBalance'] = ResolversParentTypes['TalentSearchBudgetBalance'],
> = {
  initialBalance?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currentBalance?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchConnectionAvailabilityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchConnectionAvailability'] = ResolversParentTypes['TalentSearchConnectionAvailability'],
> = {
  connectionType?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  connectionTypeName?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  isUsed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  connectionId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdDateUtc?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  nextAvailableDateUtc?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchContractResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchContract'] = ResolversParentTypes['TalentSearchContract'],
> = {
  startDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  endDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productType?: Resolver<
    ResolversTypes['TalentSearchContractType'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchCountResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchCountResult'] = ResolversParentTypes['TalentSearchCountResult'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchCountResultWithLabelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchCountResultWithLabel'] = ResolversParentTypes['TalentSearchCountResultWithLabel'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchCountsResultsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchCountsResults'] = ResolversParentTypes['TalentSearchCountsResults'],
> = {
  approachabilitysignal?: Resolver<
    Maybe<Array<ResolversTypes['TalentSearchCountResult']>>,
    ParentType,
    ContextType
  >;
  company?: Resolver<
    Maybe<Array<ResolversTypes['TalentSearchCountResult']>>,
    ParentType,
    ContextType
  >;
  currentLocation?: Resolver<
    Maybe<Array<ResolversTypes['TalentSearchCountResult']>>,
    ParentType,
    ContextType
  >;
  industry?: Resolver<
    Maybe<Array<ResolversTypes['TalentSearchCountResult']>>,
    ParentType,
    ContextType
  >;
  salary?: Resolver<
    Maybe<Array<ResolversTypes['TalentSearchCountResult']>>,
    ParentType,
    ContextType
  >;
  rightToWork?: Resolver<
    Maybe<Array<ResolversTypes['TalentSearchCountResultWithLabel']>>,
    ParentType,
    ContextType,
    RequireFields<TalentSearchCountsResults_RightToWorkArgs, 'language'>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchCriteriaResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchCriteria'] = ResolversParentTypes['TalentSearchCriteria'],
> = {
  approachable?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  company?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  countryCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  industry?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  keywords?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastUpdated?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  locationList?: Resolver<
    Array<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  locationDisplayTexts?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<TalentSearchCriteria_LocationDisplayTextsArgs, 'language'>
  >;
  nation?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  maxSalary?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minSalary?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  salaryNation?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  salaryType?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  salaryUnspecified?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  uncoupledFreeText?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  visa?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  rightToWorkList?: Resolver<
    Maybe<Array<ResolversTypes['RightToWorkInSavedSearch']>>,
    ParentType,
    ContextType
  >;
  rightToWorkUnspecified?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  willingToRelocate?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  workType?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  cv?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  profileTypes?: Resolver<
    Maybe<Array<ResolversTypes['ProfileSource']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchDailyUsageV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchDailyUsageV2'] = ResolversParentTypes['TalentSearchDailyUsageV2'],
> = {
  cvPreviewCount?: Resolver<
    Array<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  cvDownloadCount?: Resolver<
    Array<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  profileViewCount?: Resolver<
    Array<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  searchCount?: Resolver<Array<ResolversTypes['Int']>, ParentType, ContextType>;
  sendJobAppsCompleted?: Resolver<
    Array<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  sendJobAppsStarted?: Resolver<
    Array<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  sendJobCount?: Resolver<
    Array<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  sendMessageCount?: Resolver<
    Array<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  sendMessageOpened?: Resolver<
    Array<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchFeatureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchFeature'] = ResolversParentTypes['TalentSearchFeature'],
> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchHirerAccountTagsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchHirerAccountTags'] = ResolversParentTypes['TalentSearchHirerAccountTags'],
> = {
  testRecord?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  testScope?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  recordExpiry?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  testBehaviours?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchHirerContactDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchHirerContactDetails'] = ResolversParentTypes['TalentSearchHirerContactDetails'],
> = {
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  position?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchInteractionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchInteraction'] = ResolversParentTypes['TalentSearchInteraction'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  interactionType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  positionProfileId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  statusCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  seekSourceName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  associatedParties?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['AssociatedParty']>>>,
    ParentType,
    ContextType
  >;
  associatedAction?: Resolver<
    ResolversTypes['AssociatedAction'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchInteractionEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchInteractionEdge'] = ResolversParentTypes['TalentSearchInteractionEdge'],
> = {
  node?: Resolver<
    ResolversTypes['TalentSearchInteraction'],
    ParentType,
    ContextType
  >;
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchInteractionsConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchInteractionsConnection'] = ResolversParentTypes['TalentSearchInteractionsConnection'],
> = {
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  edges?: Resolver<
    Array<ResolversTypes['TalentSearchInteractionEdge']>,
    ParentType,
    ContextType
  >;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchJobLocationV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchJobLocationV2'] = ResolversParentTypes['TalentSearchJobLocationV2'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  countryCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchJobOwnerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchJobOwner'] = ResolversParentTypes['TalentSearchJobOwner'],
> = {
  firstName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  formattedName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchJobSalaryV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchJobSalaryV2'] = ResolversParentTypes['TalentSearchJobSalaryV2'],
> = {
  frequency?: Resolver<
    ResolversTypes['SalaryFrequency'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchJobV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchJobV2'] = ResolversParentTypes['TalentSearchJobV2'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  advertiserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  expiryDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  balance?: Resolver<
    ResolversTypes['TalentSearchBudgetBalance'],
    ParentType,
    ContextType
  >;
  location?: Resolver<
    ResolversTypes['TalentSearchJobLocationV2'],
    ParentType,
    ContextType,
    RequireFields<TalentSearchJobV2_LocationArgs, 'language'>
  >;
  salary?: Resolver<
    ResolversTypes['TalentSearchJobSalaryV2'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchJobsV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchJobsV2'] = ResolversParentTypes['TalentSearchJobsV2'],
> = {
  totalJobs?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  jobs?: Resolver<
    Array<ResolversTypes['TalentSearchJobV2']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchLatestInteractionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchLatestInteraction'] = ResolversParentTypes['TalentSearchLatestInteraction'],
> = {
  profileId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  interaction?: Resolver<
    Maybe<ResolversTypes['TalentSearchInteraction']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchMarketResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchMarket'] = ResolversParentTypes['TalentSearchMarket'],
> = {
  countryCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  countryName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchMessageDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchMessageDetails'] = ResolversParentTypes['TalentSearchMessageDetails'],
> = {
  subject?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  body?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hirer?: Resolver<
    ResolversTypes['TalentSearchHirerContactDetails'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchMetadataIndustryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchMetadataIndustry'] = ResolversParentTypes['TalentSearchMetadataIndustry'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subIndustries?: Resolver<
    Maybe<Array<ResolversTypes['TalentSearchMetadataSubIndustry']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchMetadataQueryValidationsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchMetadataQueryValidations'] = ResolversParentTypes['TalentSearchMetadataQueryValidations'],
> = {
  stopwords?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  whitelistRegexPattern?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchMetadataSubIndustryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchMetadataSubIndustry'] = ResolversParentTypes['TalentSearchMetadataSubIndustry'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchPoolResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchPool'] = ResolversParentTypes['TalentSearchPool'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastUpdated?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  profileCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  policy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchPoolProfileResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchPoolProfile'] = ResolversParentTypes['TalentSearchPoolProfile'],
> = {
  poolId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  profileId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastUpdated?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  searchId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchProfileConnectionsAvailabilityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchProfileConnectionsAvailability'] = ResolversParentTypes['TalentSearchProfileConnectionsAvailability'],
> = {
  profileId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  connectionsAvailability?: Resolver<
    Array<ResolversTypes['TalentSearchConnectionAvailability']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchProfileResultV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchProfileResultV2'] = ResolversParentTypes['TalentSearchProfileResultV2'],
> = {
  salary?: Resolver<
    Maybe<ResolversTypes['ProfileSalaryV2']>,
    ParentType,
    ContextType
  >;
  currentInferredIndustry?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  currentIndustry?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  currentIndustryId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  currentSubindustry?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  currentSubindustryId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  currentJobTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  currentLocation?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<TalentSearchProfileResultV2_CurrentLocationArgs, 'language'>
  >;
  homeLocationId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  pastJobTitles?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  suburb?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hasResume?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  resumeCoreDataAvailable?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  jobSeekerId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  noticePeriodDays?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  lastModifiedDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  atsLastUpdatedDateUtc?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  profileId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  profileType?: Resolver<
    ResolversTypes['ProfileTypes'],
    ParentType,
    ContextType
  >;
  profilePrivacy?: Resolver<
    ResolversTypes['PrivacyTypes'],
    ParentType,
    ContextType
  >;
  resumeSnippet?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  skills?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  workTypes?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  workHistories?: Resolver<
    Array<ResolversTypes['WorkHistory']>,
    ParentType,
    ContextType
  >;
  highlight?: Resolver<
    Maybe<ResolversTypes['ProfileResultHighlight']>,
    ParentType,
    ContextType
  >;
  approachabilitySignal?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  actionLinks?: Resolver<
    Maybe<ResolversTypes['ActionLink']>,
    ParentType,
    ContextType
  >;
  partnerDoNotContact?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  profileEducation?: Resolver<
    Maybe<Array<ResolversTypes['ProfileEducation']>>,
    ParentType,
    ContextType
  >;
  ahpraRegistrations?: Resolver<
    Maybe<Array<ResolversTypes['AhpraRegistration']>>,
    ParentType,
    ContextType
  >;
  driversLicences?: Resolver<
    Maybe<Array<ResolversTypes['DriversLicence']>>,
    ParentType,
    ContextType
  >;
  sensitiveWordsFoundInResumeSnippet?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  sensitiveSearchTermsInResumeSnippet?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  languages?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  rightToWork?: Resolver<
    Maybe<ResolversTypes['RightToWork']>,
    ParentType,
    ContextType,
    RequireFields<TalentSearchProfileResultV2_RightToWorkArgs, 'language'>
  >;
  hasVerifiedCredentials?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchProfileResultsV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchProfileResultsV2'] = ResolversParentTypes['TalentSearchProfileResultsV2'],
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  items?: Resolver<
    Array<ResolversTypes['TalentSearchProfileResultV2']>,
    ParentType,
    ContextType
  >;
  searchIdentifier?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchProfileResumeResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchProfileResumeResult'] = ResolversParentTypes['TalentSearchProfileResumeResult'],
> = {
  resumeWorkHistories?: Resolver<
    Array<ResolversTypes['ResumeWorkHistory']>,
    ParentType,
    ContextType
  >;
  resumeEducation?: Resolver<
    Array<ResolversTypes['ResumeEducation']>,
    ParentType,
    ContextType
  >;
  resumeSkills?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchProfileSendJobStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchProfileSendJobStatus'] = ResolversParentTypes['TalentSearchProfileSendJobStatus'],
> = {
  profileId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<
    ResolversTypes['TalentSearchSendJobStatus'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchSavedSearchResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchSavedSearch'] = ResolversParentTypes['TalentSearchSavedSearch'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  criteria?: Resolver<
    ResolversTypes['TalentSearchCriteria'],
    ParentType,
    ContextType
  >;
  criteriaHash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchSimilarCandidatesResultV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchSimilarCandidatesResultV2'] = ResolversParentTypes['TalentSearchSimilarCandidatesResultV2'],
> = {
  seedProfile?: Resolver<
    Maybe<ResolversTypes['TalentSearchProfileResultV2']>,
    ParentType,
    ContextType
  >;
  profiles?: Resolver<
    ResolversTypes['TalentSearchProfileResultsV2'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchUsageAdvertiserDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchUsageAdvertiserDetails'] = ResolversParentTypes['TalentSearchUsageAdvertiserDetails'],
> = {
  accountNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  advertiserName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parentAccountNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  parentAdvertiserName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  parentAdvertiserId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchUsageAggregateComparisonV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchUsageAggregateComparisonV2'] = ResolversParentTypes['TalentSearchUsageAggregateComparisonV2'],
> = {
  cvDownloadCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cvPreviewCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  profileViewCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  searchCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sendJobAppsCompleted?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  sendJobAppsStarted?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sendJobCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sendMessageCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sendMessageOpened?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchUsageAggregateV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchUsageAggregateV2'] = ResolversParentTypes['TalentSearchUsageAggregateV2'],
> = {
  comparison?: Resolver<
    ResolversTypes['TalentSearchUsageAggregateComparisonV2'],
    ParentType,
    ContextType
  >;
  cvDownloadCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cvPreviewCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  dailyUsage?: Resolver<
    ResolversTypes['TalentSearchDailyUsageV2'],
    ParentType,
    ContextType
  >;
  profileViewCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  searchCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sendJobAppsCompleted?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  sendJobAppsStarted?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sendJobCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sendMessageCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sendMessageOpened?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchUsageHirerActivityV2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchUsageHirerActivityV2'] = ResolversParentTypes['TalentSearchUsageHirerActivityV2'],
> = {
  cvPreviewCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cvDownloadCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cvDownloadCreditsUsed?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  lastUpdated?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  profileViewCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  searchCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sendJobCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sendJobCreditsUsed?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sendJobAppsCompleted?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  sendJobAppsStarted?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sendMessageCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sendMessageCreditsUsed?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  sendMessageOpened?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalConnections?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalCredits?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  userFullName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchUsageHirerActivityV2ConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchUsageHirerActivityV2Connection'] = ResolversParentTypes['TalentSearchUsageHirerActivityV2Connection'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  advertiserDetails?: Resolver<
    ResolversTypes['TalentSearchUsageAdvertiserDetails'],
    ParentType,
    ContextType
  >;
  edges?: Resolver<
    Array<ResolversTypes['TalentSearchUsageHirerActivityV2Edge']>,
    ParentType,
    ContextType
  >;
  searchFacets?: Resolver<
    Maybe<Array<ResolversTypes['TalentSearchUsageSearchFacet']>>,
    ParentType,
    ContextType
  >;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchUsageHirerActivityV2EdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchUsageHirerActivityV2Edge'] = ResolversParentTypes['TalentSearchUsageHirerActivityV2Edge'],
> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<
    ResolversTypes['TalentSearchUsageHirerActivityV2'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchUsageSearchFacetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchUsageSearchFacet'] = ResolversParentTypes['TalentSearchUsageSearchFacet'],
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchUsageSpendAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchUsageSpendAggregate'] = ResolversParentTypes['TalentSearchUsageSpendAggregate'],
> = {
  averageCreditsPerDay?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  averageCreditsPerUser?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  dailyCreditsUsed?: Resolver<
    Array<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  cvDownloadCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cvDownloadCreditsUsed?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  comparison?: Resolver<
    ResolversTypes['TalentSearchUsageSpendAggregateComparison'],
    ParentType,
    ContextType
  >;
  sendMessageCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sendMessageCreditsUsed?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  sendJobCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sendJobCreditsUsed?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalCreditsUsed?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalUsers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchUsageSpendAggregateComparisonResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchUsageSpendAggregateComparison'] = ResolversParentTypes['TalentSearchUsageSpendAggregateComparison'],
> = {
  cvDownloadCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cvDownloadCreditsUsed?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  sendMessageCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sendMessageCreditsUsed?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  sendJobCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sendJobCreditsUsed?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalCreditsUsed?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalUsers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchUser'] = ResolversParentTypes['TalentSearchUser'],
> = {
  userId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TalentSearchUserContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TalentSearchUserContext'] = ResolversParentTypes['TalentSearchUserContext'],
> = {
  user?: Resolver<ResolversTypes['TalentSearchUser'], ParentType, ContextType>;
  claims?: Resolver<
    Array<ResolversTypes['TalentSearchClaim']>,
    ParentType,
    ContextType
  >;
  advertiser?: Resolver<
    ResolversTypes['TalentSearchAdvertiser'],
    ParentType,
    ContextType
  >;
  ats?: Resolver<
    Maybe<ResolversTypes['TalentSearchAtsHirerRelationship']>,
    ParentType,
    ContextType
  >;
  contracts?: Resolver<
    Array<ResolversTypes['TalentSearchContract']>,
    ParentType,
    ContextType
  >;
  productType?: Resolver<
    Maybe<ResolversTypes['TalentSearchClaim']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaxResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Tax'] = ResolversParentTypes['Tax'],
> = {
  amount?: Resolver<Maybe<ResolversTypes['Money']>, ParentType, ContextType>;
  rate?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  code?: Resolver<ResolversTypes['TaxCode'], ParentType, ContextType>;
  displayRecommendation?: Resolver<
    Maybe<ResolversTypes['DisplayRecommendation']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TipToImproveAdsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TipToImproveAds'] = ResolversParentTypes['TipToImproveAds'],
> = {
  __resolveType: TypeResolveFn<
    | 'AddQuestions'
    | 'AddSpecificLocation'
    | 'MaximiseBranding'
    | 'RevealAdSalary'
    | 'ReviewAdDetails'
    | 'ReviewAdSummary',
    ParentType,
    ContextType
  >;
  type?: Resolver<
    ResolversTypes['TipsToImproveAdsType'],
    ParentType,
    ContextType
  >;
  adCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
};

export type TipsToImproveAdsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TipsToImproveAdsResponse'] = ResolversParentTypes['TipsToImproveAdsResponse'],
> = {
  tips?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TipToImproveAds']>>>,
    ParentType,
    ContextType
  >;
  numOfAds?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrackLocationSuggestionInvalidInputPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TrackLocationSuggestionInvalidInputPayload'] = ResolversParentTypes['TrackLocationSuggestionInvalidInputPayload'],
> = {
  reason?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrackLocationSuggestionPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TrackLocationSuggestionPayload'] = ResolversParentTypes['TrackLocationSuggestionPayload'],
> = {
  __resolveType: TypeResolveFn<
    | 'TrackLocationSuggestionSuccessPayload'
    | 'TrackLocationSuggestionInvalidInputPayload',
    ParentType,
    ContextType
  >;
};

export type TrackLocationSuggestionSuccessPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TrackLocationSuggestionSuccessPayload'] = ResolversParentTypes['TrackLocationSuggestionSuccessPayload'],
> = {
  responseStatus?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrendsAdTypeOptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TrendsAdTypeOption'] = ResolversParentTypes['TrendsAdTypeOption'],
> = {
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TriggerActivationPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TriggerActivationPayload'] = ResolversParentTypes['TriggerActivationPayload'],
> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateBrandPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateBrandPayload'] = ResolversParentTypes['UpdateBrandPayload'],
> = {
  __resolveType: TypeResolveFn<
    'UpdateBrandSuccessPayload' | 'InvalidInputPayload',
    ParentType,
    ContextType
  >;
};

export type UpdateBrandSuccessPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateBrandSuccessPayload'] = ResolversParentTypes['UpdateBrandSuccessPayload'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  legacyId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  owner?: Resolver<ResolversTypes['BrandOwner'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  assets?: Resolver<ResolversTypes['BrandAssets'], ParentType, ContextType>;
  state?: Resolver<
    Maybe<ResolversTypes['BrandState']>,
    ParentType,
    ContextType
  >;
  isDefault?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  createdOn?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateDraftJobExpressPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateDraftJobExpressPayload'] = ResolversParentTypes['UpdateDraftJobExpressPayload'],
> = {
  draftId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateDraftJobPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateDraftJobPayload'] = ResolversParentTypes['UpdateDraftJobPayload'],
> = {
  draftId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateDraftPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateDraftPayload'] = ResolversParentTypes['UpdateDraftPayload'],
> = {
  __resolveType: TypeResolveFn<
    'UpdateDraftJobExpressPayload' | 'UpdateDraftJobPayload',
    ParentType,
    ContextType
  >;
};

export type UpdateInvoiceStatusPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateInvoiceStatusPayload'] = ResolversParentTypes['UpdateInvoiceStatusPayload'],
> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateUserDetailsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateUserDetailsResponse'] = ResolversParentTypes['UpdateUserDetailsResponse'],
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateUserPermissionsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateUserPermissionsResponse'] = ResolversParentTypes['UpdateUserPermissionsResponse'],
> = {
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  permissions?: Resolver<
    ResolversTypes['UserPermissions'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpgradePathResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpgradePath'] = ResolversParentTypes['UpgradePath'],
> = {
  type?: Resolver<ResolversTypes['UpgradePathType'], ParentType, ContextType>;
  upgradeProduct?: Resolver<
    ResolversTypes['AdProductUpgrade'],
    ParentType,
    ContextType
  >;
  targetProduct?: Resolver<
    ResolversTypes['AdProduct'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpgradeToPremiumPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpgradeToPremiumPayload'] = ResolversParentTypes['UpgradeToPremiumPayload'],
> = {
  __resolveType: TypeResolveFn<
    'UpgradeToPremiumSuccessPayload' | 'JobAdNotUpgradableToPremium',
    ParentType,
    ContextType
  >;
};

export type UpgradeToPremiumSuccessPayloadResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpgradeToPremiumSuccessPayload'] = ResolversParentTypes['UpgradeToPremiumSuccessPayload'],
> = {
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  revisionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  permissions?: Resolver<
    ResolversTypes['UserPermissions'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserDetails'] = ResolversParentTypes['UserDetails'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['UserStatus'], ParentType, ContextType>;
  permissions?: Resolver<
    Maybe<ResolversTypes['UserPermissions']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserDetailsFailureMessageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserDetailsFailureMessage'] = ResolversParentTypes['UserDetailsFailureMessage'],
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserEmailDetailsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserEmailDetails'] = ResolversParentTypes['UserEmailDetails'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<
    Maybe<ResolversTypes['UserStatus']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserHeaderContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserHeaderContext'] = ResolversParentTypes['UserHeaderContext'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserInfo'] = ResolversParentTypes['UserInfo'],
> = {
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  profileProperties?: Resolver<
    Maybe<ResolversTypes['ProfileProperties']>,
    ParentType,
    ContextType
  >;
  talentSearchAccess?: Resolver<
    Maybe<ResolversTypes['TalentSearchAccess']>,
    ParentType,
    ContextType
  >;
  role?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  claims?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['Claim']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserListResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserListResponse'] = ResolversParentTypes['UserListResponse'],
> = {
  users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserOptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserOption'] = ResolversParentTypes['UserOption'],
> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserPermissionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserPermissions'] = ResolversParentTypes['UserPermissions'],
> = {
  hasPremiumTalentSearch?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  hasAllPositionAccess?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  hasAnalytics?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  canCreatePosition?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  role?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserWithJobPermissionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserWithJobPermission'] = ResolversParentTypes['UserWithJobPermission'],
> = {
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isPending?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasAccessToAllJobs?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  hasAccessToCurrentJob?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  isJobCreator?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isAdmin?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VerificationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Verification'] = ResolversParentTypes['Verification'],
> = {
  __resolveType: TypeResolveFn<'RightToWork', ParentType, ContextType>;
  isVerified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  viewFields?: Resolver<
    Maybe<Array<ResolversTypes['VerificationViewField']>>,
    ParentType,
    ContextType
  >;
};

export type VerificationViewFieldResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VerificationViewField'] = ResolversParentTypes['VerificationViewField'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fieldType?: Resolver<
    ResolversTypes['VerificationViewFieldType'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ViewsInJobSearchResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ViewsInJobSearchResponse'] = ResolversParentTypes['ViewsInJobSearchResponse'],
> = {
  adRatingConfidence?: Resolver<
    Maybe<ResolversTypes['AnalyticsAdRatingConfidence']>,
    ParentType,
    ContextType
  >;
  clickConversionInPercentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  mobileViewsInPercentage?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >;
  similarAdsAverage?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  similarAdsInPercentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  views?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  hasAdRating?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VirtualAccountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VirtualAccount'] = ResolversParentTypes['VirtualAccount'],
> = {
  accountNo?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  bank?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bankCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bankName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expires?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkHistoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WorkHistory'] = ResolversParentTypes['WorkHistory'],
> = {
  companyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  jobTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDate?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  foundInCV?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  highlight?: Resolver<
    Maybe<ResolversTypes['WorkHistoryHighlight']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkHistoryHighlightResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WorkHistoryHighlight'] = ResolversParentTypes['WorkHistoryHighlight'],
> = {
  companyName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  jobTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type YourCandidateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['YourCandidate'] = ResolversParentTypes['YourCandidate'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  emailAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  lastRoleCompanyName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  lastRoleJobTitle?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  rating?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<
    ResolversTypes['YourCandidateStatus'],
    ParentType,
    ContextType
  >;
  statusText?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastUpdated?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  applicationTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  prospectId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  candidateId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  adcentreCandidateId?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  jobId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  channel?: Resolver<
    ResolversTypes['YourCandidateChannel'],
    ParentType,
    ContextType
  >;
  totalApplications?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  jobTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hasResume?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasCoverLetter?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasSelectionCriteria?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type YourCandidateEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['YourCandidateEdge'] = ResolversParentTypes['YourCandidateEdge'],
> = {
  node?: Resolver<ResolversTypes['YourCandidate'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type YourCandidatesPayloadErrorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['YourCandidatesPayloadError'] = ResolversParentTypes['YourCandidatesPayloadError'],
> = {
  error?: Resolver<
    ResolversTypes['YourCandidatesPayloadErrorType'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type YourCandidatesResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['YourCandidatesResponse'] = ResolversParentTypes['YourCandidatesResponse'],
> = {
  __resolveType: TypeResolveFn<
    'YourCandidatesResults' | 'YourCandidatesPayloadError',
    ParentType,
    ContextType
  >;
};

export type YourCandidatesResultsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['YourCandidatesResults'] = ResolversParentTypes['YourCandidatesResults'],
> = {
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalPages?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edges?: Resolver<
    Array<ResolversTypes['YourCandidateEdge']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  AccountDetailsUpdateResponse?: AccountDetailsUpdateResponseResolvers<ContextType>;
  AccountHierarchyOption?: AccountHierarchyOptionResolvers<ContextType>;
  AccountSelectionEdge?: AccountSelectionEdgeResolvers<ContextType>;
  AccountSelectionOptions?: AccountSelectionOptionsResolvers<ContextType>;
  AccountSelectionResults?: AccountSelectionResultsResolvers<ContextType>;
  AccountSelectionV2Response?: AccountSelectionV2ResponseResolvers<ContextType>;
  ActionLink?: ActionLinkResolvers<ContextType>;
  Actor?: ActorResolvers<ContextType>;
  ActorContext?: ActorContextResolvers<ContextType>;
  Ad?: AdResolvers<ContextType>;
  AdBudget?: AdBudgetResolvers<ContextType>;
  AdBudgetMetadata?: AdBudgetMetadataResolvers<ContextType>;
  AdBudgetProduct?: AdBudgetProductResolvers<ContextType>;
  AdBudgetProductList?: AdBudgetProductListResolvers<ContextType>;
  AdBudgetProductsForUpsellResponse?: AdBudgetProductsForUpsellResponseResolvers<ContextType>;
  AdBudgetResponse?: AdBudgetResponseResolvers<ContextType>;
  AdMetaData?: AdMetaDataResolvers<ContextType>;
  AdPerformanceReportResponse?: AdPerformanceReportResponseResolvers<ContextType>;
  AdProduct?: AdProductResolvers<ContextType>;
  AdProductDisplayMetadata?: AdProductDisplayMetadataResolvers<ContextType>;
  AdProductSetResponse?: AdProductSetResponseResolvers<ContextType>;
  AdProductUpgrade?: AdProductUpgradeResolvers<ContextType>;
  AdRatingLabels?: AdRatingLabelsResolvers<ContextType>;
  AdRatingResponse?: AdRatingResponseResolvers<ContextType>;
  AdRatingTypeOption?: AdRatingTypeOptionResolvers<ContextType>;
  AdSpendFeature?: AdSpendFeatureResolvers<ContextType>;
  AdStatusOption?: AdStatusOptionResolvers<ContextType>;
  AdTipsOption?: AdTipsOptionResolvers<ContextType>;
  AdTypeAverageCost?: AdTypeAverageCostResolvers<ContextType>;
  AdTypeDiscount?: AdTypeDiscountResolvers<ContextType>;
  AdTypeDiscountsFeature?: AdTypeDiscountsFeatureResolvers<ContextType>;
  AdTypeOption?: AdTypeOptionResolvers<ContextType>;
  AdTypePostCount?: AdTypePostCountResolvers<ContextType>;
  AdUsageAdTypeTrendResponse?: AdUsageAdTypeTrendResponseResolvers<ContextType>;
  AdUsageConversionResponse?: AdUsageConversionResponseResolvers<ContextType>;
  AdUsageFiltersResponse?: AdUsageFiltersResponseResolvers<ContextType>;
  AdUsageSpendResponse?: AdUsageSpendResponseResolvers<ContextType>;
  AdUsageTrendClassification?: AdUsageTrendClassificationResolvers<ContextType>;
  AdUsageTrendClassificationsAndUsersResponse?: AdUsageTrendClassificationsAndUsersResponseResolvers<ContextType>;
  AdUsageTrendDaily?: AdUsageTrendDailyResolvers<ContextType>;
  AdUsageTrendMonthly?: AdUsageTrendMonthlyResolvers<ContextType>;
  AdUsageTrendUser?: AdUsageTrendUserResolvers<ContextType>;
  AddQuestions?: AddQuestionsResolvers<ContextType>;
  AddSpecificLocation?: AddSpecificLocationResolvers<ContextType>;
  AddUserToAccountResponse?: AddUserToAccountResponseResolvers<ContextType>;
  AdvertiserAccount?: AdvertiserAccountResolvers<ContextType>;
  AdvertiserAccountOption?: AdvertiserAccountOptionResolvers<ContextType>;
  AdvertiserHeaderContext?: AdvertiserHeaderContextResolvers<ContextType>;
  AhpraRegistration?: AhpraRegistrationResolvers<ContextType>;
  AnalyticsAdRating?: AnalyticsAdRatingResolvers<ContextType>;
  AnalyticsLatestJobs?: AnalyticsLatestJobsResolvers<ContextType>;
  AnalyticsMarketRoleInsightsJobTitlesResult?: AnalyticsMarketRoleInsightsJobTitlesResultResolvers<ContextType>;
  AnalyticsMarketRoleInsightsLocationsResult?: AnalyticsMarketRoleInsightsLocationsResultResolvers<ContextType>;
  AnalyticsMarketRoleInsightsSearchResult?: AnalyticsMarketRoleInsightsSearchResultResolvers<ContextType>;
  AnalyticsMarketRoleInsightsSuggestion?: AnalyticsMarketRoleInsightsSuggestionResolvers<ContextType>;
  AnalyticsTalentSearchUsageAccount?: AnalyticsTalentSearchUsageAccountResolvers<ContextType>;
  AnalyticsTalentSearchUsageCreditsUsage?: AnalyticsTalentSearchUsageCreditsUsageResolvers<ContextType>;
  AnalyticsTalentSearchUsageCreditsUsageListResponse?: AnalyticsTalentSearchUsageCreditsUsageListResponseResolvers<ContextType>;
  AnalyticsTalentSearchUsageFiltersResponse?: AnalyticsTalentSearchUsageFiltersResponseResolvers<ContextType>;
  AnalyticsTalentSearchUsageLastUpdatedResponse?: AnalyticsTalentSearchUsageLastUpdatedResponseResolvers<ContextType>;
  AnalyticsTalentSearchUsageUser?: AnalyticsTalentSearchUsageUserResolvers<ContextType>;
  ApplicationPerformanceResponse?: ApplicationPerformanceResponseResolvers<ContextType>;
  AssociatedAction?: AssociatedActionResolvers<ContextType>;
  AssociatedParty?: AssociatedPartyResolvers<ContextType>;
  BillableApplicantsData?: BillableApplicantsDataResolvers<ContextType>;
  BillableApplicantsResponse?: BillableApplicantsResponseResolvers<ContextType>;
  BillableApplicantsResponseSuccess?: BillableApplicantsResponseSuccessResolvers<ContextType>;
  BillingContact?: BillingContactResolvers<ContextType>;
  Brand?: BrandResolvers<ContextType>;
  BrandAssetStrategies?: BrandAssetStrategiesResolvers<ContextType>;
  BrandAssetType?: BrandAssetTypeResolvers<ContextType>;
  BrandAssets?: BrandAssetsResolvers<ContextType>;
  BrandOwner?: BrandOwnerResolvers<ContextType>;
  BrandedAd?: BrandedAdResolvers<ContextType>;
  BrandedFeaturesFeature?: BrandedFeaturesFeatureResolvers<ContextType>;
  BrandingAsset?: BrandingAssetResolvers<ContextType>;
  BrandingFeature?: BrandingFeatureResolvers<ContextType>;
  BulletPointsFeature?: BulletPointsFeatureResolvers<ContextType>;
  CandidateDemandResponse?: CandidateDemandResponseResolvers<ContextType>;
  CandidateSupplyResponse?: CandidateSupplyResponseResolvers<ContextType>;
  CappedUnitPrice?: CappedUnitPriceResolvers<ContextType>;
  CappedUnitPriceMetadata?: CappedUnitPriceMetadataResolvers<ContextType>;
  CatalogueProduct?: CatalogueProductResolvers<ContextType>;
  CatalogueProductFeature?: CatalogueProductFeatureResolvers<ContextType>;
  CatalogueProductPrice?: CatalogueProductPriceResolvers<ContextType>;
  Category?: CategoryResolvers<ContextType>;
  CategorySuggestion?: CategorySuggestionResolvers<ContextType>;
  ChildAccountBannerResponse?: ChildAccountBannerResponseResolvers<ContextType>;
  Claim?: ClaimResolvers<ContextType>;
  ClassificationOption?: ClassificationOptionResolvers<ContextType>;
  ClicksOnYourAdResponse?: ClicksOnYourAdResponseResolvers<ContextType>;
  CompanyAddress?: CompanyAddressResolvers<ContextType>;
  CompanyProfile?: CompanyProfileResolvers<ContextType>;
  CompanyProfileAward?: CompanyProfileAwardResolvers<ContextType>;
  CompanyProfileBranding?: CompanyProfileBrandingResolvers<ContextType>;
  CompanyProfileCultureAndValues?: CompanyProfileCultureAndValuesResolvers<ContextType>;
  CompanyProfileCultureValue?: CompanyProfileCultureValueResolvers<ContextType>;
  CompanyProfileCustomStatement?: CompanyProfileCustomStatementResolvers<ContextType>;
  CompanyProfileFeatured?: CompanyProfileFeaturedResolvers<ContextType>;
  CompanyProfileIds?: CompanyProfileIdsResolvers<ContextType>;
  CompanyProfileOverview?: CompanyProfileOverviewResolvers<ContextType>;
  CompanyProfilePerksAndBenefits?: CompanyProfilePerksAndBenefitsResolvers<ContextType>;
  CompanyProfilePhoto?: CompanyProfilePhotoResolvers<ContextType>;
  CompanyProfileWebsite?: CompanyProfileWebsiteResolvers<ContextType>;
  ConfirmInvoiceOrderPayload?: ConfirmInvoiceOrderPayloadResolvers<ContextType>;
  ConnectionStatusPayload?: ConnectionStatusPayloadResolvers<ContextType>;
  Contract?: ContractResolvers<ContextType>;
  ContractAdDiscount?: ContractAdDiscountResolvers<ContextType>;
  ContractBalance?: ContractBalanceResolvers<ContextType>;
  ContractDollarValue?: ContractDollarValueResolvers<ContextType>;
  ContractUnitValue?: ContractUnitValueResolvers<ContextType>;
  ContractValue?: ContractValueResolvers<ContextType>;
  ConversionRateData?: ConversionRateDataResolvers<ContextType>;
  Country?: CountryResolvers<ContextType>;
  CountrySelectionOptions?: CountrySelectionOptionsResolvers<ContextType>;
  CoverStrategies?: CoverStrategiesResolvers<ContextType>;
  Create2c2pPaymentIntentPayload?: Create2c2pPaymentIntentPayloadResolvers<ContextType>;
  CreateBrandPayload?: CreateBrandPayloadResolvers<ContextType>;
  CreateBrandSuccessPayload?: CreateBrandSuccessPayloadResolvers<ContextType>;
  CreateCustomQuestionErrorResult?: CreateCustomQuestionErrorResultResolvers<ContextType>;
  CreateCustomQuestionOutputUnion?: CreateCustomQuestionOutputUnionResolvers<ContextType>;
  CreateCustomQuestionResult?: CreateCustomQuestionResultResolvers<ContextType>;
  CreateDraftJobPayload?: CreateDraftJobPayloadResolvers<ContextType>;
  CreateJobResponse?: CreateJobResponseResolvers<ContextType>;
  CreateOrderPayload?: CreateOrderPayloadResolvers<ContextType>;
  CreateOrderSuccessPayload?: CreateOrderSuccessPayloadResolvers<ContextType>;
  CreateOrderWithPromotionPayload?: CreateOrderWithPromotionPayloadResolvers<ContextType>;
  CreatePaymentIntentPayload?: CreatePaymentIntentPayloadResolvers<ContextType>;
  CreateProductSetForAdEditingPayload?: CreateProductSetForAdEditingPayloadResolvers<ContextType>;
  CreateProductSetForAdPostingPayload?: CreateProductSetForAdPostingPayloadResolvers<ContextType>;
  CreateProductSetForAdRefreshPayload?: CreateProductSetForAdRefreshPayloadResolvers<ContextType>;
  CreateProductSetForBudgetsPurchaseForHirerPayload?: CreateProductSetForBudgetsPurchaseForHirerPayloadResolvers<ContextType>;
  CreateProductSetForBudgetsPurchaseForVisitorPayload?: CreateProductSetForBudgetsPurchaseForVisitorPayloadResolvers<ContextType>;
  CreateProductSetForMultiLocationPayload?: CreateProductSetForMultiLocationPayloadResolvers<ContextType>;
  CreateProductSetForPriceCheckerPreviewPayload?: CreateProductSetForPriceCheckerPreviewPayloadResolvers<ContextType>;
  CreateQuestionnaireResponse?: CreateQuestionnaireResponseResolvers<ContextType>;
  CreateSavedSearchPayload?: CreateSavedSearchPayloadResolvers<ContextType>;
  CreateStripePaymentIntentPayload?: CreateStripePaymentIntentPayloadResolvers<ContextType>;
  CreditCard?: CreditCardResolvers<ContextType>;
  CurrentAdvertiserContext?: CurrentAdvertiserContextResolvers<ContextType>;
  CurrentAdvertiserResponse?: CurrentAdvertiserResponseResolvers<ContextType>;
  CurrentUserContext?: CurrentUserContextResolvers<ContextType>;
  CurrentUserResponse?: CurrentUserResponseResolvers<ContextType>;
  CustomQuestionValidationFailure?: CustomQuestionValidationFailureResolvers<ContextType>;
  Date?: GraphQLScalarType;
  DateAxisLabel?: DateAxisLabelResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  DeleteBrandingAssetPayload?: DeleteBrandingAssetPayloadResolvers<ContextType>;
  DeleteDraftJobPayload?: DeleteDraftJobPayloadResolvers<ContextType>;
  DeleteSavedCreditCardPayload?: DeleteSavedCreditCardPayloadResolvers<ContextType>;
  DeleteSavedSearchPayload?: DeleteSavedSearchPayloadResolvers<ContextType>;
  DeleteStripeSavedCreditCardPayload?: DeleteStripeSavedCreditCardPayloadResolvers<ContextType>;
  DisableMessageType?: DisableMessageTypeResolvers<ContextType>;
  Discount?: DiscountResolvers<ContextType>;
  DisplayMetadataError?: DisplayMetadataErrorResolvers<ContextType>;
  DraftJob?: DraftJobResolvers<ContextType>;
  DraftJobBranding?: DraftJobBrandingResolvers<ContextType>;
  DraftJobCategory?: DraftJobCategoryResolvers<ContextType>;
  DraftJobEdge?: DraftJobEdgeResolvers<ContextType>;
  DraftJobGuidedDetails?: DraftJobGuidedDetailsResolvers<ContextType>;
  DraftJobLocation?: DraftJobLocationResolvers<ContextType>;
  DraftJobNode?: DraftJobNodeResolvers<ContextType>;
  DraftJobNotFoundPayload?: DraftJobNotFoundPayloadResolvers<ContextType>;
  DraftJobPayload?: DraftJobPayloadResolvers<ContextType>;
  DraftJobProducts?: DraftJobProductsResolvers<ContextType>;
  DraftJobRoleTitle?: DraftJobRoleTitleResolvers<ContextType>;
  DraftJobSalaryDetails?: DraftJobSalaryDetailsResolvers<ContextType>;
  DraftJobSuccessPayload?: DraftJobSuccessPayloadResolvers<ContextType>;
  DraftJobVideo?: DraftJobVideoResolvers<ContextType>;
  DraftJobsPayload?: DraftJobsPayloadResolvers<ContextType>;
  DrawdownContractDetails?: DrawdownContractDetailsResolvers<ContextType>;
  DrawdownContractValue?: DrawdownContractValueResolvers<ContextType>;
  DrawdownItem?: DrawdownItemResolvers<ContextType>;
  DriversLicence?: DriversLicenceResolvers<ContextType>;
  DurationFeature?: DurationFeatureResolvers<ContextType>;
  EditJobResponse?: EditJobResponseResolvers<ContextType>;
  Error?: ErrorResolvers<ContextType>;
  ExpectedSalaryV2?: ExpectedSalaryV2Resolvers<ContextType>;
  ExpireJobResponse?: ExpireJobResponseResolvers<ContextType>;
  Expiry?: ExpiryResolvers<ContextType>;
  GetProductSetResponse?: GetProductSetResponseResolvers<ContextType>;
  GetSurchargeAmountPayload?: GetSurchargeAmountPayloadResolvers<ContextType>;
  GranularLocation?: GranularLocationResolvers<ContextType>;
  HeaderFooterContext?: HeaderFooterContextResolvers<ContextType>;
  HelpTip?: HelpTipResolvers<ContextType>;
  HelpTipImage?: HelpTipImageResolvers<ContextType>;
  HiddenPrice?: HiddenPriceResolvers<ContextType>;
  HirerAccount?: HirerAccountResolvers<ContextType>;
  HirerAccountDetails?: HirerAccountDetailsResolvers<ContextType>;
  HirerActivity?: HirerActivityResolvers<ContextType>;
  HirerActivityConnection?: HirerActivityConnectionResolvers<ContextType>;
  HirerActivityData?: HirerActivityDataResolvers<ContextType>;
  HirerActivityEdge?: HirerActivityEdgeResolvers<ContextType>;
  HirerActivityJobApplication?: HirerActivityJobApplicationResolvers<ContextType>;
  HirerDashboardArticle?: HirerDashboardArticleResolvers<ContextType>;
  HirerDashboardBudgetUpsellResponse?: HirerDashboardBudgetUpsellResponseResolvers<ContextType>;
  HirerDashboardHiringAdviceList?: HirerDashboardHiringAdviceListResolvers<ContextType>;
  HirerDashboardJob?: HirerDashboardJobResolvers<ContextType>;
  HirerDashboardJobCounts?: HirerDashboardJobCountsResolvers<ContextType>;
  HirerDashboardJobs?: HirerDashboardJobsResolvers<ContextType>;
  HirerDashboardTalentSearch?: HirerDashboardTalentSearchResolvers<ContextType>;
  HirerDashboardUserDetails?: HirerDashboardUserDetailsResolvers<ContextType>;
  HirerDashboardWelcome?: HirerDashboardWelcomeResolvers<ContextType>;
  HirerExperience?: HirerExperienceResolvers<ContextType>;
  HirerJob?: HirerJobResolvers<ContextType>;
  HirerJobConnection?: HirerJobConnectionResolvers<ContextType>;
  HirerJobEdge?: HirerJobEdgeResolvers<ContextType>;
  HirerPrimaryContact?: HirerPrimaryContactResolvers<ContextType>;
  HirerUserDetails?: HirerUserDetailsResolvers<ContextType>;
  HirerUserEmailInput?: HirerUserEmailInputResolvers<ContextType>;
  Identity?: IdentityResolvers<ContextType>;
  IdentityHeaderContext?: IdentityHeaderContextResolvers<ContextType>;
  IdentityIds?: IdentityIdsResolvers<ContextType>;
  Image?: ImageResolvers<ContextType>;
  InitiateConnectionPayload?: InitiateConnectionPayloadResolvers<ContextType>;
  Integration?: IntegrationResolvers<ContextType>;
  IntegrationConnection?: IntegrationConnectionResolvers<ContextType>;
  IntegrationEdge?: IntegrationEdgeResolvers<ContextType>;
  IntegrationPartner?: IntegrationPartnerResolvers<ContextType>;
  IntegrationRelationship?: IntegrationRelationshipResolvers<ContextType>;
  IntegrationRelationshipType?: IntegrationRelationshipTypeResolvers<ContextType>;
  InteractedJob?: InteractedJobResolvers<ContextType>;
  InvalidInputPayload?: InvalidInputPayloadResolvers<ContextType>;
  InvoiceError?: InvoiceErrorResolvers<ContextType>;
  InvoiceOrderNotFoundPayload?: InvoiceOrderNotFoundPayloadResolvers<ContextType>;
  InvoiceOrderSuccessPayload?: InvoiceOrderSuccessPayloadResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  Job?: JobResolvers<ContextType>;
  JobAd?: JobAdResolvers<ContextType>;
  JobAdEditFeature?: JobAdEditFeatureResolvers<ContextType>;
  JobAdListResponse?: JobAdListResponseResolvers<ContextType>;
  JobAdNotUpgradableToPremium?: JobAdNotUpgradableToPremiumResolvers<ContextType>;
  JobTitleOption?: JobTitleOptionResolvers<ContextType>;
  LanguageCodeIso?: GraphQLScalarType;
  LastUpdatedResponse?: LastUpdatedResponseResolvers<ContextType>;
  LineItemProduct?: LineItemProductResolvers<ContextType>;
  Link?: LinkResolvers<ContextType>;
  LocalisedContactInfoContext?: LocalisedContactInfoContextResolvers<ContextType>;
  Location?: LocationResolvers<ContextType>;
  LocationOption?: LocationOptionResolvers<ContextType>;
  LocationSearchResult?: LocationSearchResultResolvers<ContextType>;
  LogoStrategies?: LogoStrategiesResolvers<ContextType>;
  LowApply?: LowApplyResolvers<ContextType>;
  LowClick?: LowClickResolvers<ContextType>;
  LowSalary?: LowSalaryResolvers<ContextType>;
  LowestPricedProductForAdPostingResponse?: LowestPricedProductForAdPostingResponseResolvers<ContextType>;
  Maintenance?: MaintenanceResolvers<ContextType>;
  ManagingAgency?: ManagingAgencyResolvers<ContextType>;
  MandatoryData?: MandatoryDataResolvers<ContextType>;
  MarketInsightMonth?: MarketInsightMonthResolvers<ContextType>;
  MarketInsightsTitleResponse?: MarketInsightsTitleResponseResolvers<ContextType>;
  MarketPerformanceResponse?: MarketPerformanceResponseResolvers<ContextType>;
  MatchedSearchString?: MatchedSearchStringResolvers<ContextType>;
  MaximiseBranding?: MaximiseBrandingResolvers<ContextType>;
  MissingAdvertiserIdClaimError?: MissingAdvertiserIdClaimErrorResolvers<ContextType>;
  MissingUserIdClaimError?: MissingUserIdClaimErrorResolvers<ContextType>;
  Money?: MoneyResolvers<ContextType>;
  MostAdsByPerformanceCategory?: MostAdsByPerformanceCategoryResolvers<ContextType>;
  MostAdsByPerformanceEntity?: MostAdsByPerformanceEntityResolvers<ContextType>;
  MostAdsByPerformanceResponse?: MostAdsByPerformanceResponseResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  MyJobInformationJobEdit?: MyJobInformationJobEditResolvers<ContextType>;
  MyJobInformationResponse?: MyJobInformationResponseResolvers<ContextType>;
  MyJobInformationSalary?: MyJobInformationSalaryResolvers<ContextType>;
  NotificationPreference?: NotificationPreferenceResolvers<ContextType>;
  OptionWithId?: OptionWithIdResolvers<ContextType>;
  Order?: OrderResolvers<ContextType>;
  OrderExpiredError?: OrderExpiredErrorResolvers<ContextType>;
  OrderInvalidError?: OrderInvalidErrorResolvers<ContextType>;
  OrderLineItem?: OrderLineItemResolvers<ContextType>;
  OrderNotFoundError?: OrderNotFoundErrorResolvers<ContextType>;
  OrderSummary?: OrderSummaryResolvers<ContextType>;
  OutputCustomQuestion?: OutputCustomQuestionResolvers<ContextType>;
  PageInfo?: PageInfoResolvers<ContextType>;
  PageTitleActionsResponse?: PageTitleActionsResponseResolvers<ContextType>;
  Parent?: ParentResolvers<ContextType>;
  ParentAdvertiser?: ParentAdvertiserResolvers<ContextType>;
  ParentLocation?: ParentLocationResolvers<ContextType>;
  PatchCompanyProfileResponse?: PatchCompanyProfileResponseResolvers<ContextType>;
  PayBySavedCreditCardPayload?: PayBySavedCreditCardPayloadResolvers<ContextType>;
  PayByStripeSavedCreditCardPayload?: PayByStripeSavedCreditCardPayloadResolvers<ContextType>;
  Payment2c2pInquiry?: Payment2c2pInquiryResolvers<ContextType>;
  Payment2c2pInquiryError?: Payment2c2pInquiryErrorResolvers<ContextType>;
  Payment2c2pInquiryPayload?: Payment2c2pInquiryPayloadResolvers<ContextType>;
  PaymentConfigPayload?: PaymentConfigPayloadResolvers<ContextType>;
  PaymentIntent2C2PPayload?: PaymentIntent2C2PPayloadResolvers<ContextType>;
  PaymentIntentChargeError?: PaymentIntentChargeErrorResolvers<ContextType>;
  PaymentIntentPayload?: PaymentIntentPayloadResolvers<ContextType>;
  PaymentMediumType?: PaymentMediumTypeResolvers<ContextType>;
  PaymentMethodError?: PaymentMethodErrorResolvers<ContextType>;
  PaymentMethodPayload?: PaymentMethodPayloadResolvers<ContextType>;
  PerApplicantBillingFeature?: PerApplicantBillingFeatureResolvers<ContextType>;
  PerUnitBillingFeature?: PerUnitBillingFeatureResolvers<ContextType>;
  PerformancePredictionChartEvent?: PerformancePredictionChartEventResolvers<ContextType>;
  PerformancePredictionResponse?: PerformancePredictionResponseResolvers<ContextType>;
  PhysicalAddress?: PhysicalAddressResolvers<ContextType>;
  PredictionDayOptions?: PredictionDayOptionsResolvers<ContextType>;
  Price?: PriceResolvers<ContextType>;
  PriceType?: PriceTypeResolvers<ContextType>;
  Pricing?: PricingResolvers<ContextType>;
  PrimaryContact?: PrimaryContactResolvers<ContextType>;
  Product?: ProductResolvers<ContextType>;
  ProductFeature?: ProductFeatureResolvers<ContextType>;
  ProductList?: ProductListResolvers<ContextType>;
  ProductReference?: ProductReferenceResolvers<ContextType>;
  ProductSet?: ProductSetResolvers<ContextType>;
  ProductSetExpiredError?: ProductSetExpiredErrorResolvers<ContextType>;
  ProductSetNotFoundError?: ProductSetNotFoundErrorResolvers<ContextType>;
  ProfileEducation?: ProfileEducationResolvers<ContextType>;
  ProfileProperties?: ProfilePropertiesResolvers<ContextType>;
  ProfileResultHighlight?: ProfileResultHighlightResolvers<ContextType>;
  ProfileSalaryV2?: ProfileSalaryV2Resolvers<ContextType>;
  PromoCode?: PromoCodeResolvers<ContextType>;
  PromoCodeDiscount?: PromoCodeDiscountResolvers<ContextType>;
  PromoCodeDiscountRate?: PromoCodeDiscountRateResolvers<ContextType>;
  PromoCodeProduct?: PromoCodeProductResolvers<ContextType>;
  Promotion?: PromotionResolvers<ContextType>;
  PromotionInvalidError?: PromotionInvalidErrorResolvers<ContextType>;
  PublishCPQAEventPayload?: PublishCpqaEventPayloadResolvers<ContextType>;
  PublishJobAdBookingEventPayload?: PublishJobAdBookingEventPayloadResolvers<ContextType>;
  PublishJobPostedEventPayload?: PublishJobPostedEventPayloadResolvers<ContextType>;
  PurchasableProducts?: PurchasableProductsResolvers<ContextType>;
  PurchasableProductsList?: PurchasableProductsListResolvers<ContextType>;
  PurchasableProductsString?: PurchasableProductsStringResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Questionnaire?: QuestionnaireResolvers<ContextType>;
  QuestionnaireAnswer?: QuestionnaireAnswerResolvers<ContextType>;
  QuestionnaireQuestion?: QuestionnaireQuestionResolvers<ContextType>;
  Range?: RangeResolvers<ContextType>;
  Recommendation?: RecommendationResolvers<ContextType>;
  RecommendationsResponse?: RecommendationsResponseResolvers<ContextType>;
  RefreshJobResponse?: RefreshJobResponseResolvers<ContextType>;
  RegisterPayload?: RegisterPayloadResolvers<ContextType>;
  RegistrationOptions?: RegistrationOptionsResolvers<ContextType>;
  RegistrationStatusInfo?: RegistrationStatusInfoResolvers<ContextType>;
  ReinstateResponse?: ReinstateResponseResolvers<ContextType>;
  RepostOption?: RepostOptionResolvers<ContextType>;
  ResponseError?: ResponseErrorResolvers<ContextType>;
  ResumeEducation?: ResumeEducationResolvers<ContextType>;
  ResumeWorkHistory?: ResumeWorkHistoryResolvers<ContextType>;
  ResumeWorkHistoryDisplayDescription?: ResumeWorkHistoryDisplayDescriptionResolvers<ContextType>;
  RetrievePaymentIntentPayload?: RetrievePaymentIntentPayloadResolvers<ContextType>;
  RevealAdSalary?: RevealAdSalaryResolvers<ContextType>;
  RevealSalary?: RevealSalaryResolvers<ContextType>;
  ReviewAdDetails?: ReviewAdDetailsResolvers<ContextType>;
  ReviewAdSummary?: ReviewAdSummaryResolvers<ContextType>;
  RightToWork?: RightToWorkResolvers<ContextType>;
  RightToWorkInSavedSearch?: RightToWorkInSavedSearchResolvers<ContextType>;
  RoleRequirements?: RoleRequirementsResolvers<ContextType>;
  RoleRequirementsMandatoryQuestionsFeature?: RoleRequirementsMandatoryQuestionsFeatureResolvers<ContextType>;
  Rule?: RuleResolvers<ContextType>;
  SafeInt?: GraphQLScalarType;
  SalaryAmountV2?: SalaryAmountV2Resolvers<ContextType>;
  SavedCreditCardPaymentError?: SavedCreditCardPaymentErrorResolvers<ContextType>;
  SavedCreditCardPaymentSuccessPayload?: SavedCreditCardPaymentSuccessPayloadResolvers<ContextType>;
  SecureLinkExpiredError?: SecureLinkExpiredErrorResolvers<ContextType>;
  SecureLinkInvalidError?: SecureLinkInvalidErrorResolvers<ContextType>;
  SecureLinkResponse?: SecureLinkResponseResolvers<ContextType>;
  SecureLinkSuccessResponse?: SecureLinkSuccessResponseResolvers<ContextType>;
  SecureLinkUsedError?: SecureLinkUsedErrorResolvers<ContextType>;
  SellingPoint?: SellingPointResolvers<ContextType>;
  SendEmailResponse?: SendEmailResponseResolvers<ContextType>;
  SendEmailVerificationEmailPayload?: SendEmailVerificationEmailPayloadResolvers<ContextType>;
  SendEnrolmentEmailPayload?: SendEnrolmentEmailPayloadResolvers<ContextType>;
  SendHirerAccountUsersJobAccessEmailPayload?: SendHirerAccountUsersJobAccessEmailPayloadResolvers<ContextType>;
  SendLoginCallbackEventPayload?: SendLoginCallbackEventPayloadResolvers<ContextType>;
  SetHirerAccountUsersJobAccessPayload?: SetHirerAccountUsersJobAccessPayloadResolvers<ContextType>;
  SetNotificationPreferencesPayload?: SetNotificationPreferencesPayloadResolvers<ContextType>;
  SimilarAdsDetails?: SimilarAdsDetailsResolvers<ContextType>;
  SimilarAdsResponse?: SimilarAdsResponseResolvers<ContextType>;
  SimplifiedAnswers?: SimplifiedAnswersResolvers<ContextType>;
  SponsoredSlotFeature?: SponsoredSlotFeatureResolvers<ContextType>;
  StripePaymentIntentPayload?: StripePaymentIntentPayloadResolvers<ContextType>;
  StyleEnhancementFeature?: StyleEnhancementFeatureResolvers<ContextType>;
  SubTitle?: SubTitleResolvers<ContextType>;
  Subject?: SubjectResolvers<ContextType>;
  SuggestedSalary?: SuggestedSalaryResolvers<ContextType>;
  SuggestedSalaryAverageRange?: SuggestedSalaryAverageRangeResolvers<ContextType>;
  SuggestedSalaryRange?: SuggestedSalaryRangeResolvers<ContextType>;
  Surcharge?: SurchargeResolvers<ContextType>;
  TalentSearch?: TalentSearchResolvers<ContextType>;
  TalentSearchATSJob?: TalentSearchAtsJobResolvers<ContextType>;
  TalentSearchATSJobEdge?: TalentSearchAtsJobEdgeResolvers<ContextType>;
  TalentSearchATSJobs?: TalentSearchAtsJobsResolvers<ContextType>;
  TalentSearchATSJobsConnection?: TalentSearchAtsJobsConnectionResolvers<ContextType>;
  TalentSearchAccount?: TalentSearchAccountResolvers<ContextType>;
  TalentSearchAccounts?: TalentSearchAccountsResolvers<ContextType>;
  TalentSearchAdvertiser?: TalentSearchAdvertiserResolvers<ContextType>;
  TalentSearchAssignedPool?: TalentSearchAssignedPoolResolvers<ContextType>;
  TalentSearchAssignedPools?: TalentSearchAssignedPoolsResolvers<ContextType>;
  TalentSearchAtsHirerRelationship?: TalentSearchAtsHirerRelationshipResolvers<ContextType>;
  TalentSearchAvailableJobV2?: TalentSearchAvailableJobV2Resolvers<ContextType>;
  TalentSearchBudgetBalance?: TalentSearchBudgetBalanceResolvers<ContextType>;
  TalentSearchConnectionAvailability?: TalentSearchConnectionAvailabilityResolvers<ContextType>;
  TalentSearchContract?: TalentSearchContractResolvers<ContextType>;
  TalentSearchCountResult?: TalentSearchCountResultResolvers<ContextType>;
  TalentSearchCountResultWithLabel?: TalentSearchCountResultWithLabelResolvers<ContextType>;
  TalentSearchCountsResults?: TalentSearchCountsResultsResolvers<ContextType>;
  TalentSearchCriteria?: TalentSearchCriteriaResolvers<ContextType>;
  TalentSearchDailyUsageV2?: TalentSearchDailyUsageV2Resolvers<ContextType>;
  TalentSearchFeature?: TalentSearchFeatureResolvers<ContextType>;
  TalentSearchHirerAccountTags?: TalentSearchHirerAccountTagsResolvers<ContextType>;
  TalentSearchHirerContactDetails?: TalentSearchHirerContactDetailsResolvers<ContextType>;
  TalentSearchInteraction?: TalentSearchInteractionResolvers<ContextType>;
  TalentSearchInteractionEdge?: TalentSearchInteractionEdgeResolvers<ContextType>;
  TalentSearchInteractionsConnection?: TalentSearchInteractionsConnectionResolvers<ContextType>;
  TalentSearchJobLocationV2?: TalentSearchJobLocationV2Resolvers<ContextType>;
  TalentSearchJobOwner?: TalentSearchJobOwnerResolvers<ContextType>;
  TalentSearchJobSalaryV2?: TalentSearchJobSalaryV2Resolvers<ContextType>;
  TalentSearchJobV2?: TalentSearchJobV2Resolvers<ContextType>;
  TalentSearchJobsV2?: TalentSearchJobsV2Resolvers<ContextType>;
  TalentSearchLatestInteraction?: TalentSearchLatestInteractionResolvers<ContextType>;
  TalentSearchMarket?: TalentSearchMarketResolvers<ContextType>;
  TalentSearchMessageDetails?: TalentSearchMessageDetailsResolvers<ContextType>;
  TalentSearchMetadataIndustry?: TalentSearchMetadataIndustryResolvers<ContextType>;
  TalentSearchMetadataQueryValidations?: TalentSearchMetadataQueryValidationsResolvers<ContextType>;
  TalentSearchMetadataSubIndustry?: TalentSearchMetadataSubIndustryResolvers<ContextType>;
  TalentSearchPool?: TalentSearchPoolResolvers<ContextType>;
  TalentSearchPoolProfile?: TalentSearchPoolProfileResolvers<ContextType>;
  TalentSearchProfileConnectionsAvailability?: TalentSearchProfileConnectionsAvailabilityResolvers<ContextType>;
  TalentSearchProfileResultV2?: TalentSearchProfileResultV2Resolvers<ContextType>;
  TalentSearchProfileResultsV2?: TalentSearchProfileResultsV2Resolvers<ContextType>;
  TalentSearchProfileResumeResult?: TalentSearchProfileResumeResultResolvers<ContextType>;
  TalentSearchProfileSendJobStatus?: TalentSearchProfileSendJobStatusResolvers<ContextType>;
  TalentSearchSavedSearch?: TalentSearchSavedSearchResolvers<ContextType>;
  TalentSearchSimilarCandidatesResultV2?: TalentSearchSimilarCandidatesResultV2Resolvers<ContextType>;
  TalentSearchUsageAdvertiserDetails?: TalentSearchUsageAdvertiserDetailsResolvers<ContextType>;
  TalentSearchUsageAggregateComparisonV2?: TalentSearchUsageAggregateComparisonV2Resolvers<ContextType>;
  TalentSearchUsageAggregateV2?: TalentSearchUsageAggregateV2Resolvers<ContextType>;
  TalentSearchUsageHirerActivityV2?: TalentSearchUsageHirerActivityV2Resolvers<ContextType>;
  TalentSearchUsageHirerActivityV2Connection?: TalentSearchUsageHirerActivityV2ConnectionResolvers<ContextType>;
  TalentSearchUsageHirerActivityV2Edge?: TalentSearchUsageHirerActivityV2EdgeResolvers<ContextType>;
  TalentSearchUsageSearchFacet?: TalentSearchUsageSearchFacetResolvers<ContextType>;
  TalentSearchUsageSpendAggregate?: TalentSearchUsageSpendAggregateResolvers<ContextType>;
  TalentSearchUsageSpendAggregateComparison?: TalentSearchUsageSpendAggregateComparisonResolvers<ContextType>;
  TalentSearchUser?: TalentSearchUserResolvers<ContextType>;
  TalentSearchUserContext?: TalentSearchUserContextResolvers<ContextType>;
  Tax?: TaxResolvers<ContextType>;
  TipToImproveAds?: TipToImproveAdsResolvers<ContextType>;
  TipsToImproveAdsResponse?: TipsToImproveAdsResponseResolvers<ContextType>;
  TrackLocationSuggestionInvalidInputPayload?: TrackLocationSuggestionInvalidInputPayloadResolvers<ContextType>;
  TrackLocationSuggestionPayload?: TrackLocationSuggestionPayloadResolvers<ContextType>;
  TrackLocationSuggestionSuccessPayload?: TrackLocationSuggestionSuccessPayloadResolvers<ContextType>;
  TrendsAdTypeOption?: TrendsAdTypeOptionResolvers<ContextType>;
  TriggerActivationPayload?: TriggerActivationPayloadResolvers<ContextType>;
  UpdateBrandPayload?: UpdateBrandPayloadResolvers<ContextType>;
  UpdateBrandSuccessPayload?: UpdateBrandSuccessPayloadResolvers<ContextType>;
  UpdateDraftJobExpressPayload?: UpdateDraftJobExpressPayloadResolvers<ContextType>;
  UpdateDraftJobPayload?: UpdateDraftJobPayloadResolvers<ContextType>;
  UpdateDraftPayload?: UpdateDraftPayloadResolvers<ContextType>;
  UpdateInvoiceStatusPayload?: UpdateInvoiceStatusPayloadResolvers<ContextType>;
  UpdateUserDetailsResponse?: UpdateUserDetailsResponseResolvers<ContextType>;
  UpdateUserPermissionsResponse?: UpdateUserPermissionsResponseResolvers<ContextType>;
  UpgradePath?: UpgradePathResolvers<ContextType>;
  UpgradeToPremiumPayload?: UpgradeToPremiumPayloadResolvers<ContextType>;
  UpgradeToPremiumSuccessPayload?: UpgradeToPremiumSuccessPayloadResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserDetails?: UserDetailsResolvers<ContextType>;
  UserDetailsFailureMessage?: UserDetailsFailureMessageResolvers<ContextType>;
  UserEmailDetails?: UserEmailDetailsResolvers<ContextType>;
  UserHeaderContext?: UserHeaderContextResolvers<ContextType>;
  UserInfo?: UserInfoResolvers<ContextType>;
  UserListResponse?: UserListResponseResolvers<ContextType>;
  UserOption?: UserOptionResolvers<ContextType>;
  UserPermissions?: UserPermissionsResolvers<ContextType>;
  UserWithJobPermission?: UserWithJobPermissionResolvers<ContextType>;
  Verification?: VerificationResolvers<ContextType>;
  VerificationViewField?: VerificationViewFieldResolvers<ContextType>;
  ViewsInJobSearchResponse?: ViewsInJobSearchResponseResolvers<ContextType>;
  VirtualAccount?: VirtualAccountResolvers<ContextType>;
  WorkHistory?: WorkHistoryResolvers<ContextType>;
  WorkHistoryHighlight?: WorkHistoryHighlightResolvers<ContextType>;
  YourCandidate?: YourCandidateResolvers<ContextType>;
  YourCandidateEdge?: YourCandidateEdgeResolvers<ContextType>;
  YourCandidatesPayloadError?: YourCandidatesPayloadErrorResolvers<ContextType>;
  YourCandidatesResponse?: YourCandidatesResponseResolvers<ContextType>;
  YourCandidatesResults?: YourCandidatesResultsResolvers<ContextType>;
};

export type DirectiveResolvers<ContextType = any> = {
  burstRateLimit?: BurstRateLimitDirectiveResolver<any, any, ContextType>;
  cacheControl?: CacheControlDirectiveResolver<any, any, ContextType>;
  ipRateLimit?: IpRateLimitDirectiveResolver<any, any, ContextType>;
  sustainedRateLimit?: SustainedRateLimitDirectiveResolver<
    any,
    any,
    ContextType
  >;
};
